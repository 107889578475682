import React from 'react';
import { Button, Grid } from '@mui/material';
import { SelectOption } from '../../shared/model';
import AutocompleteFilter from '../../shared/form/AutocompleteFilter';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { MachinesContext } from '../contexts/MachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface AdminMachineFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  setFilterValues?: any;
  filterChoices?: any;
  applyFilter?: (filters: string) => void;
}

const AdminMachineFilterBar: React.FC<AdminMachineFilterBarProps> = (props) => {
  const { filters, filterValues, setFilterValues, applyFilter, filterChoices } = props;
  const [systemVersions, setSystemVersions] = React.useState<string[]>([]);
  const [indusoftProject, setIndusoftProject] = React.useState<string[]>([]);
  const [dbVersion, setDbVersion] = React.useState<string[]>([]);
  const [localServiceVersion, setLocalServiceVersion] = React.useState<string[]>([]);
  const [plcVersion, setPlcVersion] = React.useState<string[]>([]);

  const { fetchAdminMachines, filterOptions, filterOptionsLoading } =
    React.useContext(MachinesContext);

  const handleApplyFilters = async () => {
    const queryParams = new URLSearchParams();

    filterValues?.name ? queryParams.append('name', filterValues.name) : null;
    filterValues?.serial_number ? queryParams.append('serial_number', filterValues.serial_number) : null;
    filterValues?.company_name ? queryParams.append('company_name', filterValues.company_name) : null;
    filterValues?.connection_state ? queryParams.append('online_status', filterValues.connection_state) : null;
    filterValues?.subscription_active ? queryParams.append('subscription_active', filterValues.subscription_active) : null;
    filterValues?.last_online ? queryParams.append('last_online', filterValues.last_online) : null;
    systemVersions ? queryParams.append('system_version', systemVersions.join(',')) : null;
    indusoftProject ? queryParams.append('indusoft_project', indusoftProject.join(',')) : null;
    dbVersion ? queryParams.append('db_version', dbVersion.join(',')) : null;
    localServiceVersion ? queryParams.append('local_service_version', localServiceVersion.join(',')) : null;
    plcVersion ? queryParams.append('pac_version', plcVersion.join(',')) : null;

    const filterParam = `${queryParams.toString()}&`;
    fetchAdminMachines(filterParam);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.name}
          onChange={(value) => setFilterValues({ ...filterValues, name: value })}
          label="Machine Name"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.serial_number}
          onChange={(value) => setFilterValues({ ...filterValues, serial_number: value })}
          label="Serial Number"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.company_name}
          onChange={(value) => setFilterValues({ ...filterValues, company_name: value })}
          label="Company"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          value={filterValues?.connection_state}
          onChange={(value) => setFilterValues({ ...filterValues, connection_state: value })}
          label="Online Status"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Offline', value: 'Offline' },
            { label: 'Online', value: 'Online' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          value={filterValues?.subscription_active}
          onChange={(value) => setFilterValues({ ...filterValues, subscription_active: value })}
          label="Subscription Status"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          value={filterValues?.last_online}
          onChange={(value) => setFilterValues({ ...filterValues, last_online: value })}
          label="Last Online"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Last 10 minutes', value: '10' },
            { label: 'Last hour', value: '60' },
            { label: 'Last 24 hours', value: '1440' },
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <AutocompleteFilter
          label="System Version"
          loading={filterOptionsLoading}
          pluralLabel="System Versions"
          choices={
            filterOptions.system_version ? filterOptions.system_version.map((option) => String(option.value)) : []
          }
          setValue={(value) => setSystemVersions(value)}
          value={systemVersions}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <AutocompleteFilter
          label="Indusoft Project"
          loading={filterOptionsLoading}
          pluralLabel="Indusoft Projects"
          choices={
            filterOptions.indusoft_project ? filterOptions.indusoft_project.map((option) => String(option.value)) : []
          }
          setValue={(value) => setIndusoftProject(value)}
          value={indusoftProject}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <AutocompleteFilter
          label="DB Version"
          loading={filterOptionsLoading}
          pluralLabel="DB Versions"
          choices={filterOptions.db_version ? filterOptions.db_version.map((option) => String(option.value)) : []}
          setValue={(value) => setDbVersion(value)}
          value={dbVersion}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <AutocompleteFilter
          label="Local Service Version"
          loading={filterOptionsLoading}
          pluralLabel="Local Service Versions"
          choices={
            filterOptions.local_service_version
              ? filterOptions.local_service_version.map((option) => String(option.value))
              : []
          }
          setValue={(value) => setLocalServiceVersion(value)}
          value={localServiceVersion}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <AutocompleteFilter
          label="PLC Version"
          loading={filterOptionsLoading}
          pluralLabel="PLC Version"
          choices={filterOptions.pac_version ? filterOptions.pac_version.map((option) => String(option.value)) : []}
          setValue={(value) => setPlcVersion(value)}
          value={plcVersion}
        />
      </Grid>
    </Grid>
  );
};

export default AdminMachineFilterBar;
