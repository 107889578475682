/* eslint-disable react-hooks/exhaustive-deps, prefer-template */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Button, Card, Grid, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { IDataTableColumn } from 'react-data-table-component';
import { LevelSensorsAutoBulkContext } from '../contexts/LevelSensorsAutoBulkContext';
import { Machine } from '../../../modules/machinesAdminManagement/model';

interface LevelSensorBarGraphProps {
  title?: string;
  activeMachine?: Machine;
}

const LevelSensorBarGraph: React.FC<LevelSensorBarGraphProps> = (props) => {
  const { title, activeMachine } = props;
  const { levelSensors } = React.useContext(LevelSensorsAutoBulkContext);

  const [dataSet, setDataSet] = React.useState({ labels: [], datasets: [] });
  const [options, setOptions] = React.useState({});
  const [selectedLabel, setSelectedLabel] = React.useState('sensor');

  const handleLabelChange = (event: SelectChangeEvent<string>) => {
    setSelectedLabel(event.target.value as string);
  };

  React.useEffect(() => {
    const remaining = [];
    const current = [];
    const axisLabels = [];

    levelSensors?.forEach(
      (key) => {
        let labelValue;
        switch (selectedLabel) {
          case 'sensor':
            labelValue = key.level_name;
            break;
          case 'source':
            labelValue = key.source_name;
            break;
          case 'product':
            labelValue = key.products;
            break;
          case 'comment':
            labelValue = key.level_comment;
            break;
          default:
            labelValue = key.level_name;
            break;
        }
        axisLabels.push(labelValue);

        const amount = Math.max((key.level_amount_volume / key.level_calculated_capacity_volume) * 100, 0);

        current.push(Math.max(Math.round(100 - amount), 0));
        remaining.push(Math.round(amount));
      },
      [levelSensors, selectedLabel],
    );

    const barData = {
      maintainAspectRatio: false,
      labels: axisLabels,
      datasets: [
        {
          label: 'Current Amount',
          stack: 'Stack 0',
          data: remaining,
          backgroundColor: ['#3d5a80'],
          borderColor: ['#3d5a80'],
        },
        {
          label: 'Empty',
          stack: 'Stack 0',
          data: current,
          backgroundColor: ['#a5adb8'],
          borderColor: ['#a5adb8'],
        },
      ],
    };

    const chartOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleFont: {
            size: 15,
            weight: 'bold',
            family: 'Helvetica',
          },
          bodyFont: {
            size: 12,
            family: 'Helvetica',
          },
          padding: 10,
          cornerRadius: 4,
          borderColor: 'rgba(255, 255, 255, 0.8)',
          borderWidth: 2,
          callbacks: {
            label: (context) => {
              const index = context.dataIndex;
              const levelSensor = levelSensors[index];
              const tooltipItems = [];

              tooltipItems.push(`Product: ${levelSensor.products}`);
              tooltipItems.push(`Sensor: ${levelSensor.level_name}`);
              tooltipItems.push(`Source: ${levelSensor.source_name}`);
              tooltipItems.push(`Comment: ${levelSensor.level_comment}`);
              tooltipItems.push(`Fill Percent: ${levelSensor.sensor_fill_percent}`);
              tooltipItems.push(
                `Capacity: ${levelSensor.level_calculated_capacity_volume} ${levelSensor.level_amount_uom_volume}`,
              );
              tooltipItems.push(`Amount: ${levelSensor.level_amount_volume} ${levelSensor.level_amount_uom_volume}`);

              return tooltipItems;
            },
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      animation: {
        duration: 0,
      },
    };

    setOptions(chartOptions as any);
    setDataSet(barData);
  }, [levelSensors, selectedLabel]);

  return (
    levelSensors?.length > 1 && (
      <>
        <Card style={{ padding: '10px 15px', position: 'relative' }}>
          <Grid container alignItems="center" style={{ paddingBottom: '0px' }}>
            <Grid item xs style={{ textAlign: 'center' }}>
              <h6 style={{ display: 'inline-block', fontSize: '16px', fontWeight: 400 }}>{title}</h6>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" size="small" style={{ marginLeft: 'auto' }}>
                <InputLabel id="label-selector">X-Axis</InputLabel>
                <Select labelId="label-selector" value={selectedLabel} onChange={handleLabelChange} label="X-Axis">
                  <MenuItem value="sensor">Sensor</MenuItem>
                  <MenuItem value="source">Source</MenuItem>
                  <MenuItem value="product">Product</MenuItem>
                  <MenuItem value="comment">Comment</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ height: 350, maxHeight: 350 }}>
            <Bar height="300px" width="100%" data={dataSet} options={options} />
          </div>
        </Card>
      </>
    )
  );
};

export default LevelSensorBarGraph;
