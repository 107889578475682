/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TransportsAutoBulkDetail from './TransportsAutoBulkDetailPage';
import TransportsAutoBulkCreate from './TransportsAutoBulkCreate';
import TransportsAutoBulkList from './TransportsAutoBulkList';

const TransportsAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchTransportsAutoBulk, detailPage, creatingPage, transportsLoading, activeMachine } =
    React.useContext(TransportsAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchTransportsAutoBulk]);

  const autoBulkTransportPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return {
    updatePage: <TransportsAutoBulkDetail />,
    createPage: <TransportsAutoBulkCreate />,
    listPage: <TransportsAutoBulkList />,
  }[autoBulkTransportPage()];
};

export default TransportsAutoBulkPage;
