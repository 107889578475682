/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Grid, Button, Typography, Tabs, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../shared/tabs/TabPanel';
import { CompaniesContext } from '../contexts/CompaniesContext';
import CompanyDetails from '../components/CompanyDetails';
import AssignMachineAccess from '../components/AssignMachineAccess';
import AssignUserAccess from '../components/AssignUserAccess';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import URLTabs from '../../shared/tabs/URLTabs';

const CompanyDetailsPage: React.FC = () => {
  const { companyId } = useParams<Record<string, string>>();
  const { fetchCompany, company, companyLoading } = React.useContext(CompaniesContext);
  const { fetchAllMachineAccessOptions } = React.useContext(UserMachineAccessContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>();
  const [machines, setMachines] = React.useState<any[]>();

  const tabNames = ['company_details', 'assign_users', 'assign_machines'];
  const baseUrl = `/admin/company_management/${companyId}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  React.useEffect(() => {
    fetchCompany(companyId);
    fetchAllMachineAccessOptions();
  }, []);

  const breadcrumbLinks = [
    {
      href: '/admin/company_management',
      text: 'Company Management',
    },
    {
      href: `/admin/company_management/${companyId}`,
      text: `${company.name} (ID: ${companyId})`,
    },
  ];

  return companyLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Company...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={breadcrumbLinks} />
          <br />
        </Grid>
      </Grid>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="Company Details" />
            <Tab style={{ textTransform: 'capitalize' }} label="Assign Users" />
            <Tab style={{ textTransform: 'capitalize' }} label="Assign Machines" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <CompanyDetails company={company} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AssignUserAccess company={company} users={users} setUsers={setUsers} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <AssignMachineAccess company={company} machines={machines} setMachines={setMachines} />
        </TabPanel>
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
