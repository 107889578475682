/* eslint-disable import/prefer-default-export */
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'seed_source',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Location',
    selector: 'seed_source_location',
    sortable: true,
    grow: 0.8,
  },
  {
    name: 'Amount',
    selector: 'seed_source_amount',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.seed_source_amount) || 0;
      const valB = parseFloat(b.seed_source_amount) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
  {
    name: 'Capacity',
    selector: 'seed_source_capacity',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.seed_source_capacity) || 0;
      const valB = parseFloat(b.seed_source_capacity) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
  {
    name: 'Crop',
    selector: 'crop_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Seed Variety',
    selector: 'variety',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Seed Lot',
    selector: 'seed_lot_data',
    sortable: true,
    grow: 1.0,
  },
  {
    name: 'Seed Size',
    selector: 'seed_cnt_per_scale_uow',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.seed_cnt_per_scale_uow) || 0;
      const valB = parseFloat(b.seed_cnt_per_scale_uow) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
  {
    name: 'CNT Unit',
    selector: 'unit_size_cnt',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.unit_size_cnt) || 0;
      const valB = parseFloat(b.unit_size_cnt) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
  {
    name: 'WT Unit',
    selector: 'unit_size_wt',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.unit_size_wt) || 0;
      const valB = parseFloat(b.unit_size_wt) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
];
