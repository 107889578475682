import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { UserFormValues } from '../model';

interface MachineOption {
  machine_name_with_serial: string;
  serial_number: string;
}

interface UsersContextInterface {
  fetchUsers?: (filterParams?: string) => Promise<void>;
  users?: any[];
  usersLoading?: boolean;

  fetchUser?: (userId: string) => Promise<void>;
  user?: any;
  userLoading?: boolean;
  userSubmitting?: boolean;
  userArchiving?: boolean;

  createUser?: (formValues: UserFormValues) => Promise<void>;
  archiveUser?: (userId: string) => Promise<void>;
  formErrors?: any;

  fetchUserMachinesByCompany?: (company_id: string) => Promise<void>;
  userMachinesByCompany?: any[];
  userMachinesByCompanyLoading?: boolean;

  fetchUserPermissionGroups?: () => Promise<void>;
  userPermissionGroups?: any[];
  userPermissionGroupsLoading?: boolean;

  fetchAdminPermissionGroups?: () => Promise<void>;
  adminPermissionGroups?: any[];
  adminPermissionGroupsLoading?: boolean;

  updateUser?: (userId: string, formValues: UserFormValues, onSuccess?: () => void) => Promise<void>;
  verifyUser?: (userId: string, formValues: UserFormValues, onSuccess?: () => void) => Promise<void>;
}

const UsersContext = React.createContext<UsersContextInterface>({});

const AdminUsersContextConsumer = UsersContext.Consumer;
const AdminUsersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [userMachinesByCompany, setUserMachinesByCompany] = React.useState<MachineOption[]>([]);
  const [userMachinesByCompanyLoading, setUserMachinesByCompanyLoading] = React.useState(false);

  const [userPermissionGroups, setUserPermissionGroups] = React.useState([]);
  const [userPermissionGroupsLoading, setUserPermissionGroupsLoading] = React.useState(false);

  const [adminPermissionGroups, setAdminPermissionGroups] = React.useState([]);
  const [adminPermissionGroupsLoading, setAdminPermissionGroupsLoading] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const [usersLoading, setUsersLoading] = React.useState(true);

  const [user, setUser] = React.useState({});
  const [userLoading, setUserLoading] = React.useState(true);
  const [userSubmitting, setUserSubmitting] = React.useState(false);
  const [userArchiving, setUserArchiving] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const fetchUsers = async (filterParams = '') => {
    setUsersLoading(true);

    axios
      .get<string, any>(`api/admin/users/?${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUsersLoading(false);
        setUsers(response.data);
      });
  };

  const fetchUser = async (userId: string) => {
    setUserLoading(true);

    axios
      .get<string, any>(`api/admin/users/${userId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserLoading(false);
        setUser(response.data);
      });
  };

  const verifyUser = async (userId: string, formValues: UserFormValues, onSuccess?: () => void) => {
    setUserSubmitting(true);

    return new Promise<void>((resolve, reject) => {
      axios
        .post<any, any>(`api/admin/users/${userId}/verify/`, formValues, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          setUserSubmitting(false);
          if (response.data.success) {
            if (onSuccess) {
              onSuccess();
            }
            window.location.href = `/admin/user_management/${userId}`;
            resolve();
          } else {
            const errors = response.data.result;
            setFormErrors({
              full_name: errors.full_name,
              email_address: errors.email_address,
              company: errors.company,
            });
            reject(errors);
          }
        })
        .catch((error) => {
          setUserSubmitting(false);
          reject(error);
        });
    });
  };

  const updateUser = async (userId: string, formValues: UserFormValues, onSuccess?: () => void): Promise<void> => {
    setUserSubmitting(true);

    return new Promise<void>((resolve, reject) => {
      axios
        .put<any, any>(
          `api/admin/users/${userId}/`,
          { ...formValues, name: formValues.email_address },
          { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
        )
        .then((response) => {
          setUserSubmitting(false);
          if (response.data.success) {
            if (onSuccess) {
              onSuccess();
            }
            window.location.href = `/admin/user_management/${userId}`;
            resolve();
          } else {
            const errors = response.data.result;
            setFormErrors({
              full_name: errors.full_name,
              email_address: errors.email_address,
              company: errors.company,
            });
            reject(errors);
          }
        })
        .catch((error) => {
          setUserSubmitting(false);
          reject(error);
        });
    });
  };

  const createUser = async (formValues: UserFormValues): Promise<void> => {
    setUserSubmitting(true);

    return new Promise<void>((resolve, reject) => {
      axios
        .post<any, any>(
          `api/admin/users/`,
          { ...formValues, name: formValues.email_address, pwtype: 'md5', status: '1' },
          { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
        )
        .then((response) => {
          setUserLoading(false);
          setUserSubmitting(false);
          if (response.data.success) {
            history.push(`/admin/user_management/${response.data.result.user_id}`);
            setFormErrors({});
            resolve();
          } else {
            const errors = response.data.result;
            setFormErrors({
              name: errors.name,
              full_name: errors.full_name,
              email_address: errors.email_address,
              company: errors.company,
            });
            reject(errors);
          }
        })
        .catch((error) => {
          setUserLoading(false);
          setUserSubmitting(false);
          reject(error);
        });
    });
  };

  const archiveUser = async (userId: string) => {
    setUserArchiving(true);

    axios
      .delete<any, any>(`api/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserArchiving(false);
        window.location.href = `/admin/user_management/`;
      })
      .catch(() => {
        setUserLoading(false);
        setUserArchiving(false);
      });
  };

  // Fetch user permission groups
  const fetchUserPermissionGroups = async (filterParams?: string) => {
    setUserPermissionGroupsLoading(true);
    axios
      .get<string, any>(`api/user/permission_group_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserPermissionGroups(response.data);
        setUserPermissionGroupsLoading(false);
      });
  };

  // Fetch admin permission groups
  const fetchAdminPermissionGroups = async (filterParams?: string) => {
    setAdminPermissionGroupsLoading(true);
    axios
      .get<string, any>(`api/admin/permission_group_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setAdminPermissionGroups(response.data);
        setAdminPermissionGroupsLoading(false);
      });
  };

  // Fetch user machines by company
  const fetchUserMachinesByCompany = async (company_id: string) => {
    setUserMachinesByCompanyLoading(true);
    axios
      .get(`api/admin/machines/?company_id=${company_id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachinesByCompany(response.data);
        setUserMachinesByCompanyLoading(false);
      })
      .catch((error) => {
        setUserMachinesByCompanyLoading(false);
      });
  };

  return (
    <UsersContext.Provider
      value={{
        fetchUsers,
        users,
        usersLoading,

        fetchUser,
        user,
        userLoading,
        userSubmitting,
        userArchiving,

        updateUser,
        verifyUser,
        createUser,
        archiveUser,
        formErrors,

        fetchUserMachinesByCompany,
        userMachinesByCompany,
        userMachinesByCompanyLoading,

        fetchUserPermissionGroups,
        userPermissionGroups,
        userPermissionGroupsLoading,

        fetchAdminPermissionGroups,
        adminPermissionGroups,
        adminPermissionGroupsLoading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { AdminUsersContextProvider, AdminUsersContextConsumer, UsersContext };
