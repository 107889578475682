import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { APPLICATION_URL } from '../../utils/env';
import ApplicationScreenshot from '../../../assets/application-screenshot.png';
import Footer from '../../navigation/components/Footer';

const UnverifiedEmailPage: React.FC = () => {
  const history = useHistory();
  const { logout } = useAuth0();

  return (
    <>
      <Grid container style={{ marginTop: 80 }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ alignItems: 'center', display: 'flex', height: '75vh', textAlign: 'center' }}>
                <div style={{ margin: 'auto' }}>
                  <Typography variant="h1" component="h1">
                    You must verify your email address in order to access AgConnex.
                  </Typography>
                  <br />
                  <Typography>
                    Please follow the link in your original sign-up email or use the forgot password link on the login
                    page.
                  </Typography>
                  <Typography>
                    If you continue to have issues, please reach out to agconnex@ksiedge.com for assistance with
                    accessing Agconnex.
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => logout({ returnTo: `${APPLICATION_URL}/login` } as any)}
                  >
                    Back to Login
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default UnverifiedEmailPage;
