import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { MachinesContext } from '../contexts/MachinesContext';
import { Machine } from '../model';

const downloadColumns = (user: Record<string, string>, machine: Machine): IDataTableColumn[] => {
  return [
    {
      name: 'Last Modified/Created',
      selector: 'last_modified',
      sortable: true,
      cell: (row) => `${row.last_modified_local}`,
    },
    {
      name: 'File Name',
      selector: 'name_without_prefix',
      sortable: true,
    },
    {
      name: 'Size (MB)',
      selector: 'size_in_mb',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.size_in_mb) || 0;
        const valB = parseFloat(b.size_in_mb) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Download',
      sortable: false,
      selector: (row: Record<string, string>): unknown => {
        return (
          <MenuItem
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_ENDPOINT}/api/admin/machines/downloads/download_database/?name=${row.name_without_prefix}&email=${user?.email}&machine_id=${machine.machine_id}&auth_token=52c64aba5dc425a0b5f436195e34e9c003d0f5f81254f6c85c511041cdac1`,
                '_blank',
                'noreferrer',
              )
            }
          >
            <ListItemIcon>
              <DownloadIcon fontSize="medium" color="secondary" />
            </ListItemIcon>
          </MenuItem>
        );
      },
    },
  ];
};

export default downloadColumns;
