import React from 'react';
import { format } from 'date-fns';
import axios from '../../../modules/utils/axios.utils';
import { AutoBulkTransaction } from '../../transactions/model';

interface AutoBulkRealTimeActivityContextInterface {
  fetchTransactions?: (filterParams?: string) => Promise<void>;
  transactions?: AutoBulkTransaction[];
  transactionsLoading?: boolean;
  errorMessage?: string;
}

const AutoBulkRealTimeActivityContext = React.createContext<AutoBulkRealTimeActivityContextInterface>({});

const AutoBulkRealTimeActivityContextConsumer = AutoBulkRealTimeActivityContext.Consumer;
const AutoBulkRealTimeActivityContextProvider: React.FC = ({ children }) => {
  const [transactions, setTransactions] = React.useState<AutoBulkTransaction[]>([]);
  const [transactionsLoading, setTransactionsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  /**
   * GET Analytics/AB/Transactions API.
   * This can be all of them or filtered on machines
   * @param filterHeaders (optional)
   */
  const fetchTransactions = async (filterParams?: string) => {
    setTransactionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');

    await axios
      .get(
        `api/autobulk/analytics/transactions/?work_order_start_date_after=01/01/1900&work_order_start_date_before=12/31/2100&machine=${cachedMachines.join()}&${filterParams}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        },
      )
      .then((response: any) => {
        setTransactions(response.data.results);
        setTransactionsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);

        setTransactions([]);
        setTransactionsLoading(false);
      });

    setTransactionsLoading(false);
  };

  return (
    <AutoBulkRealTimeActivityContext.Provider
      value={{
        fetchTransactions,
        transactions,
        transactionsLoading,
        errorMessage,
      }}
    >
      {children}
    </AutoBulkRealTimeActivityContext.Provider>
  );
};

export {
  AutoBulkRealTimeActivityContextProvider,
  AutoBulkRealTimeActivityContextConsumer,
  AutoBulkRealTimeActivityContext,
};
