/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../dataTable/DataTable/DataTable';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';
import columns from '../constants/columns';
import { PermissionGroupsContext } from '../contexts/PermissionGroupsContext';
import { PermissionGroup } from '../model';

const PermissionGroupsListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchPermissionGroups, permissionGroups, permissionGroupsLoading } =
    React.useContext(PermissionGroupsContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchPermissionGroups();
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Permission Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={permissionGroups}
            defaultSortAsc={false}
            striped
            onRowClicked={(row: PermissionGroup) =>
              history.push(`/admin/permission_management/${row.permission_group_id}/edit`)
            }
            highlightOnHover
            pointerOnHover
            progressPending={permissionGroupsLoading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default PermissionGroupsListPage;
