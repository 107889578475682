/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import DriverAutoBulkForm from '../components/DriversAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const DriversAutoBulkCreate: React.FunctionComponent = () => {
  const { setCreatingPage, activeMachine, fetchDriverOptions } = React.useContext(DriversAutoBulkContext);

  React.useEffect(() => {
    if (activeMachine?.value !== 'Select A Machine') {
      fetchDriverOptions(activeMachine?.value);
    }
  }, [activeMachine]);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: 'AutoBulk Drivers',
    },
    {
      onClick: () => null,
      text: `Create Driver (Machine: ${activeMachine?.value || 'Unknown'})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Driver
      </Typography>
      <br />
      <DriverAutoBulkForm driver={{}} onSuccess={() => setCreatingPage(false)} />
    </>
  );
};

export default DriversAutoBulkCreate;
