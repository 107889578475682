/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { CustomersAutoTreatContext } from '../contexts/CustomersAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import CustomersAutoTreatDetail from '../components/CustomersAutoTreatDetail';
import IntegrationsList from '../components/IntegrationsList';
import { CustomerIntegrationsAutoTreatContext } from '../contexts/CustomerIntegrationsAutoTreatContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const CustomersAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveCustomerAutoTreat, activeCustomerAutoTreat, activeMachine } =
    React.useContext(CustomersAutoTreatContext);

  const { fetchCustomerIntegrationsAutoTreat } = React.useContext(CustomerIntegrationsAutoTreatContext);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/customers/detail/${activeCustomerAutoTreat?.customer_id}`,
  );

  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const getIntegrations = async () => {
      if (!dataLoaded) {
        setDataLoaded(true);
        fetchCustomerIntegrationsAutoTreat(activeMachine?.value, activeCustomerAutoTreat.customer_id);
      }
    };

    getIntegrations();
  }, []);

  const handleBack = () => {
    setDetailPage(false);
    setActiveCustomerAutoTreat(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeCustomerAutoTreat?.operation}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Customer List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <CustomersAutoTreatDetail onBack={handleBack} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <IntegrationsList activeMachine={activeMachine} customer={activeCustomerAutoTreat} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default CustomersAutoTreatDetailPage;
