import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TransportsAutoBulkContextInterface {
  fetchCachedTransportsAutoBulk?: (machineSerial: string) => Promise<void>;

  fetchCachedTransportOptionsAutoBulk?: (machineSerial: string) => Promise<void>;
  transportOptions?: any;

  fetchTransportsAutoBulk?: (machineSerial: string, genericFilter: string, onSuccess?: () => void) => Promise<void>;
  transportsRefreshedAt?: string;
  transports?: any[];
  transportsLoading?: boolean;
  transportSubmitting?: boolean;

  fetchTransportOptionsAutoBulk?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;

  createTransportAutoBulk?: (formValues: any, onSuccess?: () => void) => Promise<void>;

  updateTransportAutoBulk?: (formValues: any) => Promise<void>;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: any;
  activeMachine?: Record<string, string>;

  setActiveTransportAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeTransportAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  transportOptionsLoading?: any;
}

const TransportsAutoBulkContext = React.createContext<TransportsAutoBulkContextInterface>({});

const TransportsAutoBulkContextConsumer = TransportsAutoBulkContext.Consumer;
const TransportsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [transports, setTransportsAutoBulk] = React.useState([]);
  const [transportsLoading, setTransportsAutoBulkLoading] = React.useState(true);
  const [transportsRefreshedAt, setTransportsAutoBulkRefreshedAt] = React.useState<string>();
  const [activeTransportAutoBulk, setActiveTransportAutoBulk] = React.useState({});
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('transportAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('transportAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('All');
  const [transportOptions, setTransportOptions] = React.useState({
    transportCompanyOptions: [],
    transportRFIDOptions: [],
  });
  const [transportOptionsLoading, setTransportOptionsLoading] = React.useState(false);
  const [transportSubmitting, setTransportsubmitting] = React.useState(false);

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setTransportsAutoBulkLoading(false);
  }, [activeMachine]);

  const fetchCachedTransportsAutoBulk = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');

    setTransportsAutoBulkLoading(true);
    setTransportsAutoBulkRefreshedAt(null);

    axios
      .get<string, any>(`api/autobulk/onprem/transports/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setTransportsAutoBulkLoading(false);
        setTransportsAutoBulk(response.data.results);
        setTransportsAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setTransportsAutoBulkLoading(false);
        setTransportsAutoBulk([]);
        setTransportsAutoBulkRefreshedAt(null);
      });
  };

  const fetchCachedTransportOptionsAutoBulk = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .get<string, any>(`api/autobulk/onprem/transports_options/${machineSerial}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setTransportOptions({
          ...transportOptions,
          transportCompanyOptions: response.data.results.filter((choice) => choice.category === 'transport_company'),
          transportRFIDOptions: response.data.results.filter((choice) => choice.category === 'rfid'),
        });
      });
  };

  const fetchTransportOptionsAutoBulk = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/transports_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/transports_options/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setTransportOptions({
                ...transportOptions,
                transportCompanyOptions: response.data.results.filter(
                  (choice) => choice.category === 'transport_company',
                ),
                transportRFIDOptions: response.data.results.filter((choice) => choice.category === 'rfid'),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setTransportOptions({
                  ...transportOptions,
                  transportCompanyOptions: [],
                  transportRFIDOptions: [],
                });
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchTransportsAutoBulk = async (machineSerial: string, genericFilter: string, onSuccess?: () => void) => {
    const accessToken = sessionStorage.getItem('accessToken');

    setTransportsAutoBulkLoading(true);
    setTransportsAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/transports/?serial-number=${machineSerial}&generic-filter=${genericFilter}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/transports/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setTransportsAutoBulkLoading(false);
              setTransportsAutoBulk(response.data.results);
              setTransportsAutoBulkRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setTransportsAutoBulkLoading(false);
                showErrorSnackBar('Unable to load sources');
                setTransportsAutoBulk([]);
                setTransportsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createTransportAutoBulk = async (formValues: any, onSuccess?: () => void) => {
    setTransportsubmitting(true);
    setTransportsAutoBulkLoading(true);
    setTransportsAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .post<string, any>(
        `api/autobulk/onprem/transports/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/transports/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setTransportsAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchTransportOptionsAutoBulk(activeMachine.value);
              fetchTransportsAutoBulk(activeMachine.value, '');
              setTransportsubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setTransportsAutoBulkLoading(false);
                setTransportsubmitting(false);
                showErrorSnackBar('No response from machine');
                setTransportsAutoBulk([]);
                setTransportsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateTransportAutoBulk = async (formValues: any) => {
    setTransportsubmitting(true);
    setTransportsAutoBulkLoading(true);
    setTransportsAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .put<string, any>(
        `api/autobulk/onprem/transports/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/transports/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setTransportsAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchTransportOptionsAutoBulk(activeMachine.value);
              fetchTransportsAutoBulk(activeMachine.value, '');
              setTransportsubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setTransportsAutoBulkLoading(false);
                setTransportsubmitting(false);
                showErrorSnackBar('No response from machine');
                setTransportsAutoBulk([]);
                setTransportsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  return (
    <TransportsAutoBulkContext.Provider
      value={{
        fetchCachedTransportsAutoBulk,
        fetchTransportsAutoBulk,
        transports,
        transportsRefreshedAt,
        transportsLoading,
        transportSubmitting,

        createTransportAutoBulk,

        updateTransportAutoBulk,

        fetchTransportOptionsAutoBulk,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveTransportAutoBulk,
        activeTransportAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,

        handleStatusFilterChange,
        statusFilter,

        fetchCachedTransportOptionsAutoBulk,
        transportOptions,
        transportOptionsLoading,
      }}
    >
      {children}
    </TransportsAutoBulkContext.Provider>
  );
};

export { TransportsAutoBulkContextProvider, TransportsAutoBulkContextConsumer, TransportsAutoBulkContext };
