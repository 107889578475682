import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ToolsMajor,
  DataVisualizationMajor,
  InventoryMajor,
  CustomersMajor,
  DigitalMediaReceiverMajor,
} from '@shopify/polaris-icons';

export const allAccountManagementRoutes = {
  label: 'Account Management',
  url: '/account_management/my_machines',
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/account_management/company_machines',
      disabled: false,
      label: 'Company Machines',
    },
    {
      url: '/account_management/my_machines',
      disabled: false,
      label: 'My Machines',
    },
    {
      url: '/account_management/users',
      disabled: false,
      label: 'Users',
    },
    {
      url: '/account_management/company',
      disabled: false,
      label: 'Company',
    },
  ],
};

export const allAccountManagementAndNotificationRoutes = {
  label: 'Account Management',
  url: '/account_management/my_machines',
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/account_management/company_machines',
      disabled: false,
      label: 'Company Machines',
    },
    {
      url: '/account_management/my_machines',
      disabled: false,
      label: 'My Machines',
    },
    {
      url: '/account_management/users',
      disabled: false,
      label: 'Users',
    },
    {
      url: '/account_management/company',
      disabled: false,
      label: 'Company',
    },
    {
      url: '/account_management/reports',
      disabled: false,
      label: 'Reporting',
    },
    {
      url: '/machine_requests',
      disabled: false,
      label: 'Machine Requests',
    },
  ],
};

export const machineAccountManagementRoutes = {
  label: 'Account Management',
  url: '/account_management/my_machines',
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/account_management/my_machines',
      disabled: false,
      label: 'My Machines',
    },
  ],
};
