/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoBulkTransactionFilterBar from '../../../modules/shared/form/AutoBulkTransactionFilterBar';
import filters from '../constants/productFilters';
import columns from '../constants/productColumns';
import { AutoBulkFiltersContext } from '../contexts/AutoBulkFiltersContext';
import { ProductsContext } from '../contexts/ProductsContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

const ProductSummary: React.FunctionComponent = () => {
  const { errorMessage, fetchProducts, products, productsLoading, productMetrics, exportTransactions } =
    React.useContext(ProductsContext);
  const { fetchAutoBulkMachines, autoBulkMachines } = React.useContext(MachinesContext);
  const { filterOptions, fetchFilterOptions, convertFilterValuesToUrlParams } =
    React.useContext(AutoBulkFiltersContext);

  const [activePage, setActivePage] = React.useState(1);
  const [combineMachineTotals, setCombineMachineTotals] = React.useState(true);
  const [displayUOM, setDisplayUOM] = React.useState('lbs');
  const [filterType] = React.useState('AutoBulk Product Summary');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [perPage, setPerPage] = React.useState<number>(10);

  React.useEffect(() => {
    fetchAutoBulkMachines();
    fetchProducts(combineMachineTotals);
    fetchFilterOptions();
  }, []);

  const handleFilter = (filterParams: string, combined: boolean) => {
    fetchProducts(combined, filterParams);
    fetchFilterOptions(filterParams);
  };

  const handlePageChange = (page: number) => {
    const machines = JSON.parse(localStorage.getItem('autoBulkMachines'));
    const startDate = localStorage.getItem('startDate');
    const endDate = localStorage.getItem('endDate');

    const filterHeaders = convertFilterValuesToUrlParams(
      machines,
      startDate,
      endDate,
      filterValues as Record<string, any[]>,
    );
    fetchProducts(combineMachineTotals, `${filterHeaders}&page=${page}`);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Product Summary
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoBulkTransactionFilterBar
            applyFilter={handleFilter}
            combineMachineTotals={combineMachineTotals}
            displayUOM={displayUOM}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            localStorageMachineKey="AutoBulkmachines"
            setCombineMachineTotals={setCombineMachineTotals}
            setDisplayUOM={setDisplayUOM}
            setFilterValues={setFilterValues}
            showCombineCheckbox
            exportTransactions={exportTransactions}
            includeProducts={null}
            setIncludeProducts={null}
            transactionMetrics={null}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />
      <Card style={{ padding: 30 }}>
        <DataTable
          noHeader
          columns={columns(combineMachineTotals, displayUOM)}
          data={products}
          progressPending={productsLoading}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationTotalRows={productMetrics?.total_records}
          paginationRowsPerPageOptions={[10, 25, 50]}
          tableSums={
            null /* {
            machine_serial_number: 'Totals:',
            target_amt_total_lbs: `${productMetrics?.target_amt_total_lbs} lbs`,
            actual_amt_total_lbs: `${productMetrics?.actual_amt_total_lbs} lbs`,
            target_amt_total_tons: `${productMetrics?.target_amt_total_tons} tons`,
            actual_amt_total_tons: `${productMetrics?.actual_amt_total_tons} tons`,
            target_amt_total_gallons: `${productMetrics?.target_amt_total_gallons} gallons`,
            actual_amt_total_gallons: `${productMetrics?.actual_amt_total_gallons} gallons`,
            target_amt_total_fl_oz: `${productMetrics?.target_amt_total_fl_oz} fl. oz.`,
            actual_amt_total_fl_oz: `${productMetrics?.actual_amt_total_fl_oz} fl. oz.`,
            accuracy_with_uom: `${productMetrics?.accuracy_with_uom}`,
            treatment_product_name: `Count: ${products?.length}`,
          } */
          }
        />
      </Card>
      <br />
    </>
  );
};

export default ProductSummary;
