import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const transactionTreatmentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Treatment Product',
    selector: 'liquid_name',
    sortable: true,
  },
  {
    name: 'Target Amount',
    selector: 'target_amt_with_uom',
    sortable: true,
  },
  {
    name: 'Actual Amount',
    selector: 'actual_amt_with_uom',
    sortable: false,
  },
  {
    name: 'Treatment Accuracy',
    selector: 'amt_accuracy',
    sortable: true,
  },
];

export default transactionTreatmentColumns;
