import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DataTable from '../../dataTable/DataTable/DataTable';
import { UserMachinesContext } from '../../machinesAccountManagement/contexts/UserMachinesContext';

interface UserCompanyMachinesProps {
  company: any;
}

const UserCompanyMachines: React.FC<UserCompanyMachinesProps> = (props) => {
  const { company } = props;
  const history = useHistory();
  const { fetchUserMachines, accountMachines, machinesLoading } = React.useContext(UserMachinesContext);

  React.useEffect(() => {
    fetchUserMachines();
  }, [])

  const columns = [
    {
      name: 'Machine Name',
      selector: 'machine_name',
      sortable: true,
    },
    {
      name: 'Serial Number',
      selector: 'serial_number',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.serial_number) || 0;
        const valB = parseFloat(b.serial_number) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Expiration Date',
      selector: 'subscription_expiration_date',
      sortable: true,
      grow: 0.5,
      cell: ({ subscription_expiration_date, subscription_active }): React.ReactNode => <p style={{ color: subscription_active ? "green" : "red" }}>{subscription_expiration_date}</p>,
    },
    {
      name: 'Actions',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: (row): React.ReactNode => (
        <div>
          <a href={`/account_management/company_machines/${row.serial_number}`} target="_blank" rel="noreferrer">
            Edit
          </a>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Company Machines
            </Typography>
          </Grid>
        </Grid>
        <DataTable
          noHeader
          columns={columns}
          data={accountMachines}
          defaultSortAsc={false}
          onRowClicked={(row) => history.push(`/account_management/company_machines/${row.serial_number}`)}
          striped
          highlightOnHover
          progressPending={machinesLoading}
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  );
};

export default UserCompanyMachines;
