import React from 'react';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

interface SideBarInformationFieldProps {
  label: string;
  value?: string | number | null;
}

const SideBarInformationField: React.FunctionComponent<SideBarInformationFieldProps> = (props) => {
  const { label, value } = props;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Typography variant="subtitle1" component="h6" style={{ whiteSpace: 'break-spaces', marginTop: '-10px' }}> */}
        <Typography variant="subtitle1" component="h6" style={{ whiteSpace: 'break-spaces', marginTop: '-10px' }}>
          {label}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          style={{ whiteSpace: 'break-spaces', marginLeft: '10px', marginTop: '-15px' }}
        >
          {/* <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', marginLeft: '10px', marginTop: '-10' }}> */}
          {value}
        </Typography>
      </div>
      <Divider flexItem style={{ backgroundColor: '#fff', height: '1px', width: '100%', marginBottom: '6px' }} />
    </>
  );
};

export default SideBarInformationField;
