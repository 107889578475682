import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const sourceColumns = (uom = 'lbs'): IDataTableColumn<any>[] => {
  const columns = [
    {
      name: 'Machine',
      selector: 'machine_name_with_serial',
      sortable: true,
    },
    {
      name: 'Source Name',
      selector: 'source_name',
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: 'product_name',
      sortable: true,
    },
    {
      name: 'Target Product Delivered (lbs)',
      selector: 'target_amt_total_lbs',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt) || 0;
        const valB = parseFloat(b.product_delivered_target_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'lbs',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt || 0} ${row.product_delivered_lft_uom}`}</p>,
    },
    {
      name: 'Actual Product Delivered (lbs)',
      selector: 'actual_amt_total_lbs',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'lbs',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt || 0} ${row.product_delivered_lft_uom}`}</p>,
    },
    {
      name: 'Target Product Delivered (tons)',
      selector: 'target_amt_total_tons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.target_amt_total_tons) || 0;
        const valB = parseFloat(b.target_amt_total_tons) || 0;
        return valA - valB;
      },
      omit: uom !== 'tons',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt / 2000} ${row.product_delivered_lft_uom}`}</p>,
    },
    {
      name: 'Actual Product Delivered (tons)',
      selector: 'actual_amt_total_tons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'tons',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt / 2000} ${row.product_delivered_lft_uom}`}</p>,
    },
    {
      name: 'Target Product Delivered (fl oz)',
      selector: 'target_amt_total_fl_oz',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt) || 0;
        const valB = parseFloat(b.product_delivered_target_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'fl oz',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt * 128} ${row.product_delivered_amt_uom}`}</p>,
    },
    {
      name: 'Actual Product Delivered (fl oz)',
      selector: 'actual_amt_total_fl_oz',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'fl oz',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt * 128} ${row.product_delivered_amt_uom}`}</p>,
    },
    {
      name: 'Target Product Delivered (gallons)',
      selector: 'target_amt_total_gallons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt) || 0;
        const valB = parseFloat(b.product_delivered_target_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'gallons',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt} ${row.product_delivered_amt_uom}`}</p>,
    },
    {
      name: 'Actual Product Delivered (gallons)',
      selector: 'actual_amt_total_gallons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'gallons',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt} ${row.product_delivered_amt_uom}`}</p>,
    },
    {
      name: 'Accuracy',
      selector: 'product_delivered_accuracy',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_accuracy) || 0;
        const valB = parseFloat(b.product_delivered_accuracy) || 0;
        return valA - valB;
      },
    },
  ];

  return columns;
};

export default sourceColumns;
