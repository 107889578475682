import React from 'react';
import axios from '../../utils/axios.utils';
import { Machine } from '../../machinesAdminManagement/model';

interface MachinesContextInterface {
  fetchUserMachines?: (filterParams?: string) => Promise<void>;
  fetchAllCompanyMachines?: (filterParams?: string) => Promise<void>;
  fetchUserAndCompanyMachines?: (filterParams?: string) => Promise<void>;
  accountMachines?: any[];
  machinesLoading?: boolean;

  updateMachine?: (machineId: string, formValues: any, onSuccess?: any) => Promise<void>;
  machineSubmitting?: boolean;

  fetchMachine?: (machineSerial: string) => Promise<void>;
  fetchCompanyMachine?: (machineSerial: string) => Promise<void>;
  machine?: Machine;
  machineLoading?: boolean;
}

const UserMachinesContext = React.createContext<MachinesContextInterface>({});

const UserMachinesContextConsumer = UserMachinesContext.Consumer;
const UserMachinesContextProvider: React.FC = ({ children }) => {
  const [autoTreatMachines, setAutoTreatMachines] = React.useState([]);
  const [autoBulkMachines, setAutoBulkMachines] = React.useState([]);
  const [accountMachines, setUserMachines] = React.useState([]);
  const [machinesLoading, setMachinesLoading] = React.useState(true);
  const [machineSubmitting, setMachineSubmitting] = React.useState(false);

  const [machine, setMachine] = React.useState({});
  const [machineLoading, setMachineLoading] = React.useState(true);

  const updateMachine = async (machineId: string, formValues: any, onSuccess?: any) => {
    setMachineSubmitting(true);

    axios
      .put<any, any>(
        `api/user/machines/${machineId}/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setMachineSubmitting(false);
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  const fetchUserAndCompanyMachines = async (filterParams = '') => {
    setMachinesLoading(true);

    axios
      .get<string, any>(`api/user/machines/?association=user_and_company&${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachines(response.data);
        setMachinesLoading(false);
      });
  };

  const fetchUserMachines = async (filterParams = '') => {
    setMachinesLoading(true);

    axios
      .get<string, any>(`api/user/machines/?association=user&${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachines(response.data);
        setMachinesLoading(false);
      });
  };

  const fetchMachine = async (machineSerial: string) => {
    setMachineLoading(true);

    axios
      .get<string, any>(`api/user/machines/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineLoading(false);
        setMachine(response.data);
      });
  };

  const fetchAllCompanyMachines = async (filterParams = '') => {
    setMachinesLoading(true);

    axios
      .get<string, any>(`api/user/machines/?association=company&${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachines(response.data);
        setMachinesLoading(false);
      });
  };

  const fetchCompanyMachine = async (machineSerial: string) => {
    setMachineLoading(true);

    axios
      .get<string, any>(`api/user/machines/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineLoading(false);

        setMachine(response.data);
      });
  };

  return (
    <UserMachinesContext.Provider
      value={{
        fetchUserMachines,
        fetchAllCompanyMachines,
        fetchUserAndCompanyMachines,
        accountMachines,
        machinesLoading,

        updateMachine,
        machineSubmitting,

        fetchMachine,
        fetchCompanyMachine,
        machine,
        machineLoading,
      }}
    >
      {children}
    </UserMachinesContext.Provider>
  );
};

export { UserMachinesContextProvider, UserMachinesContextConsumer, UserMachinesContext };
