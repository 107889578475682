import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@mui/styles';
import { AppBar, Menu, MenuItem, Link } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../../../assets/ksi-logo.png';
import { APPLICATION_URL } from '../../utils/env';

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: '8px',
  },
  inputRoot: {
    color: 'inherit',
    border: '1px solid #ccc',
  },
  sectionDesktop: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    '@media (min-width: 960px)': {
      display: 'none',
    },
  },
}));

const TopNavigation: React.FunctionComponent = () => {
  const { user, logout } = useAuth0();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [navigationElement, setNavigationElement] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setNavigationElement(null);
  };

  const handleLogOut = () => {
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('adminRole');
    logout({ returnTo: `${APPLICATION_URL}/login` } as any);
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link style={{ color: '#000' }} href="/">
          My Account
        </Link>
      </MenuItem>
      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <img alt="logo" src={Logo} style={{ width: '60px' }} />
          <Typography style={{ lineHeight: '65px', marginLeft: 10, color: 'black', fontSize: 18 }}>AgConnex</Typography>
          <div className={classes.grow} />
          {window.location.href.includes('/login') ||
          window.location.href.includes('/sign_up') ||
          window.location.href.includes('/invalid_account') ? (
            <div />
          ) : (
            <>
              <div className={classes.sectionDesktop}>
                <div>
                  <Typography style={{ lineHeight: '65px', color: 'black' }}>{user?.email}</Typography>
                </div>
                <IconButton edge="end" color="primary" onClick={handleProfileMenuOpen}>
                  <AccountCircle />
                </IconButton>
              </div>
            </>
          )}
          {renderMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopNavigation;
