const seedRanges = {
  // Soybeans
  '1': {
    minSeedSizeImperial: '500',
    maxSeedSizeImperial: '5000',
    defautSeedSizeImperial: '2800',
    minSeedSizeMetric: '500',
    maxSeedSizeMetric: '10000',
    defautSeedSizeMetric: '6175',
    defaultUnitSizeCount: '140000',
  },
  // Rice
  '2': {
    minSeedSizeImperial: '1',
    maxSeedSizeImperial: '55000',
    defautSeedSizeImperial: '1',
    minSeedSizeMetric: '1',
    maxSeedSizeMetric: '55000',
    defautSeedSizeMetric: '1',
    defaultUnitSizeCount: '1',
  },
  // Wheat
  '3': {
    minSeedSizeImperial: '1',
    maxSeedSizeImperial: '55000',
    defautSeedSizeImperial: '1',
    minSeedSizeMetric: '1',
    maxSeedSizeMetric: '55000',
    defautSeedSizeMetric: '1',
    defaultUnitSizeCount: '1',
  },
  // Corn
  '4': {
    minSeedSizeImperial: '500 ',
    maxSeedSizeImperial: '10000',
    defautSeedSizeImperial: '1500',
    minSeedSizeMetric: '500 ',
    maxSeedSizeMetric: '10000',
    defautSeedSizeMetric: '3300',
    defaultUnitSizeCount: '80000',
  },
  // Cotton
  '5': {
    minSeedSizeImperial: '500',
    maxSeedSizeImperial: '10000',
    defautSeedSizeImperial: '4500',
    minSeedSizeMetric: '500',
    maxSeedSizeMetric: '10000',
    defautSeedSizeMetric: '9920',
    defaultUnitSizeCount: '230000',
  },
  // Cereal
  '6': {
    minSeedSizeImperial: '1',
    maxSeedSizeImperial: '55000',
    defautSeedSizeImperial: '1',
    minSeedSizeMetric: '1',
    maxSeedSizeMetric: '55000',
    defautSeedSizeMetric: '1',
    defaultUnitSizeCount: '1',
  },
  // Other
  '7': {
    minSeedSizeImperial: '1',
    maxSeedSizeImperial: '55000',
    defautSeedSizeImperial: '1',
    minSeedSizeMetric: '1',
    maxSeedSizeMetric: '55000',
    defautSeedSizeMetric: '1',
    defaultUnitSizeCount: '1',
  },
};

export default seedRanges;
