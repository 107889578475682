import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const seedVarietyColumns = (combined: boolean): IDataTableColumn<any>[] => {
  const defaultColumns = [
    {
      name: 'Seed Variety',
      selector: 'seed_variety_name',
      sortable: true,
    },
    {
      name: 'Seed Lot',
      selector: 'seed_lot_data',
      sortable: true,
    },
    {
      name: 'Crop',
      selector: 'crop_name',
      sortable: true,
    },
    {
      name: 'Units Delivered',
      selector: 'seed_units_actual_total_with_uom',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.seed_units_actual_total_with_uom) || 0;
        const valB = parseFloat(b.seed_units_actual_total_with_uom) || 0;
        return valA - valB;
      },
      right: true,
    },
    {
      name: 'Actual Weight',
      selector: 'seed_weight_actual_total_with_uom',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.seed_weight_actual_total_with_uom) || 0;
        const valB = parseFloat(b.seed_weight_actual_total_with_uom) || 0;
        return valA - valB;
      },
      right: true,
    },
  ];

  return combined
    ? defaultColumns
    : [
        {
          name: 'Machine',
          selector: 'machine_name_with_serial',
          sortable: true,
        },
        ...defaultColumns,
      ];
};

export default seedVarietyColumns;
