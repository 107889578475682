/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import 'react-csv-importer/dist/index.css';
import { CSVLink } from 'react-csv';
import { UserMachinesContext } from '../../../modules/machinesAccountManagement/contexts/UserMachinesContext';
import exampleCSV from '../constants/exampleCSV';

const steps = ['Select File', 'Map Columns', 'Preview Data and Send to Machine', 'View Results'];

type CustomerImportStepperProps = {
  triggerNextStepProp: () => void;
  triggerBackStepProp: () => void;
  activeStep: any;
  nextDisabled: any;
};

const CustomerImportStepper: React.FC<CustomerImportStepperProps> = (props) => {
  const { triggerNextStepProp, triggerBackStepProp, activeStep, nextDisabled } = props;

  const { machineSerial } = useParams<Record<string, string>>();

  const { fetchMachine, machine } = React.useContext(UserMachinesContext);

  const triggerNextStep = () => {
    triggerNextStepProp();
  };

  const triggerBackStep = () => {
    triggerBackStepProp();
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <>
      <Box style={{ marginLeft: '-10px', marginRight: '0px', marginTop: '25px', marginBottom: '-20px' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} completed={stepProps.completed}>
                <StepLabel optional={labelProps.optional}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <br />
      <br />
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            style={{ marginTop: '15px', marginBottom: '-7px' }}
            color="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '15px', marginBottom: '-7px', marginLeft: '15px' }}
            color="primary"
            onClick={() => {
              triggerBackStep();
            }}
            disabled={activeStep === 0}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <CSVLink data={exampleCSV} filename="autobulk_customer_example.csv" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '15px', marginBottom: '-7px', marginLeft: '15px' }}
            >
              Download Example CSV
            </Button>
          </CSVLink>
        </Grid>
        <Grid item>
          {/* below button is a spacer so that the csv example download button stays centered in the stepper space. */}
          <Button
            variant="contained"
            style={{
              marginTop: '15px',
              marginBottom: '-7px',
              marginRight: '15px',
              marginLeft: '15px',
              visibility: 'hidden',
            }}
            color="primary"
            onClick={() => {
              triggerBackStep();
            }}
            disabled={activeStep === 0}
          >
            {}
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '15px', marginBottom: '-7px' }}
            color="primary"
            onClick={() => {
              triggerNextStep();
            }}
            disabled={nextDisabled}
          >
            {activeStep > 1 ? 'Send to Machine' : 'Next'}
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
};

export default CustomerImportStepper;
