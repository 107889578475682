/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid, GridSize } from '@mui/material';
import { SelectOption } from '../../shared/model';
import SelectInput from '../../shared/form/SelectInput';
import FormInput from '../../shared/form/FormInput';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

const AdminAnalyticsFilterBar: React.FC = () => {
  const {
    showAverage,
    setShowAverage,
    activeTab,
    selectedYear,
    setSelectedYear,
    selectedState,
    setSelectedState,
    mapDataType,
    setMapDataType,
    fetchSeedUnitsPerStateByYear,
    seedUnitsPerStateByYearLoading,
  } = React.useContext(AdminAnalyticsContext);

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <br />
      {activeTab !== 2 ? (
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={2}>
              <SelectInput
                value={showAverage}
                choices={[
                  { label: 'Average', value: 'Average' },
                  { label: 'Total', value: 'Total' },
                ]}
                onChange={(value) => setShowAverage(value)}
                label="Average/Total"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={2}>
              <SelectInput
                value={mapDataType}
                choices={[
                  // { label: 'Total Orders', value: 'Total Orders' },
                  { label: 'Machine Count', value: 'Machine Count' },
                  { label: 'Total Seed Units', value: 'Total Seed Units' },
                ]}
                onChange={(value) => setMapDataType(value)}
                label="Data Type"
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <FormInput value={selectedYear} onChange={(value) => setSelectedYear(value)} label="Year" />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ color: '#fff', marginTop: 19 }}
                disableElevation
                disabled={seedUnitsPerStateByYearLoading}
                onClick={() => {
                  fetchSeedUnitsPerStateByYear(selectedYear);
                }}
              >
                Fetch
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
                disableElevation
                disabled={selectedState === '' && selectedYear === ''}
                onClick={() => {
                  setSelectedState('');
                  setSelectedYear('');
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AdminAnalyticsFilterBar;
