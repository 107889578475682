/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import TabPanel from '../../shared/tabs/TabPanel';
import WrappedMapComponent from '../components/WrappedMapComponent';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';
import AdminAnalyticsFilterBar from '../components/AdminAnalyticsFilterBar';
import OrdersCharts from '../components/OrdersCharts';
import SeedUnitsCharts from '../components/SeedUnitsCharts';

const AdminAnalyticsPage: React.FunctionComponent = () => {
  const {
    fetchOrdersByYearCharts,
    fetchSeedUnitsByYearCharts,
    fetchMachineCountPerState,
    setShowMonthly,
    activeTab,
    setActiveTab,
  } = React.useContext(AdminAnalyticsContext);

  React.useEffect(() => {
    fetchOrdersByYearCharts();
    fetchSeedUnitsByYearCharts();
    fetchMachineCountPerState();
  }, []);

  React.useEffect(() => {
    setActiveTab(2);
  }, []);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setShowMonthly(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Admin Analytics
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Orders" />
          <Tab style={{ textTransform: 'capitalize' }} label="Seed Units" />
          <Tab style={{ textTransform: 'capitalize' }} label="Maps" />
        </Tabs>
      </Box>
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AdminAnalyticsFilterBar />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={activeTab} index={0}>
            <OrdersCharts />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <SeedUnitsCharts />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <WrappedMapComponent />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default AdminAnalyticsPage;
