/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { CompaniesContext } from '../contexts/CompaniesContext';
import { Company } from '../model';
import CompanyFilterBar from '../components/CompanyFilterBar';

const CompaniesListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchCompanies, companies, companiesLoading } = React.useContext(CompaniesContext);
  const [filterValues, setFilterValues] = React.useState({ status: 'status__all' });

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Company Management
          </Typography>
          <br />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/admin/company_management/new`)}>
            Create Company
          </Button>
        </Grid>
      </Grid>
      <br />

      <CompanyFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={companies}
            defaultSortAsc={false}
            striped
            onRowClicked={(row: Company) => history.push(`/admin/company_management/${row.company_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={companiesLoading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default CompaniesListPage;
