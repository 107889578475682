/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { CustomersAutoTreatContext } from '../contexts/CustomersAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import CustomerAutoTreatForm from '../components/CustomerAutoTreatForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const CustomersAutoTreatCreate: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchCustomersAutoTreat, setCreatingPage, activeMachine } = React.useContext(CustomersAutoTreatContext);
  const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchCustomersAutoTreat]);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: 'AutoTreat Customers',
    },
    {
      onClick: () => null,
      text: `Create`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            ← Return to Customer List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Customer
      </Typography>
      <br />
      <CustomerAutoTreatForm
        customer={{}}
        machineSerial={activeMachine?.value}
        onSuccess={() => setCreatingPage(false)}
      />
    </>
  );
};

export default CustomersAutoTreatCreate;
