import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import ProfileForm from './UserProfileForm';
import { UserProfileContext } from '../contexts/UserProfileContext';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface ProfileDetailsProps {
  user: any;
}

const UserProfileDetails: React.FC<ProfileDetailsProps> = (props) => {
  const { user } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const { updateUser, userSubmitting } = React.useContext(UserProfileContext);

  const handleResendInvitation = () => {
    updateUser(user.user_id, { resend_invitation: 'true' }, () =>
      showSuccessSnackBar('Invitation email successfully sent.'),
    );
  };

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" component="h2">
                    User Information
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleResendInvitation}>
                    {userSubmitting ? 'Sending...' : 'Send Password Reset Email'}
                  </Button>
                </Grid>
              </Grid>
              <br />
              <ProfileForm user={user} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfileDetails;
