import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { OrdersAutoTreatFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface OrdersAutoTreatContextInterface {
  fetchCachedOrdersAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchOrdersAutoTreat?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    completeFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  fetchOrdersOptionsAutoTreat?: (machineSerial: string) => Promise<void>;

  fetchCachedOrdersOptionsAutoTreat?: (machineSerial: string) => Promise<void>;

  ordersRefreshedAt?: string;
  orders?: any[];
  ordersLoading?: boolean;

  createOrderAutoTreat?: (
    machineSerial: string,
    formValues: OrdersAutoTreatFormValues,
    onSuccess: () => void,
  ) => Promise<void>;

  updateOrderAutoTreat?: (
    machineSerial: string,
    orderId: string,
    formValues: OrdersAutoTreatFormValues,
  ) => Promise<void>;
  orderSubmitting?: boolean;
  setOrderSubmitting?: Dispatch<SetStateAction<boolean>>;

  ordersOptions?: any;
  ordersOptionsLoading?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveOrderAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeOrderAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleCompleteFilterChange?: (completeFilter: string) => Promise<void>;
  completeFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;
}

const OrdersAutoTreatContext = React.createContext<OrdersAutoTreatContextInterface>({});

const OrdersAutoTreatContextConsumer = OrdersAutoTreatContext.Consumer;
const OrdersAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [orders, setOrdersAutoTreat] = React.useState([]);
  const [ordersLoading, setOrdersAutoTreatLoading] = React.useState(true);
  const [ordersOptions, setOrdersOptionsAutoTreat] = React.useState({});
  const [ordersOptionsLoading, setOrdersOptionsAutoTreatLoading] = React.useState(true);
  const [ordersRefreshedAt, setOrdersAutoTreatRefreshedAt] = React.useState<string>();
  const [activeOrderAutoTreat, setActiveOrderAutoTreat] = React.useState({});
  const [orderSubmitting, setOrderSubmitting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('orderAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('orderAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('Active');
  const [completeFilter, setCompleteFilter] = React.useState<string>('False');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setOrdersAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedOrdersOptionsAutoTreat = async (machineSerial: string) => {
    setOrdersOptionsAutoTreatLoading(true);

    axios
      .get<string, any>(`api/autotreat/onprem/order_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setOrdersOptionsAutoTreatLoading(false);
        setOrdersOptionsAutoTreat({
          ...ordersOptions,
          customer_choices: response.data.results
            .filter((choice) => choice.table === 'Customers')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              firstName: choice.customer_first_name,
              lastName: choice.customer_last_name,
            })),
          liquid_recipe_choices: response.data.results
            .filter((choice) => choice.table === 'LiquidRecipe')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              description: choice.description,
              cropName: choice.crop_name,
            })),
          seed_variety_choices: response.data.results
            .filter((choice) => choice.table === 'SeedVariety')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              description: choice.description,
              cropName: choice.crop_name,
            })),
        });
      });
  };

  const fetchCachedOrdersAutoTreat = async (machineSerial: string) => {
    setOrdersAutoTreatLoading(true);
    setOrdersAutoTreatRefreshedAt(null);

    axios
      .get<string, any>(`api/autotreat/onprem/orders/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setOrdersAutoTreatLoading(false);
        setOrdersAutoTreat(response.data.results);
        setOrdersAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setOrdersAutoTreatLoading(false);
        setOrdersAutoTreat([]);
        setOrdersAutoTreatRefreshedAt(null);
      });
  };

  const fetchOrdersOptionsAutoTreat = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/order_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/order_options/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setOrdersOptionsAutoTreat({
                ...ordersOptions,
                customer_choices: response.data.results
                  .filter((choice) => choice.table === 'Customers')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    firstName: choice.customer_first_name,
                    lastName: choice.customer_last_name,
                  })),
                liquid_recipe_choices: response.data.results
                  .filter((choice) => choice.table === 'LiquidRecipe')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    description: choice.description,
                    cropName: choice.crop_name,
                  })),
                seed_variety_choices: response.data.results
                  .filter((choice) => choice.table === 'SeedVariety')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    description: choice.description,
                    cropName: choice.crop_name,
                  })),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      })
      .catch((error) => {});
  };

  const fetchOrdersAutoTreat = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    completeFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setOrdersAutoTreatLoading(true);
    setOrdersAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/orders/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&complete=${completeFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              if (retryCount < MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setOrdersAutoTreatLoading(false);
                setOrdersAutoTreat(response.data.results);
                setOrdersAutoTreatRefreshedAt(response.data.created_at);
              }
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setOrdersAutoTreatLoading(false);
                setOrdersAutoTreat([]);
                setOrdersAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createOrderAutoTreat = async (
    machineSerial: string,
    formValues: OrdersAutoTreatFormValues,
    // TODO Duane
    // genericFilter: string,
    // statusFilter: string,
    // completeFilter: string,
    onSuccess?: () => void,
    genericFilter = '',
    statusFilter = 'Active',
    completeFilter = 'False',
  ) => {
    setOrderSubmitting(true);
    setCreatingPage(true);
    setOrdersAutoTreatLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/orders/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        setOrderSubmitting(false);
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setOrdersAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchOrdersOptionsAutoTreat(machineSerial);
              fetchOrdersAutoTreat(machineSerial, genericFilter, statusFilter, completeFilter, apiLinkedFilter, () => {
                setOrdersAutoTreatLoading(false);
                setOrderSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setOrdersAutoTreatLoading(false);
                setOrderSubmitting(false);
                // setOrderAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateOrderAutoTreat = async (
    machineSerial: string,
    orderId: string,
    // TODO Duane
    // genericFilter: string,
    // statusFilter: string,
    // completeFilter: string,
    // apiLinkedFilter: string,
    formValues: OrdersAutoTreatFormValues,
    onSuccess?: () => void,
    genericFilter = '',
    statusFilter = 'Active',
    completeFilter = 'False',
  ) => {
    setOrderSubmitting(true);
    setOrdersAutoTreatLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/orders/${orderId}/?serial-number=${activeMachine.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        setDetailPage(false);
        setActiveOrderAutoTreat(null);
        setCreatingPage(false);
        setOrdersAutoTreatLoading(false);

        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setOrdersAutoTreatLoading(false);
              setOrderSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchOrdersOptionsAutoTreat(machineSerial);
              fetchOrdersAutoTreat(
                activeMachine?.value,
                genericFilter,
                statusFilter,
                completeFilter,
                apiLinkedFilter,
                () => {
                  setOrdersAutoTreatLoading(false);
                  setOrderSubmitting(false);
                },
              );
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setOrdersAutoTreatLoading(false);
                setOrderSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleCompleteFilterChange = async (completeFilter: string) => {
    setCompleteFilter(completeFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <OrdersAutoTreatContext.Provider
      value={{
        fetchCachedOrdersAutoTreat,
        fetchOrdersAutoTreat,
        fetchCachedOrdersOptionsAutoTreat,
        fetchOrdersOptionsAutoTreat,
        orders,
        ordersRefreshedAt,
        ordersLoading,

        createOrderAutoTreat,
        updateOrderAutoTreat,
        orderSubmitting,
        setOrderSubmitting,

        ordersOptions,
        ordersOptionsLoading,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveOrderAutoTreat,
        activeOrderAutoTreat,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleCompleteFilterChange,
        completeFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </OrdersAutoTreatContext.Provider>
  );
};

export { OrdersAutoTreatContextProvider, OrdersAutoTreatContextConsumer, OrdersAutoTreatContext };
