/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { MenuItem, Grid, styled } from '@mui/material';
import { format } from 'date-fns';

interface DateSelectFilterProps {
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
}

const StyledDatePicker = styled(DesktopDatePicker)(() => ({
  '& .MuiPickersDay-root': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#ccc',
    boxSizing: 'border-box',
  },
}));

const DateSelectFilter: React.FC<DateSelectFilterProps> = (props) => {
  const { startDate, setStartDate, endDate, setEndDate } = props;
  const [fullStartDate, setFullStartDate] = React.useState('');
  const [fullEndDate, setFullEndDate] = React.useState('');
  const [startYear, setStartYear] = React.useState(0);
  const [endYear, setEndYear] = React.useState(0);

  const [dateRange, setDateRange] = React.useState<string>(
    localStorage.getItem('dateRange') ? JSON.parse(localStorage.getItem('dateRange')) : '',
  );

  const addDaysToDate = (date: Date, days: number) => {
    return date.setDate(date.getDate() + days);
  };

  const handleSetCustomDateLabel = () => {
    sessionStorage.setItem('timePeriod', `${startDate} to ${endDate}`);
  };

  React.useEffect(() => {
    if (sessionStorage.getItem('timePeriod') === null) {
      return;
    }
    if (sessionStorage.getItem('timePeriod') === 'Manual' || sessionStorage.getItem('timePeriod').includes('to')) {
      handleSetCustomDateLabel();
    }
  }, [startDate, endDate]);

  const handleSetDateLabel = (event?: any) => {
    sessionStorage.setItem('timePeriod', event.target.value);
  };

  const handleDateChange = (event: any) => {
    sessionStorage.setItem('timePeriod', event.target.value);
    const dateFormat = 'MM/dd/yyyy';

    if (event.target.value === 'today') {
      const startDate = new Date();
      const endDate = addDaysToDate(new Date(), 1);

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'yesterday') {
      const startDate = addDaysToDate(new Date(), -1);
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'last 7 days') {
      const startDate = addDaysToDate(new Date(), -7);
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'last 30 days') {
      const startDate = addDaysToDate(new Date(), -30);
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'last 6 months') {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 6);

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'last 12 months') {
      const startDate = addDaysToDate(new Date(), -365);
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'ytd') {
      const currentYear = new Date().getFullYear();
      const startDate = new Date(currentYear, 0, 1);
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else if (event.target.value === 'all') {
      const startDate = new Date('2020-01-01T00:00:00');
      const endDate = new Date();

      setStartDate(format(startDate, dateFormat));
      setEndDate(format(endDate, dateFormat));
    } else {
      setStartDate('');
      setEndDate('');
      setFullStartDate('');
      setFullEndDate('');
    }

    setDateRange(event.target.value);
    handleSetDateLabel(event);

    localStorage.setItem('dateRange', JSON.stringify('preset'));
  };

  return (
    <FormControl sx={{ minWidth: 120, marginTop: '7px' }}>
      {dateRange === 'Manual' ? (
        <>
          <div className="Polaris-Labelled__LabelWrapper" style={{ margin: '-7px 0 24px' }} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
              <Grid item>
                <StyledDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(newValue: any) => {
                    try {
                      setFullStartDate(newValue);
                      setStartDate(format(newValue, 'MM/dd/yyyy'));
                      setStartYear(parseInt(format(newValue, 'yyyy'), 10));
                    } catch {
                      setFullStartDate('');
                      setStartDate('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      InputProps={{ readOnly: true }}
                      sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                      {...params}
                      onChange={() => null}
                    />
                  )}
                />
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <StyledDatePicker
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={(newValue: any) => {
                    try {
                      setFullEndDate(newValue);
                      setEndDate(format(newValue, 'MM/dd/yyyy'));
                      setEndYear(parseInt(format(newValue, 'yyyy'), 10));
                    } catch {
                      setFullEndDate('');
                      setEndDate('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      InputProps={{ readOnly: true }}
                      sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </>
      ) : (
        <>
          <div className="Polaris-Labelled__LabelWrapper" style={{ margin: '-7px 0 9px' }}>
            <div className="Polaris-Label">
              <span className="Polaris-Label__Text">When</span>
            </div>
          </div>
          <Select
            variant="outlined"
            placeholder="When"
            style={{ marginTop: '-4px', height: '32px', fontSize: '12px' }}
            value={dateRange === 'Manual' ? 'December 1, 2021 to December 2, 2021' : dateRange}
            onChange={handleDateChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="yesterday">Yesterday</MenuItem>
            <MenuItem value="last 7 days">Last 7 Days</MenuItem>
            <MenuItem value="last 30 days">Last 30 Days</MenuItem>
            <MenuItem value="last 6 months">Last 6 Months</MenuItem>
            <MenuItem value="last 12 months">Last 12 Months</MenuItem>
            <MenuItem value="ytd">Year To Date</MenuItem>
            <MenuItem value="Manual">Custom</MenuItem>
            <MenuItem value="preset" style={{ display: 'none' }}>{`${startDate} to ${endDate}`}</MenuItem>
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default DateSelectFilter;
