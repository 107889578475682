/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import SeedSourcesAutoTreatDetail from '../components/SeedSourcesAutoTreatDetail';

const SeedSourcesAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveSeedSourceAutoTreat, activeSeedSourceAutoTreat, activeMachine } =
    React.useContext(SeedSourcesAutoTreatContext);

  const handleBack = () => {
    setDetailPage(false);
    setActiveSeedSourceAutoTreat(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeSeedSourceAutoTreat?.seed_source_location}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to SeedSource List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SeedSourcesAutoTreatDetail onBack={handleBack} />
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default SeedSourcesAutoTreatDetailPage;
