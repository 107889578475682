import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Company, CompanyFormValues } from '../model';

interface CompaniesContextInterface {
  fetchCompanies?: (filterParams?: string) => Promise<void>;
  fetchCompanyOptions?: () => Promise<void>;
  companies?: Company[];
  companyOptions?: any[];
  companiesLoading?: boolean;

  fetchCompany?: (company: string) => Promise<void>;
  company?: Company;
  companyLoading?: boolean;

  fetchUserCompany?: (company: string) => Promise<void>;
  userCompany?: Company;

  createCompany?: (formValues: CompanyFormValues) => Promise<void>;
  updateCompany?: (userId: string, formValues: CompanyFormValues, onSuccess: any) => Promise<void>;
  companySubmitting?: boolean;
}

const CompaniesContext = React.createContext<CompaniesContextInterface>({});

const CompaniesContextConsumer = CompaniesContext.Consumer;
const CompaniesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [companies, setCompanies] = React.useState([]);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [companiesLoading, setCompaniesLoading] = React.useState(true);
  const [company, setCompany] = React.useState({});
  const [userCompany, setUserCompany] = React.useState({});
  const [companyLoading, setCompanyLoading] = React.useState(true);
  const [companySubmitting, setCompanySubmitting] = React.useState(false);

  const fetchCompanies = async (filterParams = '') => {
    setCompaniesLoading(true);

    axios
      .get<string, any>(`api/admin/companies/?${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCompaniesLoading(false);
        setCompanies(response.data);
      });
  };

  const fetchCompanyOptions = async () => {
    setCompaniesLoading(true);

    axios
      .get<string, any>('api/admin/company_options/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCompaniesLoading(false);
        setCompanyOptions(response.data?.result?.company_options);
      })
      .catch((response) => {
        setCompaniesLoading(false);
      });
  };

  const fetchCompany = async (companyId: string) => {
    setCompanyLoading(true);

    axios
      .get<string, any>(`api/admin/companies/${companyId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCompanyLoading(false);
        setCompany(response.data.result);
      });
  };

  const fetchUserCompany = async (companyId: string) => {
    setCompanyLoading(true);

    axios
      .get<string, any>(`api/admin/companies/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCompanyLoading(false);
        setUserCompany(response.data.result);
      });
  };

  const updateCompany = async (companyId: string, formValues: CompanyFormValues, onSuccess: any) => {
    setCompanySubmitting(true);

    axios
      .put<any, any>(
        `api/admin/companies/${companyId}/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then(() => {
        history.push(`/admin/company_management/${companyId}`);
        setCompanySubmitting(false);
        onSuccess();
      });
  };

  const createCompany = async (formValues: CompanyFormValues) => {
    setCompanySubmitting(true);

    axios
      .post<any, any>(
        `api/admin/companies/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setCompanyLoading(false);
        history.push(`/admin/company_management/${response.data.result.company_id}`);
        setCompanySubmitting(false);
      });
  };

  return (
    <CompaniesContext.Provider
      value={{
        fetchCompanies,
        fetchCompanyOptions,
        companies,
        companyOptions,
        companiesLoading,

        fetchCompany,
        company,
        companyLoading,

        fetchUserCompany,
        userCompany,

        createCompany,
        updateCompany,
        companySubmitting,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export { CompaniesContextProvider, CompaniesContextConsumer, CompaniesContext };
