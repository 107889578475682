import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, FilledInput, FormControl, FormHelperText, Input, Typography, TextField } from '@mui/material';

interface FormInputProps {
  value: string;
  label: string;
  onChange?: (value: string) => void;
  display?: string;
  styleOverrides?: string;
  disabled?: boolean;
  errorMessage?: string;
  isFilled?: boolean;
  multiline?: boolean;
  required?: boolean;
  type?: string;
  rows?: number;
  maxRows?: number;
  inputProps?: Record<string, string | number>;
  endAdornment?: string;
}

const useStyles = makeStyles(() => ({
  textField: {
    minHeight: '30px',
    width: '100%',
    margin: '0 0 10px',
  },
  helperText: {
    fontSize: '12px',
  },
}));

const FormInput: React.FC<FormInputProps> = (props) => {
  const {
    value = '',
    label = '',
    onChange = () => null,
    styleOverrides = '',
    errorMessage = '',
    disabled = false,
    type = 'text',
    display = 'flex',
    required = false,
    isFilled = true,
    multiline = false,
    rows = 1,
    maxRows = 1,
    inputProps = {},
    endAdornment = '',
  } = props;

  const classes = useStyles();
  const randomId = Math.floor(Math.random() * 16777215).toString(16);

  const labelToName = (label: string): string => {
    return label
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_')
      .replace(/(^_|_$)/g, '');
  };

  const handleChange = (event: any): void => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const inputStyles = () => {
    if (multiline) {
      return { padding: '0px', lineHeight: 1.25, marginTop: -10 };
    }

    return { padding: '11px 9px', fontSize: '13px', height: '0.8375em' };
  };

  const formattedInput = isFilled ? (
    <TextField
      id={randomId}
      multiline={multiline}
      rows={rows}
      onChange={handleChange}
      name={labelToName(label)}
      type={type}
      disabled={disabled}
      value={value}
      maxRows={maxRows}
      variant="outlined"
      inputProps={{
        ...inputProps,
        style: inputStyles(),
      }}
    />
  ) : (
    <TextField
      id={randomId}
      variant="outlined"
      onChange={handleChange}
      name={labelToName(label)}
      type={type}
      disabled={disabled}
      value={value}
      inputProps={{ ...inputProps, style: { padding: '12px' } }}
    />
  );

  return (
    <Box display={display}>
      <>
        <FormControl
          variant="filled"
          error={errorMessage?.length > 0}
          className={`${styleOverrides} ${classes.textField}`}
        >
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text" htmlFor={randomId}>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
              </label>
            </div>
          </div>
          {formattedInput}
          {errorMessage && (
            <FormHelperText className={classes.helperText}>
              {`${errorMessage.toString().charAt(0).toUpperCase() + errorMessage.toString().slice(1)}`}
            </FormHelperText>
          )}
        </FormControl>
      </>
    </Box>
  );
};

export default FormInput;
