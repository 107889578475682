/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { RecipesAutoTreatContext } from '../contexts/RecipesAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import RecipesAutoTreatDetail from '../components/RecipesAutoTreatDetail';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';
import IntegrationsList from '../components/IntegrationsList';
import { RecipeIntegrationsAutoTreatContext } from '../contexts/RecipeIntegrationsAutoTreatContext';

const RecipesAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveRecipeAutoTreat, activeRecipeAutoTreat, activeMachine } =
    React.useContext(RecipesAutoTreatContext);

  const { fetchRecipeIntegrationsAutoTreat } = React.useContext(RecipeIntegrationsAutoTreatContext);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'recipe items', 'integrations'],
    `/autotreat/machines/products/${activeRecipeAutoTreat?.liquid_recipe_id}`,
  );

  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const getIntegrations = async () => {
      if (!dataLoaded) {
        setDataLoaded(true);
        fetchRecipeIntegrationsAutoTreat(activeMachine?.value, activeRecipeAutoTreat.liquid_recipe_id);
      }
    };

    getIntegrations();
  }, []);

  const handleBack = () => {
    setDetailPage(false);
    setActiveRecipeAutoTreat(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeRecipeAutoTreat.liquid_recipe_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Recipe List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <RecipesAutoTreatDetail recipe={activeRecipeAutoTreat} machineSerial={activeMachine?.value} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <IntegrationsList activeMachine={activeMachine} recipe={activeRecipeAutoTreat} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default RecipesAutoTreatDetailPage;
