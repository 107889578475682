import React from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

type TopVarietyOrProduct = {
  name: string;
  amount: number;
};

type VarietiesCardProps = {
  topVarieties: {
    [key: string]: TopVarietyOrProduct;
  };
  year?: string;
};

const VarietiesCard: React.FC<VarietiesCardProps> = ({ topVarieties, year }) => {
  const varietiesList: TopVarietyOrProduct[] = Object.values(topVarieties || {});

  return (
    <Card style={{ height: '594px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <CardContent style={{ overflowY: 'auto', padding: '20px' }}>
        <Typography
          variant="h6"
          align="center"
          style={{ marginBottom: '16px', borderBottom: '1px solid #e0e0e0', paddingBottom: '16px' }}
        >
          Variety Delivered Metrics ({year})
        </Typography>
        <TableContainer component={Paper} style={{ boxShadow: 'none', overflow: 'initial' }}>
          <Table aria-label="simple table" style={{ minWidth: '265px' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    Variety
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    Units Delivered
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {varietiesList.map((variety, index) => {
                if (variety.name && variety.amount) {
                  return (
                    <TableRow key={variety.name} hover>
                      <TableCell component="th" scope="row">
                        {index + 1}. {variety.name}
                      </TableCell>
                      <TableCell align="right">{variety.amount.toFixed(2)} units</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default VarietiesCard;
