import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { UserFormValues, User } from '../model';

interface MachineOption {
  machine_name_with_serial: string;
  serial_number: string;
}
interface UsersContextInterface {
  fetchUsers?: (filterParams?: string) => Promise<void>;
  users?: any[];
  usersLoading?: boolean;

  fetchUser?: (userId: string) => Promise<void>;
  user?: User;
  userLoading?: boolean;
  userSubmitting?: boolean;

  createUser?: (formValues: UserFormValues) => Promise<void>;
  updateUser?: (userId: string, formValues: UserFormValues) => Promise<void>;
  formErrors?: any;

  fetchUserMachinesByCompany?: () => Promise<void>;
  userMachinesByCompany?: any[];
  userMachinesByCompanyLoading?: boolean;

  fetchUserPermissionGroups?: () => Promise<void>;
  userPermissionGroups?: any[];
  userPermissionGroupsLoading?: boolean;
}

const UsersContext = React.createContext<UsersContextInterface>({});

const UserUsersContextConsumer = UsersContext.Consumer;
const UserUsersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [userMachinesByCompany, setUserMachinesByCompany] = React.useState<MachineOption[]>([]);

  const [userMachinesByCompanyLoading, setUserMachinesByCompanyLoading] = React.useState(false);

  const [userPermissionGroups, setUserPermissionGroups] = React.useState([]);
  const [userPermissionGroupsLoading, setUserPermissionGroupsLoading] = React.useState(false);

  const [usersLoading, setUserUsersLoading] = React.useState(true);
  const [users, setUserUsers] = React.useState([]);

  const [user, setUser] = React.useState({});
  const [userLoading, setUserLoading] = React.useState(true);
  const [userSubmitting, setuserSubmitting] = React.useState(false);

  const [formErrors, setFormErrors] = React.useState({});

  const fetchUsers = async (filterParams?: string) => {
    setUserUsersLoading(true);

    const url = filterParams ? `api/user/users/?${filterParams}` : 'api/user/users/';
    axios
      .get<string, any>(url, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserUsersLoading(false);
        setUserUsers(response.data);
      });
  };

  const fetchUser = async (userId: string) => {
    setUserLoading(true);

    axios
      .get<string, any>(`api/user/users/${userId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserLoading(false);
        setUser(response.data);
      });
  };

  const updateUser = async (userId: string, formValues: UserFormValues) => {
    setuserSubmitting(true);

    axios
      .put<any, any>(
        `api/user/users/${userId}/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then(() => {
        history.push(`/account_management/users/${userId}`);
        setuserSubmitting(false);
      });
  };

  const createUser = async (formValues: UserFormValues) => {
    setuserSubmitting(true);

    axios
      .post<any, any>(
        `api/user/users/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setUserLoading(false);
        setuserSubmitting(false);

        if (response.data.success) {
          history.push(`/account_management/users/${response.data.result.user_id}`);
          setFormErrors({});
        } else {
          const errors = response.data;

          setFormErrors({
            full_name: errors.full_name,
            email_address: errors.email_address,
            company: errors.company,
          });
        }
      });
  };

  // Fetch user permission groups
  const fetchUserPermissionGroups = async (filterParams?: string) => {
    setUserPermissionGroupsLoading(true);
    axios
      .get<string, any>(`api/user/permission_group_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserPermissionGroups(response.data);
        setUserPermissionGroupsLoading(false);
      });
  };

  // Fetch user machines by company
  const fetchUserMachinesByCompany = async () => {
    setUserMachinesByCompanyLoading(true);
    axios
      .get(`api/user/machines/?association=company`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachinesByCompany(response.data);
        setUserMachinesByCompanyLoading(false);
      })
      .catch((error) => {
        setUserMachinesByCompanyLoading(false);
      });
  };

  return (
    <UsersContext.Provider
      value={{
        fetchUserPermissionGroups,
        userPermissionGroups,
        userPermissionGroupsLoading,

        fetchUsers,
        users,
        usersLoading,

        fetchUser,
        user,
        userLoading,

        updateUser,
        createUser,
        formErrors,
        userSubmitting,

        fetchUserMachinesByCompany,
        userMachinesByCompany,
        userMachinesByCompanyLoading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { UserUsersContextProvider, UserUsersContextConsumer, UsersContext };
