/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { WorkOrdersAutoBulkContext } from '../contexts/WorkOrdersAutoBulkContext';
import WorkOrderAutoBulkForm from '../components/WorkOrdersAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import WorkOrdersAutoBulkDetail from '../components/WorkOrdersAutoBulkDetail';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import WorkOrdersAutoBulkItems from '../components/WorkOrdersAutoBulkItems';
import URLTabs from '../../../modules/shared/tabs/URLTabs';

const WorkOrdersAutoBulkDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveWorkOrderAutoBulk, activeWorkOrderAutoBulk, activeMachine } =
    React.useContext(WorkOrdersAutoBulkContext);

  const tabNames = ['details', 'items'];
  const baseUrl = `/workorders/detail/${activeWorkOrderAutoBulk?.id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded]);

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeWorkOrderAutoBulk?.work_order_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => {
              setDetailPage(false);
            }}
          >
            ← Return to Order List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Items" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <WorkOrderAutoBulkForm
              workOrder={activeWorkOrderAutoBulk}
              machineSerial={activeMachine?.value}
              onSuccess={() => setDetailPage(false)}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <WorkOrdersAutoBulkItems workOrder={activeWorkOrderAutoBulk} machineSerial={activeMachine?.value} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default WorkOrdersAutoBulkDetailPage;
