/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import ProductsAutoBulkDetail from './ProductsAutoBulkDetailPage';
import ProductsAutoBulkCreate from './ProductsAutoBulkCreate';
import ProductsAutoBulkList from './ProductsAutoBulkList';
import ProductsAutoBulkcsvImportPage from './ProductsAutoBulkcsvImportPage';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const ProductsAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchProductsAutoBulk, detailPage, creatingPage, activeMachine, importingPage } =
    React.useContext(ProductsAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchProductsAutoBulk]);

  const autoBulkProductPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    if (importingPage) {
      return 'importingPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <ProductsAutoBulkDetail />,
          createPage: <ProductsAutoBulkCreate />,
          importingPage: <ProductsAutoBulkcsvImportPage />,
          listPage: <ProductsAutoBulkList />,
        }[autoBulkProductPage()]
      }
    </>
  );
};

export default ProductsAutoBulkPage;
