/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { PreviewReport } from '../../parser';
import { FormatDataRowPreview } from './FormatDataRowPreview';

// import './FileStep.scss';
import { useLocale } from '../../locale/LocaleContext';

export interface FileStepState extends PreviewReport {
  hasHeaders: boolean;
  records?: any;
}

export const DataPreviewStep: React.FC<{
  onChange?: (state: FileStepState | null) => void;
  onAccept?: () => void;
  onBack?: () => void;
  records?: any;
}> = ({ onChange, onAccept, onBack, records }) => {
  const l10n = useLocale('fileStep');

  return <FormatDataRowPreview rows={records} />;
};
