import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type DetailDrawerProps = {
  open: boolean;
  recordLoading: boolean;
  bodyComponent?: React.ReactNode;
  menuComponent?: React.ReactNode;
  onClose: () => void;
};

const DetailDrawer: React.FC<DetailDrawerProps> = (props) => {
  const { open, recordLoading, bodyComponent, menuComponent, onClose } = props;

  const [drawerWidth, setDrawerWidth] = React.useState(window.innerWidth <= 768 ? 350 : 500);

  const resizingRef = React.useRef(false);
  const lastXRef = React.useRef(0);

  const handleMouseMove = (event: MouseEvent) => {
    if (resizingRef.current) {
      const diffX = -(event.clientX - lastXRef.current);
      setDrawerWidth((prevWidth) => {
        const newWidth = prevWidth + diffX;
        return newWidth < 325 ? 325 : newWidth;
      });
      lastXRef.current = event.clientX;
    }
  };

  const handleMouseUp = () => {
    resizingRef.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    resizingRef.current = true;
    lastXRef.current = event.clientX;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const internalToggleDrawer = () => {
    onClose();
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 600 }}
      role="presentation"
      style={{ width: `${drawerWidth}px` }}
    >
      <Box
        sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
        style={{ marginTop: '10px', marginLeft: '10px' }}
      >
        <Box
          sx={{
            width: '30px',
            height: '30px',
            border: `2px solid #D3D3D3`,
            borderRadius: '4px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px',
            cursor: 'pointer',
          }}
          onClick={internalToggleDrawer}
        >
          <CloseIcon color="action" />
        </Box>
        {menuComponent && (
          <Box
            sx={{
              width: '30px',
              height: '30px',
              border: `2px solid #D3D3D3`,
              borderRadius: '4px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4px',
              cursor: 'pointer',
              marginRight: '10px',
            }}
          >
            {menuComponent}
          </Box>
        )}
      </Box>
      {bodyComponent}
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={internalToggleDrawer}
      PaperProps={{ style: { width: `${drawerWidth}px`, marginTop: '65px' } }}
      BackdropProps={{ invisible: true }}
      variant="persistent"
    >
      <div
        role="button"
        tabIndex={0}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '10px',
          cursor: 'ew-resize',
          zIndex: 1000,
        }}
        onMouseDown={handleMouseDown}
      />
      {recordLoading ? (
        <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
          <h2 style={{ fontSize: '18px' }}>Loading ...</h2>
          <br />
          <CircularProgress />
        </Box>
      ) : (
        list('right')
      )}
    </Drawer>
  );
};

export default DetailDrawer;
