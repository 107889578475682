const exampleCSV = [
  {
    variety: 'Var1',
    seed_lot_data: 'Lot1',
    shipment_number: 'Shipment1',
    seed_variety_desc: 'Desc1',
    seed_size: 2800,
    crop_name: 'Soybean',
    company: 'Company1',
    brand: 'Brand1',
    unit_size_wt: 50,
    unit_size_cnt: 140000,
    seed_origin: 'Origin1',
    seed_germ: '90%',
    seed_test_date: '1/1/2023',
    seed_notes: 'Notes1',
    active: true,
    partner_record_id_1: null,
    partner_record_id_2: null,
    partner_record_id_3: null,
  },
  {
    variety: 'Var2',
    seed_lot_data: 'Lot2',
    shipment_number: 'Shipment2',
    seed_variety_desc: 'Desc2',
    seed_size: 1500,
    crop_name: 'Corn',
    company: 'Company2',
    brand: 'Brand2',
    unit_size_wt: 50,
    unit_size_cnt: 80000,
    seed_origin: 'Origin2',
    seed_germ: '85%',
    seed_test_date: '1/2/2023',
    seed_notes: 'Notes2',
    active: true,
    partner_record_id_1: null,
    partner_record_id_2: null,
    partner_record_id_3: null,
  },
  {
    variety: 'Var3',
    seed_lot_data: 'Lot3',
    shipment_number: 'Shipment2',
    seed_variety_desc: 'Desc3',
    seed_size: null,
    crop_name: 'Other',
    company: 'Company2',
    brand: 'Brand2',
    unit_size_wt: 50,
    unit_size_cnt: null,
    seed_origin: 'Origin3',
    seed_germ: null,
    seed_test_date: '1/2/2023',
    seed_notes: null,
    active: true,
    partner_record_id_1: null,
    partner_record_id_2: null,
    partner_record_id_3: null,
  },
  {
    variety: 'Var4',
    seed_lot_data: 'Lot4',
    shipment_number: 'Shipment2',
    seed_variety_desc: 'Desc4',
    seed_size: null,
    crop_name: 'Wheat',
    company: 'Company2',
    brand: 'Brand',
    unit_size_wt: 50,
    unit_size_cnt: null,
    seed_origin: 'Origin4',
    seed_germ: null,
    seed_test_date: '1/2/2023',
    seed_notes: null,
    active: true,
    partner_record_id_1: null,
    partner_record_id_2: null,
    partner_record_id_3: null,
  },
  {
    variety: '* Required',
    seed_lot_data: null,
    shipment_number: null,
    seed_variety_desc: null,
    seed_size: null,
    crop_name: 'Options: Soybean, Rice, Wheat, Corn, Cotton, Cereal, Other',
    company: null,
    brand: null,
    unit_size_wt: 'default: 50',
    unit_size_cnt: null,
    seed_origin: null,
    seed_germ: null,
    seed_test_date: null,
    seed_notes: null,
    active: 'true or false, default: true',
    partner_record_id_1: null,
    partner_record_id_2: null,
    partner_record_id_3: null,
  },
];

export default exampleCSV;
