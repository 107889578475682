import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

interface MachineDetailsProps {
  machineName?: string;
  systemType?: string;
  serialNumber?: string;
  systemVersion?: string;
  connectionStatus?: string;
  lastActive?: string;
  subscriptionStatus?: string;
  expirationDate?: string;
  daysToExpiry?: number;
  softwareVersion?: string;
}

const MachineDetails: React.FC<MachineDetailsProps> = ({
  machineName,
  serialNumber,
  systemType,
  systemVersion,
  connectionStatus,
  lastActive,
  subscriptionStatus,
  expirationDate,
  daysToExpiry,
  softwareVersion,
}) => {
  return (
    <Card style={{ height: '594px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <CardContent>
        <Typography variant="h5" style={{ marginBottom: '20px' }}>
          <strong>Machine Details:</strong>
        </Typography>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            <Typography variant="body1">
              <strong>Name:</strong> {machineName}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Serial:</strong> {serialNumber}
            </Typography>
          </li>
        </ul>
        <Divider style={{ margin: '20px 0' }} />

        <Typography variant="h6" style={{ marginBottom: '20px' }}>
          <strong>Connection Status:</strong>
        </Typography>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            <Typography variant="body1">
              <strong>Current:</strong> {connectionStatus}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Last Active:</strong> {lastActive}
            </Typography>
          </li>
        </ul>
        <Divider style={{ margin: '20px 0' }} />

        <Typography variant="h6" style={{ marginBottom: '20px' }}>
          <strong>Subscription Status:</strong>
        </Typography>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            <Typography variant="body1">
              <strong>Current Status:</strong> {subscriptionStatus}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Expiration Date:</strong> {expirationDate}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Days to Expiry:</strong> {daysToExpiry}
            </Typography>
          </li>
        </ul>
        <Divider style={{ margin: '20px 0' }} />

        <Typography variant="h6" style={{ marginBottom: '20px' }}>
          <strong>Versions:</strong>
        </Typography>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            <Typography variant="body1">
              <strong>Software Version:</strong> {softwareVersion}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>System Version:</strong> {systemVersion}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>System Type:</strong> {systemType}
            </Typography>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

export default MachineDetails;
