import { SelectOption } from '../../shared/model';

export const notificationTypeOptions = [
  { label: 'RealTime', value: 'RealTime' },
  { label: 'Scheduled', value: 'Scheduled' },
];

export const timezoneOptions = [
  { label: 'Brasília Time (Brazil/East)', value: 'Brazil/East' },
  { label: 'Amazon Time (Brazil/West)', value: 'Brazil/West' },
  { label: 'Eastern Time (America/New York)', value: 'America/New_York' },
  { label: 'Central Time (America/Chicago)', value: 'America/Chicago' },
  { label: 'Mountain Time (America/Denver)', value: 'America/Denver' },
  { label: 'Pacific Standard Time (America/Los Angeles)', value: 'America/Los_Angeles' },
];

export const scheduledIntervalOptions = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
];

export const scheduledDayOptions = [
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
];

export const scheduledDayOptionsMonth = [{ label: 'Last of the Month', value: 'Last' }];

export const scheduledTimeOptions = [
  { label: '6:00 AM', value: '06:00:00' },
  { label: '7:00 AM', value: '07:00:00' },
  { label: '8:00 AM', value: '08:00:00' },
  { label: '9:00 AM', value: '09:00:00' },
  { label: '10:00 AM', value: '10:00:00' },
  { label: '11:00 AM', value: '11:00:00' },
  { label: '12:00 PM', value: '12:00:00' },
  { label: '1:00 PM', value: '13:00:00' },
  { label: '2:00 PM', value: '14:00:00' },
  { label: '3:00 PM', value: '15:00:00' },
  { label: '4:00 PM', value: '16:00:00' },
  { label: '5:00 PM', value: '17:00:00' },
  { label: '6:00 PM', value: '18:00:00' },
  { label: '7:00 PM', value: '19:00:00' },
  { label: '8:00 PM', value: '20:00:00' },
  { label: '9:00 PM', value: '21:00:00' },
  { label: '10:00 PM', value: '22:00:00' },
  { label: '11:00 PM', value: '23:00:00' },
];

export const toleranceOptionsConverter = (options: { label: string; value: number | null }[]): SelectOption[] => {
  return options.map((option) => ({
    label: option.label,
    value: option.value !== null ? option.value.toString() : '',
  }));
};

export const toleranceAboveOptions = toleranceOptionsConverter([
  { label: 'N/A', value: null },
  { label: '1%', value: 101 },
  { label: '3%', value: 103 },
  { label: '5%', value: 105 },
  { label: '10%', value: 110 },
  { label: '15%', value: 115 },
]);

export const toleranceBelowOptions = toleranceOptionsConverter([
  { label: 'N/A', value: null },
  { label: '1%', value: 99 },
  { label: '3%', value: 97 },
  { label: '5%', value: 95 },
  { label: '10%', value: 90 },
  { label: '15%', value: 85 },
]);

export const alertConditionConsecutiveOrderCountOptions = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
];
