import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormInput from '../../shared/form/FormInput';
import { UserMachinesContext } from '../contexts/UserMachinesContext';
import { UsersContext } from '../../userUsers/contexts/UsersContext';
import { Machine, MachineFormValues } from '../../machinesAdminManagement/model';
import PolarisLabel from '../../shared/form/PolarisLabel';
import UserAccessSelector from './UserAccessSelector';

interface UserMachineFormProps {
  machine: Machine;
}

const UserMachineForm: React.FC<UserMachineFormProps> = (props) => {
  const { machine } = props;
  const [formValues, setFormValues] = React.useState<MachineFormValues>({});
  const [showRevertButton, setShowRevertButton] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);

  const { updateMachine, machineSubmitting } = React.useContext(UserMachinesContext);
  const { users, usersLoading } = React.useContext(UsersContext);

  const handleFormValueChange = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
    setShowRevertButton(machine.serial_number !== undefined);
  };

  const handleRevert = () => {
    setShowRevertButton(false);
    setFormValues({
      name: machine?.machine_name,
      serial_number: machine?.serial_number,
      autobulk: machine?.autobulk,
      subscription_active: machine?.subscription_active,
      status: machine?.status,
      description: machine?.description,
      contact_name: machine?.contact_name,
      contact_number: machine?.contact_number,
      address: machine?.address,
      subscription_expiration_date: machine?.subscription_expiration_date,
    });
  };

  const afterUpdateSuccess = () => {
    window.location.href = `/account_management/company_machines/${machine?.serial_number}`;
  };

  const handleUpdate = () => {
    const userAccessEmails = selectedUsers.map((user) => user.value);
    const updatedFormValues = { ...formValues, user_access_emails: userAccessEmails };
    updateMachine(machine.serial_number, updatedFormValues, afterUpdateSuccess);
  };

  React.useEffect(() => {
    setFormValues({
      name: machine?.machine_name,
      active: machine?.active,
      machine_id: machine?.machine_id,
      serial_number: machine?.serial_number,
      autobulk: machine?.autobulk,
      subscription_active: machine?.subscription_active,
      status: machine?.status,
      description: machine?.description,
      contact_name: machine?.contact_name,
      contact_number: machine?.contact_number,
      address: machine?.address,
    });

    setSelectedUsers(
      machine.user_access?.map((user_access) => ({
        value: user_access.email_address,
        label: `${user_access.full_name} (${user_access.email_address})`,
      })) || [],
    );
  }, [machine]);

  const action = (
    <>
      <Button style={{ color: '#ccc' }} onClick={handleRevert}>
        UNDO CHANGES
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleRevert}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3} rowSpacing={2} columnSpacing={12}>
            <Grid item xs={12} md={5}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.name}
                    onChange={(value) => handleFormValueChange('name', value)}
                    label="Machine Name"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.description}
                    onChange={(value) => handleFormValueChange('description', value)}
                    label="Machine Description"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.address}
                    onChange={(value) => handleFormValueChange('address', value)}
                    label="Machine Address"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.contact_name}
                    onChange={(value) => handleFormValueChange('contact_name', value)}
                    label="Contact Name"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.contact_number}
                    onChange={(value) => handleFormValueChange('contact_number', value)}
                    label="Contact Number"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PolarisLabel label="User Access" />
                  <UserAccessSelector
                    users={users.map((user) => ({
                      label: `${user.full_name} (${user.email_address})`,
                      value: user.email_address,
                    }))}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    loading={usersLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          {machine?.serial_number && (
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              {machineSubmitting ? 'Updating...' : 'Update Machine'}
            </Button>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default UserMachineForm;
