/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import SeedAutoTreatForm from './SeedsAutoTreatForm';

interface SeedAutoTreatDetailProps {
  seed: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const SeedsAutoTreatDetail: React.FC<SeedAutoTreatDetailProps> = (props) => {
  const { seed, machineSerial, onSuccess, onBack } = props;

  const { setDetailPage, activeSeedAutoTreat, activeMachine } = React.useContext(SeedsAutoTreatContext);

  return (
    <>
      <SeedAutoTreatForm
        seed={activeSeedAutoTreat}
        machineSerial={activeMachine?.value}
        onSuccess={() => setDetailPage(false)}
        onBack={onBack}
      />
    </>
  );
};

export default SeedsAutoTreatDetail;
