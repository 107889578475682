import React from 'react';
import { Button, Grid, Snackbar, FormControlLabel, Checkbox } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import { UserProfileContext } from '../contexts/UserProfileContext';
import { User, UserFormValues } from '../../adminUsers/model';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface UserProfileFormProps {
  user: User;
}

const UserProfileForm: React.FC<UserProfileFormProps> = (props) => {
  const { user } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const { updateUser, formErrors } = React.useContext(UserProfileContext);
  const [formValues, setFormValues] = React.useState<UserFormValues>({});
  const [sendInvitationEmail, setSendInvitationEmail] = React.useState(false);

  const handleUpdate = () => {
    showSuccessSnackBar('Saving...');
    updateUser(user.user_id, formValues);
  };

  React.useEffect(() => {
    setFormValues({
      full_name: user?.full_name,
      company: user?.company_id,
      email_address: user?.email_address,
      contact_number: user?.contact_number,
    });
  }, [user]);

  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container rowSpacing={2} columnSpacing={24}>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.full_name}
              errorMessage={formErrors?.full_name}
              onChange={(value) => setFormValues({ ...formValues, full_name: value })}
              label="Full Name"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.email_address?.toLowerCase()}
              errorMessage={formErrors?.email_address}
              onChange={(value) => setFormValues({ ...formValues, email_address: value?.toLowerCase() })}
              label="Email"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.contact_number}
              onChange={(value) => setFormValues({ ...formValues, contact_number: value })}
              label="Contact Number"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {!user.user_id && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setSendInvitationEmail(true)}
                    value="send_invitation"
                    style={{ color: '#424E7A' }}
                  />
                }
                label="Send Invitation Email"
                style={{ marginTop: 5, color: '#424E7A', fontSize: '18px' }}
              />
            )}
          </Grid>
        </Grid>
        <br />
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Update User
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserProfileForm;
