/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CustomersAutoBulkContext } from '../contexts/CustomersAutoBulkContext';
import CustomerAutoBulkForm from './CustomerAutoBulkForm';

interface CustomerAutoBulkDetailProps {
  onBack?: () => void;
}

const CustomersAutoBulkDetail: React.FC<CustomerAutoBulkDetailProps> = (props) => {
  const { onBack } = props;

  const { setDetailPage, activeCustomerAutoBulk, activeMachine } = React.useContext(CustomersAutoBulkContext);

  return (
    <>
      <br />
      <CustomerAutoBulkForm
        customer={activeCustomerAutoBulk}
        machineSerial={activeMachine?.value}
        onSuccess={() => setDetailPage(false)}
        onBack={onBack}
      />
    </>
  );
};

export default CustomersAutoBulkDetail;
