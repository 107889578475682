import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import UserMachineForm from './UserMachineForm';
import MachineMetaInformation from './MachineMetaInformation';

interface UserMachineDetailsProps {
  machine: any;
}

const UserMachineDetails: React.FC<UserMachineDetailsProps> = (props) => {
  const { machine } = props;

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Machine Information (Machine Status: {machine?.connection_state})
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <UserMachineForm machine={machine} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <MachineMetaInformation machine={machine} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserMachineDetails;
