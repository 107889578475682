import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Work Order ID',
    selector: 'work_order_id',
    sortable: true,
    omit: true,
  },
  {
    name: 'Work Order Name',
    selector: 'work_order_name',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Customer Name',
    selector: 'customer_name',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Description',
    selector: 'work_order_description',
    sortable: true,
  },
  {
    name: 'Comment',
    selector: 'work_order_comment',
    sortable: true,
    cell: (row: any) => <p style={{ maxHeight: 70, overflowY: 'auto' }}>{row.work_order_comment}</p>,
  },
  {
    name: 'Modified By',
    selector: 'user_name',
    sortable: true,
  },
  {
    name: `Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time_last_modified',
    sortable: true,
    sortFunction: (rowA, rowB) =>
      new Date(rowA.date_and_time_last_modified).getTime() - new Date(rowB.date_and_time_last_modified).getTime(),
    cell: (row: any) => (
      <p>
        {row.date_and_time_last_modified
          ? format(new Date(`${row.date_and_time_last_modified} UTC`), 'MM/dd/yyyy hh:mm aaa')
          : ''}
      </p>
    ),
  },
  {
    name: 'Completed',
    selector: 'work_order_complete',
    sortable: true,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
  },
];

export const nestedColumns: IDataTableColumn<any>[] = [
  {
    name: 'Product ID',
    selector: 'product_id',
    sortable: true,
  },
  {
    name: 'Product Name',
    selector: 'product_name',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'product_descriptions',
    sortable: true,
  },
  {
    name: 'Product Type',
    selector: 'product_type',
    sortable: true,
  },
  {
    name: 'Rate',
    selector: 'product_rate',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_rate) || 0;
      const valB = parseFloat(b.product_rate) || 0;
      return valA - valB;
    },
  },
];
