/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../shared/tabs/TabPanel';
import { UserCompaniesContext } from '../contexts/UserCompaniesContext';
import UserCompanyDetails from '../components/UserCompanyDetails';

const UserCompanyDetailsPage: React.FC = () => {
  const history = useHistory();
  const { fetchUserCompany, userCompany, companyLoading } = React.useContext(UserCompaniesContext);
  const { companyId } = useParams<Record<string, string>>();
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchUserCompany(companyId);
  }, []);

  return companyLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Company...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <Grid item>
        <Typography variant="h1" component="h1">
          Company: {userCompany?.name}
        </Typography>
      </Grid>
      <br />
      <div>
        <TabPanel value={0} index={0}>
          <UserCompanyDetails company={userCompany} />
        </TabPanel>
      </div>
    </div>
  );
};

export default UserCompanyDetailsPage;
