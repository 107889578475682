import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CircularProgress } from '@mui/material';

interface YearlyGraphProps {
  title?: string;
  labels?: string[];
  values?: string[];
  loading?: boolean;
  fetchFunction?: any;
  yearSetter?: any;
}

const YearlyGraph: React.FC<YearlyGraphProps> = (props) => {
  const { title, labels = [], values = [], loading = true, fetchFunction, yearSetter } = props;

  const lineData = {
    labels: labels.map((label) => label.substring(0, 4)),
    datasets: [
      {
        data: values,
        fill: true,
        borderColor: '#3d5a80',
        backgroundColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        color: 'rgba(63, 81, 181, 0.1)',
        tension: 0.5,
      },
    ],
  };

  const blankData = {
    labels: labels.map((label) => label.substring(0, 10)),
    datasets: [
      {
        data: [0],
        fill: true,
        borderColor: '#3d5a80',
        backgroundColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        color: 'rgba(63, 81, 181, 0.1)',
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          title: () => 'Click to see month data',
        },
      },
    },
    onClick: (event: MouseEvent, elements: any[]) => {
      if (elements.length > 0) {
        const clickedBarIndex = elements[0].index;
        const clickedBarLabel = labels[clickedBarIndex];
        const year = clickedBarLabel.slice(0, 4);
        fetchFunction(year);
        yearSetter(year);
      }
    },
  };

  return (
    <Card style={{ padding: '0px 15px 15px' }}>
      <div style={{ marginLeft: -15, position: 'relative' }}>
        <h6
          style={{
            margin: '15px 35px 7px',
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: 400,
            minHeight: '45px',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {title}
        </h6>
        {loading ? (
          <>
            <div style={{ position: 'absolute', top: '40%', left: '42%' }}>
              <CircularProgress />
              <p style={{ marginLeft: -20, marginTop: 8 }}>Loading data...</p>
            </div>
            <div style={{ opacity: 0.4 }}>
              <Bar height="200px" data={blankData} options={options as any} />
            </div>
          </>
        ) : (
          <Bar height="200px" data={lineData} options={options as any} />
        )}
      </div>
    </Card>
  );
};

export default YearlyGraph;
