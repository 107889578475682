import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface MachineIntegrationTabsProps {
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const MachineIntegrationTabs = (tabNames: string[], baseUrl: string): MachineIntegrationTabsProps => {
  const history = useHistory();
  const { tab } = useParams<Record<string, string>>();
  const [tabValue, setTabValue] = React.useState(0);

  const getTabNameByIndex = (index: number): string => {
    return tabNames[index] || tabNames[0];
  };

  const getTabIndexByName = (name: string): number => {
    return tabNames.indexOf(name);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    const tabIndex = getTabIndexByName(tab);
    if (tabIndex !== -1) {
      setTabValue(tabIndex);
    }
  }, [tab]);

  return { tabValue, handleTabChange };
};

export default MachineIntegrationTabs;
