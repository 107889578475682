/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid, Box, Tabs, Tab, Typography } from '@mui/material';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import TransportsAutoBulkDetail from '../components/TransportsAutoBulkDetail';
import URLTabs from '../../../modules/shared/tabs/URLTabs';

const TransportsAutoBulkDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveTransportAutoBulk, activeTransportAutoBulk, activeMachine } =
    React.useContext(TransportsAutoBulkContext);

  const tabNames = ['details'];
  // const baseUrl = `/transports/detail/${activeTransportAutoBulk?.id}`;
  const baseUrl = `/autobulk/machines/transports/`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  const handleBack = () => {
    setDetailPage(false);
    setActiveTransportAutoBulk(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.value}`,
    },
    {
      onClick: () => null,
      text: `${activeTransportAutoBulk?.transport_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Transport List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <TransportsAutoBulkDetail />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default TransportsAutoBulkDetailPage;
