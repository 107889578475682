const permissionGroups = [
  {
    permission_group_id: '1',
    name: 'KSi Super Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
  },
  {
    permission_group_id: '10',
    name: 'KSi Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
  },
  {
    permission_group_id: '3',
    name: 'KSi Support Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
  },
  {
    permission_group_id: '5',
    name: 'Company Super Admin',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
  },
  {
    permission_group_id: '6',
    name: 'Default User',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
  },
];

export default permissionGroups;
