import React from 'react';
import { Grid } from '@mui/material';
import TransactionWeeklyGraph from './TransactionWeeklyGraph';
import TransactionYearOverYearGraph from './TransactionYearOverYearGraph';
import { AutoBulkTransactionsContext } from '../contexts/AutoBulkTransactionsContext';

const TransactionSummaryCharts: React.FunctionComponent = () => {
  const { transactionCharts, transactionChartsLoading } = React.useContext(AutoBulkTransactionsContext);

  const currentYear = new Date().getFullYear();
  const lastFiveYears = Array.from({ length: 5 }, (_, i) => String(currentYear - i)).reverse();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <TransactionWeeklyGraph
          title="Number of Orders Per Day"
          labels={transactionCharts.orderPerDayLabels}
          values={transactionCharts.orderPerDay}
          loading={transactionChartsLoading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TransactionWeeklyGraph
          title="Total Mass Delivered Per Day"
          labels={transactionCharts.unitsDeliveredPerDayLabels}
          values={transactionCharts.unitsDeliveredPerDay}
          loading={transactionChartsLoading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TransactionWeeklyGraph
          title="Number of Orders Per Week"
          labels={transactionCharts.unitsDeliveredPerWeekLabels}
          values={transactionCharts.unitsDeliveredPerWeek}
          loading={transactionChartsLoading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TransactionYearOverYearGraph
          title="Year-over-Year"
          labels={lastFiveYears}
          ytdValues={transactionCharts.ytdUnitsDelivered}
          yoyValues={transactionCharts.yoyUnitsDelivered}
          loading={transactionChartsLoading}
        />
      </Grid>
    </Grid>
  );
};

export default TransactionSummaryCharts;
