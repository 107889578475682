import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { UserMachineAccessContext } from '../contexts/UserMachineAccessContext';
import { AuthContext } from '../../auth/contexts/AuthContext';
import UserMachineAccessForm from '../../shared/form/UserMachineAccessForm';

interface AssignMachineAccessFormProps {
  user: any;
  machines: any;
  setMachines: any;
}

const AssignMachineAccessForm: React.FC<AssignMachineAccessFormProps> = (props) => {
  const { user, machines, setMachines } = props;

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { userMachineAccessOptions, createBulkUserMachineAccess, userMachineAccessSubmitting } =
    React.useContext(UserMachineAccessContext);

  const [showAccessFormCompanyMachines, setShowAccessFormCompanyMachines] = React.useState(true);
  const [filteredMachineAccessOptions, setFilteredMachineAccessOptions] = React.useState<Record<string, string>[]>([]);

  React.useEffect(() => {
    setFilteredMachineAccessOptions(
      userMachineAccessOptions?.machine_options?.filter((option) => option.company_id === user?.company),
    );
  }, [userMachineAccessOptions, setFilteredMachineAccessOptions, user]);

  const handleUpdate = () => {
    createBulkUserMachineAccess({ user_id: user.user_id, machine_ids: machines });
  };

  const toggleCompanyMachines = () => {
    if (showAccessFormCompanyMachines) {
      setShowAccessFormCompanyMachines(false);
      setFilteredMachineAccessOptions(userMachineAccessOptions?.machine_options);
    } else {
      setShowAccessFormCompanyMachines(true);
      setFilteredMachineAccessOptions(
        filteredMachineAccessOptions?.filter((option) => option.company_id === user?.company),
      );
    }
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Assign Machine Access
              </Typography>
              <Typography>
                {showAccessFormCompanyMachines ? `(Filtered by Company: ${user?.company_name})` : ``}
              </Typography>
            </Grid>
            <Grid item>
              {currentUserHasAdminPermission('ksi_admin') && (
                <Button variant="contained" color="primary" onClick={toggleCompanyMachines} style={{ float: 'right' }}>
                  {showAccessFormCompanyMachines
                    ? 'Show All Machines'
                    : `Show Company Machines (Company Name: ${user?.company_name})`}
                </Button>
              )}
            </Grid>
          </Grid>

          <UserMachineAccessForm
            label="Machines"
            loading={false}
            pluralLabel="Machines"
            allChoices={userMachineAccessOptions?.machine_options || []}
            choices={filteredMachineAccessOptions || []}
            setValue={(value) => {
              setMachines([].concat(value));
            }}
            value={machines as string[]}
            initialValue={machines || user?.associated_machines?.map((machine) => machine?.machine_id)}
          />
          <br />
          {currentUserHasAdminPermission('ksi_admin') && (
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              {userMachineAccessSubmitting ? 'Saving...' : 'Save Machine Access'}
            </Button>
          )}
        </Grid>
        <br />
      </Grid>
    </Grid>
  );
};

export default AssignMachineAccessForm;
