import React from 'react';
import { Button, Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface OrdersChartProps {
  currentYear: number;
  lastYear: number;
  loading: boolean;
  serialNumber: number;
  systemType: string;
}

const OrdersChart: React.FC<OrdersChartProps> = ({
  currentYear = 0,
  lastYear = 0,
  loading,
  serialNumber,
  systemType,
}) => {
  const history = useHistory();

  const handleButtonClick = () => {
    if (systemType === 'AutoTreat') {
      history.push(`autotreat/analytics/transaction_summary?machine=${serialNumber}`);
    } else {
      history.push(`autobulk/analytics/transaction_summary?machine=${serialNumber}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  let progressValue = 0;
  let displayValue = 0;

  if (currentYear === 0 || (currentYear === null && lastYear === 0) || lastYear === null) {
    progressValue = 0;
    displayValue = 0;
  } else {
    progressValue = Math.round((currentYear / lastYear) * 100);
    displayValue = progressValue > 100 ? 100 : progressValue;
  }

  return (
    <Card style={{ height: '285px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <CardContent style={{ overflowY: 'auto', padding: '5px' }}>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box display="flex" justifyContent="space-between" width="100%">
            <div style={{ padding: '1% 10%' }}>
              <Typography variant="h6" gutterBottom>
                Orders
              </Typography>
            </div>
            <div style={{ padding: '1% 10%' }}>
              <Typography variant="caption" gutterBottom>
                Jan - Dec
              </Typography>
            </div>
          </Box>

          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant={loading ? 'indeterminate' : 'determinate'}
              value={100}
              size={120}
              thickness={4.5}
              style={{ color: 'grey' }}
            />
            <CircularProgress
              variant={loading ? 'indeterminate' : 'determinate'}
              value={displayValue}
              size={120}
              thickness={4.5}
              style={{ color: '#008080', position: 'absolute' }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4" component="div" style={{ color: '#008080' }}>
                {progressValue}%
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '16px 0', backgroundColor: '#008080', color: '#fff' }}
            onClick={handleButtonClick}
          >
            View All Data
          </Button>

          <Box display="flex" justifyContent="space-between" width="80%">
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {currentYear}
              <Typography variant="caption" display="block">
                Current Year
              </Typography>
            </Typography>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {lastYear}
              <Typography variant="caption" display="block">
                Last Year
              </Typography>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrdersChart;
