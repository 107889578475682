import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { NotificationContext } from '../contexts/NotificationContext';
import NotificationForm from '../components/NotificationForm';

const NotificationDetailPage: React.FunctionComponent = () => {
  const { notificationId } = useParams<Record<string, string>>();
  const { fetchNotification, notification, updateNotification } = React.useContext(NotificationContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const loadNotification = async () => {
      await fetchNotification(notificationId);
      setIsLoading(false);
    };

    if (isLoading) {
      loadNotification();
    }
  }, [notificationId, fetchNotification]);

  const handleBack = () => {
    history.goBack();
  };

  return isLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2>Loading Page...</h2>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography sx={{ cursor: 'pointer', marginBottom: 2 }} onClick={handleBack} variant="subtitle1">
        ← Return to Notification List
      </Typography>
      <Typography variant="h1" component="h1">
        Notification Details
      </Typography>
      <p>View and update the details of your notification.</p>
      <NotificationForm
        notification={notification}
        onSubmit={(values) => updateNotification(notificationId, values)}
        onCancel={handleBack}
      />
    </>
  );
};

export default NotificationDetailPage;
