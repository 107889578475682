import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#3d5a80',
      dark: '#3d5a80',
    },
    secondary: {
      main: '#3d5a80',
    },
    error: {
      main: '#FF0000',
    },
    warning: {
      main: '#FFA500',
    },
    background: {
      default: '#F4F4F4',
    },
    info: {
      main: '#D8D8D8',
    },
    success: {
      main: '#00FF00',
    },
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Lato"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),

    h1: {
      fontSize: '26px',
      fontWeight: 600,
    },

    h2: {
      fontSize: '20px',
      fontWeight: 600,
    },

    h3: {
      fontSize: '18px',
      fontWeight: 600,
    },

    h4: {
      color: '#333333',
      fontSize: '14px',
    },

    h5: {
      color: '#333333',
      fontSize: '14px',
    },

    h6: {
      color: '#333333',
      fontSize: '14px',
    },

    subtitle1: {
      fontSize: '14px',
      color: '#424E7A',
      marginBottom: '4px',
    },

    subtitle2: {
      color: '#424E7A',
      fontSize: '10px',
      textDecoration: 'none',
    },

    body1: {
      fontSize: '15px',
      color: '#333333',
    },

    body2: {
      fontSize: '14px',
      color: '#333333',
    },

    button: {
      fontSize: '14px',
      boxShadow: 'none',
      height: '40px',
    },

    caption: {
      fontSize: '14px',
    },

    overline: {
      fontSize: '14px',
    },
  },
});

export default theme;
