/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import { TransportAutoBulk } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TransportAutoBulkFilterBar from '../components/TransportsAutoBulkFilterBar';

const TransportsAutoBulkList: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const {
    fetchTransportsAutoBulk,
    transports,
    setCreatingPage,
    setDetailPage,
    setActiveTransportAutoBulk,
    activeMachine,
    setActiveMachine,
    fetchTransportOptionsAutoBulk,
  } = React.useContext(TransportsAutoBulkContext);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchTransportsAutoBulk]);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Transports Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <TransportAutoBulkFilterBar machineChoices={autoBulkMachines} machineChoicesLoading={machinesLoading} />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Transports List
                </Typography>
              </Grid>

              <Button
                variant="contained"
                color="primary"
                disabled={activeMachine?.value === 'Select A Machine' || activeMachine?.value === undefined}
                onClick={() => {
                  setCreatingPage(true);
                  setFitlerBarActive(false);
                }}
              >
                Create Transport
              </Button>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={transports}
                defaultSortField=""
                defaultSortAsc
                striped
                onRowClicked={(row: TransportAutoBulk) => {
                  setActiveTransportAutoBulk(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                  fetchTransportOptionsAutoBulk(activeMachine.value);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default TransportsAutoBulkList;
