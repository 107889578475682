import React from 'react';
import { useDrag } from '@use-gesture/react';

import { FieldAssignmentMap } from '../../../parser';
import { Column } from '../ColumnPreview';
import { DragState } from '../ColumnDragState';
import TargetBox from './TargetBox';
import { Field } from '../../ImporterField';

export type FieldTouchedMap = { [name: string]: boolean | undefined };

import './ColumnDragTargetArea.scss';
import { useLocale } from '../../../locale/LocaleContext';

export const ColumnDragTargetArea: React.FC<{
  hasHeaders: boolean; // for correct display of dummy card
  fields: Field[];
  columns: Column[];
  fieldRowSize?: number;
  fieldTouched: FieldTouchedMap;
  fieldAssignments: FieldAssignmentMap;
  dragState: DragState | null;
  eventBinder: (
    // @todo import type from drag state tracker
    column: Column,
    startFieldName?: string,
  ) => ReturnType<typeof useDrag>;
  onHover: (fieldName: string, isOn: boolean) => void;
  onAssign: (fieldName: string) => void;
  onUnassign: (column: Column) => void;
}> = ({
  hasHeaders,
  fields,
  columns,
  fieldTouched,
  fieldAssignments,
  dragState,
  eventBinder,
  onHover,
  onAssign,
  onUnassign,
}) => {
  const l10n = useLocale('fieldsStep');

  const flexBasis = `100%`;

  return (
    <section
      className="CSVImporter_ColumnDragTargetArea"
      aria-label={l10n.dragTargetAreaCaption}
      style={{ borderLeft: '1px solid lightgray', paddingLeft: '15px', overflowY: 'scroll', height: 600 }}
    >
      {fields.map((field) => {
        const assignedColumnIndex = fieldAssignments[field.name];

        return (
          <TargetBox
            key={field.name}
            field={field}
            flexBasis={flexBasis}
            touched={fieldTouched[field.name]}
            hasHeaders={hasHeaders}
            assignedColumn={assignedColumnIndex !== undefined ? columns[assignedColumnIndex] : null}
            dragState={dragState}
            eventBinder={eventBinder}
            onHover={onHover}
            onAssign={onAssign}
            onUnassign={onUnassign}
          />
        );
      })}
    </section>
  );
};
