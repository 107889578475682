import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Paper, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface MachineSubscriptionStatusProps {
  machineStats: {
    active_services?: number;
    inactive_services?: number;
  };
  loading?: boolean;
}

const MachineSubscriptionStatus: React.FC<MachineSubscriptionStatusProps> = ({ machineStats, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  const totalServices = (machineStats.active_services || 0) + (machineStats.inactive_services || 0);
  const activePercentage = ((machineStats.active_services || 0) / totalServices) * 100;
  const inactivePercentage = ((machineStats.inactive_services || 0) / totalServices) * 100;

  const subscriptionData = {
    labels: ['Active', 'Expired'],
    datasets: [
      {
        data: [activePercentage, inactivePercentage],
        backgroundColor: ['#4682B4', '#9EA79E'],
      },
    ],
  };

  const generateLabels = (chart: any) => {
    const { data } = chart;
    if (data.labels.length && data.datasets.length) {
      return data.labels.map((label, i) => {
        return {
          text: `${label}: ${Math.round(data.datasets[0].data[i])}%`,
          fillStyle: data.datasets[0].backgroundColor[i],
        };
      });
    }
    return [];
  };

  const chartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${Math.round(currentValue)}%`;
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          generateLabels,
        },
      },
    },
  };

  return (
    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center' }}>
      <Typography variant="h6">Machine Subscription Status</Typography>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ marginRight: '10px' }}>
          <ArrowUpwardIcon fontSize="inherit" style={{ verticalAlign: 'middle', color: '#4682B4' }} />
          {`${machineStats.active_services || 0} Active`}
        </Typography>
        <Typography style={{ marginLeft: '10px' }}>
          <ArrowDownwardIcon fontSize="inherit" style={{ verticalAlign: 'middle', color: '#9EA79E' }} />
          {`${machineStats.inactive_services || 0} Expired`}
        </Typography>
      </div>
      <Pie data={subscriptionData} options={chartOptions} />
    </Paper>
  );
};

export default MachineSubscriptionStatus;
