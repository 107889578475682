/* eslint-disable react-hooks/exhaustive-deps, no-alert, no-nested-ternary, react/jsx-props-no-spreading */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ToolsMajor, DigitalMediaReceiverMajor, CustomersMajor } from '@shopify/polaris-icons';
import { Button, Grid, Stack, Switch, Typography, styled } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import AppsIcon from '@mui/icons-material/Apps';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Navigation } from '@shopify/polaris';
import Logo from '../../../assets/ksi-logo.png';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { superAdminRoutes, adminRoutes, supportAdminRoutes } from '../constants/adminRoutes';
import { autotreatMachineManagementRoutes, autoTreatAnalyticsRoutes } from '../constants/autotreatUserRoutes';
import {
  autobulkMachineManagementRoutes,
  autobulkRealTimeActivityRoutes,
  autobulkAnalyticsRoutes,
} from '../constants/autobulkUserRoutes';
import {
  allAccountManagementRoutes,
  machineAccountManagementRoutes,
  allAccountManagementAndNotificationRoutes,
} from '../constants/sharedUserRoutes';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#ccc',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#3d5a80',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#ccc',
    boxSizing: 'border-box',
  },
}));

const options = ['AutoTreat', 'AutoBulk', 'Level Sensors'];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth0();
  const {
    currentUserHasAdminPermission,
    currentUserHasUserPermission,
    hasAutoBulkMachines,
    hasAutoTreatMachines,
    levelSensorsModuleEnabled,
    companyManaged,
    accessToken,
    sidebarLoading,
    loginMessage,
  } = React.useContext(AuthContext);

  const [showAutoTreat, setShowAutoTreat] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    if (index === 0 && hasAutoTreatMachines) {
      setSelectedIndex(0);
      sessionStorage.setItem('showAutoTreatSidebar', 'true');
      history.push('/autotreat/analytics/transaction_summary');
      setShowAutoTreat(showAutoTreat);
    } else if (index === 0) {
      window.alert('You do not currently have access to AutoTreat machines.');
    } else if (index === 1 && hasAutoBulkMachines) {
      setSelectedIndex(1);
      sessionStorage.setItem('showAutoTreatSidebar', 'false');
      history.push('/autobulk/analytics/transaction_summary');
      setShowAutoTreat(showAutoTreat);
    } else if (index === 1) {
      window.alert('You do not currently have access to AutoBulk machines.');
    } else if (index === 2 && hasAutoBulkMachines) {
      setSelectedIndex(2);
      sessionStorage.setItem('showAutoTreatSidebar', 'false');
      history.push('/autobulk/level_sensors');
      setShowAutoTreat(showAutoTreat);
    } else if (index === 2) {
      window.alert('You do not currently have access to AutoBulk machines.');
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (location.pathname.includes('autotreat')) {
      sessionStorage.setItem('showAutoTreatSidebar', 'true');
    }
    if (location.pathname.includes('autobulk')) {
      sessionStorage.setItem('showAutoTreatSidebar', 'false');
    }
    setShowAutoTreat(sessionStorage.getItem('showAutoTreatSidebar') !== 'false');
    setSelectedIndex(
      window.location.href.includes('level_sensors')
        ? 2
        : sessionStorage.getItem('showAutoTreatSidebar') !== 'false'
        ? 0
        : 1,
    );
  }, [location.pathname, sessionStorage.getItem('showAutoTreatSidebar')]);

  const autoTreatRealTimeActivity = {
    label: 'Real Time Activity',
    url: '/autotreat/real_time_activity',
    icon: ToolsMajor,
  };

  const levelSensorRoutes = {
    label: 'Level Sensors',
    url: '/autobulk/level_sensors',
    icon: DigitalMediaReceiverMajor,
  };

  const notificationRoutes = () => {
    return {
      label: 'Account Management',
      url: '/account_management/my_machines',
      icon: CustomersMajor,
      matches: true,
      subNavigationItems: [
        {
          url: '/account_management/company_machines',
          disabled: false,
          label: 'Company Machines',
        },
        {
          url: '/account_management/my_machines',
          disabled: false,
          label: 'My Machines',
        },
        {
          url: '/account_management/users',
          disabled: false,
          label: 'Users',
        },
        {
          url: '/account_management/company',
          disabled: false,
          label: 'Company',
        },
        {
          url: '/account_management/notifications',
          disabled: false,
          label: 'Notifications',
        },
        {
          url: '/machine_requests',
          disabled: false,
          label: 'Machine Requests',
        },
      ],
    };
  };

  const fetchAdminRoutes = () => {
    if (currentUserHasAdminPermission('ksi_superadmin')) {
      return superAdminRoutes;
    }

    if (currentUserHasAdminPermission('ksi_admin')) {
      return adminRoutes;
    }

    if (currentUserHasAdminPermission('ksi_support_admin')) {
      return supportAdminRoutes;
    }

    return null;
  };

  return (
    <Navigation location={location.pathname}>
      <a href="/admin/client-projects" className="Polaris-TopBar__NavigationIcon">
        <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
      </a>
      {loginMessage && <p style={{ marginLeft: 25, marginTop: 10, marginBottom: -10 }}>{loginMessage}</p>}
      <hr />
      {hasAutoTreatMachines && hasAutoBulkMachines && (
        <>
          <Grid
            onClick={handleToggle}
            container
            ref={anchorRef}
            aria-label="split button"
            style={{ boxShadow: 'none', display: 'inherit', paddingLeft: '2rem', marginBottom: 10, cursor: 'pointer' }}
          >
            <Grid item>
              <AppsIcon style={{ fontSize: 28, color: '#3d5a80' }} />
            </Grid>
            <Grid item onClick={handleClick} style={{ marginLeft: 15 }}>
              <Typography variant="h2" style={{ color: '#333', marginTop: 2 }}>
                {options[selectedIndex]}
              </Typography>
            </Grid>
          </Grid>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 999 }}
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem style={{ zIndex: 999 }}>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {
        {
          AutoTreat: (
            <Navigation.Section
              items={[
                currentUserHasUserPermission('reporting') && autoTreatRealTimeActivity,
                currentUserHasUserPermission('reporting') && autoTreatAnalyticsRoutes,
                (currentUserHasUserPermission('data_management') || user.email === 'demo@ksiedge.com') &&
                  autotreatMachineManagementRoutes,
              ]}
            />
          ),
          AutoBulk: (
            <Navigation.Section
              items={[
                currentUserHasUserPermission('reporting') && autobulkRealTimeActivityRoutes,
                currentUserHasUserPermission('reporting') && autobulkAnalyticsRoutes,
                (currentUserHasUserPermission('data_management') || user.email === 'demo@ksiedge.com') &&
                  autobulkMachineManagementRoutes,
              ]}
            />
          ),
          'Level Sensors': (
            <Navigation.Section
              items={[
                (levelSensorsModuleEnabled || currentUserHasAdminPermission('ksi_support_admin')) && levelSensorRoutes,
              ]}
            />
          ),
        }[options[selectedIndex]]
      }
      {(companyManaged || currentUserHasAdminPermission('ksi_support_admin')) && (
        <>
          <Navigation.Section
            items={[
              currentUserHasUserPermission('company_super_admin')
                ? notificationRoutes()
                : machineAccountManagementRoutes,
              currentUserHasAdminPermission('ksi_support_admin') && fetchAdminRoutes(),
            ]}
            separator
          />
        </>
      )}
    </Navigation>
  );
};

export default Sidebar;
