import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { Source } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';

interface SourcesAutoBulkContextInterface {
  fetchCachedSources?: (machineSerial: string) => Promise<void>;
  fetchSources?: (machineSerial: string, genericFilter: string, onSuccess?: () => void) => Promise<void>;
  sourcesRefreshedAt?: string;
  sources?: Source[];
  sourcesLoading?: boolean;

  fetchSource?: (LiquidNameID: string) => Promise<void>;
  source?: Source;
  sourceLoading?: boolean;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const SourcesAutoBulkContext = React.createContext<SourcesAutoBulkContextInterface>({});

const SourcesAutoBulkContextConsumer = SourcesAutoBulkContext.Consumer;
const SourcesAutoBulkContextProvider: React.FC = ({ children }) => {
  const [sources, setSources] = React.useState([]);
  const [sourcesLoading, setSourcesLoading] = React.useState(true);
  const [sourcesRefreshedAt, setSourcesRefreshedAt] = React.useState<string>();
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('productAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('productAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') {
      setSourcesLoading(false);
    }
  }, [activeMachine]);

  const fetchCachedSources = async (machineSerial: string) => {
    setSourcesLoading(true);
    setSourcesRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/sources/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setSourcesLoading(false);
        setSources(response.data.results);
        setSourcesRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setSourcesLoading(false);
        setSources([]);
        setSourcesRefreshedAt(null);
      });
  };

  const fetchSources = async (machineSerial: string, genericFilter: string, onSuccess?: () => void) => {
    setSourcesLoading(true);
    setSourcesRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/sources/?serial-number=${machineSerial}&generic-filter=${genericFilter}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/sources/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setSourcesLoading(false);
              setSources(response.data.results);
              setSourcesRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setSourcesLoading(false);
                setSources([]);
                setSourcesRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <SourcesAutoBulkContext.Provider
      value={{
        fetchCachedSources,
        fetchSources,
        sources,
        sourcesRefreshedAt,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
      }}
    >
      {children}
    </SourcesAutoBulkContext.Provider>
  );
};

export { SourcesAutoBulkContextProvider, SourcesAutoBulkContextConsumer, SourcesAutoBulkContext };
