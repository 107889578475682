import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CustomMaterialMenu from '../../../modules/dataTable/rowMenu/CustomMaterialMenu';
import ConfirmCancelModal from '../../../modules/shared/modal/ConfirmCancelModal';
import { AutoTreatTransactionsContext } from '../../transactions/contexts/AutoTreatTransactionsContext';
import FormInput from '../../../modules/shared/form/FormInput';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TransactionRowMenuProps {
  transaction: any;
}

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
}));

const TransactionRowMenu: React.FC<TransactionRowMenuProps> = (props: TransactionRowMenuProps) => {
  const { transaction } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const classes = useStyles();
  const { user } = useAuth0();
  const { emailTransaction } = React.useContext(AutoTreatTransactionsContext);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [destEmail, setDestEmail] = React.useState<string>('');

  const handleEmail = async () => {
    emailTransaction(transaction.ksi_transaction_guid, destEmail, new Date().getTimezoneOffset(), () =>
      setModalOpen(false),
    );
    showSuccessSnackBar(
      'Your email is now being processed. Please check your email inbox to view your transaction receipt.',
    );
  };

  return (
    <Box>
      <ConfirmCancelModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleEmail}
        closeOnConfirm
        title="Email Transaction Receipt"
        hasFooterBar
        confirmLabel="Send Email"
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Email the transaction receipt for Transaction {transaction?.order_name}?
          </Typography>
          <br />
          <FormInput value={destEmail} onChange={(value) => setDestEmail(value)} label="Email" />
        </div>
      </ConfirmCancelModal>
      <CustomMaterialMenu size="small">
        <MenuItem
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_API_ENDPOINT}/api/autotreat/analytics/transactions/pdf?id=${
                transaction.ksi_transaction_guid
              }&email=${user?.email}&utcOffset=${new Date().getTimezoneOffset()}`,
              '_blank',
              'noreferrer',
            )
          }
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DownloadIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Download Receipt</Typography>
        </MenuItem>
        <MenuItem onClick={() => setModalOpen(true)}>
          <ListItemIcon className={classes.listItemIcon}>
            <AttachEmailIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Email Receipt</Typography>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default TransactionRowMenu;
