/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import OrdersAutoTreatForm from './OrdersAutoTreatForm';

interface OrderAutoTreatDetailProps {
  order: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const OrdersAutoTreatDetail: React.FC<OrderAutoTreatDetailProps> = (props) => {
  const { order, machineSerial, onSuccess, onBack } = props;

  const { setDetailPage, activeOrderAutoTreat } = React.useContext(OrdersAutoTreatContext);

  return (
    <>
      <br />
      <OrdersAutoTreatForm order={activeOrderAutoTreat} onSuccess={() => setDetailPage(false)} onBack={onBack} />
    </>
  );
};

export default OrdersAutoTreatDetail;
