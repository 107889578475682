import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Tooltip } from '@mui/material';
import map from '../constants/usaMap.json';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';

const MapChart: React.FunctionComponent = () => {
  const {
    fetchMachineCountPerState,
    machineCountPerState,
    selectedState,
    setSelectedState,
    stateColorData,
    stateColorDataLoading,
    seedUnitsPerStateByYear,
    fetchSeedUnitsPerStateByYear,
    mapDataType,
    handleStateDataChange,
    fetchSeedUnitsPerYearByState,
    selectedYear,
  } = React.useContext(AdminAnalyticsContext);

  React.useEffect(() => {
    fetchMachineCountPerState();
    fetchSeedUnitsPerStateByYear(selectedYear);
  }, []);

  React.useEffect(() => {
    if (mapDataType === 'Machine Count') {
      handleStateDataChange(machineCountPerState, 'unique_machine_count');
    }
    if (mapDataType === 'Total Seed Units') {
      handleStateDataChange(seedUnitsPerStateByYear, 'total_seed_units');
    }
  }, [mapDataType, selectedState, seedUnitsPerStateByYear]);

  // const [hoveredStateData, setHoveredStateData] = React.useState(null);
  // const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });

  const handleStateClick = (state, STUSPS) => {
    setSelectedState(STUSPS);
    fetchSeedUnitsPerYearByState(STUSPS);
  };

  const projectionConfig = {
    scale: 1050,
    center: [0, 33],
    rotate: [96.5, 0, 0],
  };

  return (
    <>
      <ComposableMap projectionConfig={projectionConfig}>
        <Geographies geography={map}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const { STUSPS } = geo.properties;
              const state = stateColorData.find((s) => s.state === STUSPS);

              const color = state ? state.color : '#cccccc';

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: color,
                      outline: 'none',
                      stroke: '#000000',
                      strokeWidth: 0.1,
                    },
                    hover: {
                      fill: color,
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#cc9933',
                      outline: 'none',
                    },
                  }}
                  onClick={() => {
                    handleStateClick(state, STUSPS);
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>

      {/* Tooltip to display state data, this guy is wonky and needs more work. */}
      {/* {hoveredStateData && (
      <div style={{ position: 'fixed', left: `${tooltipPosition.x}px`, top: `${tooltipPosition.y+150}px`, height: '0', width: '0' }}>
        <Tooltip title={hoveredStateData} open placement="top">
          <div />
        </Tooltip>
      </div>
    )} */}
    </>
  );
};

export default MapChart;
