/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../shared/tabs/TabPanel';
import UserMachineDetails from '../components/UserMachineDetails';
import { UserMachinesContext } from '../contexts/UserMachinesContext';
import { UsersContext } from '../../userUsers/contexts/UsersContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';

const UserMachineDetail: React.FC = () => {
  const { fetchMachine, machine, machineLoading } = React.useContext(UserMachinesContext);
  const { fetchUsers } = React.useContext(UsersContext);

  const { machineSerial, tab } = useParams<Record<string, string>>();

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    fetchMachine(machineSerial);
  }, [machineSerial]);

  React.useEffect(() => {
    fetchUsers();
  }, [machine]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      window.history.pushState('', '', `/account_management/my_machines/${machine.serial_number}/details`);
    }
  };

  React.useEffect(() => {
    setValue(0);
  }, [tab]);

  const links = [
    {
      href: '/user/machine_management',
      text: 'Machine Management',
    },
  ];

  return machineLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Machine...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>

      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="Machine Details" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserMachineDetails machine={machine} />
        </TabPanel>
      </div>
    </div>
  );
};

export default UserMachineDetail;
