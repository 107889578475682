import React from 'react';

interface PolarisLabelProps {
  label: string;
  htmlFor?: string;
  styleOverrides?: Record<any, string | number>;
}

const PolarisLabel: React.FunctionComponent<PolarisLabelProps> = (props) => {
  const { label, htmlFor = '', styleOverrides = {} } = props;

  return (
    <div className="Polaris-Labelled__LabelWrapper">
      <div className="Polaris-Label">
        <label htmlFor={htmlFor} className="Polaris-Label__Text">
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label
                htmlFor={htmlFor}
                className="Polaris-Label__Text"
                style={{ marginBottom: '-5px', ...styleOverrides }}
              >
                {label}
              </label>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default PolarisLabel;
