import React from 'react';
import axios from '../../utils/axios.utils';
import { defaultStateData } from '../constants/defaultStateData.json';

interface AdminAnalyticsContextInterface {
  fetchOrdersByYearCharts?: () => Promise<void>;
  ordersByYearCharts?: Record<string, any>;
  ordersByYearChartsLoading?: boolean;

  fetchOrdersByMonthCharts?: (year: string) => Promise<void>;
  ordersByMonthCharts?: Record<string, any>;
  ordersByMonthChartsLoading?: boolean;
  ordersSelectedYear?: string;
  setOrdersSelectedYear?: any;

  fetchSeedUnitsByYearCharts?: () => Promise<void>;
  seedUnitsByYearCharts?: Record<string, any>;
  seedUnitsByYearChartsLoading?: boolean;

  fetchSeedUnitsByMonthCharts?: (year: string) => Promise<void>;
  seedUnitsByMonthCharts?: Record<string, any>;
  seedUnitsByMonthChartsLoading?: boolean;

  fetchMachineCountPerState?: () => Promise<void>;
  machineCountPerState?: Record<string, any>;
  machineCountPerStateLoading?: boolean;

  fetchSeedUnitsPerStateByYear?: (year: string) => Promise<void>;
  seedUnitsPerStateByYear?: Record<string, any>;
  seedUnitsPerStateByYearLoading?: boolean;

  fetchSeedUnitsPerYearByState?: (state: string) => Promise<void>;
  seedUnitsPerYearByState?: Record<string, any>;
  seedUnitsPerYearByStateLoading?: boolean;

  fetchStateColorData?: (stateData: unknown, value: string) => Promise<void>;
  stateColorData?: Record<string, any>;
  stateColorDataLoading?: boolean;

  handleStateDataChange?: (data: any, value: string) => Promise<void>;

  mapDataType?: string;
  setMapDataType?: any;

  selectedYear?: string;
  setSelectedYear?: any;

  selectedState?: string;
  setSelectedState?: any;

  showMonthly?: any;
  setShowMonthly?: any;

  showAverage?: any;
  setShowAverage?: any;

  activeTab?: number;
  setActiveTab?: any;

  errorMessage?: string;
}

const AdminAnalyticsContext = React.createContext<AdminAnalyticsContextInterface>({});

const AdminAnalyticsContextConsumer = AdminAnalyticsContext.Consumer;
const AdminAnalyticsContextProvider: React.FC = ({ children }) => {
  const [ordersByYearCharts, setOrdersByYearCharts] = React.useState<Record<string, any>>({});
  const [ordersByYearChartsLoading, setOrdersByYearChartsLoading] = React.useState(false);

  const [ordersByMonthCharts, setOrdersByMonthCharts] = React.useState<Record<string, any>>({});
  const [ordersByMonthChartsLoading, setOrdersByMonthChartsLoading] = React.useState(false);

  const [seedUnitsByYearCharts, setSeedUnitsByYearCharts] = React.useState<Record<string, any>>({});
  const [seedUnitsByMonthCharts, setSeedUnitsByMonthCharts] = React.useState<Record<string, any>>({});

  const [seedUnitsByMonthChartsLoading, setSeedUnitsByMonthChartsLoading] = React.useState(false);
  const [seedUnitsByYearChartsLoading, setSeedUnitsByYearChartsLoading] = React.useState(false);

  const [machineCountPerState, setMachineCountPerState] = React.useState<Record<string, any>>(defaultStateData);
  const [machineCountPerStateLoading, setMachineCountPerStateLoading] = React.useState(false);

  const [seedUnitsPerStateByYear, setSeedUnitsPerStateByYear] = React.useState<Record<string, any>>(defaultStateData);
  const [seedUnitsPerStateByYearLoading, setSeedUnitsPerStateByYearLoading] = React.useState(false);

  const [seedUnitsPerYearByState, setSeedUnitsPerYearByState] = React.useState<Record<string, any>>(defaultStateData);
  const [seedUnitsPerYearByStateLoading, setSeedUnitsPerYearByStateLoading] = React.useState(false);

  const [mapDataType, setMapDataType] = React.useState<string>('');
  const [selectedYear, setSelectedYear] = React.useState<string>('2023');
  const [selectedState, setSelectedState] = React.useState<string>('');
  const [stateColorData, setStateColorData] = React.useState<Record<string, any>>(defaultStateData);
  const [stateColorDataLoading, setStateColorDataLoading] = React.useState(true);
  const [showMonthly, setShowMonthly] = React.useState(false);
  const [showAverage, setShowAverage] = React.useState('Total');
  const [activeTab, setActiveTab] = React.useState(0);

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const fetchOrdersByYearCharts = async () => {
    setOrdersByYearChartsLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/order_count_by_year/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setOrdersByYearCharts({
          years: response.data.results.map((record: Record<string, string>) => record.year),
          unique_machine_count: response.data.results.map(
            (record: Record<string, string>) => record.unique_machine_count,
          ),
          total_order_count: response.data.results.map((record: Record<string, string>) => record.total_order_count),
          avg_order_count_per_machine: response.data.results.map(
            (record: Record<string, string>) => record.avg_order_count_per_machine,
          ),
        });
        setOrdersByYearChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setOrdersByYearChartsLoading(false);
        setOrdersByYearCharts({});
      });
  };

  const fetchOrdersByMonthCharts = async (year) => {
    setOrdersByMonthChartsLoading(true);

    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/order_count_by_month/?year=${year}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setOrdersByMonthCharts({
          month: response.data.results.map((record: Record<string, string>) => record.month),
          unique_machine_count: response.data.results.map(
            (record: Record<string, string>) => record.unique_machine_count,
          ),
          total_order_count: response.data.results.map((record: Record<string, string>) => record.total_order_count),
          avg_order_count_per_machine: response.data.results.map(
            (record: Record<string, string>) => record.avg_order_count_per_machine,
          ),
        });
        setOrdersByMonthChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setOrdersByMonthChartsLoading(false);
        setOrdersByMonthCharts({});
      });
  };

  const fetchSeedUnitsByYearCharts = async () => {
    setSeedUnitsByYearChartsLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/seed_units_by_year/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setSeedUnitsByYearCharts({
          years: response.data.results.map((record: Record<string, string>) => record.year),
          unique_machine_count: response.data.results.map(
            (record: Record<string, string>) => record.unique_machine_count,
          ),
          total_seed_units: response.data.results.map((record: Record<string, string>) => record.total_seed_units),
          avg_seed_units_per_machine: response.data.results.map(
            (record: Record<string, string>) => record.avg_seed_units_per_machine,
          ),
        });

        setSeedUnitsByYearChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedUnitsByYearChartsLoading(false);
        setSeedUnitsByYearCharts({});
      });
  };

  const fetchSeedUnitsByMonthCharts = async (year) => {
    setSeedUnitsByMonthChartsLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/seed_units_by_month?year=${year}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setSeedUnitsByMonthCharts({
          month: response.data.results.map((record: Record<string, string>) => record.month),
          unique_machine_count: response.data.results.map(
            (record: Record<string, string>) => record.unique_machine_count,
          ),
          total_seed_units: response.data.results.map((record: Record<string, string>) => record.total_seed_units),
          avg_seed_units_per_machine: response.data.results.map(
            (record: Record<string, string>) => record.avg_seed_units_per_machine,
          ),
        });
        setSeedUnitsByMonthChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedUnitsByMonthChartsLoading(false);
        setSeedUnitsByMonthCharts({});
      });
  };

  const fetchMachineCountPerState = async () => {
    setMachineCountPerStateLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/machine_count_per_state`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setMachineCountPerState(response.data.results);
        setMachineCountPerStateLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineCountPerStateLoading(false);
        setMachineCountPerState({});
      });
  };

  // this one has all states data shown for the passed year
  const fetchSeedUnitsPerStateByYear = async (year) => {
    setSeedUnitsPerStateByYearLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/seed_units_per_state_by_year?year=${year}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setSeedUnitsPerStateByYear(response.data.results);
        setSeedUnitsPerStateByYearLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedUnitsPerStateByYearLoading(false);
        setSeedUnitsPerStateByYear({});
      });
  };

  // this one is single state for multiple years
  const fetchSeedUnitsPerYearByState = async (STUSPS) => {
    setSeedUnitsPerYearByStateLoading(true);
    axios
      .get<any, any>(`api/admin/autotreat/admin_analytics/seed_units_per_year_by_state?state=${STUSPS}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setSeedUnitsPerYearByState({
          state: response.data.results.map((record: Record<string, string>) => record.state),
          year: response.data.results.map((record: Record<string, string>) => record.year),
          unique_machine_count: response.data.results.map(
            (record: Record<string, string>) => record.unique_machine_count,
          ),
          total_seed_units: response.data.results.map((record: Record<string, string>) => record.total_seed_units),
          avg_seed_units_per_machine: response.data.results.map(
            (record: Record<string, string>) => record.avg_seed_units_per_machine,
          ),
        });
        setSeedUnitsPerYearByStateLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedUnitsPerYearByStateLoading(false);
        setSeedUnitsPerYearByState({});
      });
    setSeedUnitsPerYearByStateLoading(false);
  };

  const fetchStateColorData = async (stateData, value) => {
    const maxValue = Math.max(...stateData.map((obj) => obj[value]));

    const tempStateColorData = stateData.map((state) => {
      const percentage = state[value] / maxValue;
      const alpha = 1 - (1 - percentage) * 0.63;
      let color = '';
      const stateValue = state[value];

      if (state.state === selectedState) {
        color = '#cc9933';
      } else {
        color = `rgba(61, 90, 128, ${alpha})`;
      }

      return { ...state, color, stateValue };
    });
    setStateColorData(tempStateColorData);
  };

  const handleStateDataChange = async (data, value) => {
    fetchStateColorData(data, value);
  };

  return (
    <AdminAnalyticsContext.Provider
      value={{
        fetchOrdersByYearCharts,
        ordersByYearCharts,
        ordersByYearChartsLoading,

        fetchOrdersByMonthCharts,
        ordersByMonthCharts,
        ordersByMonthChartsLoading,

        fetchSeedUnitsByYearCharts,
        seedUnitsByYearCharts,
        seedUnitsByYearChartsLoading,

        fetchSeedUnitsByMonthCharts,
        seedUnitsByMonthCharts,
        seedUnitsByMonthChartsLoading,

        fetchMachineCountPerState,
        machineCountPerState,
        machineCountPerStateLoading,

        fetchSeedUnitsPerStateByYear,
        seedUnitsPerStateByYear,
        seedUnitsPerStateByYearLoading,

        fetchSeedUnitsPerYearByState,
        seedUnitsPerYearByState,
        seedUnitsPerYearByStateLoading,

        fetchStateColorData,
        stateColorData,
        stateColorDataLoading,

        handleStateDataChange,

        mapDataType,
        setMapDataType,

        selectedYear,
        setSelectedYear,

        selectedState,
        setSelectedState,

        showMonthly,
        setShowMonthly,

        showAverage,
        setShowAverage,

        activeTab,
        setActiveTab,

        errorMessage,
      }}
    >
      {children}
    </AdminAnalyticsContext.Provider>
  );
};

export { AdminAnalyticsContextProvider, AdminAnalyticsContextConsumer, AdminAnalyticsContext };
