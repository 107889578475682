const adminMachineFilters = [
  {
    label: 'System Version',
    pluralLabel: 'System Versions',
    selector: 'machine_meta',
    type: 'select',
    optionSelector: 'system_version_list',
  },
  {
    label: 'InduSoft Project',
    pluralLabel: 'InduSoft Projects',
    selector: 'machine_meta',
    type: 'select',
    optionSelector: 'indusoft_project_list',
  },
  {
    label: 'DB Version',
    pluralLabel: 'DB Versions',
    selector: 'machine_meta',
    type: 'select',
    optionSelector: 'db_version_list',
  },
  {
    label: 'Local Service Version',
    pluralLabel: 'Local Service Versions',
    selector: 'machine_meta',
    type: 'select',
    optionSelector: 'local_service_version_list',
  },
  {
    label: 'PLC Version',
    pluralLabel: 'PLC Versions',
    selector: 'machine_meta',
    type: 'select',
    optionSelector: 'plc_version_list',
  },
];

export default adminMachineFilters;
