import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModalBody from './ModalBody';
import { BaseModalProps, DEFAULT_MODAL_WIDTH } from './model';

export const DEFAULT_CONFIRM_LABEL = 'Export';
export const DEFAULT_CANCEL_LABEL = 'Cancel';

interface DeleteModalProps {
  confirmLabel?: string;
  cancelLabel?: string;
  isOpen?: boolean;
  width?: string;
  height?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: React.ReactElement;
}

interface ModalContentProps {
  width: string;
  height: string;
  hasTitleBar: boolean;
  hasFooterBar: boolean;
}

const useStyles = makeStyles(() => ({
  errorIcon: {
    color: '#F44F64',
    width: '66px !important',
    height: '66px !important',
    marginTop: '20px',
    display: 'inline-block',
    textAlign: 'center',
  },
  modalContainer: {
    overflow: 'scroll',
  },
}));

const ModalContent = styled.div<ModalContentProps>`
  position: absolute;
  text-align: center;
  padding: 20px 0 40px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  display: inline-block;

  ${(props) => `
    left: calc( (100vw - ${props.width}) / 2 );
    top: 118px;
  `};

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
  }

  .modal-body {
    overflow: auto;
  }

  ${(props) =>
    !props.hasTitleBar &&
    `.modal-header {
      border-bottom: none;
    }`}

  ${(props) =>
    !props.hasFooterBar &&
    `.modal-body {
      text-align: center;
      padding-top: 0;
    }

    .buttons {
      text-align: center;

      button {
        width: 120px;
        margin: 7px;
      }
    }`}
`;

const DeleteModal: React.FC<DeleteModalProps> = (props: DeleteModalProps) => {
  const {
    isOpen,
    confirmLabel = DEFAULT_CONFIRM_LABEL,
    cancelLabel = DEFAULT_CANCEL_LABEL,
    width = DEFAULT_MODAL_WIDTH,
    height = 'auto',
    onConfirm = () => null,
    onCancel = () => null,
    children,
  } = props;

  const classes = useStyles();

  const headerCloseButton = (
    <Icon fontSize="large" onClick={onCancel} style={{ cursor: 'pointer' }}>
      <CloseIcon />
    </Icon>
  );

  return (
    <Modal open={isOpen} className={classes.modalContainer}>
      <ModalContent width={width} height={height} hasTitleBar={false} hasFooterBar={false}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        <ModalBody className="modal-body">
          {children}
          <div className="buttons">
            <Button
              variant="outlined"
              onClick={() => {
                onCancel();
              }}
            >
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 15, backgroundColor: '#F44F64' }}
              onClick={() => {
                onConfirm();
              }}
            >
              {confirmLabel}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
