import React, { useState } from 'react';

import { BaseRow } from '../parser';
import { ImportInfo } from './ImporterProps';
import { ImporterFrame } from './ImporterFrame';

import './CompletedDisplay.scss';
import { Box, CircularProgress } from '@mui/material';

export function CompletedDisplay<Row extends BaseRow>({
  stepperComponent,
}: React.PropsWithChildren<{
  stepperComponent?: any;
}>): React.ReactElement {
  const [error, setError] = useState<Error | null>(null);

  return (
    <ImporterFrame
      fileName={''}
      subtitle={''}
      error={error && (error.message || String(error))}
      secondaryDisabled={false}
      onSecondary={() => null}
      nextDisabled={true}
      nextLabel={''}
      onNext={() => null}
    >
      {stepperComponent}
      <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
        <h2 style={{ fontSize: '18px' }}>Sending To Machine...</h2>
        <br />
        <CircularProgress />
      </Box>
      <div className="CompletedDisplay"></div>
    </ImporterFrame>
  );
}
