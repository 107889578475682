import React from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { UserProfileContext } from '../contexts/UserProfileContext';
import UserProfileDetails from '../components/UserProfileDetails';

const UserProfilePage: React.FunctionComponent = () => {
  const { fetchUserProfile, userProfile, userProfileLoading } = React.useContext(UserProfileContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchUserProfile();
  }, []);

  return userProfileLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Profile...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        Your Profile
      </Typography>
      <br />

      <UserProfileDetails user={userProfile} />
    </>
  );
};

export default UserProfilePage;
