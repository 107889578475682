/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import TreatmentProductAutoTreatForm from './TreatmentProductsAutoTreatForm';

interface TreatmentProductAutoTreatDetailProps {
  treatmentProduct: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const TreatmentProductsAutoTreatDetail: React.FC<TreatmentProductAutoTreatDetailProps> = (props) => {
  const { treatmentProduct, machineSerial, onSuccess, onBack } = props;

  const { setDetailPage, setActiveTreatmentProductAutoTreat, activeTreatmentProductAutoTreat, activeMachine } =
    React.useContext(TreatmentProductsAutoTreatContext);

  return (
    <>
      <TreatmentProductAutoTreatForm
        treatmentProduct={activeTreatmentProductAutoTreat}
        machineSerial={activeMachine?.value}
        onSuccess={() => setDetailPage(false)}
        onBack={onBack}
      />
    </>
  );
};

export default TreatmentProductsAutoTreatDetail;
