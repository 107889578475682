/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { RfidsAutoBulkContext } from '../contexts/RfidAutoBulkContext';
import RfidAutoBulkForm from '../components/RfidAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const RfidAutoBulkCreate: React.FunctionComponent = () => {
  const { setCreatingPage, activeMachine } = React.useContext(RfidsAutoBulkContext);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: 'AutoBulk Rfids',
    },
    {
      onClick: () => null,
      text: `Create (Machine: ${activeMachine?.value})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Rfid
      </Typography>
      <br />
      <RfidAutoBulkForm rfid={{}} onSuccess={() => setCreatingPage(false)} />
    </>
  );
};

export default RfidAutoBulkCreate;
