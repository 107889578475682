import React from 'react';
import { CustomerAutoBulkFormValues } from '../model';
import { CustomersAutoBulkContext } from './CustomersAutoBulkContext';

interface CustomersAutoBulkValidationsContextInterface {
  validatecsvImportRow?: (formValues: CustomerAutoBulkFormValues) => { error: boolean; errorMessage?: string };
  validateCustomersAutoBulkFields?: (formValues: CustomerAutoBulkFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const CustomersAutoBulkValidationsContext = React.createContext<CustomersAutoBulkValidationsContextInterface>({});

const CustomersAutoBulkValidationsContextConsumer = CustomersAutoBulkValidationsContext.Consumer;
const CustomersAutoBulkValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { customers, activeCustomerAutoBulk } = React.useContext(CustomersAutoBulkContext);

  const fetchValidCharactersError = (formValues: CustomerAutoBulkFormValues): string => {
    const SpecialCharacterStringCheck =
      formValues?.customer_id +
      formValues?.customer_name +
      formValues?.customer_contact_first +
      formValues?.customer_contact_last +
      formValues?.customer_address +
      formValues?.customer_city +
      formValues?.customer_state +
      formValues?.customer_zip +
      formValues?.customer_phone_1 +
      formValues?.customer_email +
      formValues?.active;
    const specialChars = /'|\/\*|\*\//;

    if (specialChars.test(SpecialCharacterStringCheck)) {
      return `No fields can contain ' `;
    }
    return null;
  };

  const fetchCustomerAutoBulkNameError = (customer_name: string) => {
    if (!customers) {
      console.error('Customer name is not defined');
      return false;
    }

    if (customer_name === '') {
      return 'Customer Name cannot be left blank.';
    }

    if (customer_name?.length > 100) {
      return 'Customer Name cannot be longer than 100 characters';
    }

    const matchedNames = customers.filter((customer) => customer.customer_name === customer_name);
    if (matchedNames.length > 0) {
      if (activeCustomerAutoBulk.customer_name !== customer_name) {
        return 'Customer Name cannot be a duplicate name';
      }

      return false;
    }

    return false;
  };

  const fetchcsvCustomerAutoBulkNameError = (customer_name: string) => {
    if (customer_name === '') {
      return 'Customer Name cannot be left blank.';
    }

    if (customer_name?.length > 100) {
      return 'Customer Name cannot be longer than 100 characters';
    }

    return false;
  };

  const validateCustomersAutoBulkFields = (formValues: CustomerAutoBulkFormValues) => {
    const abCustomerNameError = fetchCustomerAutoBulkNameError(formValues?.customer_name);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (specialCharactersError || abCustomerNameError) {
      setFormErrors({
        CustomerName: abCustomerNameError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      CustomerName: null,
      base: null,
    });

    return true;
  };

  const validatecsvImportRow = (formValues: CustomerAutoBulkFormValues): { error: boolean; errorMessage?: string } => {
    const specialCharactersError = fetchValidCharactersError(formValues);
    const customerNameError = fetchcsvCustomerAutoBulkNameError(formValues?.customer_name);

    const errors = [specialCharactersError, customerNameError].filter(Boolean);

    if (errors.length > 0) {
      return {
        error: true,
        errorMessage: errors.join(', '),
      };
    }

    return { error: false };
  };

  return (
    <CustomersAutoBulkValidationsContext.Provider
      value={{
        validatecsvImportRow,
        validateCustomersAutoBulkFields,
        formErrors,
      }}
    >
      {children}
    </CustomersAutoBulkValidationsContext.Provider>
  );
};

export {
  CustomersAutoBulkValidationsContextProvider,
  CustomersAutoBulkValidationsContextConsumer,
  CustomersAutoBulkValidationsContext,
};
