import React from 'react';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../shared/tabs/TabPanel';
import URLTabs from '../../shared/tabs/URLTabs';
import MachineDetails from '../components/MachineDetails';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { MachinesContext } from '../contexts/MachinesContext';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import AssignUserAccessForm from '../components/AssignUserAccess';
import MachineExecuteSqlForm from '../components/MachineExecuteSqlForm';
import DownloadDatabaseForm from '../components/DownloadDatabaseForm';

const AdminMachineDetail: React.FC = () => {
  const { machineId } = useParams<Record<string, string>>();

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { fetchMachine, machine, machineLoading } = React.useContext(MachinesContext);
  const { fetchCompanyOptions, companiesLoading } = React.useContext(CompaniesContext);
  const { fetchAllMachineAccessOptions } = React.useContext(UserMachineAccessContext);

  const [users, setUsers] = React.useState<any[]>();

  React.useEffect(() => {
    fetchMachine(machineId);
    fetchCompanyOptions();
    fetchAllMachineAccessOptions();
  }, [machineId]);

  const links = [
    {
      href: '/admin/machine_management',
      text: 'Machine Management',
    },
    {
      href: `/admin/machine_management/${machine.machine_id}`,
      text: `${machine?.machine_name} (ID: ${machine.machine_id})`,
    },
  ];

  const tabNames = ['machine_details', 'user_access', 'database_backups', 'execute_sql'];
  const baseUrl = `/admin/machine_management/${machine.machine_id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  return machineLoading || companiesLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Machine...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>

      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="Machine Details" />
            <Tab style={{ textTransform: 'capitalize' }} label="User Access" />
            {currentUserHasAdminPermission('ksi_support_admin') && (
              <Tab style={{ textTransform: 'capitalize' }} label="Database Backups" />
            )}
            {currentUserHasAdminPermission('ksi_superadmin') && (
              <Tab style={{ textTransform: 'capitalize' }} label="Execute SQL" />
            )}
          </Tabs>
        </Box>
        <br />
        <TabPanel value={tabValue} index={0}>
          <MachineDetails machine={machine} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AssignUserAccessForm machine={machine} users={users} setUsers={setUsers} />
        </TabPanel>
        {currentUserHasAdminPermission('ksi_support_admin') && (
          <TabPanel value={tabValue} index={2}>
            <DownloadDatabaseForm machine={machine} />
          </TabPanel>
        )}
        {currentUserHasAdminPermission('ksi_superadmin') && (
          <TabPanel value={tabValue} index={3}>
            <MachineExecuteSqlForm machine={machine} />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default AdminMachineDetail;
