import React, { useMemo, useState, useEffect } from 'react';
import { useFieldDefinitions } from './ImporterField';

import './Importer.scss';
import { LocaleContext } from '../locale/LocaleContext';
import { enUS } from '../locale';
import { BaseRow } from '../parser';
import { FileStep, FileStepState } from './file-step/FileStep';
import { generatePreviewColumns } from './fields-step/ColumnPreview';
import { FieldsStep, FieldsStepState } from './fields-step/FieldsStep';
import { ProgressDisplay } from './ProgressDisplay';
import { ImporterFilePreview, ImporterProps } from './ImporterProps';
import { Grid, Typography } from '@mui/material';
import { CompletedDisplay } from './CompletedDisplay';

// re-export from a central spot
export { ImporterField } from './ImporterField';

export function Importer<Row extends BaseRow>(props: ImporterProps<Row>): React.ReactElement {
  const {
    dataHandler,
    processChunk,
    defaultNoHeader,
    assumeNoHeaders,
    restartable,
    displayFieldRowSize,
    displayColumnPageSize,
    onStart,
    onComplete,
    onClose,
    children: content,
    locale: userLocale,
    activeStep,
    enableNext,
    passedRecords,
    failedRecords,
    stepperComponent,
    sendToMachine,
    unAcceptFile,
    apiPartner,
    columnsTitle,
    ...customPapaParseConfig
  } = props;

  // helper to combine our displayed content and the user code that provides field definitions
  const [fields, userFieldContentWrapper] = useFieldDefinitions();

  const [fileState, setFileState] = useState<FileStepState | null>(null);
  const [fileAccepted, setFileAccepted] = useState<boolean>(false);

  const [triggerUnAcceptFile, setTriggerUnAcceptFile] = useState<number>(0);
  const [fieldsState, setFieldsState] = useState<FieldsStepState | null>(null);
  const [fieldsAccepted, setFieldsAccepted] = useState<boolean>(false);

  // reset field assignments when file changes
  const activeFile = fileState && fileState.file;
  useEffect(() => {
    if (activeFile) {
      setFieldsState(null);
    }
  }, [activeFile]);

  useEffect(() => {
    setFileAccepted(false);
    setTriggerUnAcceptFile(0);
    setFileState(null);
  }, [unAcceptFile]);

  React.useEffect(() => {}, [fileAccepted]);

  useEffect(() => {
    if (activeStep === 0) {
    }

    if (activeStep === 1) {
      setFileAccepted(true);
    }

    if (activeStep === 2) {
      setFieldsAccepted(true);
    }

    if (activeStep === 3) {
      sendToMachine();
    }
  }, [activeStep]);

  const externalPreview = useMemo<ImporterFilePreview | null>(() => {
    // generate stable externally-visible data objects
    const externalColumns = fileState && generatePreviewColumns(fileState.firstRows, fileState.hasHeaders);
    return (
      fileState &&
      externalColumns && {
        rawData: fileState.firstChunk,
        columns: externalColumns,
        skipHeaders: !fileState.hasHeaders,
        parseWarning: fileState.parseWarning,
      }
    );
  }, [fileState]);

  // fall back to enUS if no locale provided
  const locale = userLocale ?? enUS;

  const handleEnableNext = (fileState) => {
    if (fileState !== null) {
      enableNext(false);
    }
    if (fileState === null) {
      enableNext(true);
    }
  };

  if (activeStep === 0) {
    return (
      <LocaleContext.Provider value={locale}>
        <div className="CSVImporter_Importer">
          <FileStep
            customConfig={customPapaParseConfig}
            defaultNoHeader={defaultNoHeader ?? assumeNoHeaders}
            prevState={fileState}
            onChange={(parsedPreview) => {
              handleEnableNext(fileState);
              setFileState(parsedPreview);
            }}
            onAccept={() => {
              setFileAccepted(true);
            }}
            onBack={() => setFileState(null)}
            stepperComponent={stepperComponent}
            triggerUnAcceptFile={triggerUnAcceptFile}
          />
          <br />
          <Grid justifyContent="space-between" container spacing={3}>
            <Grid item>
              <Typography variant="h3" component="h3">
                CSV Import Explanation:
              </Typography>
              <Typography variant="h4" component="h4" style={{ marginLeft: '25px', marginTop: '10px' }}>
                {`1. Existing Records: If the record name matches one in the database, that record will be updated with the new CSV data.`}
              </Typography>
              <Typography variant="h4" component="h4" style={{ marginLeft: '25px', marginTop: '10px' }}>
                {`2. New Records: If the record name isn't found in the database (meaning it's a new entry), a fresh record is created using the CSV row's details.`}
              </Typography>
              <Typography variant="h4" component="h4" style={{ marginTop: '10px' }}>
                {`This ensures your database stays up-to-date with the CSV, avoiding duplicates and missed entries.`}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </LocaleContext.Provider>
    );
  }

  if (activeStep === 1) {
    return (
      <LocaleContext.Provider value={locale}>
        <div className="CSVImporter_Importer">
          <FieldsStep
            fileState={fileState}
            fields={fields}
            prevState={fieldsState}
            displayFieldRowSize={displayFieldRowSize}
            displayColumnPageSize={displayColumnPageSize}
            onChange={(state) => {
              setFieldsState(state);
            }}
            onAccept={() => {
              setFieldsAccepted(true);
            }}
            onCancel={() => {
              // keep existing preview data and assignments
              setFileAccepted(false);
            }}
            onError={(value) => handleEnableNext(value)}
            stepperComponent={stepperComponent}
            columnTitle={columnsTitle}
          />
          <br />
          {apiPartner && (
            <Grid justifyContent="space-between" container spacing={3}>
              <Grid item>
                <Typography variant="h3" component="h3">
                  API Partner Imports:
                </Typography>
                <Typography variant="h4" component="h4" style={{ marginLeft: '25px', marginTop: '10px' }}>
                  {`1. The partner_record_id_1 cannot be left blank`}
                </Typography>
                <Typography variant="h4" component="h4" style={{ marginLeft: '25px', marginTop: '10px' }}>
                  {`2. Records must have a unique set of parter_record_ids`}
                </Typography>
              </Grid>
            </Grid>
          )}
          {userFieldContentWrapper(
            // render the provided child content that defines the fields
            typeof content === 'function'
              ? content({
                  file: fileState && fileState.file,
                  preview: externalPreview,
                })
              : content,
          )}
        </div>
      </LocaleContext.Provider>
    );
  }

  if (activeStep === 2) {
    return (
      <LocaleContext.Provider value={locale}>
        <div className="CSVImporter_Importer">
          <ProgressDisplay
            fileState={fileState}
            fieldsState={fieldsState}
            externalPreview={externalPreview}
            passedRecords={passedRecords}
            failedRecords={failedRecords}
            dataHandler={dataHandler ?? processChunk!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            onStart={onStart}
            onRestart={
              restartable
                ? () => {
                    // reset all state
                    setFileState(null);
                    setFileAccepted(false);
                    setFieldsState(null);
                    setFieldsAccepted(false);
                  }
                : undefined
            }
            onComplete={onComplete}
            onClose={onClose}
            stepperComponent={stepperComponent}
          />
        </div>
      </LocaleContext.Provider>
    );
  }

  return (
    <LocaleContext.Provider value={locale}>
      <div className="CSVImporter_Importer">
        <CompletedDisplay stepperComponent={stepperComponent} />
      </div>
    </LocaleContext.Provider>
  );
}
