import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface CustomerIntegrationsAutoBulkContextInterface {
  fetchCustomerIntegrationsAutoBulk?: (
    machineSerial: string,
    customerId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  createCustomerIntegrationAutoBulk?: (
    machineSerial: string,
    customerId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  updateCustomerIntegrationAutoBulk?: (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  deleteCustomerIntegrationAutoBulk?: (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  customerIntegrations?: any[];
  customerIntegrationsLoading?: boolean;

  setCustomerIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  customerIntegrationsSubmitting?: boolean;
}

const CustomerIntegrationsAutoBulkContext = React.createContext<CustomerIntegrationsAutoBulkContextInterface>({});

const CustomerIntegrationsAutoBulkContextConsumer = CustomerIntegrationsAutoBulkContext.Consumer;
const CustomerIntegrationsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [customerIntegrations, setCustomerIntegrations] = React.useState([]);
  const [customerIntegrationsLoading, setCustomerIntegrationsLoading] = React.useState(true);
  const [customerIntegrationsSubmitting, setCustomerIntegrationsSubmitting] = React.useState(false);

  const fetchCustomerIntegrationsAutoBulk = async (
    machineSerial: string,
    customerId: string,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/customers_integrations/?serial-number=${machineSerial}&customer_id=${customerId}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setCustomerIntegrationsLoading(false);
              setCustomerIntegrations(response.data.results);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setCustomerIntegrationsLoading(false);
                setCustomerIntegrations([]);
                window.clearInterval(fetchTimer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createCustomerIntegrationAutoBulk = async (
    machineSerial: string,
    customerId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsLoading(true);
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autobulk/onprem/customers_integrations/?serial-number=${machineSerial}&customer_id=${customerId}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoBulk(machineSerial, customerId);
              setCustomerIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsLoading(false);
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateCustomerIntegrationAutoBulk = async (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => {
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autobulk/onprem/customers_integrations/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoBulk(machineSerial, customerId);
              setCustomerIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteCustomerIntegrationAutoBulk = async (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autobulk/onprem/customers_integrations/${integrationId}/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoBulk(machineSerial, customerId);
              showMachineManagementSnackBar(response.data.results[0]);
              setCustomerIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <CustomerIntegrationsAutoBulkContext.Provider
      value={{
        fetchCustomerIntegrationsAutoBulk,
        createCustomerIntegrationAutoBulk,
        updateCustomerIntegrationAutoBulk,
        deleteCustomerIntegrationAutoBulk,
        customerIntegrations,
        customerIntegrationsLoading,

        setCustomerIntegrationsSubmitting,
        customerIntegrationsSubmitting,
      }}
    >
      {children}
    </CustomerIntegrationsAutoBulkContext.Provider>
  );
};

export {
  CustomerIntegrationsAutoBulkContextProvider,
  CustomerIntegrationsAutoBulkContextConsumer,
  CustomerIntegrationsAutoBulkContext,
};
