import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';

interface LevelSensorsAutoBulkContextInterface {
  fetchCachedLevelSensors?: (machineSerial: string) => Promise<void>;
  fetchLevelSensors?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;
  levelSensorsRefreshedAt?: string;
  levelSensors?: any[];
  levelSensorsLoading?: boolean;

  fetchLevelSensor?: (LiquidNameID: string) => Promise<void>;
  levelSensor?: any;
  levelSensorLoading?: boolean;
}

const LevelSensorsAutoBulkContext = React.createContext<LevelSensorsAutoBulkContextInterface>({});

const LevelSensorsAutoBulkContextConsumer = LevelSensorsAutoBulkContext.Consumer;
const LevelSensorsAutoBulkContextProvider: React.FC = ({ children }) => {
  const [levelSensors, setLevelSensors] = React.useState([]);
  const [levelSensorsRefreshedAt, setLevelSensorsRefreshedAt] = React.useState<string>();
  const [levelSensorsLoading, setLevelSensorsLoading] = React.useState(false);

  const fetchCachedLevelSensors = async (machineSerial: string) => {
    setLevelSensorsLoading(true);
    setLevelSensorsRefreshedAt(null);

    axios
      .get<string, any>(`api/autobulk/onprem/level_sensors/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setLevelSensorsLoading(false);
        setLevelSensors(response.data.results);
        setLevelSensorsRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setLevelSensorsLoading(false);
        setLevelSensors([]);
        setLevelSensorsRefreshedAt(null);
      });
  };

  const fetchLevelSensors = async (machineSerial: string, onSuccess?: () => void) => {
    setLevelSensorsLoading(true);
    setLevelSensorsRefreshedAt(null);
    let retryCount = 0;
    axios
      .get<string, any>(`api/autobulk/onprem/level_sensors/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/level_sensors/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setLevelSensorsLoading(false);
              setLevelSensors(response.data.results);
              setLevelSensorsRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                setLevelSensorsLoading(false);
                setLevelSensors([]);
                setLevelSensorsRefreshedAt('Never');
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <LevelSensorsAutoBulkContext.Provider
      value={{
        fetchCachedLevelSensors,
        fetchLevelSensors,
        levelSensorsRefreshedAt,
        levelSensors,
        levelSensorsLoading,
      }}
    >
      {children}
    </LevelSensorsAutoBulkContext.Provider>
  );
};

export { LevelSensorsAutoBulkContextProvider, LevelSensorsAutoBulkContextConsumer, LevelSensorsAutoBulkContext };
