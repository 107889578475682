/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  useGridApiRef,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import TextField from '@mui/material/TextField';

const initialRows: GridRowsProp = [
  {
    id: randomId(),
    product_id: '',
    product_name: '',
    product_type: '',
    product_density: '',
    product_density_type: '',
    product_target_amt: '',
    product_target_uom: '',
  },
];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  isEditing: boolean;
  setEditing: any;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, isEditing, setEditing } = props;

  const handleClick = () => {
    setEditing(true);
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        product_name: '',
        product_type: '',
        product_density: '',
        product_density_type: '',
        product_target_amt: '',
        product_target_uom: '',
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        variant="contained"
        style={{ marginTop: '7px', marginLeft: '7px' }}
        disabled={isEditing}
        onClick={isEditing ? () => null : handleClick}
      >
        Add Product
      </Button>
    </GridToolbarContainer>
  );
}

interface WorkOrderAutoBulkItemsProps {
  rowsProp?: any;
  fieldOptions?: any;
  datagridDisabled?: boolean;
  setFormValueItems?: any;
}

const WorkOrdersItemsAutoBulkForm: React.FC<WorkOrderAutoBulkItemsProps> = (props) => {
  const { rowsProp, datagridDisabled = false, fieldOptions, setFormValueItems } = props;
  const [rows, setRows] = React.useState(initialRows);
  const [inputValue, setInputValue] = React.useState('');
  const [isEditing, setEditing] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const apiRef = useGridApiRef();

  const renderProductSelectEditCell = (params) => {
    const handleChange = (event) => {
      // Use apiRef to commit changes directly to the DataGrid
      apiRef.current.setEditCellValue(
        {
          id: params.id,
          field: 'product_name',
          value: event.target.textContent,
        },
        event,
      );
      apiRef.current.stopCellEditMode({ id: params.id, field: 'product_name' });

      apiRef.current.setEditCellValue({
        id: params.id,
        field: 'product_target_uom',
        value: params.row.product_type === 'Dry' ? 'lb' : params.row.product_target_uom,
      });
      apiRef.current.stopCellEditMode({ id: params.id, field: 'product_target_uom' });

      // Update the row value based on the selection
      const updatedRows = rows.map((row) => {
        if (row.id === params.id) {
          const selectedOption = fieldOptions.find((option) => option.label === event.target.textContent);

          return selectedOption
            ? {
                ...row,
                product_id: selectedOption.value,
                product_type: selectedOption.product_type,
                product_density: selectedOption.product_density,
                product_density_type: selectedOption.product_density_type,
                product_target_uom: selectedOption.product_type === 'Dry' ? 'lb' : row.product_target_uom,
              }
            : row;
        }

        return row;
      });

      setRows(updatedRows);
    };

    return (
      <Autocomplete
        disablePortal
        value={params.value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={fieldOptions}
        sx={{ width: '100%' }}
        renderInput={(inputParameters) => <TextField {...inputParameters} />}
      />
    );
  };

  function mapToGridRows(data) {
    return data.map((item) => ({
      id: randomId(),
      product_id: item.product_id,
      product_name: item.product_name,
      product_type: item.product_type,
      product_density: item.product_density,
      product_density_type: item.product_density_type,
      product_target_amt: item.product_target_amt,
      product_target_uom: item.product_target_uom,
    }));
  }

  React.useEffect(() => {
    if (rowsProp) {
      const gridRows = mapToGridRows(rowsProp);
      setRows(gridRows);
    }
  }, [rowsProp]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    setEditing(false);

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      const updatedEvent = event;
      updatedEvent.defaultMuiPrevented = true;
    }
  };

  const handleTargetUomRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      const updatedEvent = event;
      updatedEvent.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEditing(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setEditing(false);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    setEditing(false);
    const selectedOption = fieldOptions.find((option) => option.label === newRow.product_name);

    if (!selectedOption) {
      return newRow;
    }

    const updatedRow = {
      isNew: false,
      product_id: selectedOption.value,
      product_name: selectedOption.label,
      product_type: selectedOption.product_type,
      product_density: selectedOption.product_density,
      product_density_type: selectedOption.product_density_type,
      product_target_amt: newRow.product_target_amt,
      product_target_uom: newRow.product_target_uom,
    };

    const displayRow = {
      ...newRow,
      isNew: false,
      product_id: selectedOption.value,
      product_name: selectedOption.label,
      product_type: selectedOption.product_type,
      product_density: selectedOption.product_density,
      product_density_type: selectedOption.product_density_type,
    };

    setRows(rows.map((row) => (row.id === newRow.id ? displayRow : row)));
    setFormValueItems(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'product_id',
      headerName: 'ID',
      type: 'number',
      width: 50,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'product_name',
      headerName: 'Product Name',
      flex: 1,
      minWidth: 240,
      editable: true,
      renderEditCell: renderProductSelectEditCell,
    },
    {
      field: 'product_type',
      headerName: 'Type',
      width: 80,
      editable: false,
    },
    {
      field: 'density',
      headerName: 'Density',
      width: 90,
      editable: false,
      renderCell: (params) => {
        const productDensity = params.row.product_density;
        const productDensityType = params.row.product_density_type;

        return `${productDensity} ${productDensityType}`;
      },
    },
    {
      field: 'product_target_amt',
      headerName: 'Target Amount',
      type: 'number',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: (params) => {
        const handleChange = (event) => {
          apiRef.current.setEditCellValue({
            id: params.id,
            field: 'product_target_amt',
            value: event.target.value,
          });
          apiRef.current.stopCellEditMode({ id: params.id, field: 'product_target_amt' });
        };

        return (
          <TextField
            sx={{
              // Style the root of the TextField
              backgroundColor: '#fff',
              border: '1px solid #c4c4c4',
              borderRadius: '4px',

              // Style the input part of the TextField
              '& .MuiInputBase-input': {
                padding: '8px',
              },

              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            value={params.value}
            onChange={handleChange}
          />
        );
      },
    },
    {
      field: 'product_target_uom',
      headerName: 'Target Uom',
      width: 100,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params) => {
        const options = params.row.product_type === 'Dry' ? ['lb'] : ['gallon', 'lb'];

        const handleChange = (event) => {
          apiRef.current.setEditCellValue({
            id: params.id,
            field: 'product_target_uom',
            value: options?.length && options?.length === 1 ? 'lb' : event.target.value,
          });
          apiRef.current.stopCellEditMode({ id: params.id, field: 'product_target_uom' });
        };

        return (
          <Select
            value={options?.length && options?.length === 1 ? 'lb' : params.value || ''}
            onChange={handleChange}
            size="small"
            fullWidth
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`} // Unique key for the Save button
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`} // Unique key for the Cancel button
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={`edit-${id}`} // Unique key for the Edit button
            icon={<EditIcon />}
            label="Edit"
            disabled={isEditing}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={`delete-${id}`} // Unique key for the Delete button
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '85%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      {fieldOptions?.length > 0 && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell--editable.MuiDataGrid-cell--editing': {
              outline: '0px solid rgba(224, 224, 224, 200)',
              padding: '6px',
              borderRadius: '1px',
            },
            '& .MuiDataGrid-editInputCell': {
              backgroundColor: '#fff',
              border: '1px solid #c4c4c4',
              borderRadius: '4px',
              '& .MuiInputBase-input': {
                padding: '8px',
              },
            },
            '& .MuiDataGrid-cell[data-field="product_target_uom"]': {
              '& .MuiSelect-select': {
                backgroundColor: '#fff',
                border: '1px solid #c4c4c4',
                borderRadius: '4px',
                padding: '8px 12px',
              },
            },
          }}
          density="standard"
          disableVirtualization
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => null}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, isEditing, setEditing },
          }}
        />
      )}
    </Box>
  );
};

export default WorkOrdersItemsAutoBulkForm;
