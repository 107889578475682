/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { TransactionApiLogsAutoBulkContext } from '../contexts/TransactionApiLogsAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TransactionApiLogAutoBulkFilterBar from '../components/TransactionApiLogsAutoBulkFilterBar';

const TransactionApiLogsAutoBulkList: React.FunctionComponent = () => {
  const { fetchTransactionApiLogsAutoBulk, transactionApiLogsAutoBulk, activeMachine } = React.useContext(
    TransactionApiLogsAutoBulkContext,
  );

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    orderName: '',
    orderDescription: '',
    orderComment: '',
    orderFarmAndField: '',
    orderPartnerRecordID: '',
    orderPartnerRecieved: '',
    orderApiLog: '',
  });

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchTransactionApiLogsAutoBulk]);

  const filterTransactionApiLogs = () => {
    if (
      tableFilters.orderName ||
      tableFilters.orderDescription ||
      tableFilters.orderComment ||
      tableFilters.orderFarmAndField ||
      tableFilters.orderPartnerRecordID ||
      tableFilters.orderPartnerRecieved ||
      tableFilters.orderApiLog
    ) {
      const nameFilteredTransactionApiLogs = transactionApiLogsAutoBulk.filter((wordOrder) =>
        wordOrder.work_order_name?.toLowerCase().includes(tableFilters.orderName?.toLowerCase()),
      );

      const descriptionFilteredTransactionApiLogs = nameFilteredTransactionApiLogs.filter((wordOrder) =>
        wordOrder.work_order_description?.toLowerCase().includes(tableFilters.orderDescription?.toLowerCase()),
      );

      const commentFilteredTransactionApiLogs = descriptionFilteredTransactionApiLogs.filter((wordOrder) =>
        wordOrder.work_order_comment?.toLowerCase().includes(tableFilters.orderComment?.toLowerCase()),
      );

      const farmAndFieldFilteredTransactionApiLogs = commentFilteredTransactionApiLogs.filter(
        (wordOrder) =>
          wordOrder.work_order_farm_notes?.toLowerCase().includes(tableFilters.orderFarmAndField?.toLowerCase()) ||
          wordOrder.work_order_field_notes?.toLowerCase().includes(tableFilters.orderFarmAndField?.toLowerCase()),
      );

      const partnerIDFilteredTransactionApiLogs = farmAndFieldFilteredTransactionApiLogs.filter(
        (wordOrder) =>
          wordOrder.work_order_partner_record_id_1
            ?.toLowerCase()
            .includes(tableFilters.orderPartnerRecordID?.toLowerCase()) ||
          wordOrder.work_order_partner_record_id_2
            ?.toLowerCase()
            .includes(tableFilters.orderPartnerRecordID?.toLowerCase()) ||
          wordOrder.work_order_partner_record_id_3
            ?.toLowerCase()
            .includes(tableFilters.orderPartnerRecordID?.toLowerCase()),
      );

      const partnerRecievedFilteredTransactionApiLogs = partnerIDFilteredTransactionApiLogs.filter((wordOrder) =>
        wordOrder.partner_received?.toLowerCase().includes(tableFilters.orderPartnerRecieved?.toLowerCase()),
      );

      const apiLogFilteredTransactionApiLogs = partnerRecievedFilteredTransactionApiLogs.filter((wordOrder) =>
        wordOrder.response?.toLowerCase().includes(tableFilters.orderApiLog?.toLowerCase()),
      );

      return apiLogFilteredTransactionApiLogs;
    }

    return transactionApiLogsAutoBulk;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Transaction Api Logs (View Only)
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <TransactionApiLogAutoBulkFilterBar
              machineChoices={autoBulkMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterTransactionApiLogs()}
                // TODO Default sort is not working... work_order_transaction_id & work_order_start_date are treated as strings
                defaultSortField="work_order_transaction_id_dont_use"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default TransactionApiLogsAutoBulkList;
