/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { format, parseISO } from 'date-fns';
import { Typography, Grid } from '@mui/material';
import SideBarInformationField from '../../../modules/shared/form/SideBarInformationField';

interface TransactionDetailProps {
  transaction?: any;
}

const TransactionDetails: React.FunctionComponent<TransactionDetailProps> = (props) => {
  const { transaction } = props;

  const formatedDate = (date) => {
    let formattedDate = '';

    if (date) {
      try {
        const isoDate = parseISO(date);
        formattedDate = format(isoDate, 'MM/dd/yyyy hh:mm aaa');
      } catch (error) {
        console.error(`Error parsing date: ${error}`);
      }
    }
    return formattedDate;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h2" component="h2" style={{ marginTop: '-12px' }}>
            {`Transaction Details: ${transaction?.order_name}`}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SideBarInformationField label="Order Name:" value={transaction?.order_name} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField
            label={`Processed Date (${
              new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
            }):`}
            value={formatedDate(transaction?.order_complete_date_and_time_utc)}
          />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Processed At:" value={transaction?.machine_name_with_serial} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Operation:" value={transaction?.customer_name} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Comment:" value={transaction?.order_comment} />
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionDetails;
