import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CompanyForm from './CompanyForm';
import AdminCompanyMachines from './AdminCompanyMachines';
import AdminCompanyUsers from './AdminCompanyUsers';

interface CompanyDetailsProps {
  company: any;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
  const { company } = props;

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="h2" component="h2">
                    Company Information
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography style={{ position: 'relative', top: 10, fontWeight: 700 }}>Headquarters</Typography>
                </Grid>
              </Grid>
              <br />
              <CompanyForm company={company} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminCompanyUsers company={company} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminCompanyMachines company={company} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyDetails;
