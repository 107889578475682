import React from 'react';
import { Button, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import ApplicationScreenshot from '../../../assets/application-screenshot.png';
import Footer from '../../navigation/components/Footer';

const OnboardingPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Grid
        container
        alignContent="center"
        style={{
          backgroundImage: `url("https://www.ksiedge.com/wp-content/uploads/IMG_3944.jpg")`,
          backgroundPosition: 'center 75%',
          height: 500,
          boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
        }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: 36, color: 'white' }}>Actionable Insights for All Your Data</h2>
          <br />
          <Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>
            Sign Up Now
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 80 }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <img
                alt="ApplicationScreenshot"
                src={ApplicationScreenshot}
                style={{
                  height: '200px',
                  boxShadow:
                    'inset 0 1px 0 rgba(255,255,255,.6), 0 22px 70px 4px rgba(0,0,0,0.56), 0 0 0 1px rgba(0, 0, 0, 0.0)',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ alignItems: 'center', display: 'flex', height: 200 }}>
                <div>
                  <h6 style={{ fontSize: 18, fontWeight: 500 }}>THE EASIEST WAY TO ANALYZE DATA</h6>
                  <br />
                  <Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>
                    Sign Up Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default OnboardingPage;
