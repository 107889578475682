import { IDataTableColumn } from '../../dataTable/DataTable/model';
import CompanyRowMenu from '../components/CompanyRowMenu';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Company',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'External Account Number',
    selector: 'external_account_number',
    sortable: false,
  },
  {
    name: 'Associated Machines Count',
    selector: 'name',
    sortable: false,
    cell: (row): React.ReactNode => <p>{`${row?.associated_machines?.length} machines`}</p>,
  },
  {
    name: 'Associated Users Count',
    selector: 'name',
    sortable: false,
    cell: (row): React.ReactNode => <p>{`${row?.associated_users?.length} users`}</p>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    grow: 0.7,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status ? 'green' : 'red',
        }}
      >
        {status ? 'ACTIVE' : 'INACTIVE'}
      </div>
    ),
  },
  {
    cell: (row) => <CompanyRowMenu company={row} />,
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export default columns;
