const exampleCSV = [
  {
    operation: 'operation',
    first_name: 'first name',
    last_name: 'last name',
    comment: 'comment',
    customer_desc: 'description',
    address1: 'address',
    address2: 'address',
    city: 'city',
    state: 'state',
    country: 'country',
    zip_code: '12345',
    mobile_phone: '555-555-5555',
    business_phone: '555-555-5555',
    email: 'email@email.com',
    auto_email: 'false',
    active: 'true',
  },
  {
    operation: '* Required',
    auto_email: 'true/false, default: false',
    active: 'true/false, default: true',
  },
];

export default exampleCSV;
