/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import ProductAutoBulkForm from './ProductsAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

interface ProductAutoBulkDetailProps {
  product: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const ProductsAutoBulkDetail: React.FC<ProductAutoBulkDetailProps> = (props) => {
  const { product, machineSerial, onSuccess, onBack } = props;

  const { setDetailPage, setActiveProductAutoBulk, activeProductAutoBulk, activeMachine } =
    React.useContext(ProductsAutoBulkContext);

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: 'AutoBulk Products',
    },
    {
      onClick: () => null,
      text: `Create`,
    },
  ];

  return (
    <>
      <br />
      <ProductAutoBulkForm
        product={activeProductAutoBulk}
        machineSerial={activeMachine?.value}
        onSuccess={() => setDetailPage(false)}
        onBack={onBack}
      />
    </>
  );
};

export default ProductsAutoBulkDetail;
