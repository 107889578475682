import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoTreatTransaction } from '../../transactions/model';

interface AutoTreatRealTimeActivityContextInterface {
  fetchTransactions?: (filterParams?: string) => Promise<void>;
  transactions?: AutoTreatTransaction[];
  transactionsLoading?: boolean;

  errorMessage?: string;

  displayApiResponseModal?: boolean;
  setDisplayApiResponseModal?: any;

  fetchOrders?: any;
  orders?: any;
  ordersLoading?: any;
  ordersRefreshedAt?: any;

  fetchTransactionResponses?: any;
  transactionResponsesLoading?: any;
  transactionResponsesRefreshedAt?: any;
  transactionResponses?: any;
}

const AutoTreatRealTimeActivityContext = React.createContext<AutoTreatRealTimeActivityContextInterface>({});

const AutoTreatRealTimeActivityContextConsumer = AutoTreatRealTimeActivityContext.Consumer;
const AutoTreatRealTimeActivityContextProvider: React.FC = ({ children }) => {
  const [transactions, setTransactions] = React.useState<AutoTreatTransaction[]>([]);
  const [transactionsLoading, setTransactionsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [displayApiResponseModal, setDisplayApiResponseModal] = React.useState<boolean>(false);

  const [ordersLoading, setOrdersLoading] = React.useState<boolean>(true);
  const [ordersRefreshedAt, setOrdersRefreshedAt] = React.useState<string>('');
  const [orders, setOrders] = React.useState<any[]>([]);

  const [transactionResponsesLoading, setTransactionResponsesLoading] = React.useState<boolean>(false);
  const [transactionResponsesRefreshedAt, setTransactionResponsesRefreshedAt] = React.useState<string>('');
  const [transactionResponses, setTransactionResponses] = React.useState<any[]>([]);

  /**
   * GET Analytics/AB/Transactions API.
   * This can be all of them or filtered on machines
   * @param filterHeaders (optional)
   */
  const fetchTransactions = async (filterParams?: string) => {
    setTransactionsLoading(transactions.length === 0);

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');

    await axios
      .get(
        `api/autotreat/analytics/transactions/?dateandtimeordercomplete_after=01/01/1900&dateandtimeordercomplete_before=12/31/2100&machine=${cachedMachines.join()}&${filterParams}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        },
      )
      .then((response: any) => {
        setTransactions(response.data.results);
        setTransactionsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactions([]);
        setTransactionsLoading(false);
      });
  };

  const fetchTransactionResponses = async (machineSerial: string, onSuccess?: () => void) => {
    setTransactionResponsesLoading(false);
    setTransactionResponsesRefreshedAt(null);
    const retryCount = 0;

    // axios
    //   .get<string, any>(`api/autotreat/analytics/transactions/integrations/?serial-number=8-8022-8888&pickuptransactionid=138`, {
    //     headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
    //   })
    //   .then((res) => {
    //     axios
    //       .get<string, any>(`api/autotreat/analytics/transactions/integrations/${res.data.result}/`, {
    //         headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
    //       })
    //   });
  };

  const fetchOrders = async (machineSerial: string, onSuccess?: () => void) => {
    setOrdersLoading(orders.length === 0);
    setOrdersRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(`api/orders/?serial-number=8-8036-8888`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        axios
          .get<string, any>(`api/orders/${res.data.result}/`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
          })
          .then((response) => {
            setOrdersLoading(false);
            setOrders(response.data.results);
            setOrdersRefreshedAt(response.data.created_at);

            if (onSuccess) {
              onSuccess();
            }
          })
          .catch(() => {
            retryCount += 1;

            if (retryCount > 5) {
              setOrdersLoading(false);
              setOrders([]);
              setOrdersRefreshedAt('Never');
            }
          });
      });
  };

  return (
    <AutoTreatRealTimeActivityContext.Provider
      value={{
        fetchTransactions,
        transactions,
        transactionsLoading,
        errorMessage,
        displayApiResponseModal,
        setDisplayApiResponseModal,
        fetchOrders,
        orders,
        ordersLoading,
        ordersRefreshedAt,
        fetchTransactionResponses,
        transactionResponsesLoading,
        transactionResponsesRefreshedAt,
        transactionResponses,
      }}
    >
      {children}
    </AutoTreatRealTimeActivityContext.Provider>
  );
};

export {
  AutoTreatRealTimeActivityContextProvider,
  AutoTreatRealTimeActivityContextConsumer,
  AutoTreatRealTimeActivityContext,
};
