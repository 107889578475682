import React, { Dispatch, SetStateAction } from 'react';
import { AlertColor } from '@mui/material';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface DriversAutoBulkContextInterface {
  fetchCachedDriversAutoBulk?: (machineSerial: string) => Promise<void>;
  fetchDriversAutoBulk?: (machineSerial: string, genericFilter: string, onSuccess?: () => void) => Promise<void>;
  driversRefreshedAt?: string;
  drivers?: any[];
  driversLoading?: boolean;

  fetchDriverOptions?: (machineSerial: string) => Promise<void>;
  driverOptions?: any;
  driverOptionsLoading?: any;

  createDriverAutoBulk?: (formValues: any, onSuccess?: () => void) => Promise<void>;

  updateDriverAutoBulk?: (formValues: any) => Promise<void>;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setDriverSubmitting?: Dispatch<SetStateAction<boolean>>;
  driverSubmitting?: boolean;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveDriverAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeDriverAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const DriversAutoBulkContext = React.createContext<DriversAutoBulkContextInterface>({});

const DriversAutoBulkContextConsumer = DriversAutoBulkContext.Consumer;
const DriversAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [drivers, setDriversAutoBulk] = React.useState([]);
  const [driversLoading, setDriversAutoBulkLoading] = React.useState(true);
  const [driversRefreshedAt, setDriversAutoBulkRefreshedAt] = React.useState<string>();
  const [activeDriverAutoBulk, setActiveDriverAutoBulk] = React.useState({});
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('driverAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('driverAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [driverOptions, setDriverOptions] = React.useState({
    driverRFIDOptions: [],
  });
  const [driverSubmitting, setDriverSubmitting] = React.useState(false);
  const [driverOptionsLoading, setDriverOptionsLoading] = React.useState(false);

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setDriversAutoBulkLoading(false);
  }, [activeMachine]);

  const fetchCachedDriversAutoBulk = async (machineSerial: string) => {
    setDriversAutoBulkLoading(true);
    setDriversAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/drivers/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setDriversAutoBulkLoading(false);
        setDriversAutoBulk(response.data.results);
        setDriversAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setDriversAutoBulkLoading(false);
        setDriversAutoBulk([]);
        setDriversAutoBulkRefreshedAt(null);
      });
  };

  const fetchDriversAutoBulk = async (machineSerial: string, genericFilter: string, onSuccess?: () => void) => {
    setDriversAutoBulkLoading(true);
    setDriversAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/drivers/?serial-number=${machineSerial}&generic-filter=${genericFilter}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/drivers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setDriversAutoBulkLoading(false);
              setDriversAutoBulk(response.data.results);
              setDriversAutoBulkRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setDriversAutoBulkLoading(false);
                showErrorSnackBar('Unable to load drivers');
                setDriversAutoBulk([]);
                setDriversAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchDriverOptions = async (machineSerial: string) => {
    setDriverOptionsLoading(true);

    axios
      .get<string, any>(`api/autobulk/onprem/driver_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setDriverOptions({
          ...driverOptions,
          driverRFIDOptions: response.data.results.filter((choice) => choice.category === 'rfid'),
        });
      })
      .catch((response) => {
        setDriverOptions({
          ...driverOptions,
          driverRFIDOptions: [],
        });
      });
  };

  const createDriverAutoBulk = async (formValues: any, onSuccess?: () => void) => {
    setDriverSubmitting(true);
    setDriversAutoBulkLoading(true);
    setDriversAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<string, any>(
        `api/autobulk/onprem/drivers/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/drivers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setDriversAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchDriversAutoBulk(activeMachine?.value, '');
              setDriverSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setDriversAutoBulkLoading(false);
                setDriverSubmitting(false);
                showErrorSnackBar('No response from machine');
                setDriversAutoBulk([]);
                setDriversAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateDriverAutoBulk = async (formValues: any) => {
    setDriverSubmitting(true);
    setDriversAutoBulkLoading(true);
    setDriversAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<string, any>(
        `api/autobulk/onprem/drivers/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/drivers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setDriversAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchDriversAutoBulk(activeMachine?.value, '');
              setDriverSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setDriversAutoBulkLoading(false);
                setDriverSubmitting(false);
                showErrorSnackBar('No response from machine');
                setDriversAutoBulk([]);
                setDriversAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <DriversAutoBulkContext.Provider
      value={{
        fetchCachedDriversAutoBulk,
        fetchDriversAutoBulk,
        drivers,
        driversRefreshedAt,
        driversLoading,

        fetchDriverOptions,
        driverOptions,

        createDriverAutoBulk,

        updateDriverAutoBulk,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        driverSubmitting,

        setActiveDriverAutoBulk,
        activeDriverAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
      }}
    >
      {children}
    </DriversAutoBulkContext.Provider>
  );
};

export { DriversAutoBulkContextProvider, DriversAutoBulkContextConsumer, DriversAutoBulkContext };
