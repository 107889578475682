/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CustomersAutoBulkImportContext } from '../contexts/CustomersAutoBulkImportContext';
import 'react-csv-importer/dist/index.css';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import CustomerAutoBulkcsvImport from '../components/CustomerAutoBulkcsvImport';

const CustomersAutoBulkcsvImportPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineSerial } = useParams<Record<string, string>>();

  const { setFailedRecords, setPassedRecords } = React.useContext(CustomersAutoBulkImportContext);

  React.useEffect(() => {
    setFailedRecords([]);
    setPassedRecords([]);
  }, []);

  const links = [
    {
      onClick: () => history.push(`/autobulk/machines/customers`),
      text: 'AutoBulk Customers',
    },
    {
      onClick: () => null,
      text: `Bulk Import (Machine: ${machineSerial})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <CustomerAutoBulkcsvImport machineSerialNumber={machineSerial} />
    </>
  );
};

export default CustomersAutoBulkcsvImportPage;
