import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@mui/styles';
import { AppBar, Menu, MenuItem, Link, Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../../../assets/ksi-logo.png';
import { APPLICATION_URL } from '../../utils/env';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { ApiLogContext } from '../../apiLogs/contexts/ApiLogsContext';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: 2,
  },
  inputRoot: {
    color: 'inherit',
    border: '1px solid #ccc',
  },
  sectionDesktop: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    '@media (min-width: 960px)': {
      display: 'none',
    },
  },
}));

interface UserTopNavigationprops {
  mobileNavigationActive: boolean;
  setMobileNavigationActive: any;
}

const UserTopNavigation: React.FunctionComponent<UserTopNavigationprops> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const { user, logout } = useAuth0();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [browserCacheNotification, setBrowserCacheNotification] = React.useState(false);
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { sendApiLogs } = React.useContext(ApiLogContext);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearBrowserCache = () => {
    showSuccessSnackBar('Your browser cache has been successfully cleared.');
    setBrowserCacheNotification(true);
    localStorage.removeItem('machines');
    localStorage.removeItem('autoBulkMachines');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('dateRange');

    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    // if (user?.email === 'colin+dup@nextlinklabs.com') {
    //   logout({ returnTo: `${APPLICATION_URL}/invalid_account` });
    // }
  }, [user]);

  const menuId = 'primary-search-account-menu';

  const handleLogOut = () => {
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('adminRole');
    logout({ returnTo: `${APPLICATION_URL}/login` } as any);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link style={{ color: '#000', textDecoration: 'none' }} href="/profile">
          My Account
        </Link>
      </MenuItem>

      <MenuItem onClick={sendApiLogs}>
        <Link style={{ color: '#000', textDecoration: 'none' }}>Send Diagnostics</Link>
      </MenuItem>

      {currentUserHasAdminPermission('ksi_superadmin') && (
        <MenuItem onClick={handleClearBrowserCache}>Clear Browser Cache</MenuItem>
      )}

      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item>
              <button
                type="button"
                className="Polaris-TopBar__NavigationIcon"
                aria-label="Toggle menu"
                onClick={() => setMobileNavigationActive(true)}
                style={{ marginTop: 7 }}
              >
                <span className="Polaris-Icon">
                  <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
                  </svg>
                </span>
              </button>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <a href={process.env.REACT_APP_URL}>
                    <img alt="logo" src={Logo} style={{ width: '60px', marginTop: 15 }} />
                  </a>
                </Grid>
                <Grid item>
                  <a href={process.env.REACT_APP_URL} style={{ textDecoration: 'none' }}>
                    <Typography style={{ lineHeight: '65px', marginLeft: 10, color: 'black', fontSize: 18 }}>
                      AgConnex
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div>
              <Typography style={{ lineHeight: '65px', color: 'black' }}>{user?.email}</Typography>
            </div>
            <IconButton edge="end" color="primary" onClick={handleProfileMenuOpen}>
              <AccountCircle style={{ fontSize: '2.2rem' }} />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton edge="end" color="primary" onClick={handleProfileMenuOpen}>
              <AccountCircle style={{ fontSize: '2.2rem' }} />
            </IconButton>
          </div>
          {renderMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default UserTopNavigation;
