// import '@shopify/polaris/dist/styles.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import './index.css';
import AutoBulkContextsProvider from './autoBulkContexts';
import AutoTreatContextsProvider from './autoTreatContexts';
import ContextsProvider from './contexts';
import Routes from './routes';
import theme from './theme';
import { APPLICATION_URL, AUTH_ZERO_DOMAIN, AUTH_ZERO_CLIENT_ID, AUTH_ZERO_AUDIENCE } from './modules/utils/env';
import { SnackBarContextProvider } from './modules/snackBar/contexts/SnackBarContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
    maxWidth: 'none',
  },
  box: {
    width: '100%',
  },
}));

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <AppProvider i18n={enTranslations}>
      <Auth0Provider
        domain={AUTH_ZERO_DOMAIN}
        clientId={AUTH_ZERO_CLIENT_ID}
        authorizationParams={{
          audience: AUTH_ZERO_AUDIENCE,
          redirect_uri: APPLICATION_URL,
        }}
      >
        <BrowserRouter>
          <SnackBarContextProvider>
            <AutoBulkContextsProvider>
              <AutoTreatContextsProvider>
                <ContextsProvider>
                  <Container
                    style={{
                      display: 'flex',
                      minHeight: '100vh',
                      width: '100%',
                      padding: 0,
                      maxWidth: 'none',
                    }}
                    color="background"
                    maxWidth="xl"
                  >
                    <Box className={classes.box}>
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container
                          style={{
                            display: 'flex',
                            minHeight: '100vh',
                            width: '100%',
                            padding: 0,
                            maxWidth: 'none',
                          }}
                          color="background"
                          maxWidth="xl"
                        >
                          <Box className={classes.box}>
                            <Routes />
                          </Box>
                        </Container>
                      </ThemeProvider>
                    </Box>
                  </Container>
                </ContextsProvider>
              </AutoTreatContextsProvider>
            </AutoBulkContextsProvider>
          </SnackBarContextProvider>
        </BrowserRouter>
      </Auth0Provider>
    </AppProvider>
  );
};

export default App;
