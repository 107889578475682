/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@mui/material';
import { Importer, ImporterField } from '../../../modules/csvImporter';
import 'react-csv-importer/dist/index.css';
import { CustomersAutoBulkValidationsContext } from '../contexts/CustomersAutoBulkValidationsContext';
import { CustomersAutoBulkImportContext } from '../contexts/CustomersAutoBulkImportContext';
import CustomerImportStepper from './CustomerImportStepper';
import { sanitizeInputs } from '../../../modules/shared/utils/sanitizeInputs';

interface CustomerAutoBulkcsvImportProps {
  apiPartner?: string;
  machineSerialNumber: any;
}

const CustomerAutoBulkcsvImport: React.FunctionComponent<CustomerAutoBulkcsvImportProps> = (props) => {
  const { apiPartner, machineSerialNumber } = props;

  const [activeStep, setActiveStep] = React.useState(0);

  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [triggerUnAcceptFile, setTriggerUnAcceptFile] = React.useState<number>(0);

  const { validatecsvImportRow } = React.useContext(CustomersAutoBulkValidationsContext);
  const { setPassedRecords, passedRecords, setFailedRecords, sendCustomersBulkAutoBulk, failedRecords } =
    React.useContext(CustomersAutoBulkImportContext);

  const handleEnableNext = (value) => {
    setNextButtonDisabled(value);
  };

  const handleSubmit = () => {
    sendCustomersBulkAutoBulk(machineSerialNumber, passedRecords, apiPartner);
  };

  const triggerNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUnAcceptFile = () => {
    setTriggerUnAcceptFile((triggerUnAcceptFile) => triggerUnAcceptFile + 1);
  };

  const triggerBackStep = () => {
    setPassedRecords([]);
    setFailedRecords([]);
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if (activeStep === 1) {
      handleUnAcceptFile();
    }
  };

  const handleValidateRows = (rows) => {
    const tempPassedRecords = [];
    const tempFailedRecords = [];
    const compoundIdCounts = {};

    const tempSanitizedRows = sanitizeInputs(rows);

    tempSanitizedRows.forEach((row, index) => {
      const compoundId =
        (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');
      compoundIdCounts[compoundId] = (compoundIdCounts[compoundId] || 0) + 1;
    });

    tempSanitizedRows.forEach((row, index) => {
      try {
        const rowValidation: { error: boolean; errorMessage?: string } = validatecsvImportRow(row);

        if (apiPartner) {
          const compoundId =
            (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');

          if (row.partner_record_id_1 === null || row.partner_record_id_1 === '') {
            rowValidation.error = true;
            rowValidation.errorMessage = 'partner_record_id_1 cannot be blank';
          } else if (compoundIdCounts[compoundId] > 1) {
            rowValidation.error = true;
            rowValidation.errorMessage = 'Duplicate partner id found';
          }
        }

        if (rowValidation.error) {
          tempFailedRecords.push({ ...row, errorMessage: rowValidation.errorMessage });
        } else {
          tempPassedRecords.push(row);
        }
      } catch (error) {
        console.error(`Error processing row ${index}:`, error);
      }
    });

    setPassedRecords((prevPassedRecords) => [...prevPassedRecords, ...tempPassedRecords]);
    setFailedRecords((prevFailedRecords) => [...prevFailedRecords, ...tempFailedRecords]);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item marginTop={1} xs={12}>
          <Importer
            activeStep={activeStep}
            enableNext={handleEnableNext}
            dataHandler={async (rows, { startIndex }) => {
              handleValidateRows(rows);
            }}
            passedRecords={passedRecords}
            failedRecords={failedRecords}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            stepperComponent={
              <CustomerImportStepper
                triggerNextStepProp={triggerNextStep}
                triggerBackStepProp={triggerBackStep}
                activeStep={activeStep}
                nextDisabled={nextButtonDisabled}
              />
            }
            unAcceptFile={triggerUnAcceptFile}
            sendToMachine={handleSubmit}
            apiPartner={apiPartner}
            columnsTitle="Autobulk Customer Fields"
          >
            <ImporterField name="customer_name" label="customer_name" />
            <ImporterField name="customer_contact_first" label="customer_contact_first" optional />
            <ImporterField name="customer_contact_last" label="customer_contact_last" optional />
            <ImporterField name="customer_comment" label="customer_comment" optional />
            <ImporterField name="customer_desc" label="customer_desc" optional />
            <ImporterField name="customer_address" label="customer_address" optional />
            <ImporterField name="customer_city" label="customer_city" optional />
            <ImporterField name="customer_state" label="customer_state" optional />
            <ImporterField name="country" label="country" optional />
            <ImporterField name="customer_zip" label="customer_zip" optional />
            <ImporterField name="customer_phone_1" label="customer_phone_1" optional />
            <ImporterField name="customer_phone_2" label="customer_phone_2" optional />
            <ImporterField name="customer_email" label="customer_email" optional />
            <ImporterField name="active" label="active" optional description="Default: true" />
            {apiPartner && (
              <>
                {/* <ImporterField name="api_partner" label="api_partner" optional /> */}
                <ImporterField name="partner_record_id_1" label="partner_record_id_1" />
                <ImporterField name="partner_record_id_2" label="partner_record_id_2" optional />
                <ImporterField name="partner_record_id_3" label="partner_record_id_3" optional />
              </>
            )}
          </Importer>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerAutoBulkcsvImport;
