import React from 'react';
import axios from '../../utils/axios.utils';

interface TopVarietyOrProduct {
  name: string;
  amount: number;
}

interface MachineData {
  serial_number: string;
  system_type: string;
  order_count_current_year: number;
  order_count_last_year: number;
  total_amount_current_year: number;
  total_amount_last_year: number;
  top_variety_or_product_current_year: Record<string, TopVarietyOrProduct>;
  top_customer_current_year: Record<string, TopVarietyOrProduct>;
  label: string;
  current_year: number;
}

interface MachineDashboardStats {
  online_machines: number;
  offline_machines: number;
  active_services: number;
  inactive_services: number;
  total_machine_count: number;
}

interface MachineDashboardContextInterface {
  fetchMachineDashboardData?: () => Promise<void>;
  machineData?: MachineData[];
  machineDashboardData?: any;
  machineDashboardStats?: MachineDashboardStats;
  ordersLoading?: boolean;
  connectionStateLoading?: boolean;
  machineSubscriptionLoading?: boolean;
}

const MachineDashboardContext = React.createContext<MachineDashboardContextInterface>({});

const MachineDashboardContextConsumer = MachineDashboardContext.Consumer;

const MachineDashboardContextProvider: React.FC = ({ children }) => {
  const [machineDashboardData, setMachineDashboardData] = React.useState<MachineData[]>([]);
  const [machineDashboardStats, setMachineDashboardStats] = React.useState<MachineDashboardStats>();
  const [ordersLoading, setOrdersLoading] = React.useState(true);
  const [connectionStateLoading, setConnectionStateLoading] = React.useState(true);
  const [machineSubscriptionLoading, setMachineSubscriptionLoading] = React.useState(true);

  const fetchMachineDashboardData = React.useCallback(async () => {
    if (sessionStorage.getItem('accessToken')) {
      setConnectionStateLoading(true);
      setMachineSubscriptionLoading(true);
      setOrdersLoading(true);
      try {
        const response = await axios.get<any>(`api/dashboard`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        });
        setMachineDashboardData(response.data.machine_data);
        setMachineDashboardStats(response.data.machine_stats);
      } catch (error) {
        console.error('Failed to fetch machine dashboard data:', error);
      } finally {
        setConnectionStateLoading(false);
        setMachineSubscriptionLoading(false);
        setOrdersLoading(false);
      }
    }
  }, [sessionStorage.getItem('accessToken')]);

  return (
    <MachineDashboardContext.Provider
      value={{
        fetchMachineDashboardData,
        machineDashboardData,
        machineDashboardStats,
        ordersLoading,
        connectionStateLoading,
        machineSubscriptionLoading,
      }}
    >
      {children}
    </MachineDashboardContext.Provider>
  );
};

export { MachineDashboardContextProvider, MachineDashboardContextConsumer, MachineDashboardContext };
