/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Typography, Grid, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import ProductAutoBulkForm from '../components/ProductsAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import ProductsAutoBulkDetail from '../components/ProductsAutoBulkDetail';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import IntegrationsList from '../components/IntegrationsList';
import { ProductIntegrationsAutoBulkContext } from '../contexts/ProductsIntegrationsAutoBulkContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const ProductsAutoBulkDetailRedirectPage: React.FunctionComponent = () => {
  const { machineSerial, productId } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    fetchCachedProductsAutoBulk,
    setDetailPage,
    setActiveProductAutoBulk,
    activeProductAutoBulk,
    activeMachine,
    setProductAutoBulkSubmitting,
    setActiveMachine,
    productsLoading,
    products,
  } = React.useContext(ProductsAutoBulkContext);

  const { fetchProductIntegrationsAutoBulk } = React.useContext(ProductIntegrationsAutoBulkContext);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/autobulk/machines/products/${productId}`,
  );

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        setDetailPage(true);
        fetchCachedProductsAutoBulk(machineSerial);
        // setActiveMachine({ label: machineSerial, value: machineSerial });

        fetchAutoBulkMachines();
        fetchProductIntegrationsAutoBulk(machineSerial, productId);
      }
    };

    getMachines();
  }, []);

  React.useEffect(() => {
    setActiveMachine(autoBulkMachines.filter((machine) => machine.value === machineSerial)?.[0]);
  }, [autoBulkMachines]);

  React.useEffect(() => {
    if (products.length > 0) {
      setActiveProductAutoBulk(products?.filter((product) => product.product_id?.toString() === productId)[0]);
    }
  }, [products]);

  const handleBack = () => {
    setDetailPage(false);
    setActiveProductAutoBulk(null);
    setProductAutoBulkSubmitting(false);
    history.push(`/autobulk/machines/products`);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeProductAutoBulk?.product_name}`,
    },
  ];

  return activeMachine && activeProductAutoBulk && !productsLoading ? (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Product List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <ProductsAutoBulkDetail
              product={activeProductAutoBulk}
              machineSerial={activeMachine?.value}
              onSuccess={() => setDetailPage(false)}
              onBack={handleBack}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <br />
            <IntegrationsList activeMachine={activeMachine} product={activeProductAutoBulk} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  ) : (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading product...</h2>
      <br />
      <CircularProgress />
    </Box>
  );
};

export default ProductsAutoBulkDetailRedirectPage;
