/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import DriverAutoBulkForm from './DriversAutoBulkForm';

const DriversAutoBulkDetail: React.FC = (props) => {
  const { setDetailPage, activeDriverAutoBulk } = React.useContext(DriversAutoBulkContext);

  return (
    <>
      <DriverAutoBulkForm driver={activeDriverAutoBulk} onSuccess={() => setDetailPage(false)} />
    </>
  );
};

export default DriversAutoBulkDetail;
