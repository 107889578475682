import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DataTable from '../../dataTable/DataTable/DataTable';

interface CompanyMachineAccessProps {
  company: any;
}

const AdminCompanyMachines: React.FC<CompanyMachineAccessProps> = (props) => {
  const { company } = props;
  const history = useHistory();

  const columns = [
    {
      name: 'Machine Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Serial Number',
      selector: 'serial_number',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.serial_number) || 0;
        const valB = parseFloat(b.serial_number) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: ({ status }): React.ReactNode => <div>{status ? 'Active' : 'Inactive'}</div>,
    },
    {
      name: 'Actions',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: (row): React.ReactNode => (
        <div>
          <a href={`/admin/machine_management/${row.machine_id}`} target="_blank" rel="noreferrer">
            Edit
          </a>
        </div>
      ),
    },
  ];

  const handleAddMachine = () => {
    window.open(`/admin/machine_management/new/company/${company.company_id}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Company Machines
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleAddMachine}>
              Add Machine
            </Button>
          </Grid>
        </Grid>
        <DataTable
          noHeader
          columns={columns}
          data={company.associated_machines}
          defaultSortAsc={false}
          onRowClicked={(row) => history.push(`/admin/machine_management/${row.machine_id}`)}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  );
};

export default AdminCompanyMachines;
