import React from 'react';
import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InformationField from '../../shared/form/InformationField';

interface MachineMetaInformationProps {
  machine: any;
}

const MachineMetaInformation: React.FC<MachineMetaInformationProps> = (props) => {
  const { machine } = props;

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Machine Meta Details
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Machine Type" value={machine?.system_type} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Serial Number" value={machine?.serial_number} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Software Version" value={machine?.software_version} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Last Backup Date" value={machine?.last_backup_date} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Indusoft Project" value={machine?.indusoft_project} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Local App Version" value={machine?.ksi_local_app_version} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Connection State" value={machine?.connection_state} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Last Online Date" value={machine?.last_online_date} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Last Offline Date" value={machine?.last_offline_date} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Subscription Active" value={machine?.subscription_active ? 'Yes' : 'No'} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField label="Subscription Expiration Date" value={machine?.subscription_expiration_date} />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <InformationField
              label="Subscription Expiration Days Remaining"
              value={machine?.subscription_expiration_days_remaining}
            />
          </Grid>
        </Grid>
        {/* {machine?.api_partners.map((partner, index) => (
            <Grid item xs={6} md={4} xl={3} key={index}>
              <InformationField
                label={`API Partner ${index + 1}`}
                value={partner.api_partner_name || partner.global_api_partner_id}
              />
            </Grid>
          ))} */}
      </CardContent>
    </Card>
  );
};

export default MachineMetaInformation;
