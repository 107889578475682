import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const productInformationColumns: IDataTableColumn<any>[] = [
  {
    name: 'Product',
    selector: 'product_name',
    sortable: true,
  },
  {
    name: 'Density',
    selector: 'product_density_with_uom',
  },
  {
    name: 'Target Amt',
    selector: 'product_delivered_target_amt_with_uom',
  },
  {
    name: 'Actual Amt',
    selector: 'product_delivered_actual_amt_with_uom',
  },
  {
    name: 'Accuracy',
    selector: 'product_delivered_accuracy',
    grow: 0.6,
  },
];

export default productInformationColumns;
