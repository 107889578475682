import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TreatmentProductIntegrationsAutoTreatContextInterface {
  fetchTreatmentProductIntegrationsAutoTreat?: (
    machineSerial: string,
    treatmentProductId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  createTreatmentProductIntegrationAutoTreat?: (
    machineSerial: string,
    treatmentProductId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  updateTreatmentProductIntegrationAutoTreat?: (
    machineSerial: string,
    treatmentProductId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  deleteTreatmentProductIntegrationAutoTreat?: (
    machineSerial: string,
    treatmentProductId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  treatmentProductIntegrations?: any[];
  treatmentProductIntegrationsLoading?: boolean;

  setTreatmentProductIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  treatmentProductIntegrationsSubmitting?: boolean;
}

const TreatmentProductIntegrationsAutoTreatContext =
  React.createContext<TreatmentProductIntegrationsAutoTreatContextInterface>({});

const TreatmentProductIntegrationsAutoTreatContextConsumer = TreatmentProductIntegrationsAutoTreatContext.Consumer;
const TreatmentProductIntegrationsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [treatmentProductIntegrations, setTreatmentProductIntegrations] = React.useState([]);
  const [treatmentProductIntegrationsLoading, setTreatmentProductIntegrationsLoading] = React.useState(true);
  const [treatmentProductIntegrationsSubmitting, setTreatmentProductIntegrationsSubmitting] = React.useState(false);

  const fetchTreatmentProductIntegrationsAutoTreat = async (
    machineSerial: string,
    liquid_name_id: string,
    onSuccess?: () => void,
  ) => {
    setTreatmentProductIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/treatment_product_integrations/?serial-number=${machineSerial}&treatment_product_id=${liquid_name_id}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_product_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })

            .then((response) => {
              window.clearInterval(fetchTimer);
              if (retryCount < MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setTreatmentProductIntegrationsLoading(false);
                setTreatmentProductIntegrations(response.data.results);
                retryCount += 1;
              } else {
                setTreatmentProductIntegrationsLoading(false);
                setTreatmentProductIntegrations([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createTreatmentProductIntegrationAutoTreat = async (
    machineSerial: string,
    liquid_name_id: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => {
    setTreatmentProductIntegrationsLoading(true);
    setTreatmentProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/treatment_product_integrations/?serial-number=${machineSerial}&treatment_product_id=${liquid_name_id}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_product_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchTreatmentProductIntegrationsAutoTreat(machineSerial, liquid_name_id);
              setTreatmentProductIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductIntegrationsLoading(false);
                setTreatmentProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateTreatmentProductIntegrationAutoTreat = async (
    machineSerial: string,
    liquid_name_id: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => {
    setTreatmentProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/treatment_product_integrations/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_product_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchTreatmentProductIntegrationsAutoTreat(machineSerial, liquid_name_id);
              setTreatmentProductIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteTreatmentProductIntegrationAutoTreat = async (
    machineSerial: string,
    liquid_name_id: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => {
    setTreatmentProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(
        `api/autotreat/onprem/treatment_product_integrations/${integrationId}/?serial-number=${machineSerial}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_product_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchTreatmentProductIntegrationsAutoTreat(machineSerial, liquid_name_id);
              showMachineManagementSnackBar(response.data.results[0]);
              setTreatmentProductIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <TreatmentProductIntegrationsAutoTreatContext.Provider
      value={{
        fetchTreatmentProductIntegrationsAutoTreat,
        createTreatmentProductIntegrationAutoTreat,
        updateTreatmentProductIntegrationAutoTreat,
        deleteTreatmentProductIntegrationAutoTreat,
        treatmentProductIntegrations,
        treatmentProductIntegrationsLoading,

        setTreatmentProductIntegrationsSubmitting,
        treatmentProductIntegrationsSubmitting,
      }}
    >
      {children}
    </TreatmentProductIntegrationsAutoTreatContext.Provider>
  );
};

export {
  TreatmentProductIntegrationsAutoTreatContextProvider,
  TreatmentProductIntegrationsAutoTreatContextConsumer,
  TreatmentProductIntegrationsAutoTreatContext,
};
