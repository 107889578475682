import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Company',
    selector: 'company_name',
    sortable: false,
  },
  {
    name: 'Admin Role',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: ({ admin_role }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: admin_role ? 'green' : 'red',
        }}
      >
        {admin_role ? 'YES' : 'NO'}
      </div>
    ),
  },
  {
    name: 'Default',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: ({ default_group }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: default_group ? 'green' : 'red',
        }}
      >
        {default_group ? 'YES' : 'NO'}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status ? 'green' : 'red',
        }}
      >
        {status ? 'ACTIVE' : 'INACTIVE'}
      </div>
    ),
  },
];

export default columns;
