export default function convertToLocalTime(dateString: string): string {
  try {
    // Addressing "Use object destructuring"
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Addressing "Unexpected string concatenation" with template literals
    const inputDate = new Date(dateString.endsWith('Z') ? dateString : `${dateString}Z`);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      // Addressing "Expected property shorthand"
      timeZone,
    };

    return inputDate.toLocaleString('en-US', options);
  } catch (error) {
    console.error(error);
    return '';
  }
}
