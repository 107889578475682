/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { MachineRequestsContext } from '../contexts/MachineRequestsContext';
import InformationField from '../../shared/form/InformationField';

const MachineRequestDetails: React.FunctionComponent = () => {
  const { machineRequestId, machineRequestRecordId } = useParams<Record<string, string>>();

  const { machineRequest, fetchMachineRequest } = React.useContext(MachineRequestsContext);

  React.useEffect(() => {
    fetchMachineRequest(machineRequestId, machineRequestRecordId);
  }, []);

  const formatedDate = (date) => {
    let formattedDate = '';

    if (date) {
      try {
        const isoDate = parseISO(date);
        formattedDate = format(isoDate, 'MM/dd/yyyy hh:mm aaa');
      } catch (error) {
        console.error(`Error parsing date: ${error}`);
      }
    }
    return formattedDate;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h2" component="h2">
            Machine Request Details
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <Grid container spacing={12}>
            <Grid item xs={3}>
              <InformationField
                label={`Date Sent to Machine (${
                  new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
                })`}
                value={formatedDate(machineRequest?.date_time_start)}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationField
                label={`Date Completed (${
                  new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
                })`}
                value={formatedDate(machineRequest?.date_time_completed)}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationField label="Machine Serial" value={machineRequest?.machine_serial} />
            </Grid>
            <Grid item xs={3}>
              <InformationField label="Message" value={machineRequest?.message} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MachineRequestDetails;
