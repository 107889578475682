import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const SupportModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab onClick={handleClickOpen} style={{ position: 'fixed', bottom: 15, right: 15, zIndex: 1000 }}>
        <ChatBubbleIcon />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxWidth: 800, display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}
      >
        <iframe
          src="https://chat.dante-ai.com/embed?kb_id=f62e8228-3a3e-4c94-8ce5-c89726cc09c2&token=5a868d79-56f9-410e-9791-dc4b24b8c016&modeltype=gpt-3.5&logo=ZmFsc2U=&mode=true"
          allow="clipboard-write"
          width="100%"
          height="700"
          frameBorder="0"
          title="Chat Dialog"
        />
      </Modal>
    </div>
  );
};

export default SupportModal;
