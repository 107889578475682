/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import SeedAutoTreatForm from '../components/SeedsAutoTreatForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const SeedsAutoTreatCreate: React.FunctionComponent = () => {
  const { setCreatingPage, activeMachine, setSeedSubmitting } = React.useContext(SeedsAutoTreatContext);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `Create Seed`,
    },
  ];

  const handleBack = () => {
    setCreatingPage(false);
    setSeedSubmitting(false);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Seed List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Seed
      </Typography>
      <br />
      <SeedAutoTreatForm
        seed={{}}
        machineSerial={activeMachine?.value}
        onSuccess={() => setCreatingPage(false)}
        onBack={handleBack}
      />
    </>
  );
};

export default SeedsAutoTreatCreate;
