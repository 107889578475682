/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import SeedsAutoTreatDetail from './SeedsAutoTreatDetailPage';
import SeedsAutoTreatCreate from './SeedsAutoTreatCreate';
import SeedsAutoTreatList from './SeedsAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const SeedsAutoTreatPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { detailPage, creatingPage, activeMachine } = React.useContext(SeedsAutoTreatContext);
  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    if (!initialDataLoaded && autoTreatMachines.length === 0) {
      fetchAutoTreatMachines();
    }
  }, []);

  const autoTreatSeedPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <SeedsAutoTreatDetail />,
          createPage: <SeedsAutoTreatCreate />,
          listPage: <SeedsAutoTreatList />,
        }[autoTreatSeedPage()]
      }
    </>
  );
};

export default SeedsAutoTreatPage;
