/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../dataTable/DataTable/DataTable';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';
import columns from '../constants/downloadColumns';
import { MachinesContext } from '../contexts/MachinesContext';
import { Machine } from '../model';
import AdminMachineFilterBar from './AdminMachineFilterBar';

interface DownloadDatabaseFormProps {
  machine: Machine;
  showLoadingIndicator?: boolean;
}

const DownloadDatabaseForm: React.FC<DownloadDatabaseFormProps> = (props) => {
  const { machine, showLoadingIndicator = true } = props;
  const { machineId, tab } = useParams<Record<string, string>>();
  const { user } = useAuth0();

  const history = useHistory();
  const {
    fetchDatabases,
    // fetchPaginatedDatabases,
    fetchExportJson,
    exportJson,
    databases,
    machinesLoading,
  } = React.useContext(MachinesContext);
  const { fetchFilterOptions } = React.useContext(MachinesContext);

  const [filterValues, setFilterValues] = React.useState({ subscription_active: 'status__all' });
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const utcOffset = new Date().getTimezoneOffset();
    fetchExportJson();
    fetchDatabases(machineId);
    fetchFilterOptions();
  }, [machineId]);

  const convertFilters = () => {
    return Object.entries(filterValues)
      .map(([key, value]) => (value !== 'status__all' ? `${key}=${value}&` : ''))
      .join('');
  };

  return (
    <>
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns(user, machine)}
            data={databases}
            defaultSortField="last_modified"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={machinesLoading}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default DownloadDatabaseForm;
