/* eslint-disable import/prefer-default-export */
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Source Name',
    selector: 'liquid_source',
    sortable: true,
    grow: 0.8,
  },
  {
    name: 'Location',
    selector: 'liquid_source_location',
    sortable: true,
    grow: 0.8,
  },
  {
    name: 'Source Type',
    selector: 'liquid_source_type',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Liquid Name',
    selector: 'liquid_name',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Density',
    selector: 'liquid_density',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.liquid_density) || 0;
      const valB = parseFloat(b.liquid_density) || 0;
      return valA - valB;
    },
    grow: 0.5,
  },
  {
    name: 'Shift Factor',
    selector: 'pump_cal_factor',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.pump_cal_factor) || 0;
      const valB = parseFloat(b.pump_cal_factor) || 0;
      return valA - valB;
    },
    grow: 0.6,
  },
];
