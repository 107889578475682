/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import ProductAutoBulkForm from '../components/ProductsAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import ProductsAutoBulkDetail from '../components/ProductsAutoBulkDetail';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import IntegrationsList from '../components/IntegrationsList';
import { ProductIntegrationsAutoBulkContext } from '../contexts/ProductsIntegrationsAutoBulkContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const ProductsAutoBulkDetailPage: React.FunctionComponent = () => {
  const {
    setDetailPage,
    setActiveProductAutoBulk,
    activeProductAutoBulk,
    activeMachine,
    setProductAutoBulkSubmitting,
  } = React.useContext(ProductsAutoBulkContext);
  const { fetchProductIntegrationsAutoBulk } = React.useContext(ProductIntegrationsAutoBulkContext);

  const [machine, setMachine] = React.useState({});
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/autobulk/machines/products/${activeProductAutoBulk?.product_id}`,
  );

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
        fetchProductIntegrationsAutoBulk(activeMachine?.value, activeProductAutoBulk.product_id);
      }
    };

    getMachines();
  }, []);

  React.useEffect(() => {
    setMachine(autoBulkMachines.filter((machine) => machine.value === activeMachine.value)?.[0]);
  }, [autoBulkMachines]);

  const handleBack = () => {
    setDetailPage(false);
    setActiveProductAutoBulk(null);
    setProductAutoBulkSubmitting(false);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeProductAutoBulk?.product_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Product List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <ProductsAutoBulkDetail
              product={activeProductAutoBulk}
              machineSerial={activeMachine?.value}
              onSuccess={() => setDetailPage(false)}
              onBack={handleBack}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <br />
            <IntegrationsList activeMachine={machine} product={activeProductAutoBulk} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default ProductsAutoBulkDetailPage;
