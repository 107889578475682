import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import UserForm from '../components/UserForm';
import { UsersContext } from '../contexts/UsersContext';

const UserNewPage: FunctionComponent = () => {
  const history = useHistory();

  const {
    fetchUserPermissionGroups,
    fetchUserMachinesByCompany,
    userMachinesByCompanyLoading,
    userPermissionGroupsLoading,
  } = React.useContext(UsersContext);

  React.useEffect(() => {
    fetchUserPermissionGroups();
    fetchUserMachinesByCompany();
  }, []);

  return userPermissionGroupsLoading || userMachinesByCompanyLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/account_management/users`)}>
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create User
      </Typography>
      <br />
      <UserForm user={{}} />
    </>
  );
};

export default UserNewPage;
