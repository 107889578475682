import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Source Name',
    selector: 'source_name',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Description',
    selector: 'source_description',
    sortable: true,
    grow: 1.7,
  },
  {
    name: 'Location',
    selector: 'source_location',
    sortable: true,
  },
  {
    name: 'Product Name',
    selector: 'product_name',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Source Type',
    selector: 'source_type',
    sortable: true,
  },
  {
    name: 'Inventory',
    selector: 'source_inventory',
    sortable: true,
    right: true,
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.source_inventory && <>{row.source_inventory} </>}
          {row.source_amount_type && <> {row.source_amount_type} </>}
        </p>
      </div>
    ),
  },
  {
    name: 'Capacity',
    selector: 'source_capacity',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.source_capacity) || 0;
      const valB = parseFloat(b.source_capacity) || 0;
      return valA - valB;
    },
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.source_capacity && <>{row.source_capacity} </>}
          {row.source_amount_type && <> {row.source_amount_type} </>}
        </p>
      </div>
    ),
  },
  {
    name: 'Fill Percent',
    selector: 'source_fill_percent',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.source_fill_percent) || 0;
      const valB = parseFloat(b.source_fill_percent) || 0;
      return valA - valB;
    },
  },
];

export default columns;
