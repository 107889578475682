import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useTableContext } from './DataTableContext';

const ButtonStyle = styled.button`
  display: inline-flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  ${(props) => props.theme.expanderButton.style};
`;

const ExpanderButton = ({ expanded, row, onToggled, disabled }) => {
  const { expandableIcon, keyField } = useTableContext();
  const icon = expanded ? expandableIcon.expanded : expandableIcon.collapsed;
  const handleToggle = (e) => onToggled && onToggled(row, e);

  return (
    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
      <ButtonStyle
        aria-disabled={disabled}
        onClick={handleToggle}
        data-testid={`expander-button-${row[keyField]}`}
        disabled={disabled}
        role="button"
        type="button"
      >
        {icon}
      </ButtonStyle>
    </Box>
  );
};

ExpanderButton.propTypes = {
  row: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onToggled: PropTypes.func,
  disabled: PropTypes.bool,
};

ExpanderButton.defaultProps = {
  onToggled: null,
  expanded: false,
  disabled: false,
};

export default ExpanderButton;
