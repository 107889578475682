import React from 'react';
import { TreatmentProductAutoTreatFormValues } from '../model';
import { TreatmentProductsAutoTreatContext } from './TreatmentProductsAutoTreatContext';

interface TreatmentProductsAutoTreatValidationsContextInterface {
  validateTreatmentProductsAutoTreatFields?: (formValues: TreatmentProductAutoTreatFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const TreatmentProductsAutoTreatValidationsContext =
  React.createContext<TreatmentProductsAutoTreatValidationsContextInterface>({});

const TreatmentProductsAutoTreatValidationsContextConsumer = TreatmentProductsAutoTreatValidationsContext.Consumer;
const TreatmentProductsAutoTreatValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { treatmentProducts, activeTreatmentProductAutoTreat } = React.useContext(TreatmentProductsAutoTreatContext);

  const fetchValidCharactersError = (formValues: TreatmentProductAutoTreatFormValues): boolean => {
    const SpecialCharacterStringCheck =
      formValues?.liquid_name_id +
      formValues?.liquid_name +
      formValues?.liquid_name_desc +
      formValues?.liquid_available +
      formValues?.liquid_lot_data +
      formValues?.shipment_number +
      formValues?.liquid_density +
      formValues?.min_rate +
      formValues?.max_rate +
      formValues?.target_rate +
      formValues?.manufacturer_list +
      formValues?.manufacturer +
      formValues?.adjustable +
      formValues?.adjust_by +
      formValues?.epa_number +
      formValues?.liquid_type_id +
      formValues?.liquid_type +
      formValues?.global_liquid_id +
      formValues?.username +
      formValues?.active +
      formValues?.date_and_time;
    const specialChars = /'/;

    return specialChars.test(SpecialCharacterStringCheck);
  };

  const fetchLiquidNameEmptyError = (liquid_name: string) => {
    if (liquid_name === '') {
      return 'cannot be left blank.';
    }

    if (liquid_name?.length > 50) {
      return 'cannot be longer than 50 characters';
    }

    // TODO - This check is not working.
    // const matchedNames = liquid_name.filter(
    //   (customer) => customer.operation.localeCompare(liquid_name, undefined, { sensitivity: 'base' }) === 0,
    // );
    // if (matchedNames.length > 0) {
    //   if (activeLiquidNameAutoTreat.liquid_name !== liquid_name) {
    //     return 'cannot be a duplicate name';
    //   }
    //   return false;
    // }
    return false;
  };

  const fetchLiquidNameDescError = (liquid_name_desc: string) => {
    if (liquid_name_desc?.length > 250) {
      return 'cannot be longer than 250 characters';
    }

    return false;
  };

  const fetchLiquidDensityError = (liquid_density: string) => {
    const parsedLiquidDensity = parseFloat(liquid_density);
    if (liquid_density === '') {
      return 'cannot be empty';
    }
    if (parsedLiquidDensity < 0.5 || parsedLiquidDensity > 15) {
      return 'must be between 0.5 and 15.';
    }
    return false;
  };

  const validateTreatmentProductsAutoTreatFields = (formValues: TreatmentProductAutoTreatFormValues) => {
    const liquidDensityError = fetchLiquidDensityError(formValues?.liquid_density);
    const liquidNameError = fetchLiquidNameEmptyError(formValues?.liquid_name);
    const liquidNameDescError = fetchLiquidNameDescError(formValues?.liquid_name_desc);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (specialCharactersError || liquidNameError || liquidDensityError) {
      setFormErrors({
        liquid_density: liquidDensityError,
        liquid_name: liquidNameError,
        liquid_name_desc: liquidNameDescError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      liquid_density: null,
      liquid_name: null,
      liquid_name_desc: null,
      base: null,
    });

    return true;
  };

  return (
    <TreatmentProductsAutoTreatValidationsContext.Provider
      value={{
        validateTreatmentProductsAutoTreatFields,
        formErrors,
      }}
    >
      {children}
    </TreatmentProductsAutoTreatValidationsContext.Provider>
  );
};

export {
  TreatmentProductsAutoTreatValidationsContextProvider,
  TreatmentProductsAutoTreatValidationsContextConsumer,
  TreatmentProductsAutoTreatValidationsContext,
};
