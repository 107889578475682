import React, { useMemo } from 'react';

import { PREVIEW_ROW_COUNT } from '../../parser';
import { Column } from './ColumnPreview';

import './ColumnDragCard.scss';

// @todo sort out "grabbing" cursor state (does not work with pointer-events:none)
export const ColumnDragCard: React.FC<{
  hasHeaders?: boolean; // for correct display of dummy card
  style?: any;
  containerStyle?: any;
  textStyle?: any;
  column?: Column;
  rowCount?: number;
  hasError?: boolean;
  isAssigned?: boolean;
  isShadow?: boolean;
  isDraggable?: boolean;
  isDragged?: boolean;
  isDropIndicator?: boolean;
}> = ({
  hasHeaders,
  style,
  containerStyle,
  textStyle,
  column: optionalColumn,
  rowCount = PREVIEW_ROW_COUNT,
  hasError,
  isShadow,
  isDraggable,
  isDragged,
  isDropIndicator,
}) => {
  const isDummy = !optionalColumn;

  const column = useMemo<Column>(
    () =>
      optionalColumn || {
        index: -1,
        code: '',
        header: hasHeaders ? '' : undefined,
        values: [...new Array(PREVIEW_ROW_COUNT)].map(() => ''),
      },
    [optionalColumn, hasHeaders],
  );

  const headerValue = column.header;
  const dataValues = column.values.slice(0, headerValue === undefined ? rowCount : rowCount - 1);

  return (
    // not changing variant dynamically because it causes a height jump
    <div
      key={isDummy || isShadow ? 1 : isDropIndicator ? 2 : 0} // force re-creation to avoid transition anim
      className="CSVImporter_ColumnDragCard"
      data-dummy={!!isDummy}
      data-error={!!hasError}
      style={{ ...style, ...containerStyle, height: '30px' }}
      data-shadow={!!isShadow}
      data-draggable={!!isDraggable}
      data-dragged={!!isDragged}
      data-drop-indicator={!!isDropIndicator}
    >
      {/* this is the weird letter that was hanging out above the columns */}
      {/* <div className="CSVImporter_ColumnDragCard__cardHeader">
        {isDummy ? (
          <var role="text">{l10n.columnCardDummyHeader}</var>
        ) : (
          <var role="text">{l10n.getColumnCardHeader(column.code)}</var>
        )}
        {isDummy || isAssigned ? '\u00a0' : <b aria-hidden>{column.code}</b>}
      </div> */}

      {headerValue !== undefined ? (
        <div className="CSVImporter_ColumnDragCard__cardValue" data-header style={textStyle}>
          {headerValue || '\u00a0'}
        </div>
      ) : null}

      {/* all values grouped into one readable string */}
      <div role="text">
        {dataValues.map((value, valueIndex) => (
          <div key={valueIndex} className="CSVImporter_ColumnDragCard__cardValue" style={{ lineHeight: '30px' }}>
            {/* {value || '\u00a0'} */}
          </div>
        ))}
      </div>
    </div>
  );
};
