import React, { useEffect, useState, useContext } from 'react';
import {
  ListItemText,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  DialogActions,
} from '@mui/material';

import { MuiChipsInput } from 'mui-chips-input';
import SelectInput from '../../shared/form/SelectInput';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationFormValues, NotificationCategory } from '../model';
import {
  notificationTypeOptions,
  timezoneOptions,
  scheduledIntervalOptions,
  scheduledDayOptions,
  scheduledTimeOptions,
  toleranceAboveOptions,
  toleranceBelowOptions,
  alertConditionConsecutiveOrderCountOptions,
  scheduledDayOptionsMonth,
} from '../constants/options';

interface Product {
  name: string;
  selected: boolean;
}

interface NotificationFormProps {
  notification: any;
  onSubmit: (formValues) => void;
  onCancel: () => void;
}

const NotificationForm: React.FunctionComponent<NotificationFormProps> = ({ notification, onSubmit, onCancel }) => {
  const {
    companyMachinesList,
    notificationCategoriesList,
    machineProductsList,
    notificationsLoading,
    fetchNotificationCategories,
    fetchCompanyMachines,
    fetchAutoTreatMachineProducts,
    fetchAutoBulkMachineProducts,
  } = useContext(NotificationContext);

  const [formValues, setFormValues] = useState<NotificationFormValues>({});

  const [activeStep, setActiveStep] = useState(0);
  const [emailReminder, setEmailReminder] = React.useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<NotificationCategory | null>(null);
  const [filteredCategories, setFilteredCategories] = useState<NotificationCategory[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  React.useEffect(() => {
    setFormValues({
      notification_id: notification?.notification_id || '',
      notification_name: notification?.notification_name || '',
      notification_timezone: notification?.notification_timezone || '',
      notification_type: notification?.notification_type || '',
      serial_number: notification?.serial_number || '',
      scheduled_interval: notification?.scheduled_interval || '',
      scheduled_day: notification?.scheduled_day || '',
      scheduled_time: notification?.scheduled_time || '',
      alert_condition_above_tolerance_amt: notification?.alert_condition_above_tolerance_amt || null,
      alert_condition_below_tolerance_amt: notification?.alert_condition_below_tolerance_amt || null,
      include_nested_records: notification?.include_nested_records || false,
      enabled: notification?.enabled || true,
      alert_suppress_armed: notification?.alert_suppress_armed || false,
      send_day_end_summary: notification?.send_day_end_summary || false,
      include_company_logo: notification?.include_company_logo || false,
      group_results_by_lot: notification?.group_results_by_lot || false,
      notification_category_id: notification?.notification_category_id || '',
      alert_condition_consecutive_order_count: notification?.alert_condition_consecutive_order_count || 0,
      product_list:
        typeof notification?.product_list === 'string'
          ? notification?.product_list.split(',').map((product) => product.trim())
          : notification?.product_list || [],
      recipient_emails:
        typeof notification?.recipient_emails === 'string'
          ? notification?.recipient_emails.split(',').map((email) => email.trim())
          : notification?.recipient_emails || [],
    });
  }, []);

  const buildProducts = () => {
    const addedProductNames = new Set(formValues.product_list);

    // Filter out products that are already added
    const availableProducts = machineProductsList.filter((productName) => !addedProductNames.has(productName));

    // Map over `availableProducts` to create an array with selection state
    const productsWithSelectionState: Product[] = availableProducts.map((productName) => ({
      name: productName,
      selected: false,
    }));

    // Set the state with the new list of available products
    setSelectedProducts(productsWithSelectionState);
  };

  const handleSelectProduct = (productName) => {
    // Update the `selectedProducts` state to reflect the change in selection
    setSelectedProducts((currentSelectedProducts) =>
      currentSelectedProducts.map((product) =>
        product.name === productName ? { ...product, selected: !product.selected } : product,
      ),
    );
  };

  const handleOpenModal = () => {
    buildProducts();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSaveSelection = () => {
    // Collect all selected product names into an array
    const newlySelectedProductNames = selectedProducts
      .filter((product) => product.selected)
      .map((product) => product.name);

    // Filter out any products that are already in the product_list array
    const newUniqueProducts = newlySelectedProductNames.filter(
      (productName) => !formValues.product_list.includes(productName),
    );

    // Combine the existing products in the form state with the new unique selections
    const updatedProductList = [...formValues.product_list, ...newUniqueProducts];

    setFormValues({ ...formValues, product_list: updatedProductList });

    handleCloseModal();
  };

  // Fetch notification categories
  useEffect(() => {
    fetchNotificationCategories();
  }, []);

  // Generate Dropdown label and value for Notification Categories
  const notifcaitionCategoryOptions = filteredCategories.map((category) => ({
    label: category.category_name,
    value: category.notification_category_id.toString(),
  }));

  // Fetch notification categories
  useEffect(() => {
    fetchCompanyMachines();
  }, []);

  // Fetch AutoTreat or AutoBulk Machine Products
  useEffect(() => {
    const selectedMachineSystemType = companyMachinesList.find(
      (machine) => formValues.serial_number === machine.serial_number,
    )?.system_type;
    if (selectedMachineSystemType === 'AutoTreat') {
      fetchAutoTreatMachineProducts(formValues?.serial_number);
    } else if (selectedMachineSystemType === 'AutoBulk') {
      fetchAutoBulkMachineProducts(formValues?.serial_number);
    }
  }, [formValues.serial_number]);

  // useEffect for recipient_emails and product_list
  useEffect(() => {
    if (notification) {
      setFormValues({
        ...notification,
        recipient_emails:
          typeof notification?.recipient_emails === 'string'
            ? notification?.recipient_emails.split(',').map((email) => email.trim())
            : notification?.recipient_emails || [],
        product_list:
          typeof notification?.product_list === 'string'
            ? notification?.product_list.split(',').map((product) => product.trim())
            : notification?.product_list || [],
      });
    }
  }, [notification]);

  // useEffect for filtering categories
  useEffect(() => {
    const selectedMachineSystemType = companyMachinesList.find(
      (machine) => formValues.serial_number === machine.serial_number,
    )?.system_type;
    const filteredNotificationCategoriesList = notificationCategoriesList.filter(
      (category) =>
        category.notification_type === formValues.notification_type &&
        category?.system_type.includes(selectedMachineSystemType),
    );

    setFilteredCategories(filteredNotificationCategoriesList);
  }, [
    notification,
    notificationCategoriesList,
    formValues.serial_number,
    formValues.notification_type,
    companyMachinesList,
  ]);

  // useEffect for setting selected category
  useEffect(() => {
    setSelectedCategory(
      notificationCategoriesList.find(
        (category) => formValues.notification_category_id?.toString() === category.notification_category_id?.toString(),
      ),
    );
  }, [notificationCategoriesList, formValues.notification_category_id]);

  // Handle form field changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: checked,
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  // Handle submission of the form
  const handleSubmit = async () => {
    await onSubmit(formValues);
  };

  // formatDate function
  const formatDate = (dateString) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  formatDate(formValues.last_modified_date_time);
  formatDate(formValues.created_date_time);

  return (
    <Box sx={{ m: 2 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {/* Step 1: Notification Details */}
        <Step expanded>
          <StepLabel>Notification Details</StepLabel>
          <StepContent>
            <FormControlLabel
              control={<Checkbox name="enabled" checked={formValues.enabled || true} onChange={handleChange} />}
              label="Enabled"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="Notification Name"
              name="notification_name"
              value={formValues.notification_name}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '16px' }}>
              <SelectInput
                label="Notification Timezone"
                value={formValues.notification_timezone}
                onChange={(value) => {
                  setFormValues({ ...formValues, notification_timezone: value });
                }}
                choices={timezoneOptions}
              />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <SelectInput
                label="Notification Type"
                value={formValues.notification_type}
                disabled={notification?.notification_id}
                onChange={(value) => {
                  setFormValues({ ...formValues, notification_type: value });
                }}
                choices={notificationTypeOptions}
              />
            </div>
          </StepContent>
        </Step>

        {/* Step 2: Machine Selection */}
        <Step expanded>
          <StepLabel>Machine Selection</StepLabel>
          <StepContent>
            <div>
              <SelectInput
                label="Machine"
                value={formValues.serial_number}
                disabled={notification?.notification_id}
                onChange={(value) => {
                  setFormValues({ ...formValues, serial_number: value });
                }}
                choices={companyMachinesList}
              />
            </div>
          </StepContent>
        </Step>

        {/* Step 3: Notification Category */}
        <Step expanded={formValues.serial_number !== ''}>
          <StepLabel>Notification Category</StepLabel>
          <StepContent>
            {formValues.serial_number ? (
              <div style={{}}>
                <SelectInput
                  label="Category"
                  value={formValues.notification_category_id}
                  disabled={notification?.notification_id}
                  onChange={(value) => {
                    setFormValues({ ...formValues, notification_category_id: value });
                  }}
                  choices={notifcaitionCategoryOptions}
                />
              </div>
            ) : (
              <p>Make Machine Selection</p>
            )}
          </StepContent>
        </Step>

        {/* Conditional Step 4: Scheduling */}
        {formValues.notification_type === 'Scheduled' && formValues.notification_category_id && (
          <Step expanded>
            <StepLabel>Scheduling</StepLabel>
            <StepContent>
              {formValues.notification_category_id ? (
                <div>
                  {selectedCategory?.enable_option_scheduled_interval && (
                    <div style={{ marginBottom: '16px' }}>
                      <SelectInput
                        label="Scheduled Interval"
                        value={formValues.scheduled_interval}
                        onChange={(value) => {
                          setFormValues({ ...formValues, scheduled_interval: value });
                          if (value !== 'Monthly') {
                            setFormValues((prev) => ({ ...prev, scheduled_day: '' }));
                          }
                        }}
                        choices={scheduledIntervalOptions}
                      />
                    </div>
                  )}

                  {selectedCategory?.enable_option_scheduled_day_time && formValues.scheduled_interval === 'Weekly' && (
                    <>
                      <div style={{ marginBottom: '16px' }}>
                        <SelectInput
                          label="Scheduled Day"
                          value={formValues.scheduled_day}
                          onChange={(value) => {
                            setFormValues({ ...formValues, scheduled_day: value });
                          }}
                          choices={scheduledDayOptions}
                        />
                      </div>
                      <div style={{ marginBottom: '16px' }}>
                        <SelectInput
                          label="Scheduled Time"
                          value={formValues.scheduled_time}
                          onChange={(value) => {
                            setFormValues({ ...formValues, scheduled_time: value });
                          }}
                          choices={scheduledTimeOptions}
                        />
                      </div>
                    </>
                  )}

                  {selectedCategory?.enable_option_scheduled_day_time && formValues.scheduled_interval === 'Monthly' && (
                    <div style={{ marginBottom: '16px' }}>
                      <SelectInput
                        label="Scheduled Day"
                        value={formValues.scheduled_day}
                        onChange={(value) => {
                          setFormValues({ ...formValues, scheduled_day: value });
                        }}
                        choices={scheduledDayOptionsMonth}
                      />
                    </div>
                  )}

                  {selectedCategory?.enable_option_scheduled_day_time && formValues.scheduled_interval === 'Daily' && (
                    <div style={{ marginBottom: '16px' }}>
                      <SelectInput
                        label="Scheduled Time"
                        value={formValues.scheduled_time}
                        onChange={(value) => {
                          setFormValues({ ...formValues, scheduled_time: value });
                        }}
                        choices={scheduledTimeOptions}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <p>Make Category Selection</p>
              )}
            </StepContent>
          </Step>
        )}

        {/* Conditional Step 4: Products & Tolerance */}
        {formValues.notification_type === 'RealTime' && formValues.notification_category_id && (
          <Step expanded>
            <StepLabel>Products & Tolerance</StepLabel>
            <StepContent>
              {formValues.notification_category_id ? (
                <div>
                  {selectedCategory?.enable_option_product_list && (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <MuiChipsInput
                          style={{ fontSize: '16px', marginBottom: '16px', marginTop: '8px' }}
                          label="Products"
                          value={formValues.product_list}
                          onChange={(value) => setFormValues({ ...formValues, product_list: value })}
                          clearInputOnBlur
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenModal}
                          style={{ height: '100%' }}
                        >
                          Choose Products
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {selectedCategory?.enable_option_alert_condition && (
                    <div style={{ marginBottom: '16px' }}>
                      <SelectInput
                        label="Above Tolerance Amount"
                        value={formValues.alert_condition_above_tolerance_amt}
                        onChange={(value) => {
                          setFormValues({ ...formValues, alert_condition_above_tolerance_amt: value });
                        }}
                        choices={toleranceAboveOptions}
                      />
                    </div>
                  )}
                  {selectedCategory?.enable_option_alert_condition && (
                    <div style={{ marginBottom: '16px' }}>
                      <SelectInput
                        label="Below Tolerance Amount"
                        value={formValues.alert_condition_below_tolerance_amt}
                        onChange={(value) => {
                          setFormValues({ ...formValues, alert_condition_below_tolerance_amt: value });
                        }}
                        choices={toleranceBelowOptions}
                      />
                    </div>
                  )}
                  {selectedCategory?.enable_option_alert_condition && (
                    <div style={{}}>
                      <SelectInput
                        label="Consecutive Order Count"
                        value={formValues.alert_condition_consecutive_order_count}
                        onChange={(value) => {
                          setFormValues({ ...formValues, alert_condition_consecutive_order_count: value });
                        }}
                        choices={alertConditionConsecutiveOrderCountOptions}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <p>Make Category Selection</p>
              )}
            </StepContent>
          </Step>
        )}

        {/* Step 5: Additional Details */}
        <Step
          expanded={formValues.alert_condition_consecutive_order_count !== '' || formValues.product_list.length > 0}
        >
          <StepLabel>Additional Configurations</StepLabel>
          <StepContent>
            {formValues.notification_category_id ? (
              <div>
                {selectedCategory?.enable_option_alert_suppress_armed && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="alert_suppress_armed"
                        checked={formValues.alert_suppress_armed}
                        onChange={(event) =>
                          setFormValues({ ...formValues, alert_suppress_armed: event.target.checked })
                        }
                      />
                    }
                    label="Suppress Armed Alerts"
                  />
                )}
                {selectedCategory?.enable_option_send_day_end_summary && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="send_day_end_summary"
                        checked={formValues.send_day_end_summary}
                        onChange={(event) =>
                          setFormValues({ ...formValues, send_day_end_summary: event.target.checked })
                        }
                      />
                    }
                    label="Send Day End Summary"
                  />
                )}
                {selectedCategory?.enable_option_include_nested_records && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="include_nested_records"
                        checked={formValues.include_nested_records}
                        onChange={(event) =>
                          setFormValues({ ...formValues, include_nested_records: event.target.checked })
                        }
                      />
                    }
                    label="Include Nested Records"
                  />
                )}
                {selectedCategory?.enable_option_include_company_logo && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="include_company_logo"
                        checked={formValues.include_company_logo}
                        onChange={(event) =>
                          setFormValues({ ...formValues, include_company_logo: event.target.checked })
                        }
                      />
                    }
                    label="Include Company Logo"
                  />
                )}
                {selectedCategory?.enable_option_group_results_by_lot && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="group_results_by_lot"
                        checked={formValues.group_results_by_lot}
                        onChange={(event) =>
                          setFormValues({ ...formValues, group_results_by_lot: event.target.checked })
                        }
                      />
                    }
                    label="Group Results by Lot"
                  />
                )}
              </div>
            ) : (
              <p>Make Category Selection</p>
            )}
          </StepContent>
        </Step>

        {/* Step 6: Recipients */}
        <Step expanded={selectedCategory?.enable_option_recipient_emails === true}>
          <StepLabel>Email Recipients</StepLabel>
          <StepContent>
            {selectedCategory?.enable_option_recipient_emails && (
              <Grid item xs={12}>
                <MuiChipsInput
                  style={{ fontSize: '16px' }}
                  value={formValues.recipient_emails}
                  onChange={(value) => setFormValues({ ...formValues, recipient_emails: value })}
                  onInputChange={(value) => setEmailReminder(value === '' ? '' : 'Use the enter key to save changes')}
                  clearInputOnBlur
                />
                {emailReminder && <p>{emailReminder}</p>}
              </Grid>
            )}
          </StepContent>
        </Step>
      </Stepper>

      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '2rem' }}>Choose Products</DialogTitle>
        <DialogContent dividers style={{ overflowY: 'auto', maxHeight: '50vh' }}>
          <List>
            {selectedProducts.map((product, index) => (
              <ListItem
                key={index}
                style={{ padding: '3px', borderBottom: '1px solid #ddd' }}
                onClick={() => handleSelectProduct(product.name)}
              >
                <Checkbox
                  edge="start"
                  checked={product.selected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${product.name}` }}
                  style={{ color: '#1976d2', marginRight: '8px' }}
                />
                <ListItemText id={`checkbox-list-label-${product.name}`} primary={product.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveSelection} color="primary" variant="contained">
            Add Selected
          </Button>
          <Button onClick={handleCloseModal} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={notificationsLoading}
          sx={{ mr: 1 }}
        >
          {formValues.notification_id ? 'Update' : 'Create'}
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>

      {/* Footer with Last Modified and Created */}
      {notification?.notification_id && (
        <Box sx={{ borderTop: '1px solid #ddd', paddingTop: '8px', marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="caption" display="block" gutterBottom style={{ fontSize: '1.10rem' }}>
            Last Modified by: {formValues.last_modified_by_user} {formatDate(formValues.last_modified_date_time)}
          </Typography>
          <Typography variant="caption" display="block" style={{ fontSize: '1.10rem' }}>
            Created by: {formValues.created_by_user} {formatDate(formValues.created_date_time)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationForm;
