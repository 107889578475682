import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { UserMachinesContext } from '../contexts/UserMachinesContext';
import UserMachineInformation from '../components/UserMachineInformation';
import companyAdminMachineColumns from '../constants/companyAdminMachineColumns';
import { Machine } from '../../machinesAdminManagement/model';
import UserMachinesFilterBar from '../components/UserMachinesFilterBar';

const UserAllMachinesList: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchAllCompanyMachines, accountMachines, machinesLoading } = React.useContext(UserMachinesContext);
  const [activeMachine, setActiveMachine] = React.useState<any>();
  const [filterValues, setFilterValues] = React.useState();

  React.useEffect(() => {
    fetchAllCompanyMachines();
  }, []);

  return (
    <Box>
      <Grid item>
        <Typography variant="h1" component="h1">
          Machine and Access Management
        </Typography>
        <br />
      </Grid>
      <br />
      <UserMachinesFilterBar allMachines filterValues={filterValues} setFilterValues={setFilterValues} />
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={companyAdminMachineColumns}
            data={accountMachines}
            onRowClicked={(row: Machine) => setActiveMachine(row)}
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[5, 10, 25]}
            progressPending={machinesLoading}
          />
        </CardContent>
      </Card>
      <br />
      {!machinesLoading && (
        <>
          {activeMachine ? (
            <Grid justifyContent="space-between" container spacing={3}>
              <Grid item xs={12} md={12}>
                <UserMachineInformation
                  machine={activeMachine}
                  onViewMachine={() =>
                    history.push(`/account_management/company_machines/${activeMachine.serial_number}`)
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <Card>
              <CardContent>
                <Grid justifyContent="center" container spacing={1} marginTop="50">
                  <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h1" component="h1">
                      Please select a machine to view details.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </Box>
  );
};

export default UserAllMachinesList;
