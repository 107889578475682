import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Checkbox, Button, Grid, GridSize, Typography } from '@mui/material';
import { SelectOption } from '../../shared/model';
import AutocompleteFilter from '../../shared/form/AutocompleteFilter';
import MachineAutocompleteFilter from '../../shared/form/MachineAutocompleteFilter';
import DateSelectFilter from '../../shared/form/DateSelectFilter';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { UserMachinesContext } from '../contexts/UserMachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface UserMachinesFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  setFilterValues?: any;
  filterChoices?: any;
  allMachines?: boolean;
}

const UserMachinesFilterBar: React.FC<UserMachinesFilterBarProps> = (props) => {
  const { filters, allMachines, filterValues, setFilterValues, filterChoices } = props;

  const { fetchUserMachines, fetchAllCompanyMachines, accountMachines, machinesLoading } =
    React.useContext(UserMachinesContext);

  const handleApplyFilters = async () => {
    const queryParams = new URLSearchParams();

    queryParams.append('name', filterValues?.name || '');
    queryParams.append('serial_number', filterValues?.serial_number || '');
    queryParams.append('online_status', filterValues?.online_status || '');
    queryParams.append('subscription_active', filterValues?.subscription_active || '');

    const filterParam = `${queryParams.toString()}&`;

    if (allMachines) {
      fetchAllCompanyMachines(filterParam);
    } else {
      fetchUserMachines(filterParam);
    }
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.name}
          onChange={(value) => setFilterValues({ ...filterValues, name: value })}
          label="Machine Name"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.serial_number}
          onChange={(value) => setFilterValues({ ...filterValues, serial_number: value })}
          label="Serial Number"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          value={filterValues?.online_status}
          onChange={(value) => setFilterValues({ ...filterValues, online_status: value })}
          label="Online Status"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Offline', value: 'Offline' },
            { label: 'Online', value: 'Online' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          value={filterValues?.subscription_active}
          onChange={(value) => setFilterValues({ ...filterValues, subscription_active: value })}
          label="Subscription Status"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserMachinesFilterBar;
