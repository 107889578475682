/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SourcesListPage from './SourcesListPage';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';
import { SourcesAutoBulkContext } from '../contexts/SourcesAutoBulkContext';

const SourcesAutoBulkPage: React.FunctionComponent = () => {
  const { activeMachine } = React.useContext(SourcesAutoBulkContext);

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      <SourcesListPage />
    </>
  );
};

export default SourcesAutoBulkPage;
