import React from 'react';
import { DriverAutoBulkFormValues } from '../model';
import { DriversAutoBulkContext } from './DriversAutoBulkContext';

interface DriversAutoBulkValidationsContextInterface {
  validateDriversAutoBulkFields?: (formValues: DriverAutoBulkFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const DriversAutoBulkValidationsContext = React.createContext<DriversAutoBulkValidationsContextInterface>({});

const DriversAutoBulkValidationsContextConsumer = DriversAutoBulkValidationsContext.Consumer;
const DriversAutoBulkValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { drivers, activeDriverAutoBulk } = React.useContext(DriversAutoBulkContext);

  const fetchValidCharactersError = (formValues: DriverAutoBulkFormValues): boolean => {
    const SpecialCharacterStringCheck =
      formValues?.driver_name +
      formValues?.driver_phone +
      formValues?.driver_email +
      formValues?.driver_access_code +
      formValues?.driver_comment +
      formValues?.active +
      formValues?.rfid_id +
      formValues?.rfid_name +
      formValues?.driver_api_partner;
    const specialChars = /'/;

    return specialChars.test(SpecialCharacterStringCheck);
  };

  const fetchDriverNameAutoBulkError = (driver_name: string) => {
    if (driver_name === '') {
      return 'cannot be left blank.';
    }

    if (driver_name?.length > 50) {
      return 'cannot be longer than 50 characters';
    }

    const matchedNames = drivers.filter((driver) => driver.driver_name === driver_name);
    if (matchedNames.length > 0) {
      if (activeDriverAutoBulk.driver_name !== driver_name) {
        return 'cannot be a duplicate name';
      }

      return false;
    }

    return false;
  };

  const fetchDriverCodeAutoBulkError = (driver_code: string) => {
    if (driver_code === '') {
      return 'cannot be left blank.';
    }

    if (driver_code?.length > 250) {
      return 'cannot be longer than 250 characters';
    }

    const matchedNames = drivers.filter((driver) => driver.driver_code === driver_code);
    if (matchedNames.length > 0) {
      if (activeDriverAutoBulk.driver_code !== driver_code) {
        return 'cannot be a duplicate code';
      }

      return false;
    }

    return false;
  };

  const validateDriversAutoBulkFields = (formValues: DriverAutoBulkFormValues) => {
    const abDriverNameError = fetchDriverNameAutoBulkError(formValues?.driver_name);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (specialCharactersError || abDriverNameError) {
      setFormErrors({
        DriverName: abDriverNameError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      DriverName: null,
      base: null,
    });

    return true;
  };

  return (
    <DriversAutoBulkValidationsContext.Provider
      value={{
        validateDriversAutoBulkFields,
        formErrors,
      }}
    >
      {children}
    </DriversAutoBulkValidationsContext.Provider>
  );
};

export {
  DriversAutoBulkValidationsContextProvider,
  DriversAutoBulkValidationsContextConsumer,
  DriversAutoBulkValidationsContext,
};
