/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import seedVarietyColumns from '../constants/seedVarietyColumns';
import DataTable from '../../../modules/dataTable';

interface SeedDeliveryInformationProps {
  transaction?: any;
}

const SeedDeliveryInformation: React.FunctionComponent<SeedDeliveryInformationProps> = (props) => {
  const { transaction } = props;

  const [seedVarietyObject, setSeedVarietyObject] = React.useState<any>([]);

  React.useEffect(() => {
    setSeedVarietyObject([]);

    const seedVarietyObject: any = {};
    (seedVarietyObject.seed_variety_name = transaction?.seed_variety_name),
      (seedVarietyObject.seed_lot_data = transaction?.seed_lot_data);
    seedVarietyObject.crop_name = transaction?.crop_name;
    seedVarietyObject.seed_units_actual_total_with_uom = transaction?.seed_units_actual_with_uom; // check this one TODO @ANDY
    seedVarietyObject.seed_weight_actual_total_with_uom = transaction?.seed_weight_actual_with_uom;

    setSeedVarietyObject([seedVarietyObject]);
  }, [transaction]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" style={{ marginTop: '-10px', marginBottom: '-15px' }}>
            Seed Delivery Information
          </Typography>
          <br />
          <Divider flexItem style={{ backgroundColor: 'black', height: '1px', width: '100%' }} />
          <DataTable
            columns={seedVarietyColumns(true)}
            data={seedVarietyObject}
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            dense
            noHeader
          />
          <Divider flexItem style={{ backgroundColor: 'black', height: '0.5px', width: '100%' }} />
        </Grid>
      </Grid>
    </>
  );
};

export default SeedDeliveryInformation;
