/* eslint-disable import/prefer-default-export */
import { format, parse } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Transaction ID',
    selector: 'work_order_transaction_id',
    sortable: true,
    grow: 0.7,
  },
  {
    name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'work_order_start_date',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.work_order_start_date) {
        try {
          // Parse the date
          const date = parse(row.work_order_start_date, 'M/d/yyyy h:mm:ss aa', new Date());
          // Format the date
          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Order Name',
    selector: 'work_order_name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Description',
    selector: 'work_order_description',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Comment',
    selector: 'work_order_comment',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Farm',
    selector: 'work_order_farm_notes',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Field',
    selector: 'work_order_field_notes',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'API Partner',
    selector: 'work_order_api_partner',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Partner ID 1',
    selector: 'work_order_partner_record_id_1',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Partner ID 2',
    selector: 'work_order_partner_record_id_2',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Response',
    selector: 'response',
    sortable: true,
    cell: (row) => row.response.split('addLineBreak').map((line, index) => <div key={index}>{line}</div>),
    grow: 2.0,
  },
  {
    name: 'Received',
    selector: 'partner_received',
    sortable: true,
    grow: 0.6,
  },
];
