import React from 'react';
import { AutoBulkFiltersContextProvider } from './autobulkModules/transactions/contexts/AutoBulkFiltersContext';
import { AutoBulkTransactionsContextProvider } from './autobulkModules/transactions/contexts/AutoBulkTransactionsContext';
import { ProductsContextProvider } from './autobulkModules/transactions/contexts/ProductsContext';
import { SourcesContextProvider } from './autobulkModules/transactions/contexts/SourcesContext';
import { AutoBulkRealTimeActivityContextProvider } from './autobulkModules/realTimeDashboard/contexts/AutoBulkRealTimeActivityContext';
import { CustomersAutoBulkValidationsContextProvider } from './autobulkModules/customersAutoBulk/contexts/CustomersAutoBulkValidationsContext';
import { ProductsAutoBulkContextProvider } from './autobulkModules/products/contexts/ProductsAutoBulkContext';
import { ProductsAutoBulkValidationsContextProvider } from './autobulkModules/products/contexts/ProductsAutoBulkValidationsContext';
import { SourcesAutoBulkContextProvider } from './autobulkModules/sources/contexts/SourcesAutoBulkContext';
import { LevelSensorsAutoBulkContextProvider } from './autobulkModules/levelSensors/contexts/LevelSensorsAutoBulkContext';
import { WorkOrdersAutoBulkContextProvider } from './autobulkModules/workOrders/contexts/WorkOrdersAutoBulkContext';
import { TransportsAutoBulkContextProvider } from './autobulkModules/transports/contexts/TransportsAutoBulkContext';
import { TransportsAutoBulkValidationsContextProvider } from './autobulkModules/transports/contexts/TransportsAutoBulkValidationsContext';
import { RfidsAutoBulkContextProvider } from './autobulkModules/rfid/contexts/RfidAutoBulkContext';
import { RfidsAutoBulkValidationsContextProvider } from './autobulkModules/rfid/contexts/RfidAutoBulkValidationsContext';
import { DriversAutoBulkContextProvider } from './autobulkModules/drivers/contexts/DriversAutoBulkContext';
import { DriversAutoBulkValidationsContextProvider } from './autobulkModules/drivers/contexts/DriversAutoBulkValidationsContext';
import { CustomersAutoBulkContextProvider } from './autobulkModules/customersAutoBulk/contexts/CustomersAutoBulkContext';
import { CustomerIntegrationsAutoBulkContextProvider } from './autobulkModules/customersAutoBulk/contexts/CustomerIntegrationsAutoBulkContext';
import { ProductIntegrationsAutoBulkContextProvider } from './autobulkModules/products/contexts/ProductsIntegrationsAutoBulkContext';
import { ProductsAutoBulkImportContextProvider } from './autobulkModules/products/contexts/ProductsAutoBulkImportContext';
import { TransactionApiLogsAutoBulkContextProvider } from './autobulkModules/transactionsApiLog/contexts/TransactionApiLogsAutoBulkContext';
import { CustomersAutoBulkImportContextProvider } from './autobulkModules/customersAutoBulk/contexts/CustomersAutoBulkImportContext';

const AutoBulkContexts: React.FC = (props) => {
  const { children } = props;

  return (
    <AutoBulkFiltersContextProvider>
      <TransactionApiLogsAutoBulkContextProvider>
        <AutoBulkTransactionsContextProvider>
          <ProductsContextProvider>
            <ProductsAutoBulkImportContextProvider>
              <SourcesContextProvider>
                <AutoBulkRealTimeActivityContextProvider>
                  <ProductsAutoBulkContextProvider>
                    <ProductsAutoBulkValidationsContextProvider>
                      <SourcesAutoBulkContextProvider>
                        <LevelSensorsAutoBulkContextProvider>
                          <WorkOrdersAutoBulkContextProvider>
                            <TransportsAutoBulkContextProvider>
                              <TransportsAutoBulkValidationsContextProvider>
                                <RfidsAutoBulkContextProvider>
                                  <RfidsAutoBulkValidationsContextProvider>
                                    <DriversAutoBulkContextProvider>
                                      <DriversAutoBulkValidationsContextProvider>
                                        <CustomersAutoBulkContextProvider>
                                          <CustomerIntegrationsAutoBulkContextProvider>
                                            <CustomersAutoBulkImportContextProvider>
                                              <CustomersAutoBulkValidationsContextProvider>
                                                <ProductIntegrationsAutoBulkContextProvider>
                                                  {children}
                                                </ProductIntegrationsAutoBulkContextProvider>
                                              </CustomersAutoBulkValidationsContextProvider>
                                            </CustomersAutoBulkImportContextProvider>
                                          </CustomerIntegrationsAutoBulkContextProvider>
                                        </CustomersAutoBulkContextProvider>
                                      </DriversAutoBulkValidationsContextProvider>
                                    </DriversAutoBulkContextProvider>
                                  </RfidsAutoBulkValidationsContextProvider>
                                </RfidsAutoBulkContextProvider>
                              </TransportsAutoBulkValidationsContextProvider>
                            </TransportsAutoBulkContextProvider>
                          </WorkOrdersAutoBulkContextProvider>
                        </LevelSensorsAutoBulkContextProvider>
                      </SourcesAutoBulkContextProvider>
                    </ProductsAutoBulkValidationsContextProvider>
                  </ProductsAutoBulkContextProvider>
                </AutoBulkRealTimeActivityContextProvider>
              </SourcesContextProvider>
            </ProductsAutoBulkImportContextProvider>
          </ProductsContextProvider>
        </AutoBulkTransactionsContextProvider>
      </TransactionApiLogsAutoBulkContextProvider>
    </AutoBulkFiltersContextProvider>
  );
};

export default AutoBulkContexts;
