import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TransactionApiLogsAutoBulkContextInterface {
  fetchCachedTransactionApiLogsAutoBulk?: (machineSerial: string) => Promise<void>;
  fetchTransactionApiLogsAutoBulk?: (
    machineSerial: string,
    genericFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  transactionApiLogsAutoBulkRefreshedAt?: string;
  transactionApiLogsAutoBulk?: any[];
  transactionApiLogsAutoBulkLoading?: boolean;

  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveTransactionApiLogAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeTransactionApiLogAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const TransactionApiLogsAutoBulkContext = React.createContext<TransactionApiLogsAutoBulkContextInterface>({});

const TransactionApiLogsAutoBulkContextConsumer = TransactionApiLogsAutoBulkContext.Consumer;
const TransactionApiLogsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar } = React.useContext(SnackBarContext);

  const [transactionApiLogsAutoBulk, setTransactionApiLogsAutoBulk] = React.useState([]);
  const [transactionApiLogsAutoBulkLoading, setTransactionApiLogsAutoBulkLoading] = React.useState(true);
  const [transactionApiLogsAutoBulkRefreshedAt, setTransactionApiLogsAutoBulkRefreshedAt] = React.useState<string>();
  const [activeTransactionApiLogAutoBulk, setActiveTransactionApiLogAutoBulk] = React.useState({});
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('transactionApiLogsAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('transactionApiLogsAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setTransactionApiLogsAutoBulkLoading(false);
  }, [activeMachine]);

  const fetchCachedTransactionApiLogsAutoBulk = async (machineSerial: string) => {
    setTransactionApiLogsAutoBulkLoading(true);
    setTransactionApiLogsAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/transaction_api_log/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransactionApiLogsAutoBulkLoading(false);
        setTransactionApiLogsAutoBulk(response.data.results);
        setTransactionApiLogsAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setTransactionApiLogsAutoBulkLoading(false);
        setTransactionApiLogsAutoBulk([]);
        setTransactionApiLogsAutoBulkRefreshedAt(null);
      });
  };

  const fetchTransactionApiLogsAutoBulk = async (
    machineSerial: string,
    genericFilter: string,
    onSuccess?: () => void,
  ) => {
    setTransactionApiLogsAutoBulkLoading(true);
    setTransactionApiLogsAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/transaction_api_log/?serial-number=${machineSerial}&generic-filter=${genericFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/transaction_api_log/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              setTransactionApiLogsAutoBulkLoading(false);
              setTransactionApiLogsAutoBulk(response.data.results);
              setTransactionApiLogsAutoBulkRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load Transaction Api Logs');
                setTransactionApiLogsAutoBulkLoading(false);
                setTransactionApiLogsAutoBulk([]);
                setTransactionApiLogsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <TransactionApiLogsAutoBulkContext.Provider
      value={{
        fetchCachedTransactionApiLogsAutoBulk,
        fetchTransactionApiLogsAutoBulk,
        handleGenericFilterChange,
        genericFilter,
        transactionApiLogsAutoBulk,
        transactionApiLogsAutoBulkRefreshedAt,
        transactionApiLogsAutoBulkLoading,

        detailPage,
        setDetailPage,

        setActiveTransactionApiLogAutoBulk,
        activeTransactionApiLogAutoBulk,

        setActiveMachine,
        activeMachine,
      }}
    >
      {children}
    </TransactionApiLogsAutoBulkContext.Provider>
  );
};

export {
  TransactionApiLogsAutoBulkContextProvider,
  TransactionApiLogsAutoBulkContextConsumer,
  TransactionApiLogsAutoBulkContext,
};
