/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TreatmentProductsAutoTreatDetail from './TreatmentProductsAutoTreatDetailPage';
import TreatmentProductsAutoTreatCreate from './TreatmentProductsAutoTreatCreate';
import TreatmentProductsAutoTreatList from './TreatmentProductsAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const TreatmentProductsAutoTreatPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { detailPage, creatingPage, activeMachine } = React.useContext(TreatmentProductsAutoTreatContext);
  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    if (!initialDataLoaded && autoTreatMachines.length === 0) {
      fetchAutoTreatMachines();
    }
  }, []);

  const autoTreatTreatmentProductPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <TreatmentProductsAutoTreatDetail />,
          createPage: <TreatmentProductsAutoTreatCreate />,
          listPage: <TreatmentProductsAutoTreatList />,
        }[autoTreatTreatmentProductPage()]
      }
    </>
  );
};

export default TreatmentProductsAutoTreatPage;
