import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ToolsMajor,
  DataVisualizationMajor,
  InventoryMajor,
  CustomersMajor,
  DigitalMediaReceiverMajor,
} from '@shopify/polaris-icons';

export const autotreatMachineManagementRoutes = {
  label: 'Machine Management',
  url: '#',
  excludePaths: ['#'],
  icon: InventoryMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/autotreat/machines/customers',
      disabled: false,
      label: 'Customers',
    },
    {
      url: '/autotreat/machines/seeds',
      disabled: false,
      label: 'Seeds',
    },
    {
      url: '/autotreat/machines/treat_products',
      disabled: false,
      label: 'Treatment Products',
    },
    {
      url: '/autotreat/machines/recipes',
      disabled: false,
      label: 'Recipes',
    },
    {
      url: '/autotreat/machines/orders',
      disabled: false,
      label: 'Orders',
    },
    {
      url: '/autotreat/machines/seed_sources',
      disabled: false,
      label: 'Seed Sources',
    },
    {
      url: '/autotreat/machines/liquid_sources',
      disabled: false,
      label: 'Treatment Sources',
    },
    {
      url: '/autotreat/machines/transaction_api_log',
      disabled: false,
      label: 'Transaction API Log',
    },
  ],
};

export const autoTreatAnalyticsRoutes = {
  label: 'Analytics',
  url: '/autotreat/analytics',
  icon: DataVisualizationMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/autotreat/analytics/transaction_summary',
      disabled: false,
      label: 'Transaction Summary',
    },
    {
      url: '/autotreat/analytics/treatment_product_summary',
      disabled: false,
      label: 'Treamtent Product Summary',
    },
    {
      url: '/autotreat/analytics/seed_variety_summary',
      disabled: false,
      label: 'Seed Variety Summary',
    },
  ],
};
