import React from 'react';
import { Button, CircularProgress, Grid, GridSize, Typography } from '@mui/material';
import { SelectOption, FilterProps } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { LevelSensorsAutoBulkContext } from '../contexts/LevelSensorsAutoBulkContext';
import FormInput from '../../../modules/shared/form/FormInput';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';

interface LevelSensorFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  activeMachine?: Record<string, string>;
  setActiveMachine?: any;
}

const LevelSensorFilterBar: React.FC<LevelSensorFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    activeMachine,
    setActiveMachine,
  } = props;
  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);
  const [workOrderSelected, setworkOrderSelected] = React.useState<Record<string, any[]>>(filterValues);
  const [buttonAction, setButtonAction] = React.useState('Load');
  const { levelSensors, fetchLevelSensors, fetchCachedLevelSensors, levelSensorsLoading, levelSensorsRefreshedAt } =
    React.useContext(LevelSensorsAutoBulkContext);

  React.useEffect(() => {
    if (levelSensors?.length === 0 && localStorage.getItem('levelSensorAutoBulkMachine')) {
      fetchCachedLevelSensors(localStorage.getItem('levelSensorAutoBulkMachine'));
    }
  }, []);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('levelSensorAutoBulkMachine', value);
    fetchCachedLevelSensors(value);
    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <SelectInput
              value={activeMachine?.value}
              choices={[defaultMachineChoice(), ...machineChoices]}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={levelSensorsLoading || activeMachine?.value === 'Select A Machine'}
              onClick={() => {
                fetchLevelSensors(activeMachine?.value);
              }}
            >
              Load
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!levelSensorsLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {levelSensorsLoading ? (
              <span style={{ marginLeft: 25, position: 'relative', top: 23 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 13 }}>
                Last Loaded: {convertToLocalTime(levelSensorsRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LevelSensorFilterBar;
