import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../../modules/utils/axios.utils';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface ProductsAutoBulkImportContextInterface {
  sendProductsBulkAutoBulk?: (
    machine_serial_number: string,
    csvData: any,
    apiPartner: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  fetchRecordStatus?: (formValues: []) => Promise<void>;

  productBulkSubmiting?: boolean;
  setProductBulkSubmiting?: Dispatch<SetStateAction<boolean>>;

  passedRecords?: any;
  setPassedRecords?: any;

  failedRecords?: any;
  setFailedRecords?: any;
}

const ProductsAutoBulkImportContext = React.createContext<ProductsAutoBulkImportContextInterface>({});

const ProductsAutoBulkImportContextConsumer = ProductsAutoBulkImportContext.Consumer;
const ProductsAutoBulkImportContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { showErrorSnackBar, showSuccessSnackBar } = React.useContext(SnackBarContext);

  const [passedRecords, setPassedRecords] = React.useState([]);
  const [failedRecords, setFailedRecords] = React.useState([]);

  const sendProductsBulkAutoBulk = async (
    machine_serial_number: string,
    csvData: any,
    apiPartner: string,
    onSuccess?: () => void,
  ) => {
    showSuccessSnackBar('Submitting data to machine');

    let apiPartnerParam = '';
    if (apiPartner) {
      apiPartnerParam = `&api_partner=${apiPartner}`;
    }

    axios
      .post<any, any>(
        `api/autobulk/onprem/products_bulk/?serial-number=${machine_serial_number}${apiPartnerParam}`,
        { ...csvData },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        if (onSuccess) {
          onSuccess();
        } else {
          history.push(`/machine_requests/${res.data.result}/machine_request_records`);
        }
      })
      .catch((error) => {
        showErrorSnackBar('Unknown error occured, please check your machine status and try again.');
      });
  };

  return (
    <ProductsAutoBulkImportContext.Provider
      value={{
        sendProductsBulkAutoBulk,

        passedRecords,
        setPassedRecords,

        failedRecords,
        setFailedRecords,
      }}
    >
      {children}
    </ProductsAutoBulkImportContext.Provider>
  );
};

export { ProductsAutoBulkImportContextProvider, ProductsAutoBulkImportContextConsumer, ProductsAutoBulkImportContext };
