import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Operation',
    selector: 'operation',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'First Name',
    selector: 'first_name',
    sortable: true,
  },
  {
    name: 'Last Name',
    selector: 'last_name',
    sortable: true,
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.city && <>{row.city} </>}
          {row.city && row.state && <>, </>}
          {row.state && <> {row.state} </>}
          <br />
        </p>
      </div>
    ),
  },
  {
    name: 'Mobile Phone',
    selector: 'mobile_phone',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.mobile_phone) || 0;
      const valB = parseFloat(b.mobile_phone) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    grow: 1.5,
  },
  {
    name: `Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time_last_modified',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) =>
      new Date(rowA?.date_and_time_last_modified).getTime() - new Date(rowB?.date_and_time_last_modified).getTime(),
    cell: (row: any) => (
      <p>
        {row.date_and_time_last_modified
          ? format(new Date(`${row?.date_and_time_last_modified} UTC`), 'MM/dd/yyyy hh:mm aaa')
          : ''}
      </p>
    ),
  },
  {
    name: 'Modified By',
    selector: 'username',
    sortable: true,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    cell: (row: any) => <p>{row.active === 'True' ? 'Yes' : 'No'}</p>,
    omit: true,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'Integration Id',
    selector: 'integration_id',
    sortable: true,
  },
  {
    name: 'Customer Id',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'API Partner',
    selector: 'api_partner',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];
