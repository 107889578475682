const treatmentFilters = [
  {
    label: 'Treatment Product',
    pluralLabel: 'Treatment Products',
    selector: 'treatment_products',
    type: 'select',
    width: 180,
    optionSelector: 'treatment_product_list',
  },
  {
    label: 'Crop',
    pluralLabel: 'Crops',
    selector: 'crops',
    type: 'select',
    optionSelector: 'crop_name_list',
  },
  {
    label: 'Customer',
    pluralLabel: 'Customers',
    selector: 'customers',
    type: 'select',
    optionSelector: 'customer_name_list',
  },
  // {
  //   label: 'Product Type',
  //   pluralLabel: 'Product Types',
  //   selector: 'product_type',
  //   width: 2,
  //   type: 'select',
  //   options: ['Dry', 'Liquid'],
  // },
];

export default treatmentFilters;
