/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { WorkOrdersAutoBulkContext } from '../contexts/WorkOrdersAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import WorkOrdersAutoBulkDetailPage from './WorkOrdersAutoBulkDetailPage';
import WorkOrdersAutoBulkCreate from './WorkOrdersAutoBulkCreate';
import WorkOrdersAutoBulkList from './WorkOrdersAutoBulkList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const WorkOrdersAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchWorkOrdersAutoBulk, detailPage, creatingPage, workOrdersLoading, activeMachine } =
    React.useContext(WorkOrdersAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchWorkOrdersAutoBulk]);

  const autoBulkWorkOrderPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <WorkOrdersAutoBulkDetailPage />,
          createPage: <WorkOrdersAutoBulkCreate />,
          listPage: <WorkOrdersAutoBulkList />,
        }[autoBulkWorkOrderPage()]
      }
    </>
  );
};

export default WorkOrdersAutoBulkPage;
