import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { MachinesContext } from '../contexts/MachinesContext';
import MachineForm from '../components/MachineForm';

const NewMachine: FunctionComponent = () => {
  const history = useHistory();
  const { companyId } = useParams<Record<string, string>>();

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchCompanyOptions, companiesLoading } = React.useContext(CompaniesContext);

  React.useEffect(() => {
    fetchCompanyOptions();
  }, []);

  return companiesLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/admin/machine_management`)}>
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Machine
      </Typography>
      <br />
      <MachineForm
        machine={
          {
            autobulk: 'false',
            subscription_active: 'true',
            disable_analytics_upload: 'false',
            subscription_expiration_date: '2022-08-31T12:00',
            company: { company_id: companyId },
          } as any
        }
      />
    </>
  );
};

export default NewMachine;
