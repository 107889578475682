import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { SeedAutoTreatFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface SeedIntegrationsAutoTreatContextInterface {
  fetchSeedIntegrationsAutoTreat?: (machineSerial: string, seedId: string) => Promise<void>;

  createSeedIntegrationAutoTreat?: (
    machineSerial: string,
    seedVarietyId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  updateSeedIntegrationAutoTreat?: (
    machineSerial: string,
    seedVarietyId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  deleteSeedIntegrationAutoTreat?: (
    machineSerial: string,
    seedVarietyId: string,
    integrationId: string,
  ) => Promise<void>;

  seedIntegrations?: any[];
  seedIntegrationsLoading?: boolean;

  setSeedIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  seedIntegrationsSubmitting?: boolean;
}

const SeedIntegrationsAutoTreatContext = React.createContext<SeedIntegrationsAutoTreatContextInterface>({});

const SeedIntegrationsAutoTreatContextConsumer = SeedIntegrationsAutoTreatContext.Consumer;
const SeedIntegrationsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [seedIntegrations, setSeedIntegrations] = React.useState([]);
  const [seedIntegrationsLoading, setSeedIntegrationsLoading] = React.useState(true);
  const [seedIntegrationsSubmitting, setSeedIntegrationsSubmitting] = React.useState(false);

  const fetchSeedIntegrationsAutoTreat = async (machineSerial: string, seedVarietyId: string) => {
    setSeedIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/seeds_integrations/?serial-number=${machineSerial}&seed_variety_id=${seedVarietyId}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setSeedIntegrationsLoading(false);
              setSeedIntegrations(response.data.results);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setSeedIntegrationsLoading(false);
                setSeedIntegrations([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createSeedIntegrationAutoTreat = async (
    machineSerial: string,
    seedVarietyId: string,
    formValues?: SeedAutoTreatFormValues,
  ) => {
    setSeedIntegrationsLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/seeds_integrations/?serial-number=${machineSerial}&seed_variety_id=${seedVarietyId}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchSeedIntegrationsAutoTreat(machineSerial, seedVarietyId);
              setSeedIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setSeedIntegrationsLoading(false);
                setSeedIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateSeedIntegrationAutoTreat = async (
    machineSerial: string,
    seedVarietyId: string,
    integrationId: string,
    formValues?: SeedAutoTreatFormValues,
  ) => {
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/seeds_integrations/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchSeedIntegrationsAutoTreat(machineSerial, seedVarietyId);
              setSeedIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setSeedIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteSeedIntegrationAutoTreat = async (
    machineSerial: string,
    seedVarietyId: string,
    integrationId: string,
  ) => {
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autotreat/onprem/seeds_integrations/${integrationId}/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchSeedIntegrationsAutoTreat(machineSerial, seedVarietyId);
              showMachineManagementSnackBar(response.data.results[0]);
              setSeedIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setSeedIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <SeedIntegrationsAutoTreatContext.Provider
      value={{
        fetchSeedIntegrationsAutoTreat,
        createSeedIntegrationAutoTreat,
        updateSeedIntegrationAutoTreat,
        deleteSeedIntegrationAutoTreat,

        seedIntegrations,
        seedIntegrationsLoading,
        seedIntegrationsSubmitting,
      }}
    >
      {children}
    </SeedIntegrationsAutoTreatContext.Provider>
  );
};

export {
  SeedIntegrationsAutoTreatContextProvider,
  SeedIntegrationsAutoTreatContextConsumer,
  SeedIntegrationsAutoTreatContext,
};
