import React, { useMemo, useRef } from 'react';
import { useDrag } from '@use-gesture/react';

import { Column } from '../ColumnPreview';
import { DragState } from '../ColumnDragState';
import { ColumnDragCard } from '../ColumnDragCard';
import { IconButton } from '../../IconButton';
import { Field } from '../../ImporterField';

export type FieldTouchedMap = { [name: string]: boolean | undefined };

import './ColumnDragTargetArea.scss';
import { useLocale } from '../../../locale/LocaleContext';

const TargetBox: React.FC<{
  field: Field;
  hasHeaders: boolean; // for correct display of dummy card
  flexBasis?: string; // style override
  touched?: boolean;
  assignedColumn: Column | null;
  dragState: DragState | null;
  eventBinder: (column: Column, startFieldName?: string) => ReturnType<typeof useDrag>;
  onHover: (fieldName: string, isOn: boolean) => void;
  onAssign: (fieldName: string) => void;
  onUnassign: (column: Column) => void;
}> = ({
  field,
  hasHeaders,
  flexBasis,
  touched,
  assignedColumn,
  dragState,
  eventBinder,
  onHover,
  onAssign,
  onUnassign,
}) => {
  // respond to hover events when there is active mouse drag happening
  // (not keyboard-emulated one)
  const containerRef = useRef<HTMLDivElement>(null);

  // if this field is the current highlighted drop target,
  // get the originating column data for display
  const sourceColumn = dragState && dragState.dropFieldName === field.name ? dragState.column : null;

  // see if currently assigned column is being dragged again
  const isReDragged = dragState ? dragState.column === assignedColumn : false;

  // drag start handlers for columns that can be re-dragged (i.e. are assigned)
  const dragStartHandlers = useMemo(
    () => (assignedColumn && !isReDragged ? eventBinder(assignedColumn, field.name) : {}),
    [eventBinder, assignedColumn, isReDragged, field.name],
  );

  const valueContents = useMemo(() => {
    if (sourceColumn) {
      return (
        <ColumnDragCard
          rowCount={3}
          containerStyle={{ background: '#fff', paddingTop: '2px' }}
          textStyle={{ fontStyle: 'initial', fontSize: '13px', lineHeight: '20px' }}
          column={sourceColumn}
          isDropIndicator
        />
      );
    }

    if (assignedColumn) {
      return (
        <ColumnDragCard
          style={{ background: 'lightgray', fontSize: '15px', paddingTop: '2px' }}
          containerStyle={{ boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)', border: '1px solid lightgray' }}
          textStyle={{ fontStyle: 'initial', color: 'black', fontSize: 13, lineHeight: '20px' }}
          rowCount={1}
          column={assignedColumn}
          isShadow={isReDragged}
          isDraggable={!isReDragged}
        />
      );
    }

    const hasError = touched && !field.isOptional;
    return (
      <ColumnDragCard
        style={{
          background: '#fff',
          border: '1px solid lightgray',
          borderRadius: '4px',
          fontSize: '13px',
          lineHeight: '20px',
          paddingTop: '2px',
        }}
        rowCount={3}
        hasHeaders={hasHeaders}
        hasError={hasError}
      />
    );
  }, [hasHeaders, field, touched, assignedColumn, sourceColumn, isReDragged]);

  const l10n = useLocale('fieldsStep');

  return (
    <section
      className="CSVImporter_ColumnDragTargetArea__box"
      aria-label={
        field.isOptional
          ? l10n.getDragTargetOptionalCaption(field.label)
          : l10n.getDragTargetRequiredCaption(field.label)
      }
      ref={containerRef}
      style={{ flexBasis, padding: '0.5em', borderBottom: '1px solid lightgray' }}
      onPointerEnter={() => onHover(field.name, true)}
      onPointerLeave={() => onHover(field.name, false)}
    >
      <div
        className="CSVImporter_ColumnDragTargetArea__boxLabel"
        aria-hidden
        style={{
          width: '50%',
          lineHeight: '30px',
          float: 'right',
          textAlign: 'left',
          paddingLeft: '30px',
          marginTop: '-6px',
        }}
      >
        {field.label}
        {field.isOptional ? null : <b>*</b>}
        <div
          style={{
            fontSize: '11px', // Makes the font size smaller for the description
            color: '#666', // Sets the color for the description
            marginTop: '-10px',
            marginBottom: '-10px',
            minHeight: '30px', // Adjust as needed
          }}
        >
          {field.description && field.description}
        </div>
      </div>

      <div className="CSVImporter_ColumnDragTargetArea__boxValue" style={{ width: '45%' }}>
        {!sourceColumn && !assignedColumn && (
          <div className="CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp" aria-hidden>
            {l10n.dragTargetPlaceholder}
          </div>
        )}

        <div {...dragStartHandlers} style={{ touchAction: 'none' }}>
          {valueContents}
        </div>

        {/* tab order after column contents */}
        {dragState && !dragState.pointerStartInfo ? (
          <div className="CSVImporter_ColumnDragTargetArea__boxValueAction">
            <IconButton
              label={l10n.getDragTargetAssignTooltip(dragState.column.code)}
              small
              type="forward"
              onClick={() => onAssign(field.name)}
            />
          </div>
        ) : (
          !sourceColumn &&
          assignedColumn && (
            <div className="CSVImporter_ColumnDragTargetArea__boxValueAction">
              <IconButton
                label={l10n.dragTargetClearTooltip}
                small
                type="close"
                onClick={() => onUnassign(assignedColumn)}
              />
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default TargetBox;
