import { format, parse } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';
import TransactionRowMenu from '../components/TransactionRowMenu';

export const transactionColumns: IDataTableColumn<any>[] = [
  {
    name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'work_order_start_date',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.work_order_start_date) {
        try {
          // Parse the date
          const date = parse(row.work_order_start_date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
          // Format the date
          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Machine',
    selector: 'machine_name_with_serial',
    sortable: true,
  },
  {
    name: 'Order Name',
    selector: 'work_order_name',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'customer_name',
    sortable: true,
  },
  {
    name: 'Farm',
    selector: 'work_order_farm_notes',
    sortable: true,
  },
  {
    name: 'Field',
    selector: 'work_order_field_notes',
    sortable: true,
  },
  // {
  //   name: 'Target Mass',
  //   selector: 'product_delivered_target_mass_total_with_uom',
  //   sortable: true,
  // },
  // {
  //   name: 'Actual Mass',
  //   selector: 'product_delivered_actual_mass_total_with_uom',
  //   sortable: true,
  // },
  // {
  //   name: 'Accuracy',
  //   selector: 'treatment_accuracy',
  //   sortable: true,
  // },
  {
    cell: (row) => <TransactionRowMenu transaction={row} />,
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export const nestedColumns: IDataTableColumn<any>[] = [
  {
    name: 'Product',
    selector: 'product_name',
    sortable: true,
  },
  {
    name: 'Type',
    selector: 'product_type',
    sortable: true,
  },
  {
    name: 'Source',
    selector: 'source_name',
    sortable: true,
  },
  {
    name: 'Target Amount',
    selector: 'product_delivered_target_amt_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_delivered_target_amt_with_uom) || 0;
      const valB = parseFloat(b.product_delivered_target_amt_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Actual Amount',
    selector: 'product_delivered_actual_amt_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_delivered_actual_amt_with_uom) || 0;
      const valB = parseFloat(b.product_delivered_actual_amt_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Accuracy',
    selector: 'product_delivered_accuracy',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_delivered_accuracy) || 0;
      const valB = parseFloat(b.product_delivered_accuracy) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Density',
    selector: 'product_density_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_density_with_uom) || 0;
      const valB = parseFloat(b.product_density_with_uom) || 0;
      return valA - valB;
    },
  },
];
