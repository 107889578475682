/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import TransportAutoBulkForm from './TransportsAutoBulkForm';

const TransportsAutoBulkDetail: React.FC = (props) => {
  const { setDetailPage, activeTransportAutoBulk } = React.useContext(TransportsAutoBulkContext);

  return (
    <>
      <TransportAutoBulkForm transport={activeTransportAutoBulk} onSuccess={() => setDetailPage(false)} />
    </>
  );
};

export default TransportsAutoBulkDetail;
