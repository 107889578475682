import { IDataTableColumn } from '../../dataTable/DataTable/model';
import UserRowMenu from '../components/UserRowMenu';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Full Name',
    selector: 'full_name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email_address',
    sortable: true,
  },
  {
    name: 'Contact Number',
    selector: 'contact_number',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.contact_number) || 0;
      const valB = parseFloat(b.contact_number) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Company',
    selector: 'company_name',
    sortable: false,
  },
  {
    name: 'Auth0 Verification',
    selector: 'auth0_verification',
    sortable: true,
    grow: 0.7,
    cell: ({ auth0_verification }): React.ReactNode => <div>{auth0_verification ? 'Verified' : 'Unverified'}</div>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: ({ status }): React.ReactNode => <div>{status ? 'Active' : 'Inactive'}</div>,
  },
  {
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
    cell: (row) => <a href={`/admin/user_management/${row.user_id}`}>Edit</a>,
  },
];

export default columns;
