import React from 'react';
import { Grid } from '@mui/material';
import MonthlyGraph from './MonthlyGraph';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';
import YearlyGraph from './YearlyGraph';

const SeedUnitsAverageCharts: React.FunctionComponent = () => {
  const {
    seedUnitsByMonthCharts,
    fetchSeedUnitsByMonthCharts,
    seedUnitsByMonthChartsLoading,
    seedUnitsByYearCharts,
    seedUnitsByYearChartsLoading,
    fetchSeedUnitsByYearCharts,
    showMonthly,
    setShowMonthly,
    selectedYear,
    setSelectedYear,
  } = React.useContext(AdminAnalyticsContext);

  React.useEffect(() => {
    fetchSeedUnitsByYearCharts();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <YearlyGraph
            title="Average SeedUnits Per Machine Per Year"
            labels={seedUnitsByYearCharts.years}
            values={seedUnitsByYearCharts.avg_seed_units_per_machine}
            loading={seedUnitsByYearChartsLoading}
            fetchFunction={(year) => {
              fetchSeedUnitsByMonthCharts(year);
              setShowMonthly(true);
            }}
            yearSetter={(year) => {
              setSelectedYear(year);
            }}
          />
        </Grid>
      </Grid>
      {showMonthly && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <br />
            <MonthlyGraph
              title="Average SeedUnits Per Machine Per Month"
              labels={seedUnitsByMonthCharts.month}
              values={seedUnitsByMonthCharts.avg_seed_units_per_machine}
              loading={seedUnitsByMonthChartsLoading}
              year={selectedYear}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SeedUnitsAverageCharts;
