import React from 'react';
import { Box, Button, Checkbox, Grid, Typography, FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useAuth0 } from '@auth0/auth0-react';
import { AutoTreatFiltersContext } from '../../../autotreatModules/transactions/contexts/AutoTreatFiltersContext';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import { SelectOption } from '../model';
import ConfirmCancelModal from '../modal/ConfirmCancelModal';
import AutocompleteFilter from './AutocompleteFilter';
import DateSelectFilter from './DateSelectFilter';
import FormInput from './FormInput';
import MachineAutocompleteFilter from './MachineAutocompleteFilter';
import SelectInput from './SelectInput';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

export interface FilterProps {
  label: string;
  options?: SelectOption[];
  optionSelector: string;
  pluralLabel: string;
  selector?: string;
  type: string;
  width: any;
}

interface AutoTreatTransactionFilterBarProps {
  applyFilter?: (filters: string, combineMachineTotals?: boolean) => void;
  combineMachineTotals?: boolean;
  displayUOM?: string;
  errorMessage?: string;
  filterChoices?: Record<string, string[]>;
  filters?: FilterProps[];
  filterType?: string;
  filterValues?: any;
  localStorageMachineKey?: string;
  setCombineMachineTotals?: any;
  setDisplayUOM?: any;
  setErrorMessage?: any;
  setFilterValues?: any;
  showCombineCheckbox?: boolean;
  exportTransactions;
  includeProducts;
  setIncludeProducts;
  transactionMetrics;
  treatmentProductMetrics;
}

const AutoTreatTransactionFilterBar: React.FC<AutoTreatTransactionFilterBarProps> = (props) => {
  const {
    applyFilter,
    combineMachineTotals,
    displayUOM,
    errorMessage,
    filterChoices,
    filters,
    filterType,
    filterValues,
    localStorageMachineKey = 'machines',
    setCombineMachineTotals,
    setDisplayUOM,
    setErrorMessage,
    setFilterValues,
    showCombineCheckbox,
    exportTransactions,
    includeProducts,
    setIncludeProducts,
    transactionMetrics,
    treatmentProductMetrics,
  } = props;

  const { user } = useAuth0();
  const { showErrorSnackBar, showSuccessSnackBar } = React.useContext(SnackBarContext);

  const { fetchFilterOptions, filterOptionsLoading, formatTransactionFilterValues } =
    React.useContext(AutoTreatFiltersContext);

  const { autoTreatMachines } = React.useContext(MachinesContext);

  const [startDate, setStartDate] = React.useState<string>(localStorage.getItem('startDate') || '');
  const [endDate, setEndDate] = React.useState<string>(localStorage.getItem('endDate') || '');
  const [exportEmail, setDestEmail] = React.useState<string>('');
  const [includeHeader, setIncludeHeader] = React.useState<boolean>(true);

  const [initialValues, setInitialValues] = React.useState<Record<string, any>>(filterValues);
  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any>>(filterValues);

  const [machines, setMachines] = React.useState<string[]>(
    JSON.parse(localStorage.getItem(localStorageMachineKey) || '[]'),
  );
  const { machinesLoading } = React.useContext(MachinesContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [yearlyOverview, setYearlyOverview] = React.useState(false);

  const activeLiquidProductType = () => {
    if (temporaryValues?.product_type_list?.length === 1 && temporaryValues.product_type_list[0] === 'Liquid') {
      return true;
    }
    return false;
  };

  const getFilterOptions = async () => {
    fetchFilterOptions();
  };

  React.useEffect(() => {
    setDestEmail(user?.email);
  }, [user]);

  const getTotalRecords = () => {
    if (treatmentProductMetrics && treatmentProductMetrics.total_records) {
      return treatmentProductMetrics.total_records;
    }
    if (transactionMetrics && transactionMetrics.total_records) {
      return transactionMetrics.total_records;
    }
    return '';
  };

  const handleApplyFilters = (combined = combineMachineTotals) => {
    const filterHeaders = formatTransactionFilterValues?.(startDate, endDate, machines, temporaryValues);
    setFilterValues(temporaryValues);

    applyFilter?.(filterHeaders, combined);
  };

  const handleExport = () => {
    const localTime = new Date().toString();
    const utcOffset = new Date().getTimezoneOffset();
    const filterParams = formatTransactionFilterValues?.(startDate, endDate, machines, temporaryValues);
    setFilterValues(filterParams);

    exportTransactions(
      exportEmail,
      includeHeader,
      includeProducts,
      localTime,
      utcOffset,
      filterParams,
      filterType,
      yearlyOverview,
      combineMachineTotals,
    )
      .then(() => {
        showSuccessSnackBar(
          `Your export is now being processed. Please check your email inbox to view your ${filterType} export.`,
        );
        setModalOpen(false);
      })
      .catch((errorMessage) => {
        setModalOpen(false);
        showErrorSnackBar(`There was an issue: ${errorMessage}`);
        setErrorMessage(errorMessage);
      });
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  React.useEffect(() => {
    localStorage.setItem(localStorageMachineKey, JSON.stringify(machines));
    getFilterOptions();
  }, [machines]);

  React.useEffect(() => {
    localStorage.setItem('startDate', startDate);
    getFilterOptions();
  }, [startDate]);

  React.useEffect(() => {
    localStorage.setItem('endDate', endDate);
    getFilterOptions();
  }, [endDate]);

  // React.useEffect(() => {
  //   if (!combineMachineTotals) {
  //     handleApplyFilters();
  //   }
  // }, [temporaryValues]);

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <ConfirmCancelModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleExport}
        title={`Export Filtered ${filterType}`}
        hasFooterBar
        confirmLabel={`Export ${getTotalRecords()} ${filterType}`}
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Customization Options
          </Typography>
          <p style={{ marginTop: 7 }}>Include filter values in header?</p>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="Include Header"
                value={includeHeader.toString()}
                onChange={(event) => setIncludeHeader(event.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
          {filterType === 'Transactions' && (
            <>
              <p style={{ marginTop: 7 }}>Include nested product data in export?</p>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="Include Products"
                    value={includeProducts}
                    onChange={(event) => setIncludeProducts(event.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes, include products in a separate sheet"
                    />
                    <FormControlLabel value="false" control={<Radio />} label="No, do not export nested products" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          )}
          {filterType !== 'Transactions' && yearlyOverview && (
            <>
              <Grid container justifyContent="space-between" style={{ marginLeft: 8 }}>
                <Grid item style={{ marginTop: 35 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={yearlyOverview}
                        onChange={() => setYearlyOverview(!yearlyOverview)}
                        style={{ padding: 3, transform: 'scale(1.2)' }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 16, marginLeft: 5 }}>Email Yearly Overview Instead?</Typography>
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          <p style={{ marginTop: 7 }}>Destination Email Addresses (comma seperated)</p>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormInput value={exportEmail} onChange={(value) => setDestEmail(value)} label="" />
          </Box>
        </div>
      </ConfirmCancelModal>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <div>
              <DateSelectFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
          </Grid>
          <Grid item>
            <MachineAutocompleteFilter
              label="Machines"
              loading={machinesLoading}
              pluralLabel="Machines"
              choices={autoTreatMachines}
              setValue={(value) => {
                setMachines([].concat(value));
              }}
              value={machines as string[]}
            />
          </Grid>
          {filterType === 'Transactions' && (
            <Grid item xs={12} md={2}>
              <FormInput
                value={temporaryValues?.pickupordername || (temporaryValues?.work_order_name as string)}
                onChange={(value) => setTemporaryValues({ ...temporaryValues, pickupordername: value })}
                label="Order Name"
              />
            </Grid>
          )}
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={filterOptionsLoading}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    const key = filter?.selector || filter?.optionSelector;
                    setTemporaryValues({ ...temporaryValues, [key]: value });
                  }}
                  value={temporaryValues[filter?.selector] || (temporaryValues[filter?.optionSelector] as string[])}
                />
              </Grid>
            </React.Fragment>
          ))}
          {displayUOM && (
            <Grid item>
              <SelectInput
                label="Display UOM"
                value={displayUOM}
                onChange={(value) => setDisplayUOM(value)}
                choices={
                  activeLiquidProductType()
                    ? [
                        { label: 'Pounds', value: 'lbs' },
                        { label: 'Tons', value: 'tons' },
                        { label: 'Gallons', value: 'gallons' },
                        { label: 'Fluid Ounces', value: 'fl oz' },
                      ]
                    : [
                        { label: 'Pounds', value: 'lbs' },
                        { label: 'Tons', value: 'tons' },
                      ]
                }
              />
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                handleApplyFilters();
              }}
            >
              Apply
            </Button>
            <span style={{ marginRight: 7 }} />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 19 }}
              disableElevation
              onClick={() => setModalOpen(true)}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-start">
          {filterType !== 'Transactions' && showCombineCheckbox && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={combineMachineTotals}
                    onChange={() => {
                      if (!combineMachineTotals) {
                        setTemporaryValues(initialValues);
                        handleApplyFilters();
                      }
                      setCombineMachineTotals(!combineMachineTotals);
                    }}
                    style={{ padding: 3, transform: 'scale(1.2)' }}
                  />
                }
                label={<Typography style={{ fontSize: 16, marginLeft: 5 }}>Combine Machine Totals</Typography>}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AutoTreatTransactionFilterBar;
