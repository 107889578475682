import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ToolsMajor,
  DataVisualizationMajor,
  InventoryMajor,
  CustomersMajor,
  DigitalMediaReceiverMajor,
} from '@shopify/polaris-icons';

export const autobulkMachineManagementRoutes = {
  label: 'Machine Management',
  url: '#',
  excludePaths: ['#'],
  icon: InventoryMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/autobulk/machines/customers',
      disabled: false,
      label: 'Customers',
    },
    {
      url: '/autobulk/machines/products',
      disabled: false,
      label: 'Products',
    },
    {
      url: '/autobulk/machines/work_orders',
      disabled: false,
      label: 'Work Orders',
    },
    {
      url: '/autobulk/machines/sources',
      disabled: false,
      label: 'Sources',
    },
    {
      url: '/autobulk/machines/transports',
      disabled: false,
      label: 'Transports',
    },
    {
      url: '/autobulk/machines/rfids',
      disabled: false,
      label: 'RFIDs',
    },
    {
      url: '/autobulk/machines/drivers',
      disabled: false,
      label: 'Drivers',
    },
    {
      url: '/autobulk/machines/transaction_api_log',
      disabled: false,
      label: 'Transaction API Log',
    },
  ],
};

export const autobulkAnalyticsRoutes = {
  label: 'Analytics',
  url: '/autobulk/analytics/transaction_summary',
  icon: DataVisualizationMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/autobulk/analytics/transaction_summary',
      disabled: false,
      label: 'Transaction Summary',
    },
    {
      url: '/autobulk/analytics/product_summary',
      disabled: false,
      label: 'Product Summary',
    },
    {
      url: '/autobulk/analytics/transactions/products/details',
      disabled: false,
      label: 'Product Details',
    },
    {
      url: '/autobulk/analytics/source_summary',
      disabled: false,
      label: 'Source Summary',
    },
  ],
};

export const autobulkRealTimeActivityRoutes = {
  label: 'Real Time Activity',
  url: '/autobulk/real_time_activity',
  icon: ToolsMajor,
};
