/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { TransactionApiLogsAutoTreatContext } from '../contexts/TransactionApiLogsAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TransactionApiLogAutoTreatFilterBar from '../components/TransactionApiLogsAutoTreatFilterBar';

const TransactionApiLogsAutoTreatList: React.FunctionComponent = () => {
  const { fetchTransactionApiLogsAutoTreat, transactionApiLogsAutoTreat, activeMachine } = React.useContext(
    TransactionApiLogsAutoTreatContext,
  );

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    orderName: '',
    orderPartnerID: '',
    orderVariety: '',
    orderRecipe: '',
    orderResponse: '',
  });

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoTreatMachines, autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchTransactionApiLogsAutoTreat]);

  const filterTransactionApiLogs = () => {
    if (
      tableFilters.orderName ||
      tableFilters.orderPartnerID ||
      tableFilters.orderVariety ||
      tableFilters.orderRecipe ||
      tableFilters.orderResponse
    ) {
      const nameFilteredTransactionApiLogs = transactionApiLogsAutoTreat.filter((order) =>
        order.pickup_order_name?.toLowerCase().includes(tableFilters.orderName?.toLowerCase()),
      );

      const partnerIDFilteredTransactionApiLogs = nameFilteredTransactionApiLogs.filter(
        (order) =>
          order.order_partner_record_id_1?.toLowerCase().includes(tableFilters.orderPartnerID?.toLowerCase()) ||
          order.order_partner_record_id_2?.toLowerCase().includes(tableFilters.orderPartnerID?.toLowerCase()),
      );

      const varietyFilteredTransactionApiLogs = partnerIDFilteredTransactionApiLogs.filter((order) =>
        order.variety?.toLowerCase().includes(tableFilters.orderVariety?.toLowerCase()),
      );

      const recipeFilteredTransactionApiLogs = varietyFilteredTransactionApiLogs.filter((order) =>
        order.liquid_recipe_name?.toLowerCase().includes(tableFilters.orderRecipe?.toLowerCase()),
      );

      const responseFilteredTransactionApiLogs = recipeFilteredTransactionApiLogs.filter((order) =>
        order.response?.toLowerCase().includes(tableFilters.orderResponse?.toLowerCase()),
      );

      return responseFilteredTransactionApiLogs;
    }

    return transactionApiLogsAutoTreat;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Transaction Api Logs (View Only)
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <TransactionApiLogAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterTransactionApiLogs()}
                // TODO Default sort is not working... pickup_transaction_id & pu_date_and_time are treated as strings
                defaultSortField="pickup_transaction_id_dont_use"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default TransactionApiLogsAutoTreatList;
