import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SeedSourcesAutoTreatFormValues } from '../model';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface SeedSourcesAutoTreatContextInterface {
  fetchCachedSeedSourcesAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchSeedSourcesAutoTreat?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;
  seedSourcesRefreshedAt?: string;
  seedSources?: any[];
  seedSourcesLoading?: boolean;

  fetchSeedVarietyOptionsAutoTreat?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;

  seedSourcesOptions?: any[];

  updateSeedSourceAutoTreat?: (formValues: SeedSourcesAutoTreatFormValues) => Promise<void>;

  seedSourceSubmitting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveSeedSourceAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeSeedSourceAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const SeedSourcesAutoTreatContext = React.createContext<SeedSourcesAutoTreatContextInterface>({});

const SeedSourcesAutoTreatContextConsumer = SeedSourcesAutoTreatContext.Consumer;
const SeedSourcesAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [seedSources, setSeedSourcesAutoTreat] = React.useState([]);
  const [seedSourcesOptions, setSeedSourcesOptions] = React.useState([]);
  const [seedSourcesLoading, setSeedSourcesAutoTreatLoading] = React.useState(true);
  const [seedSourcesRefreshedAt, setSeedSourcesAutoTreatRefreshedAt] = React.useState<string>();
  const [activeSeedSourceAutoTreat, setActiveSeedSourceAutoTreat] = React.useState({});
  const [seedSourceSubmitting, setSeedSourceAutoTreatSubmitting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('seedSourcesAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('seedSourcesAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setSeedSourcesAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedSeedSourcesAutoTreat = async (machineSerial: string) => {
    setSeedSourcesAutoTreatLoading(true);
    setSeedSourcesAutoTreatRefreshedAt(null);

    axios
      .get<string, any>(`api/autotreat/onprem/seed_sources/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setSeedSourcesAutoTreatLoading(false);
        setSeedSourcesAutoTreat(response.data.results);
        setSeedSourcesAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setSeedSourcesAutoTreatLoading(false);
        setSeedSourcesAutoTreat([]);
        setSeedSourcesAutoTreatRefreshedAt(null);
      });
  };

  const fetchSeedSourcesAutoTreat = async (machineSerial: string, onSuccess?: () => void) => {
    setSeedSourcesAutoTreatLoading(true);
    setSeedSourcesAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/seed_sources/?serial-number=${machineSerial}&generic-filter=${genericFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seed_sources/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              setSeedSourcesAutoTreatLoading(false);
              setSeedSourcesAutoTreat(response.data.results);
              setSeedSourcesAutoTreatRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load seed sources');
                setSeedSourcesAutoTreatLoading(false);
                setSeedSourcesAutoTreat([]);
                setSeedSourcesAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchSeedVarietyOptionsAutoTreat = async (machineSerial: string, onSuccess?: () => void) => {
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/seeds/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              response.data.results.forEach((seed) => {
                seed.label = seed.variety;
                seed.value = seed.seed_variety_id;
              });
              setSeedSourcesOptions(response.data.results);
              onSuccess();
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setSeedSourcesOptions([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateSeedSourceAutoTreat = async (formValues: SeedSourcesAutoTreatFormValues) => {
    setDetailPage(true);
    setSeedSourceAutoTreatSubmitting(true);
    setSeedSourcesAutoTreatLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/seed_sources/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seed_sources/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setSeedSourceAutoTreatSubmitting(false);
              setSeedSourcesAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchSeedSourcesAutoTreat(activeMachine?.value);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setSeedSourcesAutoTreatLoading(false);
                setSeedSourceAutoTreatSubmitting(false);
                // setSeedSourceAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <SeedSourcesAutoTreatContext.Provider
      value={{
        fetchCachedSeedSourcesAutoTreat,
        fetchSeedSourcesAutoTreat,
        seedSources,
        seedSourcesRefreshedAt,
        seedSourcesLoading,

        fetchSeedVarietyOptionsAutoTreat,

        seedSourcesOptions,

        updateSeedSourceAutoTreat,
        seedSourceSubmitting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveSeedSourceAutoTreat,
        activeSeedSourceAutoTreat,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
      }}
    >
      {children}
    </SeedSourcesAutoTreatContext.Provider>
  );
};

export { SeedSourcesAutoTreatContextProvider, SeedSourcesAutoTreatContextConsumer, SeedSourcesAutoTreatContext };
