/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { CustomersAutoBulkContext } from '../contexts/CustomersAutoBulkContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import CustomersAutoBulkDetail from '../components/CustomersAutoBulkDetail';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import IntegrationsList from '../components/IntegrationsList';
import { CustomerIntegrationsAutoBulkContext } from '../contexts/CustomerIntegrationsAutoBulkContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const CustomersAutoBulkDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveCustomerAutoBulk, activeCustomerAutoBulk, activeMachine } =
    React.useContext(CustomersAutoBulkContext);
  const { fetchCustomerIntegrationsAutoBulk } = React.useContext(CustomerIntegrationsAutoBulkContext);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/customer_auto_bulk/${activeCustomerAutoBulk.customer_id}`,
  );

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
        fetchCustomerIntegrationsAutoBulk(activeMachine?.value, activeCustomerAutoBulk.customer_id);
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded]);

  const handleBack = () => {
    setDetailPage(false);
    setActiveCustomerAutoBulk(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeCustomerAutoBulk.customer_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Customer List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <CustomersAutoBulkDetail onBack={handleBack} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <IntegrationsList activeMachine={activeMachine} customer={activeCustomerAutoBulk} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default CustomersAutoBulkDetailPage;
