import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { CompanyFormValues } from '../model';
import { CompaniesContext } from '../contexts/CompaniesContext';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface CompanyFormProps {
  company: any;
}

const CompanyForm: React.FC<CompanyFormProps> = (props) => {
  const { company } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { updateCompany, createCompany, companySubmitting } = React.useContext(CompaniesContext);
  const [formValues, setFormValues] = React.useState<CompanyFormValues>({});

  const handleUpdate = () => {
    updateCompany(company.company_id, formValues, () => showSuccessSnackBar('Saving...'));
  };

  const handleCreate = () => {
    createCompany(formValues);
  };

  React.useEffect(() => {
    setFormValues({
      name: company?.name,
      main_contact: company?.main_contact,
      main_contact_phone: company?.main_contact_phone,
      main_contact_email: company?.main_contact_email,
      website: company?.website,
      external_account_number: company?.external_account_number,
      city: company?.city,
      state: company?.state,
      country: company?.country,
      zip: company?.zip,
      status: company?.status ? '1' : '0',
      managed: company?.managed ? 'True' : 'False',
      level_sensors_module_enabled: company?.level_sensors_module_enabled ? 'True' : 'False',
    });
  }, [company]);

  return (
    <Grid container>
      <Grid item xs={10}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.name}
                    onChange={(value) => setFormValues({ ...formValues, name: value })}
                    label="Company Name"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.main_contact}
                    onChange={(value) => setFormValues({ ...formValues, main_contact: value })}
                    label="Main Contact"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.main_contact_phone}
                    onChange={(value) => setFormValues({ ...formValues, main_contact_phone: value })}
                    label="Main Contact Phone"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.main_contact_email}
                    onChange={(value) => setFormValues({ ...formValues, main_contact_email: value })}
                    label="Main Contact Email"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.website}
                    onChange={(value) => setFormValues({ ...formValues, website: value })}
                    label="Website / Email Domain"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                {currentUserHasAdminPermission('ksi_superadmin') && (
                  <Grid item xs={12} md={12}>
                    <SelectInput
                      choices={[
                        { label: 'Yes', value: 'True' },
                        { label: 'No', value: 'False' },
                      ]}
                      value={formValues?.managed?.toString()}
                      onChange={(value) => setFormValues({ ...formValues, managed: value.toString() })}
                      label="Managed Company"
                      disabled={!currentUserHasAdminPermission('ksi_superadmin')}
                    />
                  </Grid>
                )}
                {currentUserHasAdminPermission('ksi_support') && (
                  <Grid item xs={12} md={12}>
                    <SelectInput
                      value={formValues?.status?.toString()}
                      choices={[
                        { label: 'Active', value: '1' },
                        { label: 'Inactive', value: '0' },
                      ]}
                      onChange={(value) => setFormValues({ ...formValues, status: value.toString() })}
                      label="Status"
                      disabled={!currentUserHasAdminPermission('ksi_superadmin')}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.city}
                    onChange={(value) => setFormValues({ ...formValues, city: value })}
                    label="City"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.state}
                    onChange={(value) => setFormValues({ ...formValues, state: value })}
                    label="State"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.zip}
                    onChange={(value) => setFormValues({ ...formValues, zip: value })}
                    label="Zip"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.country}
                    onChange={(value) => setFormValues({ ...formValues, country: value })}
                    label="Country"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.external_account_number}
                    onChange={(value) => setFormValues({ ...formValues, external_account_number: value })}
                    label="CRM Account Number"
                    disabled={!currentUserHasAdminPermission('ksi_admin')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectInput
                    value={formValues?.level_sensors_module_enabled}
                    onChange={(value) => setFormValues({ ...formValues, level_sensors_module_enabled: value })}
                    label="Level Sensors Module Enabled"
                    choices={[
                      { label: 'Yes', value: 'True' },
                      { label: 'No', value: 'False' },
                    ]}
                    disabled={!currentUserHasAdminPermission('ksi_superadmin')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          {currentUserHasAdminPermission('ksi_admin') && (
            <>
              {company?.company_id ? (
                <Button variant="contained" color="primary" onClick={handleUpdate}>
                  {companySubmitting ? 'Updating...' : 'Update Company'}
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleCreate}>
                  {companySubmitting ? 'Creating...' : 'Create Company'}
                </Button>
              )}
            </>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default CompanyForm;
