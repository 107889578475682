const transactionFilters = [
  {
    label: 'Customer',
    pluralLabel: 'Customers',
    selector: 'customers',
    type: 'select',
    optionSelector: 'customer_names_list',
  },
  {
    label: 'Farm',
    pluralLabel: 'Farms',
    selector: 'farm',
    type: 'select',
    optionSelector: 'farm_notes_list',
  },
  {
    label: 'Field',
    pluralLabel: 'Fields',
    selector: 'field',
    type: 'select',
    optionSelector: 'field_notes_list',
  },
];

export default transactionFilters;
