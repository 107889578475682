import React, { useState, FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';

interface NotificationContextInterface {
  notificationCategoriesList?: any[];
  notificationsList?: any[];
  notificationsLoading?: boolean;
  notificationOptionsLoading?: boolean;
  notificationCategoriesLoading?: boolean;
  notification?: any;
  companyMachinesList?: any[];
  machineProductsList?: any[];
  fetchNotifications?: () => Promise<void>;
  fetchNotification?: (notificationId: string) => Promise<void>;
  createNotification?: (formValues: Record<string, any>) => Promise<void>;
  updateNotification?: (notificationId: string, formValues: Record<string, any>) => Promise<void>;
  deleteNotification?: (notificationId: string) => Promise<void>;
  fetchNotificationCategories?: () => Promise<void>;
  fetchCompanyMachines?: () => Promise<void>;
  fetchAutoTreatMachineProducts?: (serial_number: string) => Promise<void>;
  fetchAutoBulkMachineProducts?: (serial_number: string) => Promise<void>;
}

const NotificationContext = React.createContext<NotificationContextInterface>({});

interface NotificationContextProviderProps {
  children?: ReactNode;
}

const NotificationContextProvider: FC<NotificationContextProviderProps> = ({ children }) => {
  const history = useHistory();
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const [notificationCategoriesList, setNotificationCategoriesList] = useState([]);
  const [companyMachinesList, setCompanyMachinesList] = useState([]);
  const [machineProductsList, setMachineProductsList] = useState([]);
  const [notificationOptionsLoading, setNotificationOptionsLoading] = useState(false);
  const [notificationCategoriesLoading, setNotificationCategoriesLoading] = useState(false);

  const fetchNotifications = async () => {
    setNotificationsLoading(true);
    try {
      const response = await axios.get('api/user/notifications', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setNotificationsList(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setNotificationsLoading(false);
    }
  };

  const fetchNotification = async (notificationId: string) => {
    setNotificationsLoading(true);
    try {
      const response = await axios.get(`api/user/notifications/${notificationId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setNotification(response.data);
    } catch (error) {
      console.error('Error fetching notification:', error);
    } finally {
      setNotificationsLoading(false);
    }
  };

  const createNotification = async (formValues: Record<string, any>) => {
    try {
      const response = await axios.post('api/user/notifications/', formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      history.push('/account_management/notifications');
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const updateNotification = async (notificationId: string, formValues: Record<string, any>) => {
    try {
      const response = await axios.put(`api/user/notifications/${notificationId}/`, formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      history.push(`/account_management/notifications/${notificationId}`);
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  const deleteNotification = async (notificationId: string) => {
    try {
      await axios.delete(`api/user/notifications/${notificationId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setNotificationsList((currentList) => currentList.filter((n) => n.notification_id !== notificationId));
      history.push('/account_management/notifications');
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const fetchNotificationCategories = async () => {
    setNotificationCategoriesLoading(true);
    try {
      const response = await axios.get('api/user/notification_categories/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setNotificationCategoriesList(response.data);
    } catch (error) {
      console.error('Error fetching notification categories:', error);
    } finally {
      setNotificationCategoriesLoading(false);
    }
  };

  const fetchCompanyMachines = async () => {
    setNotificationOptionsLoading(true);
    try {
      const response = await axios.get('api/user/machines/?association=company', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setCompanyMachinesList(response.data);
    } catch (error) {
      console.error('Error fetching company machines:', error);
      setNotificationOptionsLoading(false);
    } finally {
      setNotificationOptionsLoading(false);
    }
  };

  const fetchAutoTreatMachineProducts = async (machines: any) => {
    axios
      .get(`api/autotreat/analytics/filters/?machine_filter=${machines}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response: any) => {
        setMachineProductsList(response.data.treatment_products_list);
      })
      .catch(() => {});
  };

  const fetchAutoBulkMachineProducts = async (machines: any) => {
    axios
      .get(`api/autobulk/analytics/filters/?machine_filter=${machines}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response: any) => {
        setMachineProductsList(response.data.product_name_list);
      })
      .catch(() => {});
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationCategoriesList,
        notificationsList,
        notificationsLoading,
        notificationOptionsLoading,
        notificationCategoriesLoading,
        notification,
        companyMachinesList,
        machineProductsList,
        fetchNotifications,
        fetchNotification,
        createNotification,
        updateNotification,
        deleteNotification,
        fetchNotificationCategories,
        fetchCompanyMachines,
        fetchAutoTreatMachineProducts,
        fetchAutoBulkMachineProducts,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContextProvider, NotificationContext };
