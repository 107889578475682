import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import UserForm from './UserForm';
import { UsersContext } from '../contexts/UsersContext';
import DeleteModal from '../../shared/modal/DeleteModal';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface UserDetailsProps {
  user: any;
}

const UserDetails: React.FC<UserDetailsProps> = (props) => {
  const { user } = props;
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);
  const { verifyUser, archiveUser, userSubmitting, userArchiving } = React.useContext(UsersContext);
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleResendInvitation = () => {
    verifyUser(user.user_id, { email_address: user.email_address }, () =>
      showSuccessSnackBar('Invitation email successfully sent.'),
    );
  };

  const handleDelete = () => {
    archiveUser(user.user_id);
    showSuccessSnackBar('Archiving...');
    setModalOpen(false);
  };

  return (
    <Box>
      <DeleteModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleDelete}
        confirmLabel="Archive"
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Are you sure you want to archive this user?
          </Typography>
        </div>
      </DeleteModal>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" component="h2">
                    User Information
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleResendInvitation}>
                    {userSubmitting ? 'Sending...' : 'Send Invitation Email'}
                  </Button>
                </Grid>
              </Grid>
              <br />
              <UserForm user={user} creatingUser={false} />
            </CardContent>
          </Card>
          {currentUserHasAdminPermission('ksi_admin') && (
            <>
              <br />
              <Button
                variant="contained"
                color="error"
                onClick={() => setModalOpen(true)}
                style={{ background: 'rgb(244, 79, 100)' }}
              >
                {userArchiving ? 'Archiving...' : 'Archive User'}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetails;
