/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CustomersAutoBulkContext } from '../contexts/CustomersAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import CustomersAutoBulkDetail from './CustomersAutoBulkDetailPage';
import CustomersAutoBulkCreate from './CustomersAutoBulkCreate';
import CustomersAutoBulkList from './CustomersAutoBulkList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const CustomersAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchCustomersAutoBulk, detailPage, creatingPage, customersLoading, activeMachine } =
    React.useContext(CustomersAutoBulkContext);

  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchCustomersAutoBulk]);

  const autoBulkCustomerPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <CustomersAutoBulkDetail />,
          createPage: <CustomersAutoBulkCreate />,
          listPage: <CustomersAutoBulkList />,
        }[autoBulkCustomerPage()]
      }
    </>
  );
};

export default CustomersAutoBulkPage;
