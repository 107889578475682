const exampleCSV = [
  {
    product_name: 'name',
    product_description: 'description',
    product_shipment: 'shipment',
    product_type: 'Liquid',
    product_inventory_unit: 'gallon',
    product_inventory: '0',
    product_density: '8.345',
    product_density_type: 'lb/gal',
    product_epa: 'epa',
    product_msds: 'msds',
    product_comment: 'comment',
    product_priority: '999',
    isolated: 'false',
    isolated_load_last: 'false',
    active: 'true',
  },
  {
    product_name: '* Required',
    product_type: 'Liquid or Dry, default: Liquid',
    product_inventory_unit: 'must be gallon or lb, default: gallon',
    product_density_type: 'must be lb/gal, default: lb/gal',
    product_priority: 'default: 999',
    isolated: 'true/false, default: false',
    isolated_load_last: 'true/false, default: false',
    active: 'true/false, default: true',
  },
];

export default exampleCSV;
