/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TreatmentProductAutoTreatForm from '../components/TreatmentProductsAutoTreatForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const TreatmentProductsAutoTreatCreate: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);

  const { fetchTreatmentProductsAutoTreat, setCreatingPage, activeMachine } = React.useContext(
    TreatmentProductsAutoTreatContext,
  );

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `Create Treatment Product`,
    },
  ];

  const handleBack = () => {
    setCreatingPage(false);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Treatment Product List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3} />
      <br />
      <Typography variant="h1" component="h1">
        Create Treatment Product
      </Typography>
      <br />
      <TreatmentProductAutoTreatForm
        treatmentProduct={{}}
        machineSerial={activeMachine?.value}
        onSuccess={() => setCreatingPage(false)}
        onBack={handleBack}
      />
    </>
  );
};

export default TreatmentProductsAutoTreatCreate;
