/* eslint-disable import/prefer-default-export */
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Transaction ID',
    selector: 'pickup_transaction_id',
    sortable: true,
    grow: 0.5,
  },
  {
    name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'pu_date_and_time',
    sortable: true,
    grow: 0.7,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.pu_date_and_time) {
        try {
          // Parse the date
          const date = parse(row.pu_date_and_time, 'M/d/yyyy h:mm:ss aa', new Date());
          // Convert the date to the user's timezone
          const userTimezoneDate = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
          // Format the date
          formattedDate = userTimezoneDate.toLocaleString();
        } catch (error) {
          formattedDate = row.pu_date_and_time;
          console.error(`Error parsing date: ${error}`);
        }
      } else {
        formattedDate = '';
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Order Name',
    selector: 'pickup_order_name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Variety',
    selector: 'variety',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Recipe',
    selector: 'liquid_recipe_name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Api Partner',
    selector: 'api_partner',
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'Partner ID 1',
    selector: 'order_partner_record_id_1',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Partner ID 2',
    selector: 'order_partner_record_id_2',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Response',
    selector: 'response',
    sortable: true,
    cell: (row) => row.response.split('addLineBreak').map((line, index) => <div key={index}>{line}</div>),
    grow: 2.0,
  },
];
