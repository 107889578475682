import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { MachinesContext } from '../contexts/MachinesContext';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { Machine, MachineFormValues } from '../model';

interface MachineFormProps {
  machine: Machine;
}

const MachineForm: React.FC<MachineFormProps> = (props) => {
  const { machine } = props;

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { companyOptions } = React.useContext(CompaniesContext);
  const {
    updateMachine,
    createMachine,
    enableCortevaRecipes,
    disableCortevaRecipes,
    machineSubmitting,
    obsoleteMachine,
  } = React.useContext(MachinesContext);

  const [formValues, setFormValues] = React.useState<MachineFormValues>({});

  const handleFormValueChange = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleUpdate = () => {
    updateMachine(machine.machine_id, formValues);
  };

  const handleCreate = () => {
    createMachine(formValues);
  };

  const handleEnableCortevaRecipes = () => {
    enableCortevaRecipes(machine?.serial_number);
  };

  const handleDisableCortevaRecipes = () => {
    disableCortevaRecipes(machine?.serial_number);
  };

  const handleObsolete = () => {
    obsoleteMachine(machine.machine_id, () => {
      window.location.reload();
    });
  };

  React.useEffect(() => {
    setFormValues({
      name: machine?.machine_name,
      active: machine?.active,
      machine_id: machine?.machine_id,
      serial_number: machine?.serial_number,
      autobulk: capitalize(machine?.autobulk?.toString()),
      subscription_active: capitalize(machine?.subscription_active?.toString()),
      status: capitalize(machine?.status?.toString()),
      description: machine?.description,
      contact_name: machine?.contact_name,
      contact_number: machine?.contact_number,
      disable_analytics_upload: capitalize(machine?.disable_analytics_upload?.toString()),
      address: machine?.address,
      city: machine?.city,
      state: machine?.state,
      zip_code: machine?.zip_code,
      country: machine?.country,
      company_id: machine?.company_id,
      subscription_expiration_date: machine?.subscription_expiration_date_form_value,
    });
  }, [machine]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3} rowSpacing={2} columnSpacing={12}>
            <Grid item xs={12} md={5}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.name}
                    onChange={(value) => handleFormValueChange('name', value)}
                    label="Machine Name"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.description}
                    onChange={(value) => handleFormValueChange('description', value)}
                    label="Machine Description"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.serial_number}
                    disabled={machine.machine_id !== undefined}
                    onChange={(value) => handleFormValueChange('serial_number', value)}
                    label="Serial Number"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectInput
                    value={formValues?.company_id}
                    choices={companyOptions}
                    onChange={(value) => handleFormValueChange('company_id', value)}
                    label="Company"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.contact_number}
                    onChange={(value) => handleFormValueChange('contact_number', value)}
                    label="Contact Number"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.contact_name}
                    onChange={(value) => handleFormValueChange('contact_name', value)}
                    label="Contact Name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.address}
                    onChange={(value) => handleFormValueChange('address', value)}
                    label="Street Address"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.city}
                    onChange={(value) => handleFormValueChange('city', value)}
                    label="City"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.state}
                    onChange={(value) => handleFormValueChange('state', value)}
                    label="State"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.zip_code}
                    onChange={(value) => handleFormValueChange('zip_code', value)}
                    label="Zip Code"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    value={formValues?.country}
                    onChange={(value) => handleFormValueChange('country', value)}
                    label="Country"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">
                Advanced Machine Information
              </Typography>
            </Grid>
            <Grid item xs={6} md={5}>
              <SelectInput
                value={formValues?.autobulk?.toString() || 'False'}
                choices={[
                  { label: 'AutoBulk', value: 'True' },
                  { label: 'AutoTreat', value: 'False' },
                ]}
                onChange={(value) => handleFormValueChange('autobulk', value)}
                label="Machine Type"
              />
            </Grid>
            <Grid item xs={6} md={5}>
              <SelectInput
                value={formValues?.subscription_active?.toString() || 'True'}
                choices={[
                  { label: 'Active', value: 'True' },
                  { label: 'Inactive', value: 'False' },
                ]}
                onChange={(value) => handleFormValueChange('subscription_active', value)}
                label="Subscription Active"
              />
            </Grid>
            <Grid item xs={6} md={5}>
              <SelectInput
                value={formValues?.disable_analytics_upload || 'True'}
                choices={[
                  { label: 'Analytics Disabled', value: 'True' },
                  { label: 'Analytics Enabled', value: 'False' },
                ]}
                onChange={(value) => handleFormValueChange('disable_analytics_upload', value)}
                label="Disable Analytics Upload"
              />
            </Grid>
            <Grid item xs={6} md={5}>
              <FormInput
                value={formValues?.subscription_expiration_date}
                type="datetime-local"
                onChange={(value) => handleFormValueChange('subscription_expiration_date', value)}
                label="Subscription Expiration Date"
              />
            </Grid>
            <Grid item xs={6} md={5}>
              <SelectInput
                value={formValues?.status}
                choices={[
                  { label: 'Active', value: 'True' },
                  { label: 'Inactive', value: 'False' },
                ]}
                disabled
                onChange={(value) => handleFormValueChange('status', value)}
                label="Status"
              />
            </Grid>
          </Grid>
          {currentUserHasAdminPermission('ksi_admin') && (
            <>
              <br />
              {machine.machine_id ? (
                <Button variant="contained" color="primary" onClick={handleUpdate}>
                  {machineSubmitting ? 'Updating...' : 'Update Machine'}
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleCreate}>
                  {machineSubmitting ? 'Creating...' : 'Create Machine'}
                </Button>
              )}
              {!machine?.autobulk && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEnableCortevaRecipes}
                    style={{ marginLeft: '15px' }}
                  >
                    Enable Corteva Recipes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDisableCortevaRecipes}
                    style={{ marginLeft: '15px' }}
                  >
                    Disable Corteva Recipes
                  </Button>
                  <Button variant="contained" color="error" onClick={handleObsolete} style={{ marginLeft: '15px' }}>
                    Mark As Obsolete
                  </Button>
                </>
              )}
            </>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default MachineForm;
