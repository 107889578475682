/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SeedsAutoTreatImportContext } from '../contexts/SeedsAutoTreatImportContext';
import 'react-csv-importer/dist/index.css';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import SeedAutoTreatcsvImport from '../components/SeedsAutoTreatcsvImport';

const SeedsAutoTreatcsvImportPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineSerial } = useParams<Record<string, string>>();

  const { setFailedRecords, setPassedRecords } = React.useContext(SeedsAutoTreatImportContext);

  React.useEffect(() => {
    setFailedRecords([]);
    setPassedRecords([]);
  }, []);

  const links = [
    {
      onClick: () => history.push(`/autotreat/machines/seeds`),
      text: 'AutoTreat Seeds',
    },
    {
      onClick: () => null,
      text: `Bulk Import (Machine: ${machineSerial})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <SeedAutoTreatcsvImport machineSerialNumber={machineSerial} />
    </>
  );
};

export default SeedsAutoTreatcsvImportPage;
