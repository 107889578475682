/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { CustomersAutoBulkContext } from '../contexts/CustomersAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import CustomerAutoBulkForm from '../components/CustomerAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const CustomersAutoBulkCreate: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchCustomersAutoBulk, activeMachine, setCreatingPage } = React.useContext(CustomersAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchCustomersAutoBulk]);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `Create Customer`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Customer
      </Typography>
      <br />
      <CustomerAutoBulkForm
        customer={{}}
        machineSerial={activeMachine?.value}
        onSuccess={() => setCreatingPage(false)}
      />
    </>
  );
};

export default CustomersAutoBulkCreate;
