import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Pagination, Typography } from '@mui/material';
import { Banner } from '@shopify/polaris';
import { Machine } from '../../machinesAdminManagement/model';

interface MachineStatusBannerProps {
  activeMachine: Machine;
}

const useStyles = makeStyles(() => ({}));

const MachineStatusBanner: React.FC<MachineStatusBannerProps> = (props) => {
  const { activeMachine } = props;

  return (
    <>
      {activeMachine?.subscription_active === false && (
        <>
          <br />
          <Banner title="This machine does not have an active subscription." status="critical">
            <Grid container justifyContent="space-between">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  Please contact KSi support to renew your subscription and view or edit information on Agconnex.
                </Typography>
              </Grid>
            </Grid>
          </Banner>
          <br />
        </>
      )}
      {activeMachine?.subscription_active && activeMachine?.connection_state === 'offline' && (
        <>
          <br />
          <Banner title="This machine is currently offline and not connected to the internet." status="warning">
            <Grid container justifyContent="space-between">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  You will not be able to edit information on Agconnex until the machine comes back online.
                </Typography>
              </Grid>
            </Grid>
          </Banner>
          <br />
        </>
      )}
    </>
  );
};

export default MachineStatusBanner;
