import React from 'react';
import { Grid } from '@mui/material';
import MapChart from './MapChart';
import YearlyGraph from './YearlyGraph';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';

const WrappedMapComponent: React.FunctionComponent = () => {
  const { selectedState, seedUnitsPerYearByState, seedUnitsPerYearByStateLoading, selectedYear, mapDataType } =
    React.useContext(AdminAnalyticsContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MapChart />
      </Grid>
      {selectedState && mapDataType === 'Total Seed Units' ? (
        <Grid item xs={12} md={6} style={{ marginTop: -135 }}>
          <YearlyGraph
            title={`Total Orders Per Year in ${selectedState}`}
            labels={seedUnitsPerYearByState.year}
            values={seedUnitsPerYearByState.total_seed_units}
            loading={seedUnitsPerYearByStateLoading}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default WrappedMapComponent;
