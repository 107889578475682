import React from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../../machinesAdminManagement/constants/columns';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import { Machine } from '../../machinesAdminManagement/model';
import CloudSqlForm from '../components/CloudSqlForm';

const AdminCloudExecuteSql: React.FC = () => {
  const history = useHistory();
  const { fetchAdminMachines, adminMachines, machinesLoading, fetchFilterOptions } = React.useContext(MachinesContext);

  const [filterValues, setFilterValues] = React.useState({ subscription_active: 'status__all' });
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>();
  const [machine, setMachine] = React.useState<any>();
  const [perPage, setPerPage] = React.useState<number>(10);
  const [activePage, setActivePage] = React.useState(1);

  React.useEffect(() => {
    fetchAdminMachines('');
    fetchFilterOptions();
  }, []);

  const convertFilters = () => {
    return Object.entries(filterValues)
      .map(([key, value]) => (value !== 'status__all' ? `${key}=${value}&` : ''))
      .join('');
  };

  const handleRowClick = (machine) => {
    setMachine(machine);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Execute Cloud DB SQL
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid item>
        <CloudSqlForm machine={{ serial_number: '1' }} showLoadingIndicator={false} />
      </Grid>
    </>
  );
};

export default AdminCloudExecuteSql;
