import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Company, CompanyFormValues } from '../model';

interface UserCompaniesContextInterface {
  fetchUserCompany?: (companyId?: string) => Promise<void>;
  userCompany?: Company;
  companyLoading?: boolean;
}

const UserCompaniesContext = React.createContext<UserCompaniesContextInterface>({});

const UserCompaniesContextConsumer = UserCompaniesContext.Consumer;
const UserCompaniesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [userCompany, setUserCompany] = React.useState({});
  const [companyLoading, setCompanyLoading] = React.useState(true);

  const fetchUserCompany = async (companyId?: string) => {
    setCompanyLoading(true);

    axios
      .get<string, any>(`api/companies/0/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCompanyLoading(false);
        setUserCompany(response.data.result);
      });
  };

  return (
    <UserCompaniesContext.Provider
      value={{
        fetchUserCompany,
        userCompany,
        companyLoading,
      }}
    >
      {children}
    </UserCompaniesContext.Provider>
  );
};

export { UserCompaniesContextProvider, UserCompaniesContextConsumer, UserCompaniesContext };
