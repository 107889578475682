/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CustomersAutoTreatContext } from '../contexts/CustomersAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import CustomersAutoTreatDetailPage from './CustomersAutoTreatDetailPage';
import CustomersAutoTreatCreate from './CustomersAutoTreatCreate';
import CustomersAutoTreatList from './CustomersAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';
import { CustomerIntegrationsAutoTreatContext } from '../contexts/CustomerIntegrationsAutoTreatContext';

const CustomersAutoTreatPage: React.FunctionComponent = () => {
  const { detailPage, creatingPage, activeMachine } = React.useContext(CustomersAutoTreatContext);
  const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    fetchAutoTreatMachines();
  }, []);

  const autoTreatCustomerPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <CustomersAutoTreatDetailPage />,
          createPage: <CustomersAutoTreatCreate />,
          listPage: <CustomersAutoTreatList />,
        }[autoTreatCustomerPage()]
      }
    </>
  );
};

export default CustomersAutoTreatPage;
