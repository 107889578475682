import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import FormInput from '../../shared/form/FormInput';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import UserMachineAccessForm from '../../shared/form/UserMachineAccessForm';

interface AssignUserAccessProps {
  machine: any;
  users: any;
  setUsers: any;
}

const AssignUserAccess: React.FC<AssignUserAccessProps> = (props) => {
  const { machine, users, setUsers } = props;

  const history = useHistory();

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { userMachineAccessOptions, createBulkUserMachineAccess, userMachineAccessSubmitting } =
    React.useContext(UserMachineAccessContext);

  const [externalEmail, setExternalEmail] = React.useState('');
  const [showAccessFormCompanyUsers, setShowAccessFormCompanyUsers] = React.useState(true);
  const [filteredMachineAccessOptions, setFilteredMachineAccessOptions] = React.useState<Record<string, string>[]>([]);

  React.useEffect(() => {
    setFilteredMachineAccessOptions(
      userMachineAccessOptions?.user_options?.filter((option) => option.company_id === machine?.company_id),
    );
  }, [userMachineAccessOptions, setFilteredMachineAccessOptions, machine]);

  const handleUpdate = () => {
    createBulkUserMachineAccess({ machine_id: machine.machine_id, user_ids: users });
  };

  const toggleCompanyUsers = () => {
    if (showAccessFormCompanyUsers) {
      setShowAccessFormCompanyUsers(false);
      setFilteredMachineAccessOptions(userMachineAccessOptions?.user_options);
    } else {
      setShowAccessFormCompanyUsers(true);
      setFilteredMachineAccessOptions(
        filteredMachineAccessOptions?.filter((option) => option.company_id === machine?.company_id),
      );
    }
  };

  const assignExternalUser = () => {
    // Example validation, adapt as necessary
    if (!externalEmail || !/\S+@\S+\.\S+/.test(externalEmail)) {
      // alert('Please enter a valid email address.');
      return;
    }
    // Create a new option for the external user
    const newOption = { company_id: `0`, label: externalEmail, value: `0` };
    // Update the filteredMachineAccessOptions with the new external user
    setFilteredMachineAccessOptions((prevOptions) => [...prevOptions, newOption]);
    // Reset the external email input
    setExternalEmail('');
  };

  return !machine?.serial_number ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Assign User Access
              </Typography>
              <Typography>
                {showAccessFormCompanyUsers ? `(Filtered by Company: ${machine?.company_name})` : ``}
              </Typography>
            </Grid>
            <Grid item>
              {currentUserHasAdminPermission('ksi_admin') && (
                <Button variant="contained" color="primary" onClick={toggleCompanyUsers} style={{ float: 'right' }}>
                  {showAccessFormCompanyUsers
                    ? 'Show All Users'
                    : `Show Company Users (Company Name: ${machine?.company_name})`}
                </Button>
              )}
            </Grid>
          </Grid>
          <UserMachineAccessForm
            label="Users"
            loading={false}
            pluralLabel="Users"
            allChoices={userMachineAccessOptions?.user_options || []}
            choices={filteredMachineAccessOptions || []}
            setValue={(value) => {
              setUsers([].concat(value));
            }}
            value={users as string[]}
            initialValue={users || machine?.user_access?.map((user) => user?.user_id)}
          />
          {currentUserHasAdminPermission('ksi_admin') && (
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              {userMachineAccessSubmitting ? 'Saving...' : 'Save User Access'}
            </Button>
          )}
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      {currentUserHasAdminPermission('ksi_admin') && (
        <>
          <Grid container>
            <Typography variant="h2" component="h2">
              Assign External User
            </Typography>
            <br />
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormInput
                value={externalEmail}
                onChange={(value) => setExternalEmail(value)}
                label="External User Email"
              />
              <br />
              <Button variant="contained" color="primary" onClick={assignExternalUser}>
                {/* <Button variant="contained" color="primary" onClick={() => null}> */}
                Assign User
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default AssignUserAccess;
