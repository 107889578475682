import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { UserFormValues } from '../model';

interface UserMachineAccessContextInterface {
  fetchUserMachineAccess?: (userMachineAccessId: string) => Promise<void>;
  fetchUserMachineAccessOptions?: (companyId: string) => Promise<void>;
  fetchMachineAccessOptions?: () => Promise<void>;
  fetchAllMachineAccessOptions?: () => Promise<void>;
  fetchUserMachineByCompany?: () => Promise<void>;

  createUserMachineAccess?: (formValues: UserFormValues) => Promise<void>;
  createBulkUserMachineAccess?: (formValues: Record<string, unknown>) => Promise<void>;
  createUserBulkUserMachineAccess?: (formValues: Record<string, unknown>) => Promise<void>;
  userMachineAccessOptions?: any;
  userMachineAccess?: Record<string, string>;
  userMachineByCompany?: Record<string, string>;
  userMachinesByCompanyLoading?: boolean;
  userMachineAccessSubmitting?: boolean;
}

const UserMachineAccessContext = React.createContext<UserMachineAccessContextInterface>({});

const UserMachineAccessContextConsumer = UserMachineAccessContext.Consumer;
const UserMachineAccessContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [userMachineAccess, setUserMachineAccess] = React.useState({});
  const [userMachineAccessOptions, setUserMachineAccessOptions] = React.useState({});
  const [userMachineByCompany, setUserMachinesByCompany] = React.useState({});
  const [userMachinesByCompanyLoading, setUserMachineAccessLoading] = React.useState(true);
  const [userMachineAccessSubmitting, setUserMachineAccessSubmitting] = React.useState(true);

  const fetchUserMachineAccess = async (userMachineAccessId: string) => {
    setUserMachineAccessLoading(true);

    axios
      .get<any, any>(`api/admin/user_machine_access/${userMachineAccessId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachineAccess(response.data.result);
        setUserMachineAccessSubmitting(false);
      });
  };

  // Needs to be removed this was moved into the Users context
  const fetchMachineAccessOptions = async () => {
    setUserMachineAccessLoading(true);

    axios
      .get<any, any>(`api/user/machines/?association=company`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachineAccessOptions({
          machine_options: response.data.map((machine) => ({
            label: machine.machine_name_with_serial,
            value: machine.serial_number,
            company_id: machine.company_id,
          })),
        });
        setUserMachineAccessSubmitting(false);
      });
  };

  // Needs to be removed this was moved into the Users context
  const fetchUserMachineByCompany = async (filterParams?: string) => {
    setUserMachineAccessLoading(true);

    axios
      .get<any, any>(`api/user/machines/?association=company`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        console.log(response.data.result);
        setUserMachinesByCompany(response.data);
        setUserMachineAccessLoading(false);
      });
  };

  const fetchUserMachineAccessOptions = async (companyId: string) => {
    setUserMachineAccessLoading(true);

    axios
      .get<any, any>(`api/companies/${companyId}/user_machine_access_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachineAccessOptions(response.data.result);
        setUserMachineAccessSubmitting(false);
      });
  };

  const fetchAllMachineAccessOptions = async () => {
    setUserMachineAccessLoading(true);

    axios
      .get<any, any>(`api/admin/user_machine_access_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserMachineAccessOptions(response.data.result);
        setUserMachineAccessSubmitting(false);
      });
  };

  const createUserMachineAccess = async (formValues: UserFormValues) => {
    setUserMachineAccessSubmitting(true);

    axios
      .post<any, any>(
        `api/admin/user_machine_access/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        history.push(`/admin/user_management/${response.data.result.user_id}`);
        setUserMachineAccessSubmitting(false);
      });
  };

  const createBulkUserMachineAccess = async (formValues: UserFormValues) => {
    setUserMachineAccessSubmitting(true);

    axios
      .post<any, any>(
        `api/admin/bulk_user_machine_access/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setUserMachineAccessSubmitting(false);
      })
      .catch((error) => {
        setUserMachineAccessSubmitting(false);
      });
  };

  const createUserBulkUserMachineAccess = async (formValues: UserFormValues) => {
    setUserMachineAccessSubmitting(true);

    axios
      .post<any, any>(
        `api/user/bulk_user_machine_access/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setUserMachineAccessSubmitting(false);
      })
      .catch((error) => {
        setUserMachineAccessSubmitting(false);
      });
  };

  return (
    <UserMachineAccessContext.Provider
      value={{
        fetchUserMachineAccess,
        fetchUserMachineAccessOptions,
        fetchMachineAccessOptions,
        fetchAllMachineAccessOptions,
        fetchUserMachineByCompany,

        createUserMachineAccess,
        createBulkUserMachineAccess,
        createUserBulkUserMachineAccess,
        userMachineAccess,
        userMachineAccessOptions,
        userMachinesByCompanyLoading,
        userMachineAccessSubmitting,
        userMachineByCompany,
      }}
    >
      {children}
    </UserMachineAccessContext.Provider>
  );
};

export { UserMachineAccessContextProvider, UserMachineAccessContextConsumer, UserMachineAccessContext };
