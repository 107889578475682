import React from 'react';

import './FormatDataRowPreview.scss';

export const FormatDataRowPreview: React.FC<{
  rows: Array<Object>;
}> = React.memo(({ rows = [] }) => {
  if (rows.length === 0) {
    return null;
  }

  // Get keys from the first object and move any error messages to the front of the object
  let keys = Object.keys(rows[0]);
  const errorMessageIndex = keys.indexOf('errorMessage');
  if (errorMessageIndex !== -1) {
    keys = ['errorMessage', ...keys.filter((key, index) => index !== errorMessageIndex)];
  }

  return (
    <div className="CSVImporter_FormatDataRowPreview">
      <table className="CSVImporter_FormatDataRowPreview__table">
        <thead>
          <tr>
            {keys.map((key, index) => (
              <th key={index}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((obj, rowIndex) => (
            <tr key={rowIndex}>
              {keys.map((key, keyIndex) => (
                <td key={keyIndex}>{obj[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
