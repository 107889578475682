import React from 'react';
import { format } from 'date-fns';
import { Tooltip } from '@mui/material';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import MachineRowMenu from '../components/MachineRowMenu';
import AdminMachineRowMenu from '../../machinesAdminManagement/components/MachineRowMenu';

const getColorByConnectionState = (state) => {
  switch (state) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    default:
      return 'black';
  }
};

const defaultUserMachineColumns: IDataTableColumn<any>[] = [
  {
    name: 'Machine Name',
    selector: 'machine_name',
    sortable: true,
  },
  {
    name: 'Machine Serial',
    selector: 'serial_number',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.serial_number) || 0;
      const valB = parseFloat(b.serial_number) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Connection State',
    selector: 'connection_state',
    sortable: true,
    grow: 0.9,
    cell: ({ connection_state }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          fontSize: 13,
          color: getColorByConnectionState(connection_state),
        }}
      >
        {connection_state}
      </div>
    ),
  },
  {
    name: 'Last Online',
    selector: 'last_online_date',
    grow: 1.5,
    sortable: true,
    cell: (row): unknown => <p>{row?.last_online_date === 'Never' ? 'Never' : row?.last_online_date}</p>,
  },
  {
    name: 'System Version',
    selector: 'system_type',
    sortable: true,
  },
  {
    name: 'InduSoftProject',
    selector: 'indusoft_project',
    sortable: true,
  },
  {
    name: 'Subscription',
    selector: 'subscription_active',
    sortable: true,
    cell: ({ subscription_active }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          fontSize: 13,
          color: subscription_active ? 'green' : 'red',
        }}
      >
        {subscription_active ? 'Active' : 'Inactive'}
      </div>
    ),
  },
];

export default defaultUserMachineColumns;
