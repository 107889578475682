/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { TreatmentProductAutoTreatFormValues } from '../model';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import { TreatmentProductsAutoTreatValidationsContext } from '../contexts/TreatmentProductsAutoTreatValidationsContext';

interface TreatmentProductsAutoTreatFormProps {
  treatmentProduct: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const TreatmentProductsAutoTreatForm: React.FC<TreatmentProductsAutoTreatFormProps> = (props) => {
  const { treatmentProduct, machineSerial, onSuccess, onBack } = props;

  const {
    updateTreatmentProductAutoTreat,
    createTreatmentProductAutoTreat,
    options,
    treatmentProductSubmitting,
    activeMachine,
  } = React.useContext(TreatmentProductsAutoTreatContext);
  const { validateTreatmentProductsAutoTreatFields, formErrors } = React.useContext(
    TreatmentProductsAutoTreatValidationsContext,
  );
  const [formValues, setFormValues] = React.useState<TreatmentProductAutoTreatFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateTreatmentProductAutoTreat(activeMachine.value, formValues);
  };

  const handleCreate = () => {
    createTreatmentProductAutoTreat(activeMachine.value, formValues);
  };

  React.useEffect(() => {
    const abTreatmentProductsFieldsValid = validateTreatmentProductsAutoTreatFields(formValues);
    setActionButtonDisabled(!abTreatmentProductsFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      liquid_name_id: treatmentProduct?.liquid_name_id,
      liquid_name: treatmentProduct?.liquid_name,
      liquid_name_desc: treatmentProduct?.liquid_name_desc,
      liquid_available: treatmentProduct?.liquid_available,
      liquid_lot_data: treatmentProduct?.liquid_lot_data,
      shipment_number: treatmentProduct?.shipment_number,
      liquid_density: treatmentProduct?.liquid_density,
      min_rate: treatmentProduct?.min_rate,
      max_rate: treatmentProduct?.max_rate,
      target_rate: treatmentProduct?.target_rate,
      manufacturer_list: treatmentProduct?.manufacturer_list,
      manufacturer: treatmentProduct?.manufacturer,
      adjustable: treatmentProduct?.adjustable,
      adjust_by: treatmentProduct?.adjust_by,
      epa_number: treatmentProduct?.epa_number,
      liquid_type_id: treatmentProduct?.liquid_type_id,
      liquid_type: treatmentProduct?.liquid_type,
      global_liquid_id: treatmentProduct?.global_liquid_id,
      username: treatmentProduct?.username,
      active: treatmentProduct?.active || 'True',
      date_and_time: treatmentProduct?.date_and_time,
    });
  }, [treatmentProduct]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                disabled={formValues.manufacturer_list === 'True'}
                value={formValues?.liquid_name}
                onChange={(value) => setFormValues({ ...formValues, liquid_name: value })}
                label="Name"
                errorMessage={formErrors.liquid_name}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.liquid_name_desc}
                onChange={(value) => setFormValues({ ...formValues, liquid_name_desc: value })}
                label="Description"
                errorMessage={formErrors.liquid_name_desc}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.liquid_available}
                onChange={(value) => setFormValues({ ...formValues, liquid_available: value })}
                label={`Inventory ${activeMachine.metric ? '(mg)' : '(fl. oz)'}`}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                disabled={formValues.manufacturer_list === 'True'}
                value={formValues?.target_rate}
                onChange={(value) => setFormValues({ ...formValues, target_rate: value })}
                label="Target Rate Per CWT"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.liquid_density}
                onChange={(value) => setFormValues({ ...formValues, liquid_density: value })}
                label={`Density ${activeMachine.metric ? '(g/ml)' : '(lb/gal)'}`}
                errorMessage={formErrors.liquid_density}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                disabled={formValues.manufacturer_list === 'True'}
                value={formValues?.manufacturer}
                onChange={(value) => setFormValues({ ...formValues, manufacturer: value })}
                label="Manufacturer Name"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                disabled={formValues.manufacturer_list === 'True'}
                value={formValues?.epa_number}
                onChange={(value) => setFormValues({ ...formValues, epa_number: value })}
                label="EPA #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.liquid_lot_data}
                onChange={(value) => setFormValues({ ...formValues, liquid_lot_data: value })}
                label="Lot #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.shipment_number}
                onChange={(value) => setFormValues({ ...formValues, shipment_number: value })}
                label="Batch #"
              />
            </Grid>
            {/* TODO Populate from onprem DB. */}
            {/* TODO do not let user select Product Type Slurry (ID: 6) or Active Ingredient (ID: 9). The only time we will see a product with type Active Ingredient is if manufacturer_list = true which means this dropdown will be disabled.  */}
            <Grid item xs={5}>
              <SelectInput
                disabled={formValues.manufacturer_list === 'True'}
                // choices={options?.types}
                choices={options?.product_type_options}
                value={formValues?.liquid_type_id}
                onChange={(value) => setFormValues({ ...formValues, liquid_type_id: value })}
                label="Product Type"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
            {treatmentProduct?.liquid_name_id && (
              <Grid item xs={5}>
                <FormInput
                  disabled
                  value={formValues?.manufacturer_list}
                  onChange={(value) => setFormValues({ ...formValues, manufacturer_list: value })}
                  label="Manufacturer List"
                />
              </Grid>
            )}
          </Grid>
          <br />

          {activeMachine ? (
            <>
              {treatmentProduct?.liquid_name_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || treatmentProductSubmitting}
                  onClick={handleUpdate}
                >
                  {treatmentProductSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || treatmentProductSubmitting}
                  onClick={handleCreate}
                >
                  {treatmentProductSubmitting ? 'Creating...' : 'Create'}
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary">
              Select A Machine
            </Button>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default TreatmentProductsAutoTreatForm;
