import React from 'react';
import { Box, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { PermissionGroupsContext } from '../contexts/PermissionGroupsContext';
import { PermissionGroupFormValues } from '../model';

const EditPermissionGroupPage: React.FC = () => {
  const { currentUserHasUserPermission } = React.useContext(AuthContext);
  const { permissionGroupId } = useParams<Record<string, string>>();

  const { fetchPermissionGroup, permissionGroup, permissionGroupsLoading } = React.useContext(PermissionGroupsContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const [formValues, setFormValues] = React.useState<PermissionGroupFormValues>({});
  const [roleChoices] = React.useState({
    superAdmin: 'KSi Super Admin',
    technicalAdmin: 'KSi Technical Admin',
    supportAdmin: 'KSi Support Admin',
    support: 'KSi Support',
  });

  React.useEffect(() => {
    fetchPermissionGroup(permissionGroupId);
  }, []);

  const handleChange = () => {};

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h2">Permission Group: {permissionGroup?.name}</Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">{permissionGroup?.description}</FormLabel>
        <br />
        <FormGroup>
          {permissionGroup.admin_role ? (
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Authorization Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.authorization?.map((permission) => (
                  <Grid key={permission.display_anme} item xs={3}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Machine Management Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.machine?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Company Management Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.company?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    User Management Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.user?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Api Partner Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.api_partner?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Analytics Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.analytics?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Account Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.account?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 6 }} color="primary">
                    Machine Permissions
                  </Typography>
                </Grid>
                {permissionGroup?.permissions?.machine?.map((permission) => (
                  <Grid item xs={3} key={permission.display_name}>
                    <FormControlLabel
                      key={roleChoices?.technicalAdmin}
                      checked={formValues?.role_ids?.includes(roleChoices?.technicalAdmin)}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          disabled
                          checked={permission.granted}
                          name={roleChoices?.technicalAdmin}
                          value={roleChoices?.technicalAdmin}
                        />
                      }
                      label={permission.display_name}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ marginBottom: 6 }}>{permission.description}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </FormGroup>
      </FormControl>
    </form>
  );
};

export default EditPermissionGroupPage;
