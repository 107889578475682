/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { LevelSensorsAutoBulkContext } from '../contexts/LevelSensorsAutoBulkContext';
import { LevelSensor } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import LevelSensorsFilterBar from '../components/LevelSensorFilterBar';
import LevelSensorsLiveFilterBar from '../components/LevelSensorsLiveFilterBar';
import LevelSensorBarGraph from '../components/LevelSensorBarGraph';
import LevelSensorsDataTable from '../components/LevelSensorsDataTable';

const LevelSensorsListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchLevelSensors, levelSensors, levelSensorsLoading } = React.useContext(LevelSensorsAutoBulkContext);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);
  const [tableFilters, setTableFilters] = React.useState({
    sensor_name: '',
    source_name: '',
    source_description: '',
    product_name: '',
  });
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('levelSensorAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('levelSensorAutoBulkMachine') || 'Select A Machine',
  });

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchLevelSensors]);

  const filterLevelSensors = () => {
    if (
      tableFilters.sensor_name ||
      tableFilters.source_name ||
      tableFilters.product_name ||
      tableFilters.source_description
    ) {
      const sensorNameFilteredValues = levelSensors.filter((levelSensor) =>
        levelSensor.level_name?.toLowerCase()?.includes(tableFilters.sensor_name?.toLowerCase()),
      );

      const sourceNameFilteredValues = sensorNameFilteredValues.filter((levelSensor) =>
        levelSensor.source_name?.toLowerCase()?.includes(tableFilters.source_name?.toLowerCase()),
      );

      const sourceDescFilteredValues = sourceNameFilteredValues.filter((levelSensor) =>
        levelSensor.source_description?.toLowerCase()?.includes(tableFilters.source_description?.toLowerCase()),
      );

      const productNameFilteredValues = sourceDescFilteredValues.filter((levelSensor) =>
        levelSensor.products?.toLowerCase()?.includes(tableFilters.product_name?.toLowerCase()),
      );

      return productNameFilteredValues;
    }

    return levelSensors;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Level Sensors Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <LevelSensorsFilterBar
            machineChoices={autoBulkMachines}
            machineChoicesLoading={machinesLoading}
            activeMachine={activeMachine}
            setActiveMachine={setActiveMachine}
          />
          <br />
        </Grid>
      </Grid>
      <br />
      {activeMachine ? (
        <Grid container>
          <Grid item xs={12}>
            <LevelSensorBarGraph title={`Machine: ${activeMachine?.value}`} />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
            Please select a machine to view details.
          </Typography>
        </Grid>
      )}

      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <LevelSensorsLiveFilterBar tableFilters={tableFilters} setTableFilters={setTableFilters} />
          <br />
        </Grid>
      </Grid>
      <>
        <Card>
          <CardContent>
            {activeMachine?.value ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterLevelSensors()}
                defaultSortField="DateAndTime"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                progressPending={levelSensorsLoading}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default LevelSensorsListPage;
