/* eslint-disable react-hooks/exhaustive-deps, no-alert */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Button, Typography, Checkbox, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormInput from '../../shared/form/FormInput';
import { MachinesContext } from '../contexts/MachinesContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import ColumnSelectModal from '../../shared/modal/ConfirmCancelModal';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Machine } from '../model';

interface MachineExecuteSqlFormProps {
  machine: Machine;
  showLoadingIndicator?: boolean;
}

const MachineExecuteSqlForm: React.FC<MachineExecuteSqlFormProps> = (props) => {
  const { machine, showLoadingIndicator = true } = props;

  const { executeSql, sqlResponse, columnsList, clearSql, cancelSql, sqlSubmitting } =
    React.useContext(MachinesContext);

  const [machineSql, setMachineSQl] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [clearButtonDisabled, setClearButtonDisabled] = React.useState(true);
  const [columns, setColumns] = React.useState<IDataTableColumn<any>[]>([]);
  const [selectedColumns, setSelectedColumns] = React.useState<IDataTableColumn<any>[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([]);

  const buildColumns = (arr) => {
    const tempColumns = [];
    const tempCharCountArr = [];

    arr?.forEach((key) => {
      tempCharCountArr.push(key.length);
    });

    const charCount = tempCharCountArr.reduce((partialSum, a) => partialSum + a, 0);

    arr?.forEach((key) => {
      tempColumns.push({
        name: key,
        selector: key,
        sortable: true,
        minWidth: `${Number((key.length / (charCount / tempCharCountArr.length)).toFixed(2)) * 115}px`,
      });
    });
    setColumns(tempColumns);
  };

  React.useEffect(() => {
    if (selectedColumns[0] === undefined) {
      buildColumns(columnsList);
    } else {
      buildColumns(selectedColumns);
    }
  }, [columnsList]);

  const handleExecuteSql = () => {
    const machineQuery = machineSql.toUpperCase();
    const regex = /INSERT|UPDATE|DELETE/i;
    let executeQuery = false;

    if (regex.test(machineQuery)) {
      const confirmExecution = window.confirm(
        '**CAUTION**\nThis action may modify the data on this machine.\n\nClick OK to execute the query.',
      );
      executeQuery = confirmExecution;
    } else {
      executeQuery = true;
    }

    if (executeQuery) {
      executeSql(machine.serial_number, machineSql);
      setSelectedColumns([]);
      setChecked([]);
    }
  };

  const handleCancelSql = () => {
    cancelSql();
  };

  const handleColumnsChange = () => {
    buildColumns(selectedColumns);
    setModalOpen(false);
  };

  const handleCheckBoxes = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setSelectedColumns(updatedList);
  };

  const handleClearColumns = () => {
    setSelectedColumns([]);
    setChecked([]);
    buildColumns(columnsList);
  };

  return !machine?.machine_id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      {showLoadingIndicator ? (
        <>
          <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
          <br />
          <CircularProgress />
        </>
      ) : (
        <h2 style={{ fontSize: '18px', marginTop: -200 }}>Please select a machine to begin SQL execution</h2>
      )}
    </Box>
  ) : (
    <Grid container>
      <ColumnSelectModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        hasFooterBar
        confirmLabel="Save Selections"
        onConfirm={handleColumnsChange}
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Columns Returned from Sql Query
          </Typography>
          <p style={{ marginTop: 7 }}>Select columns to show on table, in the order checked</p>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="Include Header"
                value={columnsList}
              >
                {columnsList?.map((key: any) => {
                  return (
                    <React.Fragment key={key}>
                      <FormControlLabel
                        checked={selectedColumns.includes(key)}
                        value={key}
                        control={<Checkbox />}
                        label={key}
                        onChange={handleCheckBoxes}
                      />
                    </React.Fragment>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </div>
      </ColumnSelectModal>

      <Grid item xs={12}>
        <Grid container spacing={3} rowSpacing={2} columnSpacing={12}>
          <Grid item xs={12} md={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" component="h2">
                  Execute Sql on Machine {machine.serial_number}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{}}
                  onClick={handleExecuteSql}
                  disabled={sqlSubmitting}
                >
                  Execute Sql
                </Button>
                <Button variant="contained" color="primary" style={{ marginLeft: 13 }} onClick={handleCancelSql}>
                  Cancel
                </Button>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 13 }}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    rows={10}
                    multiline
                    value={machineSql}
                    onChange={(value) => setMachineSQl(value)}
                    label="Sql to Run"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 13 }}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    rows={15}
                    multiline
                    value={JSON.stringify(sqlResponse, undefined, 4)}
                    label="Raw Machine Response"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 25 }}>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Response Table
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" style={{ marginLeft: 15 }} onClick={handleClearColumns}>
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 15 }}
                    onClick={() => setModalOpen(true)}
                  >
                    Select Columns
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 25 }}>
                {sqlResponse ? (
                  <DataTable
                    noHeader
                    columns={columns}
                    data={sqlResponse}
                    defaultSortAsc={false}
                    striped
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                  />
                ) : (
                  <Grid container alignItems="center" justifyContent="center">
                    <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                      Enter a Sql query to view records.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MachineExecuteSqlForm;
