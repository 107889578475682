import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Pagination, Typography } from '@mui/material';
import { Banner } from '@shopify/polaris';

interface PaginationBannerProps {
  label?: string;
  totalRecords?: number;
  currentPage?: number;
  onPageChange?: (newPage: number) => void;
}

const useStyles = makeStyles(() => ({}));

const PaginationBanner: React.FC<PaginationBannerProps> = (props) => {
  const { label = 'customers', totalRecords = 1230, currentPage = 1, onPageChange = null } = props;

  const totalPages = () => {
    return Math.ceil(totalRecords / 500);
  };

  return (
    <>
      <br />
      <Banner
        title="Exceed max return size of 500 customers. Refine your search above or select a specific page."
        status="info"
      >
        <Grid container justifyContent="space-between">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>
              Displaying {label} 1 - 500 of {totalRecords} in total
            </Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Page {currentPage}:</Typography>
            <Pagination
              count={totalPages()}
              color="primary"
              page={currentPage}
              onChange={(event, newPage) => onPageChange(newPage)}
            />
          </Grid>
        </Grid>
      </Banner>
      <br />
    </>
  );
};

export default PaginationBanner;
