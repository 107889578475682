import React from 'react';
import { Button, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InformationField from '../../shared/form/InformationField';
import { AuthContext } from '../../auth/contexts/AuthContext';

interface MachineMetaInformationProps {
  machine: any;
}

const MachineMetaInformation: React.FC<MachineMetaInformationProps> = (props) => {
  const { machine } = props;
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Machine Meta Details
            </Typography>
          </Grid>
        </Grid>
        <br />
        {machine?.machine_meta && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} xl={3}>
              <InformationField label="Last Backup Date" value={machine?.last_backup_date} />
            </Grid>
            {!currentUserHasAdminPermission('ksi_support') && (
              <>
                <Grid item xs={6} md={4} xl={3}>
                  <InformationField label="Serial Number" value={machine?.serial_number} />
                </Grid>
                <Grid item xs={6} md={4} xl={3}>
                  <InformationField label="Machine Type" value={machine?.autobulk ? 'AutoBulk' : 'AutoTreat'} />
                </Grid>
                <Grid item xs={6} md={4} xl={3}>
                  <InformationField
                    label="Subscription Expiration Date"
                    value={machine?.subscription_expiration_date}
                  />
                </Grid>
              </>
            )}
            {Object.entries(JSON.parse(machine?.machine_meta)).map((meta_value) => (
              <Grid key={meta_value[0]} item xs={6} md={4} xl={3}>
                <InformationField
                  label={meta_value[0]}
                  value={meta_value[1] !== null ? (meta_value[1].toString() as string) : ''}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default MachineMetaInformation;
