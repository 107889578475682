import React from 'react';
import { useDrag } from '@use-gesture/react';
import { Card, CardContent, Grid } from '@mui/material';

import { FieldAssignmentMap } from '../../../parser';
import { Column } from '../ColumnPreview';
import { DragState } from '../ColumnDragState';

import './ColumnDragSourceArea.scss';
import SourceBox from './SourceBox';

import { useLocale } from '../../../locale/LocaleContext';

const ColumnDragSourceArea: React.FC<{
  columns: Column[];
  columnPageSize?: number;
  fieldAssignments: FieldAssignmentMap;
  dragState: DragState | null;
  eventBinder: (column: Column) => ReturnType<typeof useDrag>;
  onSelect: (column: Column) => void;
  onUnassign: (column: Column) => void;
}> = ({ columns, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
  const l10n = useLocale('fieldsStep');

  return (
    <section
      className="CSVImporter_ColumnDragSourceArea"
      aria-label={l10n.dragSourceAreaCaption}
      style={{ borderRight: '1px solid lightgray', paddingRight: '15px', overflowY: 'scroll', height: 600 }}
    >
      <div className="CSVImporter_ColumnDragSourceArea__page">
        <Grid justifyContent="space-between" container spacing={1} style={{ marginTop: '-17px' }}>
          {columns.map((column, columnIndex) => (
            <Grid
              item
              marginTop={1}
              // marginRight={2}
              xs={12}
            >
              <SourceBox
                key={columnIndex}
                column={column}
                fieldAssignments={fieldAssignments}
                dragState={dragState}
                eventBinder={eventBinder}
                onSelect={onSelect}
                onUnassign={onUnassign}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default ColumnDragSourceArea;
