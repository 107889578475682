import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Recipe Name',
    selector: 'liquid_recipe_name',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'liquid_recipe_desc',
    sortable: true,
  },
  {
    name: 'Crop',
    selector: 'crop_name',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'Mfr List',
    selector: 'manufacturer_list',
    sortable: true,
    grow: 0.5,
  },
  {
    name: `Last Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time',
    sortable: true,
    grow: 1.3,
    sortFunction: (rowA, rowB) => new Date(rowA.date_and_time).getTime() - new Date(rowB.date_and_time).getTime(),
    cell: (row: any) => (
      <p>{row.date_and_time ? format(new Date(`${row.date_and_time} UTC`), 'MM/dd/yyyy hh:mm aaa') : ''}</p>
    ),
  },
  {
    name: 'Last Modified By',
    selector: 'username',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
    grow: 0.5,
  },
];

export const nestedColumns: IDataTableColumn<any>[] = [
  {
    name: 'Treatment Product',
    selector: 'liquid_name',
    sortable: true,
  },
  {
    name: 'Rate',
    selector: 'liquid_rate',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.liquid_rate) || 0;
      const valB = parseFloat(b.liquid_rate) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Rate Type',
    selector: 'liquid_rate_type',
    sortable: true,
  },
  {
    name: 'Treat Source',
    selector: 'liquid_source',
    sortable: true,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'Integration Id',
    selector: 'integration_id',
    sortable: true,
  },
  {
    name: 'Recipe Id',
    selector: 'liquid_recipe_id',
    sortable: true,
  },
  {
    name: 'API Partner',
    selector: 'api_partner',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];
