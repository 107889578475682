import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const treatmentColumns = (combined: boolean): IDataTableColumn<any>[] => {
  const defaultColumns = [
    {
      name: 'Treatment Product',
      selector: 'liquid_name',
      sortable: true,
    },
    {
      name: 'Target Amount',
      selector: 'target_amt_total_with_uom',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.target_amt_total_with_uom) || 0;
        const valB = parseFloat(b.target_amt_total_with_uom) || 0;
        return valA - valB;
      },
      cell: (row) => <p>{row.target_amt_total_with_uom?.replace('None', '')}</p>,
    },
    {
      name: 'Actual Amount',
      selector: 'actual_amt_total_with_uom',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.actual_amt_total_with_uom) || 0;
        const valB = parseFloat(b.actual_amt_total_with_uom) || 0;
        return valA - valB;
      },
      cell: (row) => <p>{row.actual_amt_total_with_uom?.replace('None', '')}</p>,
    },
    {
      name: 'Treatment Accuracy',
      selector: 'amt_total_accuracy',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.amt_total_accuracy) || 0;
        const valB = parseFloat(b.amt_total_accuracy) || 0;
        return valA - valB;
      },
      cell: (row) => <p>{`${row.amt_total_accuracy}`}</p>,
    },
    {
      name: 'Total Seed Units',
      selector: 'seed_units_total_with_uom',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.seed_units_total_with_uom) || 0;
        const valB = parseFloat(b.seed_units_total_with_uom) || 0;
        return valA - valB;
      },
    },
  ];

  return combined
    ? defaultColumns
    : [
        {
          name: 'Machine',
          selector: 'machine_name_with_serial',
          sortable: true,
        },
        ...defaultColumns,
      ];
};

export default treatmentColumns;
