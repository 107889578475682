import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Banner } from '@shopify/polaris';

type BannerStatus = 'success' | 'info' | 'warning' | 'critical';

interface WorkOrderAutoBulkStatusBannerProps {
  isOrderStarted: boolean;
  isOrderAPI: boolean;
}

const WorkOrderAutoBulkStatusBanner: React.FC<WorkOrderAutoBulkStatusBannerProps> = ({
  isOrderStarted,
  isOrderAPI,
}) => {
  let title = '';
  let message = '';
  let status: BannerStatus | null = null;

  if (isOrderStarted) {
    title = 'This order has already been started.';
    message = 'You cannot edit this order as it has already been initiated.';
    status = 'warning';
  } else if (isOrderAPI) {
    title = 'This order is linked to an external API.';
    message = 'You cannot edit this order while it is linked to an external system.';
    status = 'warning';
  }

  if (!title) return null;

  return (
    <>
      <Banner title={title} status={status}>
        <Grid container justifyContent="space-between">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{message}</Typography>
          </Grid>
        </Grid>
      </Banner>
      <br />
    </>
  );
};

export default WorkOrderAutoBulkStatusBanner;
