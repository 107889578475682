/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RfidsAutoBulkContext } from '../contexts/RfidAutoBulkContext';
import RfidAutoBulkForm from './RfidAutoBulkForm';

const RfidAutoBulkDetail: React.FC = (props) => {
  const { setDetailPage, activeRfidAutoBulk } = React.useContext(RfidsAutoBulkContext);

  return (
    <>
      <RfidAutoBulkForm rfid={activeRfidAutoBulk} onSuccess={() => setDetailPage(false)} />
    </>
  );
};

export default RfidAutoBulkDetail;
