/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RecipesAutoTreatContext } from '../contexts/RecipesAutoTreatContext';
import RecipeAutoTreatForm from './RecipesAutoTreatForm';

interface RecipeAutoTreatDetailProps {
  recipe: any;
  machineSerial?: string;
  onSuccess?: () => void;
}

const RecipesAutoTreatDetail: React.FC<RecipeAutoTreatDetailProps> = (props) => {
  const { recipe, machineSerial, onSuccess } = props;

  const { setDetailPage, activeRecipeAutoTreat } = React.useContext(RecipesAutoTreatContext);

  return (
    <>
      <RecipeAutoTreatForm recipe={activeRecipeAutoTreat} onSuccess={() => setDetailPage(false)} />
    </>
  );
};

export default RecipesAutoTreatDetail;
