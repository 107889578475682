import { format, parseISO } from 'date-fns';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const machineRequestRecordColumns: IDataTableColumn<any>[] = [
  {
    name: `Record Info`,
    selector: 'record_info',
    sortable: true,
  },
  {
    name: 'Response Message',
    selector: 'response_message',
    sortable: false,
  },
  {
    name: 'Response Status',
    selector: 'response_status',
    sortable: true,
    grow: 0.7,
    cell: (row: Record<string, string>): JSX.Element => (
      <div data-tag="___react-data-table-allow-propagation___">
        {row.response_status ? row.response_status : 'Pending'}
      </div>
    ),
  },
  {
    name: `Date Sent to Machine (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_time_sent_to_machine',
    sortable: true,
    grow: 0.7,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';

      if (row.date_time_sent_to_machine) {
        try {
          const date = parseISO(row.date_time_sent_to_machine);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <div data-tag="___react-data-table-allow-propagation___">{formattedDate}</div>;
    },
  },
  {
    name: `Date Reponse (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_time_machine_response',
    sortable: true,
    grow: 0.7,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';

      if (row.date_time_machine_response) {
        try {
          const date = parseISO(row.date_time_machine_response);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <div data-tag="___react-data-table-allow-propagation___">{formattedDate}</div>;
    },
  },
];

export default machineRequestRecordColumns;
