/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import { RfidAutoBulkFormValues } from '../model';
import { RfidsAutoBulkContext } from '../contexts/RfidAutoBulkContext';
import { RfidsAutoBulkValidationsContext } from '../contexts/RfidAutoBulkValidationsContext';
import SelectInput from '../../../modules/shared/form/SelectInput';

interface RfidAutoBulkFormProps {
  rfid: any;
  onSuccess?: () => void;
}

const RfidAutoBulkForm: React.FC<RfidAutoBulkFormProps> = (props) => {
  const { rfid, onSuccess } = props;

  const { rfidsubmitting, createRfidAutoBulk, updateRfidAutoBulk } = React.useContext(RfidsAutoBulkContext);
  const { validateRfidsAutoBulkFields, formErrors } = React.useContext(RfidsAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<RfidAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateRfidAutoBulk(formValues);
  };

  const handleCreate = () => {
    createRfidAutoBulk(formValues, onSuccess);
  };

  React.useEffect(() => {
    const abRfidsFieldsValid = validateRfidsAutoBulkFields(formValues);
    setActionButtonDisabled(!abRfidsFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      rfid_id: rfid.rfid_id,
      rfid_name: rfid.rfid_name,
      rfid_comment: rfid.rfid_comment,
      rfid_code: rfid.rfid_code,
      driver_name: rfid.driver_name,
      transport_name: rfid.transport_name,
      active: rfid.active,
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.rfid_name}
                onChange={(value) => setFormValues({ ...formValues, rfid_name: value })}
                label="Rfid Name"
                errorMessage={formErrors.RfidName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.rfid_comment}
                onChange={(value) => setFormValues({ ...formValues, rfid_comment: value })}
                label="Comment"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.rfid_code}
                onChange={(value) => setFormValues({ ...formValues, rfid_code: value })}
                label="Rfid Code"
                errorMessage={formErrors.RfidCode}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          <>
            {rfid?.rfid_id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || rfidsubmitting}
                onClick={handleUpdate}
              >
                {rfidsubmitting ? 'Updating Rfid...' : 'Update Rfid'}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || rfidsubmitting}
                onClick={handleCreate}
              >
                {rfidsubmitting ? 'Creating Rfid...' : 'Create Rfid'}
              </Button>
            )}
          </>
        </form>
      </Grid>
    </Grid>
  );
};

export default RfidAutoBulkForm;
