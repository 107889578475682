import React from 'react';
import { Grid } from '@mui/material';
import { SelectOption } from '../../shared/model';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface MachineRequestRecordsFilterBarProps {
  setFilterValues?: any;
  filterValues?: any;
}

const MachineRequestRecordsFilterBar: React.FC<MachineRequestRecordsFilterBarProps> = (props) => {
  const { setFilterValues, filterValues } = props;

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} md={2}>
            <FormInput
              value={filterValues?.recordInfo}
              onChange={(value) => {
                setFilterValues({ ...filterValues, recordInfo: value });
              }}
              label="Record Info"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormInput
              value={filterValues?.responseMessage}
              onChange={(value) => {
                setFilterValues({ ...filterValues, responseMessage: value });
              }}
              label="Response Message"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={filterValues.recordStatus}
              choices={[
                { label: 'Pending', value: 'Pending' },
                { label: 'Success', value: 'Success' },
                { label: 'Error', value: 'Error' },
                { label: 'Failed', value: 'Failed' },
              ]}
              defaultChoice={{ label: 'All', value: 'e' }}
              onChange={(value) => setFilterValues({ ...filterValues, recordStatus: value })}
              label="Status"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MachineRequestRecordsFilterBar;
