import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface RecipeIntegrationsAutoTreatContextInterface {
  fetchRecipeIntegrationsAutoTreat?: (machineSerial: string, recipeId: string, onSuccess?: () => void) => Promise<void>;

  createRecipeIntegrationAutoTreat?: (
    machineSerial: string,
    recipeId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => Promise<void>;

  updateRecipeIntegrationAutoTreat?: (
    machineSerial: string,
    recipeId: string,
    integrationId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => Promise<void>;

  deleteRecipeIntegrationAutoTreat?: (
    machineSerial: string,
    recipeId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  recipeIntegrations?: any[];
  recipeIntegrationsLoading?: boolean;

  setRecipeIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  recipeIntegrationsSubmitting?: boolean;
}

const RecipeIntegrationsAutoTreatContext = React.createContext<RecipeIntegrationsAutoTreatContextInterface>({});

const RecipeIntegrationsAutoTreatContextConsumer = RecipeIntegrationsAutoTreatContext.Consumer;
const RecipeIntegrationsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [recipeIntegrations, setRecipeIntegrations] = React.useState([]);
  const [recipeIntegrationsLoading, setRecipeIntegrationsLoading] = React.useState(true);
  const [recipeIntegrationsSubmitting, setRecipeIntegrationsSubmitting] = React.useState(false);

  const fetchRecipeIntegrationsAutoTreat = async (machineSerial: string, recipeId: string, onSuccess?: () => void) => {
    setRecipeIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/recipe_integrations/?serial-number=${machineSerial}&recipe_id=${recipeId}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/recipe_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              if (retryCount < MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setRecipeIntegrationsLoading(false);
                setRecipeIntegrations(response.data.results);
                retryCount += 1;
              } else {
                setRecipeIntegrationsLoading(false);
                setRecipeIntegrations([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createRecipeIntegrationAutoTreat = async (
    machineSerial: string,
    recipeId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => {
    setRecipeIntegrationsLoading(true);
    setRecipeIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/recipe_integrations/?serial-number=${machineSerial}&recipe_id=${recipeId}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/recipe_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchRecipeIntegrationsAutoTreat(machineSerial, recipeId);
              setRecipeIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setRecipeIntegrationsLoading(false);
                setRecipeIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateRecipeIntegrationAutoTreat = async (
    machineSerial: string,
    recipeId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => {
    setRecipeIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/recipe_integrations/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/recipe_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchRecipeIntegrationsAutoTreat(machineSerial, recipeId);
              setRecipeIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setRecipeIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteRecipeIntegrationAutoTreat = async (
    machineSerial: string,
    recipeId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => {
    setRecipeIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autotreat/onprem/recipe_integrations/${integrationId}/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/recipe_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchRecipeIntegrationsAutoTreat(machineSerial, recipeId);
              showMachineManagementSnackBar(response.data.results[0]);
              setRecipeIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setRecipeIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <RecipeIntegrationsAutoTreatContext.Provider
      value={{
        fetchRecipeIntegrationsAutoTreat,
        createRecipeIntegrationAutoTreat,
        updateRecipeIntegrationAutoTreat,
        deleteRecipeIntegrationAutoTreat,

        recipeIntegrations,
        recipeIntegrationsLoading,

        setRecipeIntegrationsSubmitting,
        recipeIntegrationsSubmitting,
      }}
    >
      {children}
    </RecipeIntegrationsAutoTreatContext.Provider>
  );
};

export {
  RecipeIntegrationsAutoTreatContextProvider,
  RecipeIntegrationsAutoTreatContextConsumer,
  RecipeIntegrationsAutoTreatContext,
};
