/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { User } from '../model';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { UsersContext } from '../contexts/UsersContext';
import UserFilterBar from '../components/UserFilterBar';

const UserListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchUsers, users, usersLoading } = React.useContext(UsersContext);
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({ status: 'true' });

  React.useEffect(() => {
    fetchUsers('status=true');
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Admin User Management
          </Typography>
          <br />
        </Grid>
        <Grid item />
      </Grid>
      <br />
      <UserFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
      <br />
      <Card>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                Users List
              </Typography>
            </Grid>
            <Grid item>
              {currentUserHasAdminPermission('ksi_admin') && (
                <Button variant="contained" color="primary" onClick={() => history.push(`/admin/user_management/new`)}>
                  Create User
                </Button>
              )}
            </Grid>
          </Grid>
          <DataTable
            noHeader
            columns={columns}
            data={users}
            defaultSortAsc={false}
            striped
            onRowClicked={(row: User) => history.push(`/admin/user_management/${row.user_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={usersLoading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default UserListPage;
