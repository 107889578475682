import React from 'react';
import { Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import { RecipesAutoTreatFormValues } from '../model';
import RecipeItemsList from './RecipeItemsList';

interface RecipesAutoTreatFormProps {
  recipe: any;
  onSuccess?: () => void;
}

const RecipesAutoTreatForm: React.FC<RecipesAutoTreatFormProps> = (props) => {
  const { recipe, onSuccess } = props;

  const [formValues, setFormValues] = React.useState<RecipesAutoTreatFormValues>({});

  React.useEffect(() => {
    setFormValues({
      liquid_recipe_id: recipe?.liquid_recipe_id,
      liquid_recipe_name: recipe?.liquid_recipe_name,
      liquid_recipe_desc: recipe?.liquid_recipe_desc,
      comment: recipe?.comment,
      manufacturer_list: recipe?.manufacturer_list,
      manufacturer: recipe?.manufacturer,
      mapping_profile_complete: recipe?.mapping_profile_complete,
      recipe_volume_max: recipe?.recipe_volume_max,
      recipe_volume_recommended: recipe?.recipe_volume_recommended,
      crop_id: recipe?.crop_id,
      crop_name: recipe?.crop_name,
      seed_cnt_per_scale_uow: recipe?.seed_cnt_per_scale_uow,
      unit_size_cnt: recipe?.unit_size_cnt,
      global_recipe_id: recipe?.global_recipe_id,
      username: recipe?.username,
      active: recipe?.active,
      date_and_time: recipe?.date_and_time,
      obsolete: recipe?.obsolete,
    });
  }, [recipe]);

  return (
    <Grid container>
      <Grid item xs={7}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.liquid_recipe_id}
                onChange={(value) => setFormValues({ ...formValues, liquid_recipe_id: value })}
                label="Recipe ID"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.liquid_recipe_name}
                onChange={(value) => setFormValues({ ...formValues, liquid_recipe_name: value })}
                label="Recipe Name"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.liquid_recipe_desc}
                onChange={(value) => setFormValues({ ...formValues, liquid_recipe_desc: value })}
                label="Recipe Description"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.comment}
                onChange={(value) => setFormValues({ ...formValues, comment: value })}
                label="Comment"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.manufacturer_list}
                onChange={(value) => setFormValues({ ...formValues, manufacturer_list: value })}
                label="Manufacturer List"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.manufacturer}
                onChange={(value) => setFormValues({ ...formValues, manufacturer: value })}
                label="Manufacturer"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.mapping_profile_complete}
                onChange={(value) => setFormValues({ ...formValues, mapping_profile_complete: value })}
                label="Mapping Profile Complete"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.crop_name}
                onChange={(value) => setFormValues({ ...formValues, crop_name: value })}
                label="Crop"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.seed_cnt_per_scale_uow}
                onChange={(value) => setFormValues({ ...formValues, seed_cnt_per_scale_uow: value })}
                label="Seed Size"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                value={formValues?.username}
                onChange={(value) => setFormValues({ ...formValues, username: value })}
                label="UserName"
                disabled
              />
            </Grid>
          </Grid>
        </form>
        <br />
        <RecipeItemsList recipeItems={recipe.products} />
      </Grid>
    </Grid>
  );
};

export default RecipesAutoTreatForm;
