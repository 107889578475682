import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { NotificationContext } from '../contexts/NotificationContext';
import NotificationForm from '../components/NotificationForm';

const NewNotificationPage: React.FunctionComponent = () => {
  const { createNotification, notificationsLoading } = React.useContext(NotificationContext);
  const history = useHistory();
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!initialDataLoaded) {
      setDataLoaded(true);
    }
  }, [initialDataLoaded]);

  const handleBack = () => {
    history.goBack();
  };

  return notificationsLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2>Loading Page...</h2>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography sx={{ cursor: 'pointer', marginBottom: 2 }} onClick={handleBack} variant="subtitle1">
        ← Return to Notifications
      </Typography>
      <Typography variant="h1" component="h1">
        Create Notification
      </Typography>
      <p>Set up a new notification.</p>
      <NotificationForm notification={null} onSubmit={createNotification} onCancel={handleBack} />
    </>
  );
};

export default NewNotificationPage;
