import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Full Name',
    selector: 'full_name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email_address',
    sortable: true,
  },
  {
    name: 'Contact Number',
    selector: 'contact_number',
  },
  {
    name: 'User Permission Group',
    selector: 'user_permission_group_description',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status ? 'green' : 'red',
        }}
      >
        {status ? 'Active' : 'Inactive'}
      </div>
    ),
  },
];

export default columns;
