const seedSelectOptions = {
  crop_data: {
    '1': {
      label: 'Soybean',
      seedCNTUnit: '140000',
      UnitSizeWT: '50',
      UnitSizeCNT: '140000',
      seedAmountTypeID: '',
      minSeedSizeImperial: '500',
      maxSeedSizeImperial: '5000',
      amount_types: ['1', '2', '3'],
    },
    '2': {
      label: 'Rice',
      UnitSizeWT: '',
      UnitSizeCNT: '',
      seedAmountTypeID: '',
      minSeedSizeImperial: '1',
      maxSeedSizeImperial: '55000',
      amount_types: ['1', '2'],
    },
    '3': {
      label: 'Wheat',
      UnitSizeWT: '',
      UnitSizeCNT: '',
      seedAmountTypeID: '',
      minSeedSizeImperial: '1',
      maxSeedSizeImperial: '55000',
      amount_types: ['1', '2'],
    },
    '4': {
      label: 'Corn',
      UnitSizeWT: '',
      UnitSizeCNT: '',
      seedAmountTypeID: '',
      minSeedSizeImperial: '500 ',
      maxSeedSizeImperial: '10000',
      amount_types: ['1', '2', '3'],
    },
    '5': {
      label: 'Cotton',
      UnitSizeWT: '50',
      UnitSizeCNT: '140000',
      seedAmountTypeID: '',
      minSeedSizeImperial: '500',
      maxSeedSizeImperial: '10000',
      amount_types: ['1', '2', '3'],
    },
    '6': {
      label: 'Cereal',
      UnitSizeWT: '',
      UnitSizeCNT: '',
      seedAmountTypeID: '',
      minSeedSizeImperial: '1',
      maxSeedSizeImperial: '55000',
      amount_types: ['1', '2'],
    },
    '7': {
      label: 'Other',
      UnitSizeWT: '',
      UnitSizeCNT: '',
      seedAmountTypeID: '',
      minSeedSizeImperial: '1',
      maxSeedSizeImperial: '55000',
      amount_types: ['1', '2'],
    },
  },
  amount_types: {
    '1': {
      label: 'Pounds',
      seedAmountTypeAbbreviated: 'lbs',
    },
    '2': {
      label: 'Weight Units',
      seedAmountTypeAbbreviated: 'unit/wt',
    },
    '3': {
      label: 'Seed Count Unit',
      seedAmountTypeAbbreviated: 'unit/cnt',
    },
  },
};

export default seedSelectOptions;
