/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CustomersAutoTreatImportContext } from '../contexts/CustomersAutoTreatImportContext';
import 'react-csv-importer/dist/index.css';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import CustomerAutoTreatcsvImport from '../components/CustomerAutoTreatcsvImport';

const CustomersAutoTreatcsvImportPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineSerial } = useParams<Record<string, string>>();

  const { setFailedRecords, setPassedRecords } = React.useContext(CustomersAutoTreatImportContext);

  React.useEffect(() => {
    setFailedRecords([]);
    setPassedRecords([]);
  }, []);

  const links = [
    {
      onClick: () => history.push(`/autotreat/machines/customers`),
      text: 'AutoTreat Customers',
    },
    {
      onClick: () => null,
      text: `Bulk Import (Machine: ${machineSerial})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <CustomerAutoTreatcsvImport machineSerialNumber={machineSerial} />
    </>
  );
};

export default CustomersAutoTreatcsvImportPage;
