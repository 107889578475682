/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import TransportAutoBulkForm from '../components/TransportsAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const TransportsAutoBulkCreate: React.FunctionComponent = () => {
  const { fetchTransportsAutoBulk, setCreatingPage, activeMachine } = React.useContext(TransportsAutoBulkContext);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: 'AutoBulk Transports',
    },
    {
      onClick: () => null,
      text: `Create (Machine: ${activeMachine?.value})`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Transport
      </Typography>
      <br />
      <TransportAutoBulkForm transport={{}} onSuccess={() => setCreatingPage(false)} />
    </>
  );
};

export default TransportsAutoBulkCreate;
