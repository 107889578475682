import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

type TableRow = {
  order_id: string;
  [key: string]: any;
};

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Order ID',
    selector: 'order_id',
    sortable: true,
    sortFunction: (rowA: TableRow, rowB: TableRow): number => {
      const orderIdA = parseInt(rowA.order_id, 10);
      const orderIdB = parseInt(rowB.order_id, 10);
      return orderIdA - orderIdB;
    },
    omit: true,
  },
  {
    name: 'Order Name',
    selector: 'order_name',
    sortable: true,
  },
  {
    name: 'Order Comment',
    selector: 'comment',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'operation',
    sortable: true,
  },
  {
    name: 'Recipe',
    selector: 'liquid_recipe_name',
    sortable: true,
  },
  {
    name: 'Variety',
    selector: 'variety',
    sortable: true,
  },
  {
    name: 'Amount',
    selector: (row) => `${row.seed_amount_ordered} ${row.seed_amount_ordered_type_display_uom}`,
    sortable: true,
    grow: 1.0,
  },
  {
    name: 'Modified By',
    selector: 'username',
    sortable: true,
  },
  {
    name: `Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time_last_modified',
    sortable: true,
    sortFunction: (rowA, rowB) =>
      new Date(rowA.date_and_time_last_modified).getTime() - new Date(rowB.date_and_time_last_modified).getTime(),
    cell: (row: any) => (
      <p>
        {row.date_and_time_last_modified
          ? format(new Date(`${row.date_and_time_last_modified} UTC`), 'MM/dd/yyyy hh:mm aaa')
          : ''}
      </p>
    ),
  },
  {
    name: 'Completed',
    selector: 'order_complete',
    sortable: true,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
  },
];

export default columns;
