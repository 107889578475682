const permissions = {
  '1': {
    id: '1',
    name: 'KSi Super Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
    permissions: {
      authorization: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Login as Another User',
          granted: true,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access',
          granted: true,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access View Only',
          granted: true,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Associate User and Machines',
          granted: true,
          description: '',
        },
      ],
      machine: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine View',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Create',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Export',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Execute SQL',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Actions',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Deploy Updates',
          granted: true,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Download SQL and PAC backups',
          granted: true,
          description: '',
        },
      ],
      company: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Inactivate',
          granted: true,
          description: '',
        },
      ],
      user: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Assign Permissions',
          granted: true,
          description: '',
        },
      ],
      api_partner: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner Full',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '2': {
    id: '2',
    name: 'KSi Technical Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
    permissions: {
      authorization: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Login as Another User',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access View Only',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Associate User and Machines',
          granted: false,
          description: '',
        },
      ],
      machine: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine View',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Create',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Export',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Execute SQL',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Actions',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Deploy Updates',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Download SQL and PAC backups',
          granted: false,
          description: '',
        },
      ],
      company: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Inactivate',
          granted: true,
          description: '',
        },
      ],
      user: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Assign Permissions',
          granted: true,
          description: '',
        },
      ],
      api_partner: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner Full',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '3': {
    id: '3',
    name: 'KSi Support Admin',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
    permissions: {
      authorization: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Login as Another User',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access View Only',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Associate User and Machines',
          granted: false,
          description: '',
        },
      ],
      machine: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine View',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Create',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Export',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Execute SQL',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Actions',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Deploy Updates',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Download SQL and PAC backups',
          granted: false,
          description: '',
        },
      ],
      company: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Inactivate',
          granted: true,
          description: '',
        },
      ],
      user: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Assign Permissions',
          granted: true,
          description: '',
        },
      ],
      api_partner: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner Full',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '4': {
    id: '4',
    name: 'KSi Support',
    description: '',
    company_name: 'KSi',
    admin_role: 'true',
    default_group: 'true',
    status: 'Active',
    permissions: {
      authorization: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Login as Another User',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Temporary Company Access View Only',
          granted: false,
          description: '',
        },
        {
          codename: 'view_real_time_activity',
          display_name: 'Admin - Associate User and Machines',
          granted: false,
          description: '',
        },
      ],
      machine: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine View',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Create',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Export',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Execute SQL',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Actions',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Deploy Updates',
          granted: false,
          description: '',
        },
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Admin - Machine Download SQL and PAC backups',
          granted: false,
          description: '',
        },
      ],
      company: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - Company Management Inactivate',
          granted: true,
          description: '',
        },
      ],
      user: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Create',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Management Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - User Assign Permissions',
          granted: true,
          description: '',
        },
      ],
      api_partner: [
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner View',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_customer',
          display_name: 'Admin - API Partner Full',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '5': {
    id: '5',
    name: 'Company Super Admin',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
    permissions: {
      analytics: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Analytics - Real Time Activity',
          granted: true,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Analytics - Transactions',
          granted: true,
        },
        {
          codename: 'view_analytics_products',
          display_name: 'Analytics - Products',
          granted: true,
        },
      ],
      account: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Account - Grant KSi support temporary access to company',
          granted: true,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Company Full',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines View',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines Modify',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Users',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Integrations',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Notifications',
          granted: true,
        },
      ],
      machine: [
        {
          codename: 'view_machine_customer',
          display_name: 'Machine - Customer View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_customer',
          display_name: 'Machine - Customer Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_customer',
          display_name: 'Machine - Customer Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_customer',
          display_name: 'Machine - Customer Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_product',
          display_name: 'Machine - Product View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_product',
          display_name: 'Machine - Product Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_product',
          display_name: 'Machine - Product Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_product',
          display_name: 'Machine - Product Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_source',
          display_name: 'Machine - Source View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_source',
          display_name: 'Machine - Source Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_source',
          display_name: 'Machine - Source Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_source',
          display_name: 'Machine - Source Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_transport',
          display_name: 'Machine - Transport View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_transport',
          display_name: 'Machine - Transport Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_transport',
          display_name: 'Machine - Transport Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_transport',
          display_name: 'Machine - Transport Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_work_order',
          display_name: 'Machine - Work Order View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_work_order',
          display_name: 'Machine - Work Order Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_work_order',
          display_name: 'Machine - Work Order Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_work_order',
          display_name: 'Machine - Work Order Delete',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '6': {
    id: '6',
    name: 'Default User',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
    permissions: {
      analytics: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Analytics - Real Time Activity',
          granted: true,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Analytics - Transactions',
          granted: true,
        },
        {
          codename: 'view_analytics_products',
          display_name: 'Analytics - Products',
          granted: true,
        },
      ],
      account: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Account - Grant KSi support temporary access to company',
          granted: false,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Company Full',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines View',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines Modify',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Users',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Integrations',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Notifications',
          granted: true,
        },
      ],
      machine: [
        {
          codename: 'view_machine_customer',
          display_name: 'Machine - Customer View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_customer',
          display_name: 'Machine - Customer Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_customer',
          display_name: 'Machine - Customer Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_customer',
          display_name: 'Machine - Customer Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_product',
          display_name: 'Machine - Product View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_product',
          display_name: 'Machine - Product Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_product',
          display_name: 'Machine - Product Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_product',
          display_name: 'Machine - Product Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_source',
          display_name: 'Machine - Source View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_source',
          display_name: 'Machine - Source Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_source',
          display_name: 'Machine - Source Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_source',
          display_name: 'Machine - Source Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_transport',
          display_name: 'Machine - Transport View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_transport',
          display_name: 'Machine - Transport Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_transport',
          display_name: 'Machine - Transport Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_transport',
          display_name: 'Machine - Transport Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_work_order',
          display_name: 'Machine - Work Order View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_work_order',
          display_name: 'Machine - Work Order Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_work_order',
          display_name: 'Machine - Work Order Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_work_order',
          display_name: 'Machine - Work Order Delete',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '7': {
    id: '7',
    name: 'Default View Only User',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
    permissions: {
      analytics: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Analytics - Real Time Activity',
          granted: true,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Analytics - Transactions',
          granted: true,
        },
        {
          codename: 'view_analytics_products',
          display_name: 'Analytics - Products',
          granted: true,
        },
      ],
      account: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Account - Grant KSi support temporary access to company',
          granted: false,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Company Full',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines View',
          granted: true,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines Modify',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Users',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Integrations',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Notifications',
          granted: false,
        },
      ],
      machine: [
        {
          codename: 'view_machine_customer',
          display_name: 'Machine - Customer View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_customer',
          display_name: 'Machine - Customer Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_customer',
          display_name: 'Machine - Customer Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_customer',
          display_name: 'Machine - Customer Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_product',
          display_name: 'Machine - Product View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_product',
          display_name: 'Machine - Product Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_product',
          display_name: 'Machine - Product Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_product',
          display_name: 'Machine - Product Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_source',
          display_name: 'Machine - Source View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_source',
          display_name: 'Machine - Source Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_source',
          display_name: 'Machine - Source Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_source',
          display_name: 'Machine - Source Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_transport',
          display_name: 'Machine - Transport View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_transport',
          display_name: 'Machine - Transport Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_transport',
          display_name: 'Machine - Transport Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_transport',
          display_name: 'Machine - Transport Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_work_order',
          display_name: 'Machine - Work Order View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_work_order',
          display_name: 'Machine - Work Order Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_work_order',
          display_name: 'Machine - Work Order Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_work_order',
          display_name: 'Machine - Work Order Delete',
          granted: false,
          description: '',
        },
      ],
    },
  },
  '8': {
    id: '8',
    name: 'Data Management',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
    permissions: {
      analytics: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Analytics - Real Time Activity',
          granted: false,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Analytics - Transactions',
          granted: false,
        },
        {
          codename: 'view_analytics_products',
          display_name: 'Analytics - Products',
          granted: false,
        },
      ],
      account: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Account - Grant KSi support temporary access to company',
          granted: false,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Company Full',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines View',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines Modify',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Users',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Integrations',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Notifications',
          granted: false,
        },
      ],
      machine: [
        {
          codename: 'view_machine_customer',
          display_name: 'Machine - Customer View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_customer',
          display_name: 'Machine - Customer Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_customer',
          display_name: 'Machine - Customer Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_customer',
          display_name: 'Machine - Customer Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_product',
          display_name: 'Machine - Product View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_product',
          display_name: 'Machine - Product Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_product',
          display_name: 'Machine - Product Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_product',
          display_name: 'Machine - Product Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_source',
          display_name: 'Machine - Source View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_source',
          display_name: 'Machine - Source Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_source',
          display_name: 'Machine - Source Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_source',
          display_name: 'Machine - Source Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_transport',
          display_name: 'Machine - Transport View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_transport',
          display_name: 'Machine - Transport Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_transport',
          display_name: 'Machine - Transport Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_transport',
          display_name: 'Machine - Transport Delete',
          granted: true,
          description: '',
        },
        {
          codename: 'view_machine_work_order',
          display_name: 'Machine - Work Order View',
          granted: true,
          description: '',
        },
        {
          codename: 'create_machine_work_order',
          display_name: 'Machine - Work Order Create',
          granted: true,
          description: '',
        },
        {
          codename: 'update_machine_work_order',
          display_name: 'Machine - Work Order Modify',
          granted: true,
          description: '',
        },
        {
          codename: 'delete_machine_work_order',
          display_name: 'Machine - Work Order Delete',
          granted: true,
          description: '',
        },
      ],
    },
  },
  '9': {
    id: '9',
    name: 'Reporting',
    description: '',
    company_name: 'KSi',
    admin_role: false,
    default_group: 'true',
    status: 'Active',
    permissions: {
      analytics: [
        {
          codename: 'view_real_time_activity',
          display_name: 'Analytics - Real Time Activity',
          granted: true,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Analytics - Transactions',
          granted: true,
        },
        {
          codename: 'view_analytics_products',
          display_name: 'Analytics - Products',
          granted: true,
        },
      ],
      account: [
        {
          codename: 'grant_ksi_company_access',
          display_name: 'Account - Grant KSi support temporary access to company',
          granted: false,
          description: '',
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Company Full',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines View',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Machines Modify',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Users',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Integrations',
          granted: false,
        },
        {
          codename: 'view_analytics_transactions',
          display_name: 'Account - Notifications',
          granted: false,
        },
      ],
      machine: [
        {
          codename: 'view_machine_customer',
          display_name: 'Machine - Customer View',
          granted: false,
          description: '',
        },
        {
          codename: 'create_machine_customer',
          display_name: 'Machine - Customer Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_customer',
          display_name: 'Machine - Customer Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_customer',
          display_name: 'Machine - Customer Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_product',
          display_name: 'Machine - Product View',
          granted: false,
          description: '',
        },
        {
          codename: 'create_machine_product',
          display_name: 'Machine - Product Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_product',
          display_name: 'Machine - Product Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_product',
          display_name: 'Machine - Product Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_source',
          display_name: 'Machine - Source View',
          granted: false,
          description: '',
        },
        {
          codename: 'create_machine_source',
          display_name: 'Machine - Source Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_source',
          display_name: 'Machine - Source Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_source',
          display_name: 'Machine - Source Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_transport',
          display_name: 'Machine - Transport View',
          granted: false,
          description: '',
        },
        {
          codename: 'create_machine_transport',
          display_name: 'Machine - Transport Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_transport',
          display_name: 'Machine - Transport Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_transport',
          display_name: 'Machine - Transport Delete',
          granted: false,
          description: '',
        },
        {
          codename: 'view_machine_work_order',
          display_name: 'Machine - Work Order View',
          granted: false,
          description: '',
        },
        {
          codename: 'create_machine_work_order',
          display_name: 'Machine - Work Order Create',
          granted: false,
          description: '',
        },
        {
          codename: 'update_machine_work_order',
          display_name: 'Machine - Work Order Modify',
          granted: false,
          description: '',
        },
        {
          codename: 'delete_machine_work_order',
          display_name: 'Machine - Work Order Delete',
          granted: false,
          description: '',
        },
      ],
    },
  },
};

export default permissions;
