import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import IntegrationForm from './IntegrationForm';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { TreatmentProductIntegrationsAutoTreatContext } from '../contexts/TreatmentProductIntegrationsAutoTreatContext';
import { integrationColumns } from '../constants/columns';
import { Machine } from '../../../modules/machinesAdminManagement/model';

interface IntegrationsListProps {
  activeMachine: Machine;
  treatmentProduct: any;
}

const IntegrationsList: React.FC<IntegrationsListProps> = (props) => {
  const { activeMachine, treatmentProduct } = props;

  const { treatmentProductIntegrations, treatmentProductIntegrationsLoading, treatmentProductIntegrationsSubmitting } =
    React.useContext(TreatmentProductIntegrationsAutoTreatContext);
  const [showIntegrationEditForm, setShowIntegrationForm] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] = React.useState({});

  const handleAddIntegration = () => {
    setShowIntegrationForm(true);
    setSelectedIntegration({});
  };

  const handleEditIntegration = (integration) => {
    if (sessionStorage.getItem('manageIntegrationRecords') === 'true' && activeMachine.manage_integration_records) {
      setShowIntegrationForm(true);
      setSelectedIntegration(integration);
    }
  };

  return showIntegrationEditForm ? (
    <IntegrationForm
      treatmentProductId={treatmentProduct?.liquid_name_id}
      treatmentProductIntegration={selectedIntegration}
      setShowIntegrationForm={setShowIntegrationForm}
      machineSerial={activeMachine?.value}
      partnerChoices={activeMachine?.api_partners?.map((partner) => ({
        label: partner.global_api_partner_id,
        value: partner.global_api_partner_id,
      }))}
    />
  ) : (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Treatment Product Integrations
                  </Typography>
                </Grid>
                {sessionStorage.getItem('manageIntegrationRecords') === 'true' &&
                  activeMachine.manage_integration_records && (
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={handleAddIntegration}>
                        Add Integration
                      </Button>
                    </Grid>
                  )}
              </Grid>
              <br />
              <DataTable
                noHeader
                columns={integrationColumns}
                data={treatmentProductIntegrations}
                defaultSortField="TreatmentProductID"
                defaultSortAsc={false}
                striped
                onRowClicked={(row) => handleEditIntegration(row)}
                highlightOnHover
                pointerOnHover
                progressPending={treatmentProductIntegrationsLoading || treatmentProductIntegrationsSubmitting}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntegrationsList;
