import React from 'react';
import { TransportAutoBulkFormValues } from '../model';
import { TransportsAutoBulkContext } from './TransportsAutoBulkContext';

interface TransportsAutoBulkValidationsContextInterface {
  validateTransportsAutoBulkFields?: (formValues: TransportAutoBulkFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const TransportsAutoBulkValidationsContext = React.createContext<TransportsAutoBulkValidationsContextInterface>({});

const TransportsAutoBulkValidationsContextConsumer = TransportsAutoBulkValidationsContext.Consumer;
const TransportsAutoBulkValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { transports, activeTransportAutoBulk } = React.useContext(TransportsAutoBulkContext);

  const fetchValidCharactersError = (formValues: TransportAutoBulkFormValues): boolean => {
    const SpecialCharacterStringCheck =
      formValues?.transport_id +
      formValues?.transport_name +
      formValues?.transport_number +
      formValues?.transport_empty_wt +
      formValues?.transport_max_wt +
      formValues?.transport_company_id +
      formValues?.transport_company_name +
      formValues?.transport_comment +
      formValues?.rfid_id +
      formValues?.rfid_name +
      formValues?.active +
      formValues?.transport_api_partner +
      formValues?.flow_control_id +
      formValues?.flow_control_name;
    const specialChars = /'/;

    return specialChars.test(SpecialCharacterStringCheck);
  };

  const fetchTransportNameAutoBulkError = (transport_name: string) => {
    if (transport_name === '') {
      return 'cannot be left blank.';
    }

    if (transport_name?.length > 100) {
      return 'cannot be longer than 100 characters';
    }

    const matchedNames = transports.filter((transport) => transport.transport_name === transport_name);
    if (matchedNames.length > 0) {
      if (activeTransportAutoBulk.transport_name !== transport_name) {
        return 'cannot be a duplicate name';
      }

      return false;
    }

    return false;
  };

  const fetchTransportNumberAutoBulkError = (transport_number: string) => {
    if (transport_number === '') {
      return 'cannot be left blank.';
    }

    if (transport_number?.length > 100) {
      return 'cannot be longer than 100 characters';
    }

    const matchedNumbers = transports.filter((transport) => transport.transport_number === transport_number);
    if (matchedNumbers.length > 0) {
      if (activeTransportAutoBulk.transport_number !== transport_number) {
        return 'cannot be a duplicate number';
      }

      return false;
    }

    return false;
  };

  const fetchTransportEmptyWeightAutoBulkError = (transport_empty_weight: string) => {
    if (/[a-zA-Z]/.test(transport_empty_weight) && transport_empty_weight !== undefined) {
      return 'cannot contain letters';
    }

    return false;
  };

  const fetchTransportMaxWeightAutoBulkError = (transport_max_weight: string) => {
    if (/[a-zA-Z]/.test(transport_max_weight) && transport_max_weight !== undefined) {
      return 'cannot contain letters';
    }

    return false;
  };

  const validateTransportsAutoBulkFields = (formValues: TransportAutoBulkFormValues) => {
    const abTransportNameError = fetchTransportNameAutoBulkError(formValues?.transport_name);
    const abTransportNumberError = fetchTransportNumberAutoBulkError(formValues?.transport_number);
    const abTransportEmptyWeightError = fetchTransportEmptyWeightAutoBulkError(formValues?.transport_empty_wt);
    const abTransportMaxWeightError = fetchTransportMaxWeightAutoBulkError(formValues?.transport_max_wt);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (
      specialCharactersError ||
      abTransportNameError ||
      abTransportNumberError ||
      abTransportEmptyWeightError ||
      abTransportMaxWeightError
    ) {
      setFormErrors({
        TransportName: abTransportNameError,
        TransportNumber: abTransportNumberError,
        TransportEmptyWeight: abTransportEmptyWeightError,
        TransportMaxWeight: abTransportMaxWeightError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      TransportName: null,
      TransportNumber: null,
      TransportEmptyWeight: null,
      TransportMaxWeight: null,
      base: null,
    });

    return true;
  };

  return (
    <TransportsAutoBulkValidationsContext.Provider
      value={{
        validateTransportsAutoBulkFields,
        formErrors,
      }}
    >
      {children}
    </TransportsAutoBulkValidationsContext.Provider>
  );
};

export {
  TransportsAutoBulkValidationsContextProvider,
  TransportsAutoBulkValidationsContextConsumer,
  TransportsAutoBulkValidationsContext,
};
