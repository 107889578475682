import React from 'react';
import { SeedAutoTreatFormValues } from '../model';
import { SeedsAutoTreatContext } from './SeedsAutoTreatContext';
import seedRanges from '../constants/seedRanges';

interface SeedsAutoTreatValidationsContextInterface {
  validatecsvImportRow?: (formValues: SeedAutoTreatFormValues) => { error: boolean; errorMessage?: string };
  // validateSeedsAutoTreatFields?: (formValues: SeedAutoTreatFormValues) => boolean;
  validateSeedsFields?: (formValues: SeedAutoTreatFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const SeedsAutoTreatValidationsContext = React.createContext<SeedsAutoTreatValidationsContextInterface>({});

const SeedsAutoTreatValidationsContextConsumer = SeedsAutoTreatValidationsContext.Consumer;
const SeedsAutoTreatValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { seeds, activeSeedAutoTreat } = React.useContext(SeedsAutoTreatContext);

  const fetchValidCharactersError = (formValues: SeedAutoTreatFormValues): boolean => {
    const SpecialCharacterStringCheck =
      formValues?.seed_variety_id +
      formValues?.variety +
      formValues?.seed_available +
      formValues?.seed_lot_data +
      formValues?.shipment_number +
      formValues?.seed_variety_desc +
      formValues?.seed_cnt_per_scale_uow +
      formValues?.manufacturer_list +
      formValues?.crop_id +
      formValues?.crop_name +
      formValues?.supplier_id +
      formValues?.company_name +
      formValues?.supplier_brand_id +
      formValues?.supplier_brand +
      formValues?.unit_size_wt +
      formValues?.unit_size_cnt +
      formValues?.seed_amount_type_id +
      formValues?.seed_amount_type +
      formValues?.seed_origin +
      formValues?.seed_germ +
      formValues?.seed_test_date +
      formValues?.seed_notes +
      formValues?.username +
      formValues?.active +
      formValues?.date_and_time;
    const specialChars = /'/;

    return specialChars.test(SpecialCharacterStringCheck);
  };

  const fetchSeedVarietyError = (variety: string) => {
    if (variety === '') {
      return 'cannot be left blank.';
    }

    if (variety?.length > 50) {
      return 'cannot be longer than 50 characters';
    }

    // TODO - For some reason this is crashing my app. Duane 1-12-23 1139
    const matchedNames = seeds.filter(
      (seed) => seed.variety.localeCompare(variety, undefined, { sensitivity: 'base' }) === 0,
    );
    if (matchedNames?.length > 0) {
      if (activeSeedAutoTreat?.variety !== variety) {
        return 'cannot be a duplicate name';
      }

      return false;
    }

    return false;
  };

  const fetchSeedVarietycsvError = (variety: string) => {
    if (variety === '') {
      return 'cannot be left blank.';
    }

    if (variety?.length > 50) {
      return 'cannot be longer than 50 characters';
    }

    return false;
  };

  const fetchSeedVarietyDescError = (seed_variety_desc: string) => {
    if (seed_variety_desc?.length > 250) {
      return 'cannot be longer than 250 characters';
    }

    return false;
  };

  const fetchSeedLotDataError = (seed_lot_data: string) => {
    if (seed_lot_data?.length > 50) {
      return 'cannot be longer than 50 characters';
    }
    return false;
  };

  const fetchSeedCountError = (seed_cnt_per_scale_uow: string, crop_id: string): boolean | string => {
    const seedCount = parseInt(seed_cnt_per_scale_uow, 10);
    const minimumSeedCount = seedRanges[crop_id]?.minSeedSizeImperial;
    const maximumSeedCount = seedRanges[crop_id]?.maxSeedSizeImperial;

    if (/[a-zA-Z]/.test(seed_cnt_per_scale_uow) && seed_cnt_per_scale_uow != null) {
      return 'cannot contain letters';
    }

    if (seedCount < minimumSeedCount || seedCount > maximumSeedCount) {
      return `must be between ${minimumSeedCount} and ${maximumSeedCount}.`;
    }

    return false;
  };

  const validateSeedsFields = (formValues: SeedAutoTreatFormValues) => {
    const specialCharactersError = fetchValidCharactersError(formValues);
    const seedVarietyError = fetchSeedVarietyError(formValues?.variety);
    const seedVarietyDescError = fetchSeedVarietyDescError(formValues?.seed_variety_desc);
    const seedLotDataError = fetchSeedLotDataError(formValues?.seed_lot_data);
    const seedCountError = fetchSeedCountError(formValues?.seed_cnt_per_scale_uow, formValues?.crop_id);

    if (specialCharactersError || seedVarietyError || seedVarietyDescError || seedLotDataError || seedCountError) {
      setFormErrors({
        base: specialCharactersError,
        seed_variety: seedVarietyError,
        seed_variety_desc: seedVarietyDescError,
        seed_lot_data: seedLotDataError,
        seed_cnt_per_scale_uow: seedCountError,
      });

      return false;
    }

    setFormErrors({
      base: null,
      seed_variety: null,
      seed_variety_desc: null,
      seed_lot_data: null,
      seed_cnt_per_scale_uow: null,
    });

    return true;
  };

  const validatecsvImportRow = (formValues: SeedAutoTreatFormValues): { error: boolean; errorMessage?: string } => {
    const specialCharactersError = fetchValidCharactersError(formValues);
    const seedDescriptionError = fetchSeedVarietyDescError(formValues?.seed_variety_desc);
    const seedVarietyError = fetchSeedVarietycsvError(formValues?.variety);
    const seedLotDataError = fetchSeedLotDataError(formValues?.seed_lot_data);
    const seedCountError = fetchSeedCountError(formValues?.seed_cnt_per_scale_uow, formValues?.crop_id);

    const errors = [
      specialCharactersError,
      seedVarietyError,
      seedDescriptionError,
      seedLotDataError,
      seedCountError,
    ].filter(Boolean);

    if (errors.length > 0) {
      return {
        error: true,
        errorMessage: errors.join(', '),
      };
    }

    return { error: false };
  };

  return (
    <SeedsAutoTreatValidationsContext.Provider
      value={{
        validatecsvImportRow,
        // validateSeedsAutoTreatFields: validateSeedsFields,
        validateSeedsFields,
        formErrors,
      }}
    >
      {children}
    </SeedsAutoTreatValidationsContext.Provider>
  );
};

export {
  SeedsAutoTreatValidationsContextProvider,
  SeedsAutoTreatValidationsContextConsumer,
  SeedsAutoTreatValidationsContext,
};
