import React, { FunctionComponent } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface CustomMaterialMenuProps {
  size: 'small' | 'medium';
  children?: React.ReactElement[] | React.ReactElement;
  row?: any;
}

const CustomMaterialMenu: FunctionComponent<CustomMaterialMenuProps> = (props: CustomMaterialMenuProps) => {
  const { children } = props;

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} size="medium">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
};

export default CustomMaterialMenu;
