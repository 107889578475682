import React from 'react';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackBarContext } from '../contexts/SnackBarContext';

const AgconnexSnackbar: React.FC = () => {
  const { snackBarOpen, setSnackBarOpen, snackBarMessage, setSnackBarMessage, snackbarAlertSeverity } =
    React.useContext(SnackBarContext);

  // can be used if we want to have clickable text in the snackbar. see this url for a current example: http://localhost:3000/account_management/company_machines/8-8038-8888

  // const action = (
  //   <>
  //     <Button style={{ color: '#ccc' }} onClick={() => console.log('undo button clicked')}>
  //       UNDO CHANGES
  //     </Button>
  //     <IconButton size="small" aria-label="close" color="inherit" onClick={() => console.log('close button clicked')}>
  //       <CloseIcon fontSize="small" />
  //     </IconButton>
  //   </>
  // );

  return snackBarOpen ? (
    <>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => {
          setSnackBarOpen(false);
          setSnackBarMessage('');
        }}
        // message={snackBarMessage}
        // action={action}
      >
        <Alert severity={snackbarAlertSeverity}>{snackBarMessage}</Alert>
      </Snackbar>
    </>
  ) : (
    <></>
  );
};

export default AgconnexSnackbar;
