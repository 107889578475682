import React from 'react';
import { Grid } from '@mui/material';
import MonthlyGraph from './MonthlyGraph';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';
import YearlyGraph from './YearlyGraph';

const OrdersTotalCharts: React.FunctionComponent = () => {
  const {
    ordersByMonthCharts,
    fetchOrdersByMonthCharts,
    ordersByMonthChartsLoading,
    ordersByYearCharts,
    ordersByYearChartsLoading,
    selectedYear,
    setSelectedYear,
    fetchOrdersByYearCharts,
    showMonthly,
    setShowMonthly,
  } = React.useContext(AdminAnalyticsContext);

  React.useEffect(() => {
    fetchOrdersByYearCharts();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <YearlyGraph
            title="Total Orders Per Year"
            labels={ordersByYearCharts.years}
            values={ordersByYearCharts.total_order_count}
            loading={ordersByYearChartsLoading}
            fetchFunction={(year) => {
              fetchOrdersByMonthCharts(year);
              setShowMonthly(true);
            }}
            yearSetter={(year) => {
              setSelectedYear(year);
            }}
            // state={null}
            // state={"testing 123"}
          />
        </Grid>
      </Grid>
      {showMonthly && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <br />
            <MonthlyGraph
              title="Total Orders Per Month"
              labels={ordersByMonthCharts.month}
              values={ordersByMonthCharts.total_order_count}
              loading={ordersByMonthChartsLoading}
              year={selectedYear}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OrdersTotalCharts;
