/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { LiquidSourcesAutoTreatContext } from '../contexts/LiquidSourcesAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import LiquidSourcesAutoTreatList from './LiquidSourcesAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const LiquidSourcesAutoTreatPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchLiquidSourcesAutoTreat, detailPage, activeMachine } = React.useContext(LiquidSourcesAutoTreatContext);
  const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchLiquidSourcesAutoTreat]);

  const autoTreatLiquidSourcePage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          listPage: <LiquidSourcesAutoTreatList />,
        }[autoTreatLiquidSourcePage()]
      }
    </>
  );
};

export default LiquidSourcesAutoTreatPage;
