export const stripNonAlphanumeric = (str: string): string => {
  return str ? str.replace(/['\u00a0\xa0\ufffd]/g, '') : '';
};

// Function to sanitize inputs
export const sanitizeInputs = (rows: any[]): any => {
  return rows.map((row) => {
    const newRow = { ...row };
    Object.keys(newRow).forEach((key) => {
      if (typeof newRow[key] === 'string') {
        newRow[key] = stripNonAlphanumeric(newRow[key]);
      }
    });
    return newRow;
  });
};
