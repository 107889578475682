import React from 'react';
import { Grid, Typography } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { nestedColumns } from '../constants/columns';

interface RecipeItemsListProps {
  recipeItems: any;
}

const RecipeItemsList: React.FC<RecipeItemsListProps> = (props) => {
  const { recipeItems } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          Recipe Items
        </Typography>
        <hr />
        <DataTable
          noHeader
          columns={nestedColumns}
          data={recipeItems}
          defaultSortField="liquid_recipe_id"
          defaultSortAsc={false}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50]}
        />
      </Grid>
    </Grid>
  );
};

export default RecipeItemsList;
