import { format, parseISO } from 'date-fns';
import { Checkbox } from '@mui/material';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

export const testMachineRequestColumns = {};

const machineRequestColumns: IDataTableColumn<any>[] = [
  {
    name: 'Machine Name',
    selector: 'machine_name_with_serial',
    sortable: false,
  },
  {
    name: 'Request Type',
    selector: 'machine_request_type',
    sortable: false,
    grow: 0.8,
  },
  {
    name: 'Request Category',
    selector: 'machine_request_category',
    sortable: false,
  },
  {
    name: 'Record Count',
    selector: 'record_count',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.record_count) || 0;
      const valB = parseFloat(b.record_count) || 0;
      return valA - valB;
    },
    grow: 0.5,
    style: { maxWidth: '95px !important' },
  },
  {
    name: 'Message',
    selector: 'message',
    sortable: false,
  },
  {
    name: `Date Start (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_time_start',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';

      if (row.date_time_start) {
        try {
          const date = parseISO(row.date_time_start);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: `Date Complete (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_time_completed',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';

      if (row.date_time_completed) {
        try {
          const date = parseISO(row.date_time_completed);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Completed',
    selector: 'event_completed',
    sortable: true,
    cell: ({ event_completed }): React.ReactNode => <Checkbox checked={event_completed} />,
    grow: 0.4,
  },
  {
    name: 'User',
    selector: 'user_name',
    sortable: false,
    right: true,
    cell: (row) => <div style={{ textAlign: 'right' }}>{row.user_name}</div>,
  },
];

export default machineRequestColumns;
