import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface ProductIntegrationsAutoBulkContextInterface {
  fetchProductIntegrationsAutoBulk?: (
    machineSerial: string,
    productId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  createProductIntegrationAutoBulk?: (
    machineSerial: string,
    productId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  updateProductIntegrationAutoBulk?: (
    machineSerial: string,
    productId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  deleteProductIntegrationAutoBulk?: (
    machineSerial: string,
    productId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  productIntegrations?: any[];
  productIntegrationsLoading?: boolean;

  setProductIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  productIntegrationsSubmitting?: boolean;
}

const ProductIntegrationsAutoBulkContext = React.createContext<ProductIntegrationsAutoBulkContextInterface>({});

const ProductIntegrationsAutoBulkContextConsumer = ProductIntegrationsAutoBulkContext.Consumer;
const ProductIntegrationsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [productIntegrations, setProductIntegrations] = React.useState([]);
  const [productIntegrationsLoading, setProductIntegrationsLoading] = React.useState(true);
  const [productIntegrationsSubmitting, setProductIntegrationsSubmitting] = React.useState(false);

  const fetchProductIntegrationsAutoBulk = async (machineSerial: string, productId: string, onSuccess?: () => void) => {
    setProductIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/products_integration/?serial-number=${machineSerial}&product_id=${productId}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products_integration/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setProductIntegrationsLoading(false);
              setProductIntegrations(response.data.results);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setProductIntegrationsLoading(false);
                setProductIntegrations([]);
                window.clearInterval(fetchTimer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createProductIntegrationAutoBulk = async (
    machineSerial: string,
    productId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => {
    setProductIntegrationsLoading(true);
    setProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autobulk/onprem/products_integration/?serial-number=${machineSerial}&product_id=${productId}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products_integration/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchProductIntegrationsAutoBulk(machineSerial, productId);
              setProductIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setProductIntegrationsLoading(false);
                setProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateProductIntegrationAutoBulk = async (
    machineSerial: string,
    productId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => {
    setProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autobulk/onprem/products_integration/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products_integration/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchProductIntegrationsAutoBulk(machineSerial, productId);
              setProductIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteProductIntegrationAutoBulk = async (
    machineSerial: string,
    productId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => {
    setProductIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autobulk/onprem/products_integration/${integrationId}/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products_integration/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchProductIntegrationsAutoBulk(machineSerial, productId);
              showMachineManagementSnackBar(response.data.results[0]);
              setProductIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setProductIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <ProductIntegrationsAutoBulkContext.Provider
      value={{
        fetchProductIntegrationsAutoBulk,
        createProductIntegrationAutoBulk,
        updateProductIntegrationAutoBulk,
        deleteProductIntegrationAutoBulk,
        productIntegrations,
        productIntegrationsLoading,

        setProductIntegrationsSubmitting,
        productIntegrationsSubmitting,
      }}
    >
      {children}
    </ProductIntegrationsAutoBulkContext.Provider>
  );
};

export {
  ProductIntegrationsAutoBulkContextProvider,
  ProductIntegrationsAutoBulkContextConsumer,
  ProductIntegrationsAutoBulkContext,
};
