import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Paper, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface MachineConnectionProps {
  machineStats: {
    online_machines: number;
    offline_machines: number;
    total_machine_count: number;
  };
  loading?: boolean;
}

const MachineConnectionState: React.FC<MachineConnectionProps> = ({ machineStats, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  const onlinePercentage = (machineStats.online_machines / machineStats.total_machine_count) * 100;
  const offlinePercentage = 100 - onlinePercentage;

  const connectionData = {
    labels: ['Online', 'Offline'],
    datasets: [
      {
        data: [onlinePercentage, offlinePercentage],
        backgroundColor: ['#4682B4', '#9EA79E'],
      },
    ],
  };

  const generateLabels = (chart: any) => {
    const { data } = chart;
    if (data.labels.length && data.datasets.length) {
      return data.labels.map((label, i) => {
        return {
          text: `${label}: ${Math.round(data.datasets[0].data[i])}%`,
          fillStyle: data.datasets[0].backgroundColor[i],
        };
      });
    }
    return [];
  };

  const chartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${Math.round(currentValue)}%`;
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          generateLabels,
        },
      },
    },
  };

  return (
    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center' }}>
      <Typography variant="h6">Machine Connection State</Typography>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ marginRight: '10px' }}>
          <ArrowUpwardIcon fontSize="inherit" style={{ verticalAlign: 'middle', color: '#4682B4' }} />
          {`${machineStats.online_machines || 0} Online`}
        </Typography>
        <Typography style={{ marginLeft: '10px' }}>
          <ArrowDownwardIcon fontSize="inherit" style={{ verticalAlign: 'middle', color: '#9EA79E' }} />
          {`${machineStats.offline_machines || 0} Offline`}
        </Typography>
      </div>
      <Pie data={connectionData} options={chartOptions} />
    </Paper>
  );
};

export default MachineConnectionState;
