import React, { useMemo } from 'react';
import { useDrag } from '@use-gesture/react';

import { FieldAssignmentMap } from '../../../parser';
import { Column } from '../ColumnPreview';
import { DragState } from '../ColumnDragState';
import { ColumnDragCard } from '../ColumnDragCard';
import { IconButton } from '../../IconButton';

import './ColumnDragSourceArea.scss';
import { useLocale } from '../../../locale/LocaleContext';

// @todo readable status text if not mouse-drag
const SourceBox: React.FC<{
  column: Column;
  fieldAssignments: FieldAssignmentMap;
  dragState: DragState | null;
  eventBinder: (column: Column) => ReturnType<typeof useDrag>;
  onSelect: (column: Column) => void;
  onUnassign: (column: Column) => void;
}> = ({ column, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
  const isDragged = dragState ? column === dragState.column : false;

  const isAssigned = useMemo(
    () => Object.keys(fieldAssignments).some((fieldName) => fieldAssignments[fieldName] === column.index),
    [fieldAssignments, column],
  );

  const eventHandlers = useMemo(() => eventBinder(column), [eventBinder, column]);

  const l10n = useLocale('fieldsStep');

  return (
    <div className="CSVImporter_ColumnDragSourceArea__box" style={{ width: '100%' }}>
      {/* <section className="CSVImporter_ColumnDragSourceArea__box"> */}
      <div {...(isAssigned ? {} : eventHandlers)} style={{ touchAction: 'none', textAlign: 'center' }}>
        <ColumnDragCard
          column={column}
          textStyle={{ paddingTop: 2, textAlign: 'center', fontSize: 13, fontStyle: 'initial', color: 'black' }}
          containerStyle={{
            height: 30,
            width: '90%',
            display: 'inline-block',
            background: isAssigned ? 'lightgray' : '#fff',
            border: isAssigned ? 'none' : '1px solid lightgray',
            boxShadow: isAssigned ? 'none' : '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
            marginRight: '15%',
          }}
          isAssigned={isAssigned}
          isShadow={isDragged || isAssigned}
          isDraggable={!dragState && !isDragged && !isAssigned}
        />
      </div>

      {/* tab order after column contents */}
      <div className="CSVImporter_ColumnDragSourceArea__boxAction">
        {isAssigned ? (
          <IconButton
            key="clear" // key-prop helps clear focus on click
            label={l10n.clearAssignmentTooltip}
            small
            type="replay"
            onClick={() => {
              onUnassign(column);
            }}
          />
        ) : (
          <IconButton
            key="dragSelect" // key-prop helps clear focus on click
            focusOnly
            label={dragState && dragState.column === column ? l10n.unselectColumnTooltip : l10n.selectColumnTooltip}
            small
            type="back"
            onClick={() => {
              onSelect(column);
            }}
          />
        )}
      </div>
      {/* </section> */}
    </div>
  );
};

export default SourceBox;
