const sourceFilters = [
  {
    label: 'Source',
    pluralLabel: 'Sources',
    selector: 'sources',
    width: 2,
    type: 'select',
    optionSelector: 'sources_list',
  },
  {
    label: 'Product',
    pluralLabel: 'Products',
    selector: 'products',
    width: 2,
    type: 'select',
    optionSelector: 'product_name_list',
  },
  {
    label: 'Product Type',
    pluralLabel: 'Product Types',
    selector: 'product_types',
    width: 2,
    type: 'select',
    optionSelector: 'product_type_list',
  },
];

export default sourceFilters;
