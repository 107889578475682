import React from 'react';
import { Box, MenuItem, Typography, ListItemIcon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import { MachinesContext } from '../contexts/MachinesContext';

interface MachineRowMenuProps {
  machine: any;
  isAdmin: any;
}

const MachineRowMenu: React.FC<MachineRowMenuProps> = (props: MachineRowMenuProps) => {
  const { machine, isAdmin } = props;
  const { pingMachine } = React.useContext(MachinesContext);

  const handlePing = () => {
    pingMachine(machine.serial_number);
  };

  return (
    <Box>
      <CustomMaterialMenu size="small">
        <MenuItem>
          <ListItemIcon style={{ minWidth: 24 }}>
            <EditIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          {isAdmin && (
            <a
              style={{ textDecoration: 'none' }}
              href={`/admin/machine_management/${machine.machine_id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="inherit">Edit Machine</Typography>
            </a>
          )}
          {!isAdmin && (
            <a
              style={{ textDecoration: 'none' }}
              href={`/account_management/my_machines/${machine.serial_number}`}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="inherit">Edit Machine</Typography>
            </a>
          )}
        </MenuItem>
        <MenuItem onClick={() => handlePing()}>
          <ListItemIcon style={{ minWidth: 24 }}>
            <PodcastsIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Ping Machine</Typography>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default MachineRowMenu;
