/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import RealTimeActivityFilterBar from '../components/RealTimeActivityFilterBar';
import { columns, nestedColumns } from '../../transactions/constants/transactionColumns';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import { AutoTreatRealTimeActivityContext } from '../contexts/AutoTreatRealTimeActivityContext';
import { AUTH_ZERO_AUDIENCE } from '../../../modules/utils/env';
import { pickupTransactionApiData } from '../constants/constants';
import ConfirmCancelModal from '../../../modules/shared/modal/ConfirmCancelModal';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    const id = setInterval(() => {
      (savedCallback as any).current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const AutoTreatRealTimeActivity: React.FunctionComponent = () => {
  const {
    fetchTransactions,
    transactions,
    transactionsLoading,
    displayApiResponseModal,
    setDisplayApiResponseModal,
    fetchOrders,
    orders,
    ordersLoading,
    fetchTransactionResponses,
    transactionResponses,
  } = React.useContext(AutoTreatRealTimeActivityContext);
  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [lastUpdatedTimer, setLastUpdatedTimer] = React.useState<number>(0);
  const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions as any);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState<string>(dateFormatter.format(new Date()));

  useInterval(() => {
    if (lastUpdatedTimer > 60) {
      setLastUpdatedAt(dateFormatter.format(new Date()));
      setLastUpdatedTimer(lastUpdatedTimer + 1);

      const getChoices = async () => {
        setDataLoaded(true);

        fetchTransactionResponses('8-8036-8888');
        fetchOrders('8-8036-8888');
        fetchTransactions();
        setLastUpdatedTimer(0);
      };

      getChoices();
    } else {
      setLastUpdatedTimer(lastUpdatedTimer + 1);
    }
  }, 1000);

  React.useEffect(() => {
    // fetchTransactionResponses('8-8036-8888');
    // fetchOrders('8-8036-8888');
    fetchTransactions();
    fetchAutoTreatMachines();
  }, []);

  const handleFilter = (filterHeaders: string) => {
    fetchTransactions();
  };

  const ExpandedComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ border: '1px solid #3f51b5', margin: 15 }}>
          <DataTable
            columns={nestedColumns}
            data={row.data.treatment_products[0]?.liquid_name ? Array.from(new Set(row.data.treatment_products)) : []}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            noDataComponent={<div style={{ padding: '24px' }}>This transaction has no treatment products.</div>}
          />
        </Grid>
      </Grid>
    );
  };

  const ApiResponseComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ margin: '15px 0px' }}>
          <p style={{ padding: '0 8px 0 8px', fontWeight: 700 }}>Response:</p>
          <p style={{ padding: '0 8px 0 8px' }}>{row.data.Response}</p>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Real Time Activity
          </Typography>
        </Grid>
      </Grid>
      <br />

      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <RealTimeActivityFilterBar machineChoices={autoTreatMachines} applyFilter={handleFilter} />
          <br />
          {/* <p style={{ color: 'red' }}>{filterError}</p> */}
        </Grid>
      </Grid>
      <br />

      <Card>
        <CardContent>
          <div>
            <span style={{ fontSize: '22px', padding: '0 8px 0 8px' }}>Recent Transactions</span> Last Updated:{' '}
            {lastUpdatedAt.toString()}
          </div>
          <DataTable
            noHeader
            columns={columns}
            data={transactions}
            defaultSortField="order_start_date_and_time_utc"
            defaultSortAsc={false}
            progressPending={transactionsLoading}
            expandableRows
            expandableRowsComponent={<ExpandedComponent row />}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 25]}
          />
        </CardContent>
      </Card>
      <br />

      {/* <Card>
        <CardContent>
          <div>
            <span style={{ fontSize: '22px', padding: '0 8px 0 8px' }}>Orders In Progress</span> Last Updated:{' '}
            {lastUpdatedAt.toString()}
          </div>
          <DataTable
            noHeader
            columns={orderColumns}
            data={orders}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            progressPending={ordersLoading}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5]}
          />
        </CardContent>
        <br />
      </Card> */}
    </>
  );
};

export default AutoTreatRealTimeActivity;
