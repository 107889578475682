import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Company, CompanyAccessFormValues } from '../model';

interface CompanyAccessContextInterface {
  createBulkCompanyAssignment?: (formValues: CompanyAccessFormValues) => Promise<void>;
  companySubmitting?: boolean;
}

const CompanyAccessContext = React.createContext<CompanyAccessContextInterface>({});

const CompanyAccessContextConsumer = CompanyAccessContext.Consumer;
const CompanyAccessContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [companySubmitting, setCompanySubmitting] = React.useState(false);

  const createBulkCompanyAssignment = async (formValues: CompanyAccessFormValues) => {
    setCompanySubmitting(true);

    axios
      .post<any, any>(
        `api/admin/bulk_companies/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        history.push(`/admin/company_management/${formValues?.company_id}`);
        setCompanySubmitting(false);
      });
  };

  return (
    <CompanyAccessContext.Provider
      value={{
        createBulkCompanyAssignment,
        companySubmitting,
      }}
    >
      {children}
    </CompanyAccessContext.Provider>
  );
};

export { CompanyAccessContextProvider, CompanyAccessContextConsumer, CompanyAccessContext };
