import React from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, FormHelperText, ListSubheader, Select, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import HelpIcon from '@mui/icons-material/Help';
import { SelectOption } from '../model';

interface SelectInputProps {
  label: string;
  value: string;
  loading?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  tooltip?: string;
  required?: boolean;
  styleOverrides?: string;
  errorMessage?: string;
  choices?: SelectOption[];
  groupedChoices?: Record<string, SelectOption[]>;
  defaultChoice?: SelectOption;
  customPadding?: string;
}

const useStyles = makeStyles(() => ({
  textField: {
    minHeight: '30px',
    minWidth: '200px',
    width: '100%',
    padding: 0,
  },
  helperText: {
    fontSize: '12px',
  },
}));

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    label,
    value = '',
    disabled = false,
    required = false,
    loading = false,
    onChange,
    tooltip,
    styleOverrides,
    errorMessage,
    choices = [],
    groupedChoices,
    defaultChoice,
    customPadding = '18px 12px 12px',
  } = props;

  const classes = useStyles();
  const randomId = Math.floor(Math.random() * 16777215).toString(16);

  const labelToName = (label: string): string => {
    return label
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_')
      .replace(/(^_|_$)/g, '');
  };

  const handleChange = (event: any): void => {
    onChange(event.target.value);
  };

  const createMenuItem = (choice: SelectOption) =>
    choice ? (
      <MenuItem style={{ lineHeight: '12px' }} value={choice.value} key={choice.value}>
        <span style={{ fontSize: '1.4rem', padding: '4px 0', ...choice.styles }}>{choice.label}</span>
      </MenuItem>
    ) : null;

  const defaultMenuItem = createMenuItem(defaultChoice);
  const menuItemChoices = choices.map((choice) => createMenuItem(choice));

  return (
    <FormControl
      variant="outlined"
      error={errorMessage?.length > 0}
      className={`${styleOverrides} ${classes.textField}`}
    >
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text" htmlFor="formInput">
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <label className="Polaris-Label__Text" htmlFor={randomId} style={{ marginBottom: -5 }}>
                  {label}
                  {required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </div>
            </div>
            {tooltip && (
              <Tooltip title={<p style={{ fontSize: '1.4rem', padding: 5 }}>{tooltip}</p>}>
                <HelpIcon style={{ marginLeft: 5 }} />
              </Tooltip>
            )}
          </label>
        </div>
      </div>
      {groupedChoices ? (
        <Select
          value={value}
          name={labelToName(label)}
          onChange={handleChange}
          disabled={disabled}
          SelectDisplayProps={{
            style: { fontSize: '1.2em', padding: '3.5px 30px 3.5px 10px', position: 'relative', lineHeight: '1.2em' },
          }}
        >
          {defaultMenuItem}
          {Object.keys(groupedChoices).map(
            (key) =>
              groupedChoices[key]?.length > 0 && [
                <ListSubheader key={key} style={{ fontSize: 15, lineHeight: '32px', opacity: '0.8' }}>
                  {key}
                </ListSubheader>,
                groupedChoices[key].map((choice) => createMenuItem(choice)),
              ],
          )}
        </Select>
      ) : (
        <Select
          value={value}
          name={labelToName(label)}
          onChange={handleChange}
          disabled={disabled}
          SelectDisplayProps={{
            style: { fontSize: '1.2em', padding: '3.5px 30px 3.5px 10px', position: 'relative', lineHeight: '1.2em' },
          }}
        >
          {defaultMenuItem}
          {menuItemChoices}
        </Select>
      )}
      {errorMessage && <FormHelperText className={classes.helperText}>{`${errorMessage}`}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
