/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, CircularProgress, Box } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import MachineRequestRecordsFilterBar from '../components/MachineRequestRecordsFilterBar';
import { MachineRequestsContext } from '../contexts/MachineRequestsContext';
import machineRequestRecordColumns from '../constants/machineRequestRecordColumns';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import MachineRequestDetails from '../components/MachineRequestDetails';
import { AuthContext } from '../../auth/contexts/AuthContext';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    const id = setInterval(() => {
      (savedCallback as any).current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const MachineRequestRecordsPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineRequestId } = useParams<Record<string, string>>();
  const {
    fetchMachineRequest,
    machineRequest,
    fetchMachineRequestRecords,
    machineRequestRecords,
    errorMessage,
    machineRequestRecordsLoading,
  } = React.useContext(MachineRequestsContext);

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [tableFilters, setTableFilters] = React.useState({ recordInfo: '', recordStatus: 'e', responseMessage: '' });
  const [lastUpdatedTimer, setLastUpdatedTimer] = React.useState<number>(0);

  React.useEffect(() => {
    fetchMachineRequestRecords(machineRequestId);
    fetchMachineRequest(machineRequestId);
  }, []);

  useInterval(() => {
    if (
      machineRequestRecords?.filter((request_record) => request_record.response_status !== 'Pending').length !==
        machineRequest?.record_count &&
      lastUpdatedTimer > 3
    ) {
      fetchMachineRequest(machineRequestId);
      fetchMachineRequestRecords(machineRequestId, () => null);
      setLastUpdatedTimer(0);
    } else if (
      machineRequestRecords?.filter((request_record) => request_record.response_status !== 'Pending').length !==
      machineRequest?.record_count
    ) {
      setLastUpdatedTimer(lastUpdatedTimer + 1);
    }
  }, 1000);

  const handleViewMachineRequestRecord = (machine_request_id, machine_request_record_id) => {
    history.push(`/machine_requests/${machine_request_id}/machine_request_records/${machine_request_record_id}`);
  };

  const filterMachineRequestRecords = () => {
    if (tableFilters.recordInfo || tableFilters.recordStatus || tableFilters.responseMessage) {
      // const recordInfoFilteredMachineRequestRecords = machineRequestRecords?.filter((machineRequestRecord) =>
      //   machineRequestRecord.record_info?.toLowerCase().includes(tableFilters.recordInfo?.toLowerCase()),
      // );

      const responseMessageFilteredMachineRequestRecords = machineRequestRecords?.filter(
        // const responseMessageFilteredMachineRequestRecords = recordInfoFilteredMachineRequestRecords?.filter(
        (machineRequestRecord) =>
          machineRequestRecord.response_message?.toLowerCase().includes(tableFilters.responseMessage?.toLowerCase()),
      );

      const recordStatusFilteredMachineRequestRecords = responseMessageFilteredMachineRequestRecords?.filter(
        (machineRequestRecord) =>
          machineRequestRecord.response_status?.toLowerCase().includes(tableFilters.recordStatus?.toLowerCase()),
      );

      return recordStatusFilteredMachineRequestRecords;
    }

    return machineRequestRecords;
  };

  const links = [
    {
      href: '/machine_requests/',
      text: 'Machine Requests',
    },
    {
      href: `/machine_requests/${machineRequest?.machine_request_id}/machine_request_records`,
      text: `Machine Request Details`,
    },
  ];

  return machineRequestRecordsLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Machine Request...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <MachineRequestDetails />
      <br />
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h2" component="h2">
            Machine Request Records
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <MachineRequestRecordsFilterBar setFilterValues={setTableFilters} filterValues={tableFilters} />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            // TODO order by machine_request_record_id but don't show that column if possible
            columns={machineRequestRecordColumns}
            data={filterMachineRequestRecords()}
            defaultSortField="machine_request_record_id"
            defaultSortAsc
            // defaultSortAsc={false}
            progressPending={machineRequestRecordsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            pagination
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onRowClicked={(row) => {
              if (currentUserHasAdminPermission('ksi_support_admin')) {
                handleViewMachineRequestRecord(machineRequestId, row.machine_request_record_id);
              }
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default MachineRequestRecordsPage;
