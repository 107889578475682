/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import OrdersAutoTreatForm from '../components/OrdersAutoTreatForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const OrdersAutoTreatCreate: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchOrdersAutoTreat, setCreatingPage, activeMachine } = React.useContext(OrdersAutoTreatContext);
  // const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        // fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchOrdersAutoTreat]);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `Create`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => {
              setCreatingPage(false);
            }}
          >
            ← Return to Orders List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Order
      </Typography>
      <br />
      <OrdersAutoTreatForm
        order={{}}
        // machineSerial={activeMachine?.value}
        onSuccess={() => setCreatingPage(false)}
        onBack={() => setCreatingPage(false)}
      />
    </>
  );
};

export default OrdersAutoTreatCreate;
