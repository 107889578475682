import React from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { MachinesContext } from '../contexts/MachinesContext';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import { Machine } from '../model';
import MachineExecuteSqlForm from '../components/MachineExecuteSqlForm';
import AdminMachineFilterBar from '../components/AdminMachineFilterBar';

const AdminMachineExecuteSql: React.FC = () => {
  const history = useHistory();
  const { fetchAdminMachines, adminMachines, machinesLoading, fetchFilterOptions } = React.useContext(MachinesContext);

  const [filterValues, setFilterValues] = React.useState({ subscription_active: 'status__all' });
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>();
  const [machine, setMachine] = React.useState<any>();

  React.useEffect(() => {
    fetchAdminMachines('');
    fetchFilterOptions();
  }, []);

  const convertFilters = () => {
    return Object.entries(filterValues)
      .map(([key, value]) => (value !== 'status__all' ? `${key}=${value}&` : ''))
      .join('');
  };

  const handleRowClick = (machine) => {
    setMachine(machine);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Execute Machine SQL
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <AdminMachineFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={adminMachines}
            onRowClicked={(row: Machine) => handleRowClick(row)}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={machinesLoading}
          />
        </CardContent>
      </Card>
      <br />
      <Grid item>
        <MachineExecuteSqlForm machine={machine} showLoadingIndicator={false} />
      </Grid>
    </>
  );
};

export default AdminMachineExecuteSql;
