/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { LiquidSourcesAutoTreatContext } from '../contexts/LiquidSourcesAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import LiquidSourceAutoTreatFilterBar from '../components/LiquidSourcesAutoTreatFilterBar';

const LiquidSourcesAutoTreatList: React.FunctionComponent = () => {
  const { fetchLiquidSourcesAutoTreat, liquidSources, activeMachine } = React.useContext(LiquidSourcesAutoTreatContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    liquidSourceName: '',
    liquidSourceLocation: '',
    liquidSourceLiquidName: '',
  });

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoTreatMachines, autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchLiquidSourcesAutoTreat]);

  // TODO talk to duane about these live filters and the .toLowerCase issue with the live filters

  const filterLiquidSources = () => {
    if (tableFilters.liquidSourceName || tableFilters.liquidSourceLocation || tableFilters.liquidSourceLiquidName) {
      const nameFilteredLiquidSources = liquidSources.filter((liquidSource) =>
        liquidSource.liquid_source?.toLowerCase().includes(tableFilters.liquidSourceName?.toLowerCase()),
      );

      const locationFilteredLiquidSources = nameFilteredLiquidSources.filter((liquidSource) =>
        liquidSource.liquid_source_location?.toLowerCase().includes(tableFilters.liquidSourceLocation?.toLowerCase()),
      );

      const liquidNameFilteredLiquidSources = locationFilteredLiquidSources.filter((liquidSource) =>
        liquidSource.liquid_name?.toLowerCase().includes(tableFilters.liquidSourceLiquidName?.toLowerCase()),
      );

      return liquidNameFilteredLiquidSources;
    }

    return liquidSources;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Treatment Source Management (View Only)
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <LiquidSourceAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterLiquidSources()}
                defaultSortField="liquid_liquidSources_id"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default LiquidSourcesAutoTreatList;
