/* eslint-disable react-hooks/exhaustive-deps, no-alert */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Button, Typography, Checkbox, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import ColumnSelectModal from '../../shared/modal/ConfirmCancelModal';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Machine } from '../../machinesAdminManagement/model';

interface CloudSqlFormProps {
  machine: Machine;
  showLoadingIndicator?: boolean;
}

const CloudSqlForm: React.FC<CloudSqlFormProps> = (props) => {
  const { machine, showLoadingIndicator = true } = props;

  const { executeSql, sqlResponse, columnsList, clearSql, cancelSql, sqlSubmitting } =
    React.useContext(MachinesContext);

  const [machineSql, setMachineSQl] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [clearButtonDisabled, setClearButtonDisabled] = React.useState(true);
  const [columns, setColumns] = React.useState<IDataTableColumn<any>[]>([]);
  const [selectedColumns, setSelectedColumns] = React.useState<IDataTableColumn<any>[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([]);

  const buildColumns = (arr) => {
    const tempColumns = [];
    const tempCharCountArr = [];

    arr?.forEach((key) => {
      tempCharCountArr.push(key.length);
    });

    const charCount = tempCharCountArr.reduce((partialSum, a) => partialSum + a, 0);

    arr?.forEach((key) => {
      tempColumns.push({
        name: key,
        selector: key,
        sortable: true,
        minWidth: `${Number((key.length / (charCount / tempCharCountArr.length)).toFixed(2)) * 115}px`,
      });
    });
    setColumns(tempColumns);
  };

  React.useEffect(() => {
    if (selectedColumns[0] === undefined) {
      buildColumns(columnsList);
    } else {
      buildColumns(selectedColumns);
    }
  }, [columnsList]);

  const handleExecuteSql = () => {
    const machineQuery = machineSql.toUpperCase();
    const regex = /INSERT|UPDATE|DELETE/i;
    let executeQuery = false;

    if (regex.test(machineQuery)) {
      const confirmExecution = window.confirm(
        '**CAUTION**\nThis action may modify the data on this machine.\n\nClick OK to execute the query.',
      );
      executeQuery = confirmExecution;
    } else {
      executeQuery = true;
    }

    if (executeQuery) {
      executeSql(machine.serial_number, machineSql);
      setSelectedColumns([]);
      setChecked([]);
    }
  };

  const handleCancelSql = () => {
    cancelSql();
  };

  const handleColumnsChange = () => {
    buildColumns(selectedColumns);
    setModalOpen(false);
  };

  const handleCheckBoxes = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setSelectedColumns(updatedList);
  };

  const handleClearColumns = () => {
    setSelectedColumns([]);
    setChecked([]);
    buildColumns(columnsList);
  };

  return !machine?.serial_number ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      {showLoadingIndicator ? (
        <>
          <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
          <br />
          <CircularProgress />
        </>
      ) : (
        <h2 style={{ fontSize: '18px', marginTop: -200 }}>Please select a machine to begin SQL execution</h2>
      )}
    </Box>
  ) : (
    <Grid container>
      <ColumnSelectModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        hasFooterBar
        confirmLabel="Save Selections"
        onConfirm={handleColumnsChange}
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Columns Returned from Sql Query
          </Typography>
          <p style={{ marginTop: 7 }}>Select columns to show on table, in the order checked</p>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="Include Header"
                value={columnsList}
              >
                {columnsList?.map((key: any) => {
                  return (
                    <React.Fragment key={key}>
                      <FormControlLabel
                        checked={selectedColumns.includes(key)}
                        value={key}
                        control={<Checkbox />}
                        label={key}
                        onChange={handleCheckBoxes}
                      />
                    </React.Fragment>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </div>
      </ColumnSelectModal>
      <Grid item xs={12}>
        <Grid container spacing={3} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} md={12}>
            <Grid container rowSpacing={1}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} md={12}>
                  <SelectInput
                    choices={[
                      {
                        label: 'Traffic Cop Orders cache (-5 hours)',
                        value: `SELECT tco.traffic_cop_orders_id, tco.serial_number, m.name
                          	,global_api_partner_id, global_api_adapter_id, partner_record_id_1, partner_record_id_2, partner_record_id_3, order_type,processed
                          	,TO_CHAR(ksi_machine_last_updated  - interval '5 hours', 'yyyy-mm-dd hh12:mi:ss AM') As ksi_machine_last_updated_CST
                          	,TO_CHAR(partner_last_modified  - interval '5 hours', 'yyyy-mm-dd hh12:mi:ss AM') As partner_last_modified_CST
                          	,TO_CHAR(partner_last_recieved - interval '5 hours', 'yyyy-mm-dd hh12:mi:ss AM') As  partner_last_recieved_CST
                          	,global_api_adapter_id, partner_record_id_1, partner_record_id_2, partner_record_id_3, processed, order_obj,order_obj ->> 'KSiProcessed' AS order_obj_KSiProcessed
                          	,order_obj ->> 'KSiProcessed' AS order_obj_KSiProcessed,order_obj
                          FROM public.traffic_cop_orders tco
                          JOIN machine m on tco.serial_number = m.serial_number
                          --WHERE m.serial_number = ''
                          --WHERE global_api_partner_id = 'cfs_at'
                          --WHERE partner_record_id_1 LIKE '%132008488%'
                          Order By traffic_cop_orders_id desc
                          --Order By ksi_machine_last_updated desc --Update Date
                          LIMIT 100;`,
                      },
                      {
                        label: 'Transactions Send To Traffic Cop CST Time...(subtract 5 hours from time stamp)',
                        value: `SELECT transactions_sent_to_traffic_cop_id, tstc.serial_number, m.name, transaction_id, attempted_send_cnt,
                                  	TO_CHAR(created - interval '5 hours', 'yyyy-mm-dd hh12:mi:ss AM') as created_CST,
                                  	TO_CHAR(last_updated - interval '5 hours', 'yyyy-mm-dd hh12:mi:ss AM') as last_updated_CST,
                                  FROM public.transactions_sent_to_traffic_cop tstc
                                  LEFT JOIN machine m on tstc.serial_number = m.serial_number
                                  --WHERE m.serial_number = ''
                                  --WHERE transaction_id = ''
                                  ORDER BY transactions_sent_to_traffic_cop_id DESC
                                  LIMIT 100;`,
                      },
                    ]}
                    value={machineSql}
                    onChange={(value) => setMachineSQl(value)}
                    label="Saved SQL Query"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 13 }}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    rows={10}
                    multiline
                    value={machineSql}
                    onChange={(value) => setMachineSQl(value)}
                    label="Sql to Run"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 13 }}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{}}
                    onClick={handleExecuteSql}
                    disabled={sqlSubmitting}
                  >
                    Execute Sql
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" style={{}} onClick={handleExecuteSql} disabled={sqlSubmitting}>
                    Save Query
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 13, background: 'rgb(244, 79, 100)' }}
                    onClick={handleCancelSql}
                  >
                    Delete Saved Query
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 13 }}>
                <Grid item xs={12} md={12}>
                  <FormInput
                    rows={15}
                    multiline
                    value={JSON.stringify(sqlResponse, undefined, 4)}
                    label="Raw Response"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 25 }}>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Response Table
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" style={{ marginLeft: 15 }} onClick={handleClearColumns}>
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 15 }}
                    onClick={() => setModalOpen(true)}
                  >
                    Select Columns
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 25 }}>
                {sqlResponse ? (
                  <DataTable
                    noHeader
                    columns={columns}
                    data={sqlResponse}
                    defaultSortAsc={false}
                    striped
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                  />
                ) : (
                  <Grid container alignItems="center" justifyContent="center">
                    <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                      Enter a Sql query to view records.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CloudSqlForm;
