import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { nestedColumns } from '../constants/columns';

interface WorkOrdersAutoBulkItemsProps {
  machineSerial: any;
  workOrder: any;
}

const WorkOrdersAutoBulkItems: React.FC<WorkOrdersAutoBulkItemsProps> = (props) => {
  const { machineSerial, workOrder } = props;

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Work Order Items
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <DataTable
                noHeader
                columns={nestedColumns}
                data={workOrder.workOrderItems}
                defaultSortField="CustomerID"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                progressPending={false}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkOrdersAutoBulkItems;
