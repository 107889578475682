import React from 'react';
import { Button, Grid } from '@mui/material';
import { AdminAnalyticsContext } from '../contexts/AdminAnalyticsContext';
import SeedUnitsTotalCharts from './SeedUnitsTotalCharts';
import SeedUnitsAverageCharts from './SeedUnitsAverageCharts';

const SeedUnitsCharts: React.FunctionComponent = () => {
  const { fetchSeedUnitsByYearCharts, showAverage } = React.useContext(AdminAnalyticsContext);

  return (
    <>
      <Grid container spacing={3}>
        {showAverage === 'Total' ? (
          <Grid item xs={12} md={6}>
            <SeedUnitsTotalCharts />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <SeedUnitsAverageCharts />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SeedUnitsCharts;
