import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Grid, CardContent, Typography } from '@mui/material';
import InformationField from '../../shared/form/InformationField';

interface UserMachineInformationProps {
  machine: any;
  onViewMachine?: () => void;
}

const UserMachineInformation: React.FC<UserMachineInformationProps> = (props) => {
  const { machine, onViewMachine } = props;
  const history = useHistory();

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Machine Information (Machine Status: {machine?.connection_state})
            </Typography>
          </Grid>
          {onViewMachine && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={onViewMachine}>
                View Machine
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <InformationField label="Machine Name" value={machine.machine_name} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Serial Number" value={machine.serial_number} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Address" value={machine?.address} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Description" value={machine?.description} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Last Backup on File" value={machine?.last_backup_date} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Subscription Expiration Date" value={machine?.subscription_expiration_date} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserMachineInformation;
