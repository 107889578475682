import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, Grid, Stack, Switch, Typography, CircularProgress } from '@mui/material';

interface TransactionYearOverYearGraphProps {
  title?: string;
  labels?: string[];
  yoyValues?: string[];
  ytdValues?: string[];
  loading?: boolean;
}

const TransactionYearOverYearGraph: React.FC<TransactionYearOverYearGraphProps> = (props) => {
  const { title, labels = [], yoyValues = [], ytdValues = [], loading } = props;
  const [checked, setChecked] = React.useState(true);

  const barData = {
    labels,
    datasets: [
      {
        label: 'Units Delivered',
        data: checked ? yoyValues : ytdValues,
        backgroundColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        borderColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        borderWidth: 0,
      },
    ],
  };

  const blankData = {
    labels,
    datasets: [
      {
        label: 'Units Delivered',
        data: checked ? yoyValues : ytdValues,
        backgroundColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        borderColor: ['#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80', '#3d5a80'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    plugins: {
      legend: false,
    },
  };

  return (
    <Card style={{ padding: '0px 15px 15px' }}>
      <div style={{ marginLeft: -5, position: 'relative' }}>
        <Grid container>
          <Grid item xs={12}>
            <h6
              style={{
                margin: '15px 35px 7px',
                textAlign: 'left',
                fontSize: '16px',
                fontWeight: 400,
                minHeight: '45px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {title}
            </h6>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center" style={{ marginLeft: 35 }}>
              <Typography style={{ fontSize: '1.6rem' }}>YTD</Typography>
              <Switch checked={checked} onChange={(_event, value) => setChecked(value)} />
              <Typography style={{ fontSize: '1.6rem' }}>Jan - Dec</Typography>
            </Stack>
          </Grid>
        </Grid>
        {loading ? (
          <>
            <div style={{ position: 'absolute', top: '40%', left: '42%' }}>
              <CircularProgress />
              <p style={{ marginLeft: -20, marginTop: 8 }}>Loading data...</p>
            </div>
            <div style={{ opacity: 0.4 }}>
              <Bar height="160px" data={blankData} options={options as any} />
            </div>
          </>
        ) : (
          <Bar height="160px" data={barData} options={options as any} />
        )}
        <p style={{ textAlign: 'center' }}>Mass Delivered</p>
      </div>
    </Card>
  );
};

export default TransactionYearOverYearGraph;
