import React from 'react';
import { CustomerAutoTreatFormValues } from '../model';
import { CustomersAutoTreatContext } from './CustomersAutoTreatContext';

interface CustomersAutoTreatValidationsContextInterface {
  validatecsvImportRow?: (formValues: CustomerAutoTreatFormValues) => { error: boolean; errorMessage?: string };
  validateCustomersAutoTreatFields?: (formValues: CustomerAutoTreatFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const CustomersAutoTreatValidationsContext = React.createContext<CustomersAutoTreatValidationsContextInterface>({});

const CustomersAutoTreatValidationsContextConsumer = CustomersAutoTreatValidationsContext.Consumer;
const CustomersAutoTreatValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { customers, activeCustomerAutoTreat } = React.useContext(CustomersAutoTreatContext);

  const fetchValidCharactersError = (formValues: CustomerAutoTreatFormValues): string => {
    const SpecialCharacterStringCheck =
      formValues?.customer_id +
      formValues?.operation +
      formValues?.first_name +
      formValues?.last_name +
      formValues?.address1 +
      formValues?.address2 +
      formValues?.city +
      formValues?.state +
      formValues?.zip_code +
      formValues?.country +
      formValues?.mobile_phone +
      formValues?.business_phone +
      formValues?.comment +
      formValues?.email +
      formValues?.auto_email +
      formValues?.active;

    const specialChars = /'|\/\*|\*\//;

    if (specialChars.test(SpecialCharacterStringCheck)) {
      return `No fields can contain ' `;
    }
    return null;
  };

  const fetchCustomerNameError = (operation: string) => {
    if (operation === '') {
      return 'Customer Name cannot be left blank.';
    }

    if (operation?.length > 50) {
      return 'Customer Name cannot be longer than 50 characters';
    }

    const matchedNames = customers.filter(
      (customer) => customer.operation.localeCompare(operation, undefined, { sensitivity: 'base' }) === 0,
    );
    if (matchedNames.length > 0) {
      if (activeCustomerAutoTreat.operation !== operation) {
        return 'Customer Name cannot be a duplicate name';
      }
      return false;
    }
    return false;
  };

  const fetchcsvCustomerNameError = (operation: string) => {
    if (operation === '') {
      return 'Customer Name cannot be left blank.';
    }

    if (operation?.length > 50) {
      return 'Customer Name cannot be longer than 50 characters';
    }

    return false;
  };

  const fetchCommentError = (comment: string) => {
    if (!comment) {
      return false;
    }

    if (comment?.length > 250) {
      return 'Comment cannot be longer than 250 characters';
    }
    return false;
  };

  const fetchActiveError = (active: string) => {
    if (typeof active === 'undefined') {
      return false;
    }
    if (
      active !== '1' &&
      active !== '0' &&
      active.toLowerCase() !== 'true' &&
      active.toLowerCase() !== 'false' &&
      active !== '' &&
      active !== null
    ) {
      return 'active must be 0 or 1';
    }
    return false;
  };

  const fetchAutoEmailError = (auto_email: string) => {
    if (typeof auto_email === 'undefined') {
      return false;
    }
    if (
      auto_email !== '1' &&
      auto_email !== '0' &&
      auto_email.toLowerCase() !== 'true' &&
      auto_email.toLowerCase() !== 'false' &&
      auto_email !== '' &&
      auto_email !== null
    ) {
      return 'auto email must be 0 or 1';
    }
    return false;
  };

  const validateCustomersAutoTreatFields = (formValues: CustomerAutoTreatFormValues) => {
    const customerNameError = fetchCustomerNameError(formValues?.operation);
    const customerCommentError = fetchCommentError(formValues?.comment);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (specialCharactersError || customerNameError || customerCommentError) {
      setFormErrors({
        CustomerName: customerNameError,
        CustomerComment: customerCommentError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      CustomerName: null,
      CustomerComment: null,
      base: null,
    });
    return true;
  };

  const validatecsvImportRow = (formValues: CustomerAutoTreatFormValues): { error: boolean; errorMessage?: string } => {
    const customerNameError = fetchcsvCustomerNameError(formValues?.operation);
    const customerCommentError = fetchCommentError(formValues?.comment);
    const customerActiveError = fetchActiveError(formValues?.active);
    const customerAutoEmailError = fetchAutoEmailError(formValues?.auto_email);
    const specialCharactersError = fetchValidCharactersError(formValues);

    const errors = [
      specialCharactersError,
      customerActiveError,
      customerCommentError,
      customerAutoEmailError,
      customerNameError,
    ].filter(Boolean);

    if (errors.length > 0) {
      return {
        error: true,
        errorMessage: errors.join(', '),
      };
    }

    return { error: false };
  };

  return (
    <CustomersAutoTreatValidationsContext.Provider
      value={{
        validatecsvImportRow,
        validateCustomersAutoTreatFields,
        formErrors,
      }}
    >
      {children}
    </CustomersAutoTreatValidationsContext.Provider>
  );
};

export {
  CustomersAutoTreatValidationsContextProvider,
  CustomersAutoTreatValidationsContextConsumer,
  CustomersAutoTreatValidationsContext,
};
