/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import SeedSourcesAutoTreatList from './SeedSourcesAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';
import SeedSourcesAutoTreatDetailPage from './SeedSourcesAutoTreatDetailPage';

const SeedSourcesAutoTreatPage: React.FunctionComponent = () => {
  const { detailPage, activeMachine } = React.useContext(SeedSourcesAutoTreatContext);
  const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    fetchAutoTreatMachines();
  }, []);

  const autoTreatSeedSourcePage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <SeedSourcesAutoTreatDetailPage />,
          listPage: <SeedSourcesAutoTreatList />,
        }[autoTreatSeedSourcePage()]
      }
    </>
  );
};

export default SeedSourcesAutoTreatPage;
