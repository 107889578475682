/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { AUTH_ZERO_AUDIENCE } from '../../../modules/utils/env';
import columns from '../constants/columns';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import { OrderAutoTreat } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import OrderAutoTreatFilterBar from '../components/OrdersAutoTreatFilterBar';

const OrdersAutoTreatList: React.FunctionComponent = () => {
  const {
    fetchOrdersAutoTreat,
    orders,
    setDetailPage,
    setCreatingPage,
    setOrderSubmitting,
    setActiveOrderAutoTreat,
    activeMachine,
    setActiveMachine,
  } = React.useContext(OrdersAutoTreatContext);

  const [tableFilters, setTableFilters] = React.useState({
    name: '',
    description: '',
    operation: '',
    variety: '',
    recipe: '',
  });
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoTreatMachines, autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    fetchAutoTreatMachines();
  }, []);

  const filterOrders = () => {
    if (tableFilters.name || tableFilters.operation) {
      const nameFilteredOrders = orders.filter((order) =>
        order.order_name?.toLowerCase().includes(tableFilters.name?.toLowerCase()),
      );

      const operationFilteredCustomers = nameFilteredOrders.filter((order) =>
        order.operation?.toLowerCase().includes(tableFilters.operation?.toLowerCase()),
      );

      return operationFilteredCustomers;
    }

    return orders;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Order Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <OrderAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid justifyContent="right" container spacing={0}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={activeMachine?.value === 'Select A Machine' || activeMachine?.value === undefined}
                  onClick={() => {
                    setCreatingPage(true);
                    setFitlerBarActive(false);
                  }}
                >
                  New Order
                </Button>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterOrders()}
                defaultSortField="date_and_time_last_modified"
                defaultSortAsc={false}
                striped
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                onRowClicked={(row: OrderAutoTreat) => {
                  setActiveOrderAutoTreat(row as any);
                  setDetailPage(true);
                  setOrderSubmitting(false);
                  setFitlerBarActive(false);
                }}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default OrdersAutoTreatList;
