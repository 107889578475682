/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import SeedsAutoTreatDetail from '../components/SeedsAutoTreatDetail';
import IntegrationsList from '../components/IntegrationsList';
import { SeedIntegrationsAutoTreatContext } from '../contexts/SeedsIntegrationsAutoTreatContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const SeedsAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveSeedAutoTreat, activeSeedAutoTreat, activeMachine, setSeedSubmitting } =
    React.useContext(SeedsAutoTreatContext);
  const { fetchSeedIntegrationsAutoTreat } = React.useContext(SeedIntegrationsAutoTreatContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/autotreat/${activeMachine?.value}/seeds`,
  );

  React.useEffect(() => {
    const getIntegrations = async () => {
      if (!initialDataLoaded) {
        fetchSeedIntegrationsAutoTreat(activeMachine?.value, activeSeedAutoTreat.seed_variety_id);
      }
    };

    getIntegrations();
  }, [initialDataLoaded, setDataLoaded]);

  const handleBack = () => {
    setDetailPage(false);
    setActiveSeedAutoTreat(null);
    setSeedSubmitting(false);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeSeedAutoTreat.variety}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Seed List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <SeedsAutoTreatDetail
              seed={activeSeedAutoTreat}
              machineSerial={activeMachine?.value}
              onSuccess={() => setDetailPage(false)}
              onBack={handleBack}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <IntegrationsList seed={activeSeedAutoTreat} activeMachine={activeMachine} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default SeedsAutoTreatDetailPage;
