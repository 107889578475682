import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import PolarisLabel from '../../shared/form/PolarisLabel';
import { UsersContext } from '../contexts/UsersContext';
import { UserFormValues, User } from '../model';
import MachineSelector from './MachineSelector';

interface UserFormProps {
  user: User;
}

const UserForm: React.FC<UserFormProps> = ({ user }) => {
  const {
    updateUser,
    createUser,
    userSubmitting,
    formErrors,

    fetchUserMachinesByCompany,
    userMachinesByCompany,
    userMachinesByCompanyLoading,

    fetchUserPermissionGroups,
    userPermissionGroups,
    userPermissionGroupsLoading,
  } = useContext(UsersContext);

  const [formValues, setFormValues] = useState<UserFormValues>({});
  const [selectedMachines, setSelectedMachines] = useState<any[]>([]);
  const [combinedMachines, setCombinedMachines] = useState<any[]>([]);

  useEffect(() => {
    setFormValues({
      full_name: user?.full_name,
      status: user?.status ? 'True' : 'False',
      email_address: user?.email_address,
      contact_number: user?.contact_number,
      user_permission_group_id: user?.user_permission_group_id,
    });

    const machinesFromUser =
      user?.machine_access?.map((machine) => ({
        label: machine.machine_name_with_serial,
        value: machine.serial_number,
      })) || [];

    const machinesFromCompany = (userMachinesByCompany || []).map((machine) => ({
      label: machine.machine_name_with_serial,
      value: machine.serial_number,
    }));

    const combinedMachineValues = new Set(
      [...machinesFromCompany, ...machinesFromUser].map((machine) => JSON.stringify(machine)),
    );
    const uniqueMachines = Array.from(combinedMachineValues).map((machine) => JSON.parse(machine));

    setCombinedMachines(uniqueMachines);
    if (user?.user_id) {
      setSelectedMachines(machinesFromUser);
    }
  }, [user, userMachinesByCompany]);

  const handleUpdate = () => {
    const serialNumbers = selectedMachines.map((machine) => machine.value);
    const updatedFormValues = { ...formValues, serial_numbers: serialNumbers };
    updateUser(user.user_id, updatedFormValues);
  };

  const handleCreate = () => {
    const serialNumbers = selectedMachines.map((machine) => machine.value);
    const updatedFormValues = { ...formValues, serial_numbers: serialNumbers };
    createUser(updatedFormValues);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <FormInput
            value={formValues?.full_name}
            errorMessage={formErrors?.full_name}
            onChange={(value) => setFormValues({ ...formValues, full_name: value })}
            label="Full Name"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormInput
            value={formValues?.email_address}
            errorMessage={formErrors?.email_address}
            onChange={(value) => setFormValues({ ...formValues, email_address: value })}
            label="Email"
            disabled={!!user?.user_id}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormInput
            value={formValues?.contact_number}
            onChange={(value) => setFormValues({ ...formValues, contact_number: value })}
            label="Contact Number"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <SelectInput
            value={formValues?.user_permission_group_id}
            loading={userPermissionGroupsLoading}
            choices={[
              { label: 'None', value: undefined },
              ...userPermissionGroups.map((group) => ({
                label: group.description,
                value: group.permission_group_id,
              })),
            ]}
            onChange={(value) => setFormValues({ ...formValues, user_permission_group_id: value })}
            label="User Permission Group"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <PolarisLabel label="Machine Access" />
          <MachineSelector
            machines={combinedMachines}
            selectedMachines={selectedMachines}
            setSelectedMachines={setSelectedMachines}
            loading={userMachinesByCompanyLoading}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <SelectInput
            value={formValues?.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            choices={[
              { label: 'Active', value: 'True' },
              { label: 'Inactive', value: 'False' },
            ]}
            label="Status"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {user.user_id ? (
            <Button variant="contained" color="primary" onClick={handleUpdate} disabled={userSubmitting}>
              {userSubmitting ? 'Saving...' : 'Update User'}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleCreate} disabled={userSubmitting}>
              {userSubmitting ? 'Saving...' : 'Create User'}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
