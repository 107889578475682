const exampleCSV = [
  {
    customer_name: 'customer',
    customer_contact_first: 'first_name',
    customer_contact_last: 'last_name',
    customer_comment: 'comment',
    customer_desc: 'description',
    customer_address: 'address',
    customer_city: 'city',
    customer_state: 'state',
    country: 'country',
    customer_zip: '12345',
    customer_phone_1: '123-456-7890',
    customer_phone_2: '123-456-7890',
    customer_email: 'email',
    active: true,
  },
  {
    customer_name: '* Required',
    active: 'true/false, default: true',
  },
];

export default exampleCSV;
