import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface InformationFieldProps {
  label: string;
  value?: string | number | null;
}

const InformationField: React.FunctionComponent<InformationFieldProps> = (props) => {
  const { label, value } = props;

  return (
    <div>
      <Typography variant="subtitle1" component="h6">
        {label}
        {/* <IconButton aria-label="delete" size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton> */}
      </Typography>
      <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces' }}>
        {value}
      </Typography>
    </div>
  );
};

export default InformationField;
