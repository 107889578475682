import React from 'react';

export const DEFAULT_MODAL_WIDTH = '728px';
export const DEFAULT_MODAL_HEIGHT = '415px';

export interface BaseModalProps {
  isOpen: boolean;
  title?: string;
  openStatusChanged?: (isOpen: boolean) => void;
  width?: string;
  height?: string;
  children?: React.ReactElement;
}

export class ConfirmCancelLabels {
  confirm: string;

  cancel: string;

  static readonly Defaults: ConfirmCancelLabels = {
    confirm: 'Continue',
    cancel: 'Back',
  };
}

export type ConfirmCancelLabelsByIndex = { [index: number]: ConfirmCancelLabels } | ConfirmCancelLabels[];
