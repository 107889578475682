import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DataTable from '../../dataTable/DataTable/DataTable';
import locationColumns from '../constants/locationColumns';

interface AdminCompanyUsersProps {
  company: any;
}

const AdminCompanyUsers: React.FC<AdminCompanyUsersProps> = (props) => {
  const { company } = props;
  const history = useHistory();

  const columns = [
    {
      name: 'Full Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email_address',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      grow: 0.5,
      sortable: true,
      cell: ({ status }): React.ReactNode => <div>{status ? 'Active' : 'Inactive'}</div>,
    },
    {
      name: 'Actions',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: (row): React.ReactNode => (
        <div>
          <a href={`/admin/user_management/${row.user_id}`} target="_blank" rel="noreferrer">
            Edit
          </a>
        </div>
      ),
    },
  ];

  const handleAddUser = () => {
    window.open(`/admin/user_management/new/company/${company.company_id}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Company Users
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleAddUser}>
              Add User
            </Button>
          </Grid>
        </Grid>
        <DataTable
          noHeader
          columns={columns}
          data={company?.associated_users?.filter((user) => user?.status)}
          onRowClicked={(row) => history.push(`/admin/user_management/${row.user_id}`)}
          defaultSortAsc={false}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  );
};

export default AdminCompanyUsers;
