/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import RealTimeActivityFilterBar from '../components/RealTimeActivityFilterBar';
import { columns } from '../constants/tableColumns';
import { nestedColumns } from '../../transactions/constants/transactionColumns';
import { AutoBulkRealTimeActivityContext } from '../contexts/AutoBulkRealTimeActivityContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    const id = setInterval(() => {
      (savedCallback as any).current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const RealTimeActivity: React.FunctionComponent = () => {
  const { fetchTransactions, transactions, transactionsLoading } = React.useContext(AutoBulkRealTimeActivityContext);

  const { fetchAutoBulkMachines, autoBulkMachines } = React.useContext(MachinesContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [lastUpdatedTimer, setLastUpdatedTimer] = React.useState<number>(0);

  useInterval(() => {
    if (lastUpdatedTimer > 60) {
      setLastUpdatedTimer(lastUpdatedTimer + 1);

      const getChoices = async () => {
        setDataLoaded(true);
        fetchTransactions();
        setLastUpdatedTimer(0);
      };

      getChoices();
    } else {
      setLastUpdatedTimer(lastUpdatedTimer + 1);
    }
  }, 1000);

  React.useEffect(() => {
    const getChoices = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchTransactions();
        fetchAutoBulkMachines();
      }
    };

    getChoices();
  }, [initialDataLoaded]);

  const handleFilter = (filterParams: string) => {
    fetchTransactions(filterParams);
  };

  const ExpandedComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ border: '1px solid #3f51b5', margin: 15 }}>
          <DataTable
            columns={nestedColumns}
            data={row.data.products}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Real Time Activity
          </Typography>
        </Grid>
      </Grid>
      <br />

      {/* Filter Bar */}
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <RealTimeActivityFilterBar machineChoices={autoBulkMachines} applyFilter={handleFilter} />
          <br />
          {/* <p style={{ color: 'red' }}>{filterError}</p> */}
        </Grid>
      </Grid>
      <br />

      {/* Transactions */}
      <Card>
        <CardContent>
          <div>
            <span style={{ fontSize: '22px', padding: '0 8px 0 8px' }}>Transactions</span> last updated{' '}
            {lastUpdatedTimer.toString()} seconds ago
          </div>
          <DataTable
            noHeader
            columns={columns}
            data={transactions}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            progressPending={transactionsLoading}
            expandableRows
            expandableRowsComponent={<ExpandedComponent row />}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
          />
        </CardContent>
        <br />
      </Card>
      <br />
    </>
  );
};

export default RealTimeActivity;
