import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Footer from '../../navigation/components/Footer';

const InvalidAccountPage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Grid container style={{ marginTop: 80 }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ alignItems: 'center', display: 'flex', height: '75vh', textAlign: 'center' }}>
                <div style={{ margin: 'auto' }}>
                  <Typography variant="h1" component="h1">
                    Your account does not have access Agconnex.
                  </Typography>
                  <br />
                  <Typography>
                    Please reach out to agconnex@ksiedge.com for assistance with accessing Agconnex.
                  </Typography>
                  <br />
                  <Button variant="contained" color="primary" onClick={() => history.push('/login')}>
                    Back to Login
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default InvalidAccountPage;
