import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import UserCompanyInformation from './UserCompanyInformation';
import UserCompanyMachines from './UserCompanyMachines';
import UserCompanyUsers from './UserCompanyUsers';

interface UserCompanyDetailsProps {
  company: any;
}

const UserCompanyDetails: React.FC<UserCompanyDetailsProps> = (props) => {
  const { company } = props;

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Company Information
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <FormInput value={company?.name} onChange={(value) => null} label="Name" disabled />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <UserCompanyUsers company={company} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserCompanyMachines company={company} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCompanyDetails;
