import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Sensor Name',
    selector: 'level_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Type',
    selector: 'level_sensor',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'Source Name',
    selector: 'source_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Source Desc',
    selector: 'source_description',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Products',
    selector: 'products',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Inv Amt',
    selector: 'level_amount_volume',
    sortable: true,
    right: true,
    grow: 0.6,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.level_amount_volume) || 0;
      const valB = parseFloat(b.level_amount_volume) || 0;
      return valA - valB;
    },
    cell: (row): React.ReactNode => (
      <div>
        <p>{`${row.level_amount_volume} ${row.level_amount_uom_volume}`}</p>
      </div>
    ),
  },
  {
    name: 'Tank Capactiy',
    selector: 'level_calculated_capacity_volume',
    sortable: true,
    right: true,
    grow: 0.6,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.level_calculated_capacity_volume) || 0;
      const valB = parseFloat(b.level_calculated_capacity_volume) || 0;
      return valA - valB;
    },
    cell: (row): React.ReactNode => (
      <div>
        <p>{`${row.level_calculated_capacity_volume} ${row.level_amount_uom_volume}`}</p>
      </div>
    ),
  },
  {
    name: 'Fill Percent',
    selector: 'sensor_fill_percent',
    sortable: true,
    right: true,
    grow: 0.6,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.sensor_fill_percent) || 0;
      const valB = parseFloat(b.sensor_fill_percent) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Status',
    selector: 'level_fault',
    sortable: true,
    center: true,
    grow: 0.5,
    cell: (row: any) => <p>{row.level_fault === '1' ? 'Faulted' : 'OK'}</p>,
  },
];

export default columns;
