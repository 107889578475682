import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import FormInput from '../../shared/form/FormInput';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import { CompanyAccessContext } from '../contexts/CompanyAccessContext';
import UserMachineAccessForm from '../../shared/form/UserMachineAccessForm';

interface AssignUserAccessProps {
  company: any;
  users: any;
  setUsers: any;
}

const AssignUserAccess: React.FC<AssignUserAccessProps> = (props) => {
  const { company, users, setUsers } = props;

  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [externalEmail, setExternalEmail] = React.useState('');
  const [showAccessFormCompanyUsers, setShowAccessFormCompanyUsers] = React.useState(false);
  const [filteredMachineAccessOptions, setFilteredMachineAccessOptions] = React.useState<Record<string, string>[]>([]);

  const { userMachineAccessOptions } = React.useContext(UserMachineAccessContext);
  const { createBulkCompanyAssignment, companySubmitting } = React.useContext(CompanyAccessContext);

  React.useEffect(() => {
    setFilteredMachineAccessOptions(userMachineAccessOptions?.user_options);
  }, [userMachineAccessOptions, setFilteredMachineAccessOptions]);

  const handleUpdate = () => {
    createBulkCompanyAssignment({ company_id: company.company_id, user_ids: users });
  };

  return !company?.company_id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Assign Users to Company
              </Typography>
            </Grid>
          </Grid>
          <UserMachineAccessForm
            label="Users"
            loading={false}
            pluralLabel="Users"
            allChoices={userMachineAccessOptions?.user_options || []}
            choices={filteredMachineAccessOptions || []}
            setValue={(value) => {
              setUsers([].concat(value));
            }}
            value={users as string[]}
            initialValue={users || company?.associated_users?.map((user) => user?.user_id)}
          />
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            {companySubmitting ? 'Saving...' : 'Assign Users'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AssignUserAccess;
