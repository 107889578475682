/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@mui/material';
import SideBarInformationField from '../../../modules/shared/form/SideBarInformationField';

interface RecipeDetailProps {
  transaction?: any;
}

const RecipeDetails: React.FunctionComponent<RecipeDetailProps> = (props) => {
  const { transaction } = props;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SideBarInformationField label="Recipe Name:" value={transaction?.treatment_recipe_name} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Recipe Description:" value={transaction?.treatment_recipe_description} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Applicator (license):" value={transaction?.operator_license} />
        </Grid>
      </Grid>
    </>
  );
};

export default RecipeDetails;
