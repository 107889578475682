import React from 'react';
import { RfidAutoBulkFormValues } from '../model';
import { RfidsAutoBulkContext } from './RfidAutoBulkContext';

interface RfidsAutoBulkValidationsContextInterface {
  validateRfidsAutoBulkFields?: (formValues: RfidAutoBulkFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const RfidsAutoBulkValidationsContext = React.createContext<RfidsAutoBulkValidationsContextInterface>({});

const RfidsAutoBulkValidationsContextConsumer = RfidsAutoBulkValidationsContext.Consumer;
const RfidsAutoBulkValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});
  const { rfids, activeRfidAutoBulk } = React.useContext(RfidsAutoBulkContext);

  const fetchValidCharactersError = (formValues: RfidAutoBulkFormValues): boolean => {
    const SpecialCharacterStringCheck =
      formValues?.rfid_id +
      formValues?.rfid_name +
      formValues?.rfid_comment +
      formValues?.rfid_code +
      formValues?.driver_name +
      formValues?.transport_name +
      formValues?.active;
    const specialChars = /'/;

    return specialChars.test(SpecialCharacterStringCheck);
  };

  const fetchRfidNameAutoBulkError = (rfid_name: string) => {
    if (rfid_name === '') {
      return 'cannot be left blank.';
    }

    if (rfid_name?.length > 50) {
      return 'cannot be longer than 50 characters';
    }

    const matchedNames = rfids.filter((rfid) => rfid.rfid_name === rfid_name);
    if (matchedNames.length > 0) {
      if (activeRfidAutoBulk.rfid_name !== rfid_name) {
        return 'cannot be a duplicate name';
      }

      return false;
    }

    return false;
  };

  const fetchRfidCodeAutoBulkError = (rfid_code: string) => {
    if (rfid_code === '') {
      return 'cannot be left blank.';
    }

    if (rfid_code?.length > 250) {
      return 'cannot be longer than 250 characters';
    }

    const matchedNames = rfids.filter((rfid) => rfid.rfid_code === rfid_code);
    if (matchedNames.length > 0) {
      if (activeRfidAutoBulk.rfid_code !== rfid_code) {
        return 'cannot be a duplicate code';
      }

      return false;
    }

    return false;
  };

  const validateRfidsAutoBulkFields = (formValues: RfidAutoBulkFormValues) => {
    const abRfidNameError = fetchRfidNameAutoBulkError(formValues?.rfid_name);
    const abRfidCodeError = fetchRfidCodeAutoBulkError(formValues?.rfid_code);
    const specialCharactersError = fetchValidCharactersError(formValues);

    if (specialCharactersError || abRfidNameError || abRfidCodeError) {
      setFormErrors({
        RfidName: abRfidNameError,
        RfidCode: abRfidCodeError,
        base: specialCharactersError,
      });

      return false;
    }

    setFormErrors({
      RfidName: null,
      RfidCode: null,
      base: null,
    });

    return true;
  };

  return (
    <RfidsAutoBulkValidationsContext.Provider
      value={{
        validateRfidsAutoBulkFields,
        formErrors,
      }}
    >
      {children}
    </RfidsAutoBulkValidationsContext.Provider>
  );
};

export {
  RfidsAutoBulkValidationsContextProvider,
  RfidsAutoBulkValidationsContextConsumer,
  RfidsAutoBulkValidationsContext,
};
