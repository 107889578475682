import React from 'react';
import axios from '../../utils/axios.utils';

interface ApiLogContextInterface {
  sendApiLogs?: () => Promise<void>;
}

const ApiLogContext = React.createContext<ApiLogContextInterface>({});

const ApiLogContextConsumer = ApiLogContext.Consumer;
const ApiLogContextProvider: React.FC = ({ children }) => {
  const sendApiLogs = async () => {
    const requests = sessionStorage.getItem('networkRequests') || [];

    axios
      .post<any, any>(
        `api/submit_api_logs/`,
        {
          requests,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((response) => {
        console.log(response);
      })

      .catch((error) => {
        console.log(`error sending requests to backend: ${error}`);
      });
  };

  return (
    <ApiLogContext.Provider
      value={{
        sendApiLogs,
      }}
    >
      {children}
    </ApiLogContext.Provider>
  );
};

export { ApiLogContextProvider, ApiLogContextConsumer, ApiLogContext };
