/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { MachineRequestsContext } from '../contexts/MachineRequestsContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import InformationField from '../../shared/form/InformationField';

const MachineRequestRecordDetails: React.FunctionComponent = () => {
  const { machineRequestId, machineRequestRecordId } = useParams<Record<string, string>>();

  const { fetchMachineRequestRecord, machineRequestRecord, machineRequestRecordLoading, errorMessage } =
    React.useContext(MachineRequestsContext);

  React.useEffect(() => {
    fetchMachineRequestRecord(machineRequestId, machineRequestRecordId);
  }, []);

  const links = [
    {
      href: '/machine_requests/',
      text: 'Machine Requests',
    },
    {
      href: `/machine_requests/${machineRequestId}/machine_request_records`,
      text: `Machine Request`,
    },
    {
      href: `/machine_requests/${machineRequestId}/machine_request_records/${machineRequestRecordId}`,
      text: `Machine Request Record`,
    },
  ];

  return machineRequestRecordLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading Machine Request Record...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InformationField
                label="machine_request_record_id"
                value={machineRequestRecord?.machine_request_record_id}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationField
                label="date_time_machine_response"
                value={machineRequestRecord?.date_time_machine_response}
              />
            </Grid>

            <Grid item xs={4}>
              <InformationField
                label="date_time_sent_to_machine"
                value={machineRequestRecord?.date_time_sent_to_machine}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="machine_request" value={machineRequestRecord?.machine_request} />
            </Grid>
            <Grid item xs={4}>
              <InformationField
                label="machine_request_record_uuid"
                value={machineRequestRecord?.machine_request_record_uuid}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="record_id" value={machineRequestRecord?.record_id} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="record_meta" value={machineRequestRecord?.record_meta} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="record_table" value={machineRequestRecord?.record_table} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="request_record_object" value={machineRequestRecord?.request_record_object} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="response_message" value={machineRequestRecord?.response_message} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="response_status" value={machineRequestRecord?.response_status} />
            </Grid>
            <Grid item xs={4}>
              <InformationField label="sqs_message_id" value={machineRequestRecord?.sqs_message_id} />
            </Grid>
            <Grid item xs={12}>
              <InformationField label="request_record_sql" value={machineRequestRecord?.request_record_sql} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MachineRequestRecordDetails;
