/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import FormInput from '../../../modules/shared/form/FormInput';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { AUTH_ZERO_AUDIENCE } from '../../../modules/utils/env';
import columns from '../constants/columns';
import { SourcesAutoBulkContext } from '../contexts/SourcesAutoBulkContext';
import { Source } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import SourcesFilterBar from '../components/SourcesFilterBar';

const SourcesListPage: React.FunctionComponent = () => {
  const history = useHistory();

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({ source_name: '', product_name: '' });

  const { fetchSources, sources, activeMachine, setActiveMachine } = React.useContext(SourcesAutoBulkContext);

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    fetchAutoBulkMachines();
  }, []);

  const filterSources = () => {
    const sourceNameFiltered = sources.filter((sources) =>
      sources.source_name?.toLowerCase().includes(tableFilters.source_name?.toLowerCase()),
    );
    // TODO add product_name to filter
    //   const productFiltered = sourceNameFiltered.filter((product) =>
    //   sources.product_name?.toLowerCase().includes(tableFilters.product_name?.toLowerCase()),
    // );

    return sourceNameFiltered;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Sources Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <SourcesFilterBar
            machineChoices={autoBulkMachines}
            machineChoicesLoading={machinesLoading}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
          />
          <br />
        </Grid>
      </Grid>
      <br />
      <>
        <Card>
          <CardContent>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterSources()}
                defaultSortField="source_name"
                defaultSortAsc
                striped
                onRowClicked={(row: Source) => {
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default SourcesListPage;
