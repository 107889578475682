/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,
  Grid,
  InputAdornment,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'light' ? '#eaecef' : '#30363d',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
    '&.Mui-focused fieldset': {
      boxShadow: `${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'} 0px 0px 0px 2px`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px !important',
    fontSize: 14,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)', // Adjust the icon position vertically
  },
}));

interface Machine {
  label: string;
  value: string;
}

interface MachineSelectorProps {
  machines: Machine[];
  selectedMachines: Machine[];
  setSelectedMachines: React.Dispatch<React.SetStateAction<Machine[]>>;
  loading: boolean; // Add this line
}

const MachineSelector: React.FC<MachineSelectorProps> = ({
  machines,
  selectedMachines,
  setSelectedMachines,
  loading,
}) => {
  const theme = useTheme();

  return (
    <Box id="machine-selector-container">
      <Autocomplete
        multiple
        PaperComponent={({ children }) => (
          <Paper style={{ border: '1px solid #ccc', borderTop: '2px solid #ccc' }}>{children}</Paper>
        )}
        options={machines}
        value={selectedMachines}
        onChange={(event, newValue) => {
          setSelectedMachines(newValue);
        }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ fontSize: '13px' }}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={selectedMachines.some((machine) => machine.value === option.value)}
            />
            {option.label}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />)
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label=""
            placeholder="Select Machines"
            InputProps={{
              ...params.InputProps,
              style: { padding: '20px' },
            }}
            variant="outlined"
            fullWidth
          />
        )}
        disableCloseOnSelect
        disablePortal
        fullWidth
      />
    </Box>
  );
};

export default MachineSelector;
