import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TransactionApiLogsAutoTreatContextInterface {
  fetchCachedTransactionApiLogsAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchTransactionApiLogsAutoTreat?: (
    machineSerial: string,
    genericFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  transactionApiLogsAutoTreatRefreshedAt?: string;
  transactionApiLogsAutoTreat?: any[];
  transactionApiLogsAutoTreatLoading?: boolean;

  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveTransactionApiLogAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeTransactionApiLogAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const TransactionApiLogsAutoTreatContext = React.createContext<TransactionApiLogsAutoTreatContextInterface>({});

const TransactionApiLogsAutoTreatContextConsumer = TransactionApiLogsAutoTreatContext.Consumer;
const TransactionApiLogsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar } = React.useContext(SnackBarContext);

  const [transactionApiLogsAutoTreat, setTransactionApiLogsAutoTreat] = React.useState([]);
  const [transactionApiLogsAutoTreatLoading, setTransactionApiLogsAutoTreatLoading] = React.useState(true);
  const [transactionApiLogsAutoTreatRefreshedAt, setTransactionApiLogsAutoTreatRefreshedAt] = React.useState<string>();
  const [activeTransactionApiLogAutoTreat, setActiveTransactionApiLogAutoTreat] = React.useState({});
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('transactionApiLogsAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('transactionApiLogsAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setTransactionApiLogsAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedTransactionApiLogsAutoTreat = async (machineSerial: string) => {
    setTransactionApiLogsAutoTreatLoading(true);
    setTransactionApiLogsAutoTreatRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/transaction_api_log/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransactionApiLogsAutoTreatLoading(false);
        setTransactionApiLogsAutoTreat(response.data.results);
        setTransactionApiLogsAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setTransactionApiLogsAutoTreatLoading(false);
        setTransactionApiLogsAutoTreat([]);
        setTransactionApiLogsAutoTreatRefreshedAt(null);
      });
  };

  const fetchTransactionApiLogsAutoTreat = async (
    machineSerial: string,
    genericFilter: string,
    onSuccess?: () => void,
  ) => {
    setTransactionApiLogsAutoTreatLoading(true);
    setTransactionApiLogsAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/transaction_api_log/?serial-number=${machineSerial}&generic-filter=${genericFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/transaction_api_log/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              setTransactionApiLogsAutoTreatLoading(false);
              setTransactionApiLogsAutoTreat(response.data.results);
              setTransactionApiLogsAutoTreatRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load Transaction Api Logs');
                setTransactionApiLogsAutoTreatLoading(false);
                setTransactionApiLogsAutoTreat([]);
                setTransactionApiLogsAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <TransactionApiLogsAutoTreatContext.Provider
      value={{
        fetchCachedTransactionApiLogsAutoTreat,
        fetchTransactionApiLogsAutoTreat,
        handleGenericFilterChange,
        genericFilter,
        transactionApiLogsAutoTreat,
        transactionApiLogsAutoTreatRefreshedAt,
        transactionApiLogsAutoTreatLoading,

        detailPage,
        setDetailPage,

        setActiveTransactionApiLogAutoTreat,
        activeTransactionApiLogAutoTreat,

        setActiveMachine,
        activeMachine,
      }}
    >
      {children}
    </TransactionApiLogsAutoTreatContext.Provider>
  );
};

export {
  TransactionApiLogsAutoTreatContextProvider,
  TransactionApiLogsAutoTreatContextConsumer,
  TransactionApiLogsAutoTreatContext,
};
