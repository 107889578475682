/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Breadcrumb from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  crumb: {
    color: '#3d5a80 !important',
    fontWeight: 700,
    fontSize: '20px !important',
    margin: -4,
  },
  disabled: {
    color: '#000 !important',
    fontSize: '18px !important',
  },
});

interface BreadcrumbProps {
  links?: BreadcrumbLink[];
}

export interface BreadcrumbLink {
  href?: string;
  onClick?: () => void;
  text: string;
}

const Breadcrumbs: React.FC<BreadcrumbProps> = (props: BreadcrumbProps) => {
  const { links } = props;
  const classes = useStyles();

  return (
    <Breadcrumb separator={<NavigateNextIcon fontSize="small" className={classes.crumb} />} aria-label="breadcrumb">
      {links.map((link, index) => (
        <div key={index}>
          {link.href ? (
            <Link href={link.href} key={link.href} underline="none">
              <Typography className={index !== links.length - 1 ? classes.crumb : classes.disabled}>
                {link.text}
              </Typography>
            </Link>
          ) : (
            <Link onClick={link.onClick} key={index} underline="none" style={{ cursor: 'pointer' }}>
              <Typography className={index !== links.length - 1 ? classes.crumb : classes.disabled}>
                {link.text}
              </Typography>
            </Link>
          )}
        </div>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
