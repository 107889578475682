import { format, parse } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';
import TransactionRowMenu from '../../realTimeDashboard/components/TransactionRowMenu';

export const columns: IDataTableColumn<any>[] = [
  {
    name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'order_start_date_and_time_utc',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.order_start_date_and_time_utc) {
        try {
          // Parse the date
          const date = parse(row.order_start_date_and_time_utc, "yyyy-MM-dd HH:mm:ss", new Date());
          // Format the date
          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Machine',
    selector: 'machine_name_with_serial',
    sortable: true,
  },
  {
    name: 'Order Name',
    selector: 'order_name',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'customer_name',
    sortable: true,
  },
  {
    name: 'Crop',
    selector: 'crop_name',
    sortable: true,
  },
  {
    name: 'Seed',
    selector: 'seed_variety_name',
    sortable: true,
  },
  {
    name: 'Seed Lot',
    selector: 'seed_lot_data',
    sortable: true,
  },
  {
    name: 'Recipe',
    selector: 'treatment_recipe_name',
    sortable: true,
  },
  {
    name: 'Units Delivered',
    selector: 'seed_units_actual_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.seed_units_actual_with_uom) || 0;
      const valB = parseFloat(b.seed_units_actual_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Weight Delivered',
    selector: 'seed_weight_actual_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.seed_weight_actual_with_uom) || 0;
      const valB = parseFloat(b.seed_weight_actual_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Treatment Accuracy',
    selector: 'treatment_accuracy',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.treatment_accuracy) || 0;
      const valB = parseFloat(b.treatment_accuracy) || 0;
      return valA - valB;
    },
    cell: (row) => <p>{row.treatment_accuracy.length > 1 ? row.treatment_accuracy : 'N/A'}</p>,
  },
  {
    cell: (row) => <TransactionRowMenu transaction={row} />,
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export const nestedColumns: IDataTableColumn<any>[] = [
  {
    name: 'Product',
    selector: 'liquid_name',
    sortable: true,
  },
  {
    name: 'Application Rate',
    selector: 'application_rate_with_uom',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.application_rate_with_uom) || 0;
      const valB = parseFloat(b.application_rate_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Density',
    selector: 'liquid_density_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.liquid_density_with_uom) || 0;
      const valB = parseFloat(b.liquid_density_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Target Amount',
    selector: 'target_amt_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.target_amt_with_uom) || 0;
      const valB = parseFloat(b.target_amt_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Actual Amount',
    selector: 'actual_amt_with_uom',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.actual_amt_with_uom) || 0;
      const valB = parseFloat(b.actual_amt_with_uom) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Treatment Accuracy',
    selector: 'amt_accuracy',
    sortable: true,
    right: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.amt_accuracy) || 0;
      const valB = parseFloat(b.amt_accuracy) || 0;
      return valA - valB;
    },
    cell: (row) => <p>{`${row.amt_accuracy}%`}</p>,
  },
];
