/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { UsersContext } from '../contexts/UsersContext';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { User } from '../../adminUsers/model';
import UsersFilterBar from '../components/UsersFilterBar';

const UserListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { currentUserHasUserPermission } = React.useContext(AuthContext);
  const { fetchUsers, users, usersLoading } = React.useContext(UsersContext);
  const [filterValues, setFilterValues] = React.useState({ status: 'status__all' });

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            User Management
          </Typography>
          <br />
        </Grid>
        {currentUserHasUserPermission('company_super_admin') && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push(`/account_management/users/new`)}>
              Create User
            </Button>
          </Grid>
        )}
      </Grid>
      <br />
      <UsersFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={users}
            defaultSortAsc={false}
            striped
            onRowClicked={(row: User) => history.push(`/account_management/users/${row.user_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={usersLoading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default UserListPage;
