import React, { useState, useEffect, useRef } from 'react';
import { Chip, Box, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const formatCommaDelimited = (items) => {
  return items?.filter(Boolean).join(', ') || '';
};

const ToleranceTooltipContent = ({ row }) => {
  return (
    <>
      {row?.alert_condition_above_tolerance_amt && (
        <div>Above Tolerance: {row.alert_condition_above_tolerance_amt}</div>
      )}
      {row?.alert_condition_below_tolerance_amt && (
        <div>Below Tolerance: {row.alert_condition_below_tolerance_amt}</div>
      )}
      {row?.alert_condition_consecutive_order_count && (
        <div>Consecutive Orders: {row.alert_condition_consecutive_order_count}</div>
      )}
    </>
  );
};

const TolerancesCell = ({ row }) => {
  const hasTolerances =
    row?.alert_condition_above_tolerance_amt ||
    row?.alert_condition_below_tolerance_amt ||
    row?.alert_condition_consecutive_order_count;

  return (
    <Tooltip title={<ToleranceTooltipContent row={row} />} enterDelay={300} leaveDelay={200}>
      {hasTolerances ? <Chip icon={<InfoIcon />} label="Details" size="small" variant="outlined" /> : <span />}
    </Tooltip>
  );
};

const ChipWithTooltip = ({ label, maxWidth }) => {
  return (
    <Tooltip title={label} enterDelay={300} leaveDelay={200}>
      <Chip
        label={label}
        size="small"
        sx={{ margin: 'px', maxWidth, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      />
    </Tooltip>
  );
};

const RenderChips = ({ items }) => {
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setMaxWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  const itemList = typeof items === 'string' ? items.split(',').map((item) => item.trim()) : items;
  const visibleItems = showAll ? itemList : itemList.slice(0, 2); // Show 2 items initially

  return (
    <Box
      ref={containerRef}
      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxWidth: '100%', width: '100%', overflow: 'hidden' }}
    >
      {visibleItems.map((item, index) => (
        <ChipWithTooltip key={index} label={item} maxWidth="100%" />
      ))}
      {itemList.length > 2 && !showAll && (
        <IconButton size="small" onClick={() => setShowAll(true)} title="Show more">
          <MoreHorizIcon />
        </IconButton>
      )}
    </Box>
  );
};

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'notification_name',
    sortable: true,
  },
  {
    name: 'Machine',
    selector: 'machine_name_with_serial',
    sortable: true,
  },
  {
    name: 'Emails',
    selector: 'recipient_emails',
    sortable: true,
    cell: (row) => (
      <div className="notification-chips">
        <RenderChips items={row.recipient_emails || ''} />
      </div>
    ),
  },
  {
    name: 'Details',
    selector: 'details',
    sortable: true,
    cell: (row) => <p>{formatCommaDelimited([row.scheduled_interval, row.scheduled_day, row.scheduled_time])}</p>,
  },
  {
    name: 'Tolerances',
    selector: 'tolerances',
    sortable: true,
    cell: (row) => (
      <p>
        {formatCommaDelimited([
          row.alert_condition_above_tolerance_amt,
          row.alert_condition_below_tolerance_amt,
          row.alert_condition_consecutive_order_count,
        ])}
      </p>
    ),
  },
  {
    name: 'Tolerances',
    selector: 'tolerances',
    sortable: true,
    cell: TolerancesCell,
  },
  {
    name: 'Products',
    selector: 'product_list',
    sortable: true,
    cell: (row) => <RenderChips items={row.product_list || ''} />,
  },
  {
    name: 'Last Executed',
    selector: 'last_executed_date_time',
    sortable: true,
    cell: (row) => <p>{row.last_executed_date_time ? new Date(row.last_executed_date_time).toLocaleString() : ''}</p>,
  },
  {
    name: 'Enabled',
    selector: 'enabled',
    sortable: true,
    cell: ({ enabled }) => (
      <div>
        <input type="checkbox" checked={enabled} onClick={() => null} />
      </div>
    ),
  },
];

export default columns;
