import React from 'react';
import axios from '../../utils/axios.utils';
import { User, UserFormValues } from '../model';

interface UserProfileContextInterface {
  fetchUserProfile?: () => Promise<void>;
  userProfile?: User;
  userProfileLoading?: boolean;

  userSubmitting?: boolean;

  updateUser?: (userId: string, formValues: UserFormValues, onSuccess?: () => void) => Promise<void>;
  formErrors?: any;
}

const UserProfileContext = React.createContext<UserProfileContextInterface>({});
const ProfileContextConsumer = UserProfileContext.Consumer;

const UserProfileContextProvider: React.FC = ({ children }) => {
  const [userProfile, setUserProfile] = React.useState({});
  const [userProfileLoading, setUserProfileLoading] = React.useState(true);
  const [userSubmitting, setUserSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const fetchUserProfile = async () => {
    axios
      .get<string, any>(`api/user/profile/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setUserProfile(response.data);
        setUserProfileLoading(false);
      });
  };

  const updateUser = async (userId: string, formValues: UserFormValues, onSuccess?: () => void) => {
    setUserSubmitting(true);

    axios
      .put<any, any>(
        `api/admin/users/${userId}/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setUserSubmitting(false);

        if (response.data.success) {
          if (onSuccess) {
            onSuccess();
          }
          window.location.href = '/profile';
        } else {
          const errors = response.data.result;

          setFormErrors({ full_name: errors.full_name });
        }
      });
  };

  return (
    <UserProfileContext.Provider
      value={{
        fetchUserProfile,
        userProfile,
        userProfileLoading,
        updateUser,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileContextProvider, ProfileContextConsumer, UserProfileContext };
