/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns, nestedColumns } from '../constants/columns';
import { RecipesAutoTreatContext } from '../contexts/RecipesAutoTreatContext';
import { RecipeAutoTreat } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import RecipeAutoTreatFilterBar from '../components/RecipesAutoTreatFilterBar';

const RecipesAutoTreatList: React.FunctionComponent = () => {
  const { fetchRecipesAutoTreat, recipes, setDetailPage, setActiveRecipeAutoTreat, activeMachine, setActiveMachine } =
    React.useContext(RecipesAutoTreatContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    liquid_recipe_name: '',
    liquid_recipe_desc: '',
    crop_name: '',
  });
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoTreatMachines, autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchRecipesAutoTreat]);

  const filterRecipes = () => {
    if (tableFilters.liquid_recipe_name || tableFilters.liquid_recipe_desc || tableFilters.crop_name) {
      const recipeNameFilteredRecipes = recipes.filter((recipe) =>
        recipe.liquid_recipe_name?.toLowerCase().includes(tableFilters.liquid_recipe_name?.toLowerCase()),
      );

      const descriptionFilteredRecipes = recipeNameFilteredRecipes.filter(
        (recipe) =>
          recipe.liquid_recipe_desc?.toLowerCase().includes(tableFilters.liquid_recipe_desc?.toLowerCase()) ||
          recipe.comment?.toLowerCase().includes(tableFilters.liquid_recipe_desc?.toLowerCase()),
      );

      const cropFilteredRecipes = descriptionFilteredRecipes.filter((recipe) =>
        recipe.crop_name?.toLowerCase().includes(tableFilters.crop_name?.toLowerCase()),
      );

      return cropFilteredRecipes;
    }

    return recipes;
  };

  const ExpandedComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ border: '1px solid #3f51b5', margin: 15 }}>
          <DataTable
            columns={nestedColumns}
            data={row.data.products}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Recipe Management (View Only)
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <RecipeAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                expandableRows
                expandableRowsComponent={<ExpandedComponent row />}
                columns={columns}
                data={filterRecipes()}
                defaultSortField="liquid_recipe_id"
                defaultSortAsc={false}
                striped
                onRowClicked={(row: RecipeAutoTreat) => {
                  setActiveRecipeAutoTreat(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default RecipesAutoTreatList;
