/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TransactionApiLogsAutoTreatContext } from '../contexts/TransactionApiLogsAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TransactionApiLogsAutoTreatList from './TransactionApiLogsAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const TransactionApiLogsAutoTreatPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchTransactionApiLogsAutoTreat, detailPage, activeMachine } = React.useContext(
    TransactionApiLogsAutoTreatContext,
  );
  const { fetchAutoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoTreatMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchTransactionApiLogsAutoTreat]);

  const autoTreatTransactionApiLogPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          listPage: <TransactionApiLogsAutoTreatList />,
        }[autoTreatTransactionApiLogPage()]
      }
    </>
  );
};

export default TransactionApiLogsAutoTreatPage;
