/* eslint-disable import/prefer-default-export */
import React, { useRef, useEffect } from 'react';

import { TextButton } from './TextButton';
import { IconButton } from './IconButton';

import './ImporterFrame.scss';
import { useLocale } from '../locale/LocaleContext';

export const ImporterFrame: React.FC<{
  fileName: string;
  subtitle?: string; // @todo allow multiple crumbs
  secondaryDisabled?: boolean;
  secondaryLabel?: string;
  nextDisabled?: boolean;
  nextLabel?: string | false;
  fileAccepted?: boolean;
  error?: string | null;
  onSecondary?: () => void;
  onNext: () => void;
  onCancel?: () => void;
}> = ({
  fileName,
  subtitle,
  secondaryDisabled,
  secondaryLabel,
  nextDisabled,
  nextLabel,
  fileAccepted,
  error,
  onSecondary,
  onNext,
  onCancel,
  children,
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (subtitleRef.current) {
      subtitleRef.current.focus();
    } else if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  const l10n = useLocale('general');

  return (
    <div className="CSVImporter_ImporterFrame">
      <div style={{ maxWidth: '1600px', margin: 'auto' }}>
        <div className="CSVImporter_ImporterFrame__header"></div>

        {children}

        <div className="CSVImporter_ImporterFrame__footer">
          <div className="CSVImporter_ImporterFrame__footerFill" />

          {error ? (
            <div className="CSVImporter_ImporterFrame__footerError" role="status">
              {error}
            </div>
          ) : null}

          {secondaryLabel ? (
            <div className="CSVImporter_ImporterFrame__footerSecondary">
              <TextButton disabled={!!secondaryDisabled} onClick={onSecondary}>
                {secondaryLabel}
              </TextButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
