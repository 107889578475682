import React from 'react';
import { Button, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InformationField from '../../shared/form/InformationField';
import { AuthContext } from '../../auth/contexts/AuthContext';
import DataTable from '../../dataTable/DataTable/DataTable';

const columns: any[] = [
  {
    name: 'api_partner_id',
    selector: 'api_partner_id',
    sortable: true,
  },
  {
    name: 'api_partner_name',
    selector: 'api_partner_name',
    sortable: true,
  },
  {
    name: 'global_api_partner_id',
    selector: 'global_api_partner_id',
    sortable: true,
  },
  {
    name: 'name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'description',
    selector: 'description',
    sortable: true,
  },
];

interface MachineApiPartnersProps {
  machine: any;
}

const MachineApiPartners: React.FC<MachineApiPartnersProps> = (props) => {
  const { machine } = props;
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Machine API Partners
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DataTable
              noHeader
              columns={columns}
              data={machine.api_partners}
              defaultSortAsc={false}
              striped
              highlightOnHover
              pointerOnHover
              pagination
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MachineApiPartners;
