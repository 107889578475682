/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import SeedSourceAutoTreatForm from './SeedSourceAutoTreatForm';

interface SeedSourceAutoTreatDetailProps {
  onBack?: () => void;
}

const SeedSourcesAutoTreatDetail: React.FC<SeedSourceAutoTreatDetailProps> = (props) => {
  const { onBack } = props;

  const { setDetailPage, activeSeedSourceAutoTreat, activeMachine } = React.useContext(SeedSourcesAutoTreatContext);

  return (
    <>
      <br />
      <SeedSourceAutoTreatForm
        seedSource={activeSeedSourceAutoTreat}
        machineSerial={activeMachine?.value}
        onSuccess={() => setDetailPage(false)}
        onBack={onBack}
      />
    </>
  );
};

export default SeedSourcesAutoTreatDetail;
