import React from 'react';
import tempPermissionGroups from '../constants/permissionGroups';
import tempPermissions from '../constants/permissions';

interface PermissionGroupsContextInterface {
  fetchPermissionGroups?: () => Promise<void>;
  permissionGroups?: any[];
  permissionGroupsLoading?: boolean;

  fetchPermissionGroup?: (permissionGroupVarietyId: string) => Promise<void>;
  permissionGroup?: any;
  permissionGroupLoading?: boolean;
  permissionGroupSubmitting?: boolean;

  fetchOptions?: () => Promise<void>;
  options?: Record<string, any[]>;
}

const PermissionGroupsContext = React.createContext<PermissionGroupsContextInterface>({});

const PermissionGroupsContextConsumer = PermissionGroupsContext.Consumer;
const PermissionGroupsContextProvider: React.FC = ({ children }) => {
  // const history = useHistory();

  const [permissionGroups, setPermissionGroups] = React.useState([]);
  const [permissionGroupsLoading, setPermissionGroupsLoading] = React.useState(true);

  const [permissionGroup, setPermissionGroup] = React.useState({});
  const [permissionGroupLoading, setPermissionGroupLoading] = React.useState(true);

  const fetchPermissionGroups = async () => {
    setPermissionGroupsLoading(true);
    setPermissionGroups(tempPermissionGroups);
    setPermissionGroupsLoading(false);
  };

  const fetchPermissionGroup = async (permissionGroupId: string) => {
    setPermissionGroupLoading(true);
    setPermissionGroupLoading(false);
    setPermissionGroup(tempPermissions[permissionGroupId]);
  };

  return (
    <PermissionGroupsContext.Provider
      value={{
        fetchPermissionGroups,
        permissionGroups,
        permissionGroupsLoading,

        fetchPermissionGroup,
        permissionGroup,
        permissionGroupLoading,
      }}
    >
      {children}
    </PermissionGroupsContext.Provider>
  );
};

export { PermissionGroupsContextProvider, PermissionGroupsContextConsumer, PermissionGroupsContext };
