import React from 'react';
import { Box, MenuItem, Typography, ListItemIcon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';

interface CompanyRowMenuProps {
  company: any;
}

const CompanyRowMenu: React.FC<CompanyRowMenuProps> = (props: CompanyRowMenuProps) => {
  const { company } = props;

  return (
    <Box>
      <CustomMaterialMenu size="small">
        <MenuItem>
          <ListItemIcon style={{ minWidth: 24 }}>
            <EditIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <a
            style={{ textDecoration: 'none' }}
            href={`/admin/company_management/${company.company_id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="inherit">Edit Company</Typography>
          </a>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default CompanyRowMenu;
