/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import { DriverAutoBulkFormValues } from '../model';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import { DriversAutoBulkValidationsContext } from '../contexts/DriversAutoBulkValidationsContext';
import SelectInput from '../../../modules/shared/form/SelectInput';

interface DriversAutoBulkFormProps {
  driver: any;
  onSuccess?: () => void;
}

const DriversAutoBulkForm: React.FC<DriversAutoBulkFormProps> = (props) => {
  const { driver, onSuccess } = props;

  const { driverSubmitting, createDriverAutoBulk, updateDriverAutoBulk, driverOptions } =
    React.useContext(DriversAutoBulkContext);
  const { validateDriversAutoBulkFields, formErrors } = React.useContext(DriversAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<DriverAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateDriverAutoBulk(formValues);
  };

  const handleCreate = () => {
    createDriverAutoBulk(formValues, onSuccess);
  };

  React.useEffect(() => {
    const abDriversFieldsValid = validateDriversAutoBulkFields(formValues);
    setActionButtonDisabled(!abDriversFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      driver_id: driver.driver_id,
      driver_name: driver.driver_name,
      driver_phone: driver.driver_phone,
      driver_email: driver.driver_email,
      driver_access_code: driver.driver_access_code,
      driver_comment: driver.driver_comment,
      active: driver.active,
      rfid_id: driver.rfid_id,
      rfid_name: driver.rfid_name,
      driver_api_partner: driver.driver_api_partner,
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.driver_name}
                onChange={(value) => setFormValues({ ...formValues, driver_name: value })}
                label="Driver Name"
                errorMessage={formErrors.DriverName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.driver_phone}
                onChange={(value) => setFormValues({ ...formValues, driver_phone: value })}
                label="Driver Phone"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.driver_email}
                onChange={(value) => setFormValues({ ...formValues, driver_email: value })}
                label="Driver Email"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.driver_access_code}
                onChange={(value) => setFormValues({ ...formValues, driver_access_code: value })}
                label="Driver Access Code"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.driver_comment}
                onChange={(value) => setFormValues({ ...formValues, driver_comment: value })}
                label="Comment"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.rfid_id}
                // groupedChoices={{
                //   'Not assigned': driverOptions.driverRFIDOptions.filter(
                //     (option) => option.transport_id === '' && option.driver_id === '',
                //   ),
                //   Assigned: driverOptions.driverRFIDOptions.filter(
                //     (option) => option.transport_id !== '' || option.driver_id !== '',
                //   ),
                // }}
                groupedChoices={{
                  'Not assigned': driverOptions.driverRFIDOptions.filter(
                    (option) => option.associated_transport_id === '' && option.associated_driver_id === '',
                  ),
                  'Assigned Transport': driverOptions.driverRFIDOptions
                    .filter((option) => option.associated_transport_id !== '')
                    .map((option) => ({
                      ...option,
                      label: `${option.label} ----> ${option.associated_transport_name}`,
                    })),
                  'Assigned Driver': driverOptions.driverRFIDOptions
                    .filter((option) => option.associated_driver_id !== '')
                    .map((option) => ({
                      ...option,
                      label: `${option.label} ----> ${option.associated_driver_name}`,
                    })),
                }}
                onChange={(value) => setFormValues({ ...formValues, rfid_id: value, rfid_name: value })}
                label="RFID"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          <>
            {driver?.driver_id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || driverSubmitting}
                onClick={handleUpdate}
              >
                {driverSubmitting ? 'Updating Driver...' : 'Update Driver'}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || driverSubmitting}
                onClick={handleCreate}
              >
                {driverSubmitting ? 'Creating Driver...' : 'Create Driver'}
              </Button>
            )}
          </>
        </form>
      </Grid>
    </Grid>
  );
};

export default DriversAutoBulkForm;
