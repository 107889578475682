import React from 'react';
import Logo from '../../../assets/ksi-logo.png';

const Footer: React.FunctionComponent = () => {
  return (
    <footer
      id="main-footer"
      style={{
        backgroundColor: '#333',
        color: '#fff',
        marginTop: 80,
        paddingTop: 40,
        paddingBottom: 20,
        position: 'absolute',
        bottom: 'auto',
        width: '100%',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img alt="Logo" src={Logo} style={{ width: '100px', marginBottom: 40 }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <ul style={{ display: 'inline', textAlign: 'center', padding: 0 }}>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{
                marginRight: 20,
                marginLeft: -10,
                color: '#fff',
                textDecoration: 'none',
              }}
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ color: '#fff', textDecoration: 'none', marginRight: 20, marginLeft: -10 }} href="/terms-of-use">
              Terms and Conditions
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ color: '#fff', textDecoration: 'none', marginLeft: -10 }} href="/terms-of-use">
              CCPA
            </a>
          </li>
        </ul>
      </div>
      <div id="footer-bottom">
        <div className="container clearfix">
          <div id="footer-info" style={{ textAlign: 'center', fontSize: 12, marginTop: 40 }}>
            © 2021 Copyright KSi. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
