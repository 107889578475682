/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Button, Typography, Tabs, Tab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../shared/tabs/TabPanel';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { UsersContext } from '../contexts/UsersContext';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import UserDetails from '../components/UserDetails';
import AssignMachineAccessForm from '../components/AssignMachineAccessForm';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import URLTabs from '../../shared/tabs/URLTabs';

interface RouteParams {
  userId: string;
}

const UserDetailPage: React.FC = () => {
  const { userId } = useParams<Record<string, string>>();
  const {
    user,
    fetchUser,
    userLoading,
    fetchUserMachinesByCompany,
    fetchUserPermissionGroups,
    fetchAdminPermissionGroups,
  } = useContext(UsersContext);

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { fetchCompanyOptions, companiesLoading } = React.useContext(CompaniesContext);

  const tabNames = ['profile', 'machine_access'];
  const baseUrl = `/admin/user_management/${user?.user_id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [machines, setMachines] = React.useState<any[]>();

  React.useEffect(() => {
    fetchCompanyOptions();
    fetchUser(userId);
    fetchUserPermissionGroups();
    fetchAdminPermissionGroups();
  }, [userId]);

  useEffect(() => {
    if (user.company_id) {
      console.log('fetching companies');
      fetchUserMachinesByCompany(user.company_id);
    }
  }, [user.company_id]);

  const links = [
    { href: '/admin/user_management', text: 'User Management' },
    { href: `/account_management/users/${user?.user_id}`, text: `${user?.full_name} | ${user?.email_address}` },
  ];

  return userLoading || companiesLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <CircularProgress />
      <Typography variant="h2" sx={{ fontSize: '18px', mt: 2 }}>
        Loading User...
      </Typography>
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="User Details" />
          </Tabs>
        </Box>
        <br />
        <TabPanel value={tabValue} index={0}>
          <UserDetails user={user} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AssignMachineAccessForm user={user} machines={machines} setMachines={setMachines} />
        </TabPanel>
      </div>
    </div>
  );
};

export default UserDetailPage;
