/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import OrdersAutoTreatDetail from '../components/OrdersAutoTreatDetail';
import URLTabs from '../../../modules/shared/tabs/URLTabs';

const OrdersAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveOrderAutoTreat, activeOrderAutoTreat, activeMachine, setCreatingPage } =
    React.useContext(OrdersAutoTreatContext);

  const tabNames = ['details'];
  const baseUrl = `/orders/detail/${activeOrderAutoTreat?.order_id}`;

  const handleBack = () => {
    setDetailPage(false);
    setActiveOrderAutoTreat(null);
    setCreatingPage(false);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeOrderAutoTreat?.order_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Order List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Update Order
      </Typography>
      <br />
      <OrdersAutoTreatDetail order={{}} onSuccess={() => setDetailPage(false)} onBack={handleBack} />
    </>
  );
};

export default OrdersAutoTreatDetailPage;
