import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Machine } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../utils/env';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface MachinesContextInterface {
  adminMachines?: any[];
  allUserAndCompanyMachines?: any[];
  autoBulkMachines?: any[];
  autoTreatMachines?: any[];
  cancelSql?: () => Promise<void>;
  clearSql?: () => Promise<void>;
  columnsList?: any;
  createMachine?: (formValues: any) => Promise<void>;
  enableCortevaRecipes?: (machine_serial: string) => Promise<void>;
  disableCortevaRecipes?: (machine_serial: string) => Promise<void>;
  databases?: any[];
  executeSql?: (machineSerial: string, machineSql: any, onSuccess?: any) => Promise<void>;
  exportJson?: string;
  fetchAdminMachines?: (filterParams?: string) => Promise<void>;
  fetchAllUserAndCompanyMachines?: () => Promise<void>;
  fetchAutoBulkMachines?: () => Promise<void>;
  fetchAutoTreatMachines?: () => Promise<void>;
  fetchDatabases?: (machineId?: string) => Promise<void>;
  fetchExportJson?: () => Promise<void>;
  fetchFilterOptions?: () => Promise<void>;
  fetchMachine?: (machineId: string) => Promise<void>;
  // fetchPaginatedAdminMachines?: (url?: string) => Promise<void>;
  // fetchPaginatedDatabases?: (url?: string) => Promise<void>;
  filterOptions?: Record<string, any>;
  filterOptionsLoading?: boolean;
  machine?: Record<string, any>;
  machineLoading?: boolean;
  machinesLoading?: boolean;
  machineSubmitting?: boolean;
  obsoleteMachine?: (machineId: string, onSuccess?: any) => Promise<void>;
  pingMachine?: (machineId: string) => Promise<void>;
  sqlResponse?: any;
  sqlSubmitting?: boolean;
  updateMachine?: (machineId: string, formValues: any, onSuccess?: any) => Promise<void>;
}

const MachinesContext = React.createContext<MachinesContextInterface>({});

const MachinesContextConsumer = MachinesContext.Consumer;
const MachinesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { showErrorSnackBar, showSuccessSnackBar } = React.useContext(SnackBarContext);

  const [autoTreatMachines, setAutoTreatMachines] = React.useState<Machine[]>([]);
  const [autoBulkMachines, setAutoBulkMachines] = React.useState<Machine[]>([]);
  const [allUserAndCompanyMachines, setAllUserAndCompanyMachines] = React.useState<Machine[]>([]);

  const [adminMachines, setAdminMachines] = React.useState([]);
  const [databases, setDatabases] = React.useState([]);

  const [machinesLoading, setMachinesLoading] = React.useState(true);
  const [machineSubmitting, setMachineSubmitting] = React.useState(false);

  const [machine, setMachine] = React.useState({});
  const [machineLoading, setMachineLoading] = React.useState(true);

  const [formErrors, setFormErrors] = React.useState({});

  const [filterOptions, setFilterOptions] = React.useState<Record<string, any>>({});
  const [exportJson, setExportJson] = React.useState<any>('');
  const [filterOptionsLoading, setFilterOptionsLoading] = React.useState<boolean>(false);

  const [sqlSubmitting, setSqlSubmitting] = React.useState<boolean>(false);
  const [cancelsql, setCancelsql] = React.useState<boolean>(false);

  const [sqlResponse, setSqlResponse] = React.useState({});
  const [columnsList, setColumnsList] = React.useState([]);

  const fetchExportJson = async () => {
    axios
      .get(`api/admin/machines/export`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setExportJson(response.data);
      })
      .catch(() => {});
  };

  const fetchFilterOptions = async () => {
    setFilterOptionsLoading(true);

    axios
      .get(`api/admin/machines/filter_options`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setFilterOptions({
          system_version: response.data.system_version,
          indusoft_project: response.data.indusoft_project,
          db_version: response.data.db_version,
          local_service_version: response.data.local_service_version,
          pac_version: response.data.pac_version,
        });
        setFilterOptionsLoading(false);
      })
      .catch(() => {
        setFilterOptionsLoading(false);
      });
  };

  const fetchAutoBulkMachines = async () => {
    setMachinesLoading(true);

    axios
      .get<string, any>('api/user/machines/?association=user&autobulk=true', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachinesLoading(false);
        const tempMachines = [];

        response.data.forEach((machine: Record<string, any>) => {
          if (machine.autobulk === true) {
            tempMachines.push({
              machine_id: machine.machine_id,
              label: machine.machine_name,
              value: machine.serial_number,
              serial_number: machine.serial_number,
              status: machine.status,
              metric: machine.metric,
              online_status: machine.connection_state,
              subscription_active: machine.subscription_active,
              manage_integration_records: machine.manage_integration_records,
              api_partners: machine.api_partners,
            });
          }
        });

        setAutoBulkMachines(tempMachines);
      });
  };

  const fetchAllUserAndCompanyMachines = async (filterParams = '') => {
    setMachinesLoading(true);

    axios
      .get<string, any>(`api/user/machines/?association=user_and_company&${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachinesLoading(false);
        const tempMachines = [];

        response.data.forEach((machine: Record<string, string>) => {
          tempMachines.push({
            label: machine?.machine_name,
            value: machine?.serial_number,
            serial_number: machine?.serial_number,
            status: machine?.status,
            metric: machine?.metric,
            online_status: machine?.connection_state,
            subscription_active: machine?.subscription_active,
            manage_integration_records: machine?.manage_integration_records,
            api_partners: machine?.api_partners,
          });
        });

        setAllUserAndCompanyMachines(tempMachines);
      });
  };

  const fetchAutoTreatMachines = async () => {
    setMachinesLoading(true);

    axios
      .get<string, any>('api/user/machines/?association=user&autobulk=false', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachinesLoading(false);
        const tempMachines = [];

        response.data.forEach((machine: Record<string, any>) => {
          if (machine.autobulk !== true) {
            tempMachines.push({
              machine_id: machine.machine_id,
              label: machine.machine_name,
              value: machine.serial_number,
              serial_number: machine.serial_number,
              status: machine.status,
              metric: machine.metric,
              online_status: machine.connection_state,
              subscription_active: machine.subscription_active,
              manage_integration_records: machine.manage_integration_records,
              api_partners: machine.api_partners,
            });
          }
        });

        setAutoTreatMachines(tempMachines);
      });
  };

  const fetchAdminMachines = async (filterParams = '') => {
    setMachinesLoading(true);

    axios
      .get<string, any>(`api/admin/machines/?${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachinesLoading(false);
        setAdminMachines(response.data);
      });
  };

  const fetchDatabases = async (machineId: string) => {
    setMachinesLoading(true);

    axios
      .get<string, any>(
        `api/admin/machines/downloads/?machine_id=${machineId}&utc_offset=${new Date().getTimezoneOffset()}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        setMachinesLoading(false);

        setDatabases(response.data);
      });
  };

  const fetchMachine = async (machineId: string) => {
    setMachineLoading(true);

    axios
      .get<string, any>(`api/admin/machines/${machineId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineLoading(false);

        setMachine(response.data);
      });
  };

  const pingMachine = async (machineSerial: string) => {
    console.log(`pingMachine running with machine: ${machineSerial}`);
    setMachineLoading(true);
    showSuccessSnackBar('Sending ping to machine...');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/admin/machines/ping/`,
        { machineSerial },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        console.log('res');
        console.log(res);
        const fetchTimer = setInterval(() => {
          axios
            .get<any, any>(`api/admin/machines/ping/${machineSerial}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              console.log(`response: ${response}`);
              window.clearInterval(fetchTimer);
              showSuccessSnackBar('Machine is online');
            })
            .catch((error) => {
              console.log(`error: ${error}`);
              retryCount += 1;

              if (retryCount > 10) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No ping response recevied from machine: machine may be offline.');
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateMachine = async (machineId: string, formValues: any, onSuccess?: any) => {
    setMachineSubmitting(true);

    axios
      .put<any, any>(
        `api/admin/machines/${machineId}/`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setMachineSubmitting(false);
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  const enableCortevaRecipes = async (machine_serial: string) => {
    console.log(`disable corteva recipes running with serial_number: ${machine_serial}`);

    axios
      .post<any>(
        `api/admin/machines/mfr_recipe/?serial_number=${machine_serial}&mfr_global_id=3003&mfr_recipe_state=1`,
        { test123: 'testing123' },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error('Error enabling Corteva recipes on machine:', error);
      });
  };

  const disableCortevaRecipes = async (machine_serial: string) => {
    console.log(`disable corteva recipes running with serial_number: ${machine_serial}`);

    axios
      .post<any>(
        `api/admin/machines/mfr_recipe/?serial_number=${machine_serial}&mfr_global_id=3003&mfr_recipe_state=0`,
        { test123: 'testing123' },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error('Error disabling Corteva recipes on machine:', error);
      });
  };

  const createMachine = async (formValues: any) => {
    setMachineSubmitting(true);

    axios
      .post<string, any>(
        `api/admin/machines/`,
        { ...formValues, company: formValues.company, active: '1', status: '1' },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        setMachineSubmitting(false);
        // Since the response structure is {message: 'Machine created successfully', machine_id: #}
        if (response.data.machine_id) {
          history.push(`/admin/machine_management/${response.data.machine_id}`);
        } else {
          console.error(`Machine ID not found in the response. Message: ${response.data.message}`);
          const errors = response.data.result || {};
          setFormErrors({
            full_name: errors.machine_name,
            email_address: errors.description,
            company: errors.company,
          });
        }
      })
      .catch((error) => {
        console.error('Error creating machine:', error);
        setMachineSubmitting(false);
      });
  };

  const obsoleteMachine = async (machineId: string, onSuccess?: any) => {
    setMachineSubmitting(true);

    axios
      .post<any, any>(`api/admin/machines/${machineId}/obsolete/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineSubmitting(false);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setMachineSubmitting(false);
        console.error(error);
      });
  };

  const executeSql = async (machineSerial: string, machineSql: any, onSuccess?: any) => {
    setSqlSubmitting(true);
    let retryCount = 0;
    setSqlResponse({});
    setColumnsList([]);

    axios
      .post<any, any>(
        `api/admin/machines/execute_sql/?serial-number=${machineSerial}`,
        { machineSql },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          if (cancelsql === false) {
            axios
              .get<string, any>(`api/admin/machines/execute_sql/${res.data.result}`, {
                headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
              })
              .then((response) => {
                window.clearInterval(fetchTimer);

                setSqlSubmitting(false);
                setSqlResponse(response.data);

                if (machineSql.includes(';')) {
                  setColumnsList([]);
                } else if (response.data?.results[0]?.records?.length > 0) {
                  setColumnsList(Object?.keys(response.data?.results[0].records));
                } else {
                  setColumnsList(Object?.keys(response.data?.results[0]));
                }
              })
              .catch((error) => {
                retryCount += 1;

                if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                  window.clearInterval(fetchTimer);
                  setSqlSubmitting(false);
                  setSqlResponse({});
                  setColumnsList([]);
                }
              });
          } else {
            setSqlResponse({});
            setColumnsList([]);
            setSqlSubmitting(false);
          }
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const clearSql = async () => {
    setSqlSubmitting(false);
    setSqlResponse({});
    setColumnsList([]);
  };

  const cancelSql = async () => {
    setSqlSubmitting(false);
    setCancelsql(true);
    setSqlResponse({});
    setColumnsList([]);
  };

  return (
    <MachinesContext.Provider
      value={{
        adminMachines,
        allUserAndCompanyMachines,
        autoBulkMachines,
        autoTreatMachines,
        cancelSql,
        clearSql,
        columnsList,
        createMachine,
        enableCortevaRecipes,
        disableCortevaRecipes,
        databases,
        executeSql,
        exportJson,
        fetchAdminMachines,
        fetchAllUserAndCompanyMachines,
        fetchAutoBulkMachines,
        fetchAutoTreatMachines,
        fetchDatabases,
        fetchExportJson,
        fetchFilterOptions,
        fetchMachine,
        // fetchPaginatedAdminMachines,
        // fetchPaginatedDatabases,
        filterOptions,
        machine,
        machineLoading,
        machinesLoading,
        machineSubmitting,
        pingMachine,
        obsoleteMachine,
        sqlResponse,
        sqlSubmitting,
        updateMachine,
      }}
    >
      {children}
    </MachinesContext.Provider>
  );
};

export { MachinesContextProvider, MachinesContextConsumer, MachinesContext };
