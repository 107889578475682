import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
  Card,
  CardContent,
  Tabs,
  Tab,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import { UsersContext } from '../contexts/UsersContext';
import UserForm from '../components/UserForm';
import URLTabs from '../../shared/tabs/URLTabs';

interface RouteParams {
  userId: string;
}

const UserDetailPage = () => {
  const { userId } = useParams<RouteParams>();
  const { user, fetchUser, userLoading, fetchUserPermissionGroups, fetchUserMachinesByCompany } =
    useContext(UsersContext);

  useEffect(() => {
    fetchUser(userId);
    fetchUserPermissionGroups();
    fetchUserMachinesByCompany();
  }, [userId]);

  const tabNames = ['details'];
  const baseUrl = `/admin/user_management/${user?.user_id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  const links = [
    { href: '/account_management/users', text: 'User Management' },
    { href: `/account_management/users/${user?.user_id}`, text: `${user?.full_name} | ${user?.email_address}` },
  ];

  return userLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <CircularProgress />
      <Typography variant="h2" sx={{ fontSize: '18px', mt: 2 }}>
        Loading User...
      </Typography>
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="User Details" />
          </Tabs>
        </Box>
        <br />
        <Box>
          <Grid justifyContent="space-between" container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h2" component="h2">
                        User Information
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <UserForm user={user} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default UserDetailPage;
