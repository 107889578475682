/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import { TreatmentProductAutoTreat } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import TreatmentProductAutoTreatFilterBar from '../components/TreatmentProductsAutoTreatFilterBar';

const TreatmentProductsAutoTreatList: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    liquid_name: '',
    liquid_name_desc: '',
    lot: '',
    epa_number: '',
  });
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  const {
    treatmentProducts,
    setCreatingPage,
    setDetailPage,
    setActiveTreatmentProductAutoTreat,
    activeMachine,
    setActiveMachine,
  } = React.useContext(TreatmentProductsAutoTreatContext);

  const filterTreatmentProducts = () => {
    if (tableFilters.liquid_name || tableFilters.liquid_name_desc || tableFilters.lot || tableFilters.epa_number) {
      const liquidNameFilteredCustomers = treatmentProducts.filter((treatmentProduct) =>
        treatmentProduct.liquid_name?.toLowerCase().includes(tableFilters.liquid_name?.toLowerCase()),
      );

      const descriptionFilteredCustomers = liquidNameFilteredCustomers.filter((treatmentProduct) =>
        treatmentProduct.liquid_name_desc?.toLowerCase().includes(tableFilters.liquid_name_desc?.toLowerCase()),
      );

      const lotFilteredCustomers = descriptionFilteredCustomers.filter(
        (treatmentProduct) =>
          treatmentProduct.liquid_lot_data?.toLowerCase().includes(tableFilters.lot?.toLowerCase()) ||
          treatmentProduct.shipment_number?.toLowerCase().includes(tableFilters.lot?.toLowerCase()),
      );

      const epaNumberFilteredCustomers = lotFilteredCustomers.filter((treatmentProduct) =>
        treatmentProduct.epa_number?.toLowerCase().includes(tableFilters.epa_number?.toLowerCase()),
      );

      return epaNumberFilteredCustomers;
    }

    return treatmentProducts;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Treatment Product Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <TreatmentProductAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid justifyContent="space-between" container spacing={0}>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Treatment Product List
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={activeMachine?.value === 'Select A Machine' || activeMachine?.value === undefined}
                  onClick={() => {
                    setCreatingPage(true);
                    setFitlerBarActive(false);
                  }}
                >
                  New Product
                </Button>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterTreatmentProducts()}
                defaultSortField="TreatmentProductID"
                defaultSortAsc={false}
                striped
                onRowClicked={(row: TreatmentProductAutoTreat) => {
                  setActiveTreatmentProductAutoTreat(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default TreatmentProductsAutoTreatList;
