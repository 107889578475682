/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { Box, InputBase, ButtonBase, ClickAwayListener, Popper, TextField, Checkbox, Chip, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from '@mui/material/Autocomplete';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    maxHeight: 250,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'}`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

interface AutocompleteFilterProps {
  label: string;
  loading?: boolean;
  pluralLabel: string;
  choices: any[];
  setValue: (value: any) => void;
  value: any[];
  filterWidth?: number;
  multiple?: boolean;
}

const AutocompleteFilter: React.FC<AutocompleteFilterProps> = (props) => {
  const { label, loading = false, pluralLabel, choices, setValue, value, filterWidth, multiple = true } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = React.useState<any[]>([]);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'autocomplete-label' : undefined;

  const formattedValue = () => {
    if (value?.length > 1) {
      return `${value?.length} ${pluralLabel}`;
    }

    if (value?.[0] !== undefined) {
      return value?.[0];
    }

    return 'All';
  };

  return (
    <>
      <Box sx={{ width: 'auto', fontSize: 13 }}>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text" htmlFor="formInput">
              {label}
            </label>
          </div>
        </div>
        <TextField
          sx={{
            '& .MuiInputBase-input': {
              padding: '12px !important',
              fontSize: '12px !important',
              height: '0.8375em',
              width: formattedValue() === 'All' ? '100%' : 'auto',
            },
          }}
          value={formattedValue()}
          onClick={handleClick}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  data-testid="ArrowDropDownIcon"
                >
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              {pluralLabel}
            </Box>
            <Autocomplete
              open
              multiple={multiple}
              onClose={(event: React.ChangeEvent<any>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingValue(newValue);
                setValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={loading ? 'Loading...' : 'No data'}
              renderOption={(props, option, { selected }) => (
                <>
                  {loading ? (
                    <p style={{ margin: 7 }}>Loading...</p>
                  ) : (
                    <li {...props}>
                      <Checkbox checked={selected} style={{ padding: 3 }} />
                      <Box
                        component="span"
                        sx={{
                          width: 14,
                          height: 14,
                          flexShrink: 0,
                          borderRadius: '3px',
                          mr: 1,
                          mt: '2px',
                        }}
                        /* style={{ backgroundColor: option.color }} */
                      />
                      <Box
                        sx={{
                          flexGrow: 1,
                          '& span': {
                            color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                          },
                        }}
                      >
                        {option}
                      </Box>
                      <Box
                        component={CloseIcon}
                        sx={{ opacity: 0.6, width: 18, height: 18 }}
                        style={{
                          visibility: selected ? 'visible' : 'hidden',
                        }}
                      />
                    </li>
                  )}
                </>
              )}
              options={loading ? ['loading'] : [...choices]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={`Search ${pluralLabel?.toLowerCase()}...`}
                />
              )}
            />
            <hr />
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box sx={{ padding: '8px 10px', fontWeight: 600 }}>
                  {`Selected (${pendingValue ? pendingValue.length : '0'})`}
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{ padding: '8px 10px', fontWeight: 600, color: '#0645AD', cursor: 'pointer' }}
                  onClick={() => {
                    setValue([]);
                    setPendingValue([]);
                  }}
                >
                  Clear All
                </Box>
              </Grid>
            </Grid>
            <div style={{ padding: 5 }}>
              <Grid container spacing={1}>
                {multiple
                  ? pendingValue?.map((selectedValue, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={selectedValue}
                          onDelete={() => setPendingValue(pendingValue.filter((e) => e !== selectedValue))}
                        />
                        <br />
                      </Grid>
                    ))
                  : pendingValue && (
                      <Grid item>
                        <Chip label={pendingValue} onDelete={() => setPendingValue([])} />
                      </Grid>
                    )}
              </Grid>
            </div>
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default AutocompleteFilter;
