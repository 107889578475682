/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import DataTable from '../../../modules/dataTable';
import transactionTreatmentColumns from '../constants/transactionTreatmentColumns';

interface TreatmentProductInformationProps {
  transaction?: any;
}

const TreatmentProductInformation: React.FunctionComponent<TreatmentProductInformationProps> = (props) => {
  const { transaction } = props;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" style={{ marginTop: '-10px', marginBottom: '-15px' }}>
            Treatment Products
          </Typography>
          <br />
          <Divider flexItem style={{ backgroundColor: 'black', height: '1px', width: '100%' }} />
          <DataTable
            columns={transactionTreatmentColumns}
            data={transaction?.treatment_products}
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            dense
            noHeader
          />
          <Divider flexItem style={{ backgroundColor: 'black', height: '0.5px', width: '100%' }} />
        </Grid>
      </Grid>
    </>
  );
};

export default TreatmentProductInformation;
