import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Transport ID',
    selector: 'transport_id',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Transport Name',
    selector: 'transport_name',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Transport Company Name',
    selector: 'transport_company_name',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Transport Number',
    selector: 'transport_number',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Rfid Name',
    selector: 'rfid_name',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Comment',
    selector: 'transport_comment',
    sortable: true,
    cell: (row: any) => <p style={{ maxHeight: 70, overflowY: 'auto' }}>{row.transport_comment}</p>,
  },
  {
    name: 'Active',
    selector: 'work_order_complete',
    sortable: true,
    grow: 0.6,
    cell: (row: any) => <p>{row.active === 'True' ? 'Yes' : 'No'}</p>,
  },
];

export default columns;
