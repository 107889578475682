import React from 'react';
import axios from '../../utils/axios.utils';
import { MachineRequest } from '../model';

interface MachineRequestsContextInterface {
  fetchMachineRequests?: (filterParams?: string, pageSize?: any) => Promise<void>;
  machineRequests?: MachineRequest[];
  machineRequestsLoading?: boolean;

  fetchMachineRequest?: (filterParams?: string, pageSize?: any) => Promise<void>;
  machineRequest?: MachineRequest;
  machineRequestLoading?: boolean;

  fetchMachineRequestRecords?: (machineRequestId?: string, onSuccess?: () => void) => Promise<void>;
  machineRequestRecords?: any;
  machineRequestRecordsLoading?: boolean;

  fetchMachineRequestRecord?: (machineRequestId?: string, machine_request_record_id?: string) => Promise<void>;
  machineRequestRecord?: any;
  machineRequestRecordLoading?: boolean;

  errorMessage?: string;
}

const MachineRequestsContext = React.createContext<MachineRequestsContextInterface>({});

const MachineRequestsContextConsumer = MachineRequestsContext.Consumer;
const MachineRequestsContextProvider: React.FC = ({ children }) => {
  const [machineRequests, setMachineRequests] = React.useState<MachineRequest[]>([]);
  const [machineRequestsLoading, setMachineRequestsLoading] = React.useState<boolean>(true);

  const [machineRequest, setMachineRequest] = React.useState<MachineRequest | null>(null);
  const [machineRequestLoading, setMachineRequestLoading] = React.useState<boolean>(true);

  const [machineRequestRecords, setMachineRequestRecords] = React.useState<MachineRequest>();
  const [machineRequestRecordsLoading, setMachineRequestRecordsLoading] = React.useState<boolean>(false);

  const [machineRequestRecord, setMachineRequestRecord] = React.useState<MachineRequest>();
  const [machineRequestRecordLoading, setMachineRequestRecordLoading] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const computeRecordInfo = (record) => {
    const jsonString = record.request_record_object.replace(/'/g, '"');
    const parsedObject = JSON.parse(jsonString);

    const recordName =
      parsedObject.customer_name || parsedObject.operation || parsedObject.product_name || parsedObject.variety || '';

    let recordInfo = recordName;
    if (parsedObject.api_partner) {
      recordInfo += ` | ${parsedObject.api_partner}`;
    }

    ['partner_record_id_1', 'partner_record_id_2', 'partner_record_id_3'].forEach((key) => {
      if (parsedObject[key]) {
        recordInfo += ` | ${parsedObject[key]}`;
      }
    });

    return recordInfo;
  };

  const fetchMachineRequests = async (filterParams = '') => {
    setMachineRequestsLoading(true);

    axios
      .get<string, any>(`api/companies/0/machine_requests/?${filterParams}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineRequests(response.data.result);
        setMachineRequestsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequests([]);
        setMachineRequestsLoading(false);
      });
  };

  const fetchMachineRequest = async (machineRequestId) => {
    setMachineRequestLoading(true);

    axios
      .get<string, any>(`api/companies/0/machine_requests/${machineRequestId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineRequest(response.data.result);
        setMachineRequestLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequest(null);
        setMachineRequestLoading(false);
      });
  };

  const fetchMachineRequestRecords = async (machineRequestId = '', onSuccess?: () => void) => {
    if (onSuccess) {
      setMachineRequestRecordsLoading(false);
    } else {
      setMachineRequestRecordsLoading(true);
    }

    axios
      .get<string, any>(`api/companies/0/machine_requests/${machineRequestId}/machine_request_records`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        const modifiedRecords = response.data.result.map((record) => ({
          ...record,
          record_info: computeRecordInfo(record),
        }));

        setMachineRequestRecords(modifiedRecords);

        if (onSuccess) {
          onSuccess();
        } else {
          setMachineRequestRecordsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequestRecords(null);
        setMachineRequestRecordsLoading(false);
      });
  };

  const fetchMachineRequestRecord = async (machineRequestId = '', machine_request_record_id) => {
    setMachineRequestRecordLoading(true);

    axios
      .get<string, any>(
        `api/companies/0/machine_requests/${machineRequestId}/machine_request_records/${machine_request_record_id}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        setMachineRequestRecord(response.data.result);
        setMachineRequestRecordLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequestRecord(null);
        setMachineRequestRecordLoading(false);
      });
  };

  return (
    <MachineRequestsContext.Provider
      value={{
        fetchMachineRequests,
        machineRequests,
        machineRequestsLoading,

        fetchMachineRequest,
        machineRequest,
        machineRequestLoading,

        fetchMachineRequestRecords,
        machineRequestRecords,
        machineRequestRecordsLoading,

        fetchMachineRequestRecord,
        machineRequestRecord,
        machineRequestRecordLoading,

        errorMessage,
      }}
    >
      {children}
    </MachineRequestsContext.Provider>
  );
};

export { MachineRequestsContextProvider, MachineRequestsContextConsumer, MachineRequestsContext };
