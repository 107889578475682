/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import OrdersAutoTreatDetail from './OrdersAutoTreatDetailPage';
import OrdersAutoTreatList from './OrdersAutoTreatList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';
import OrdersAutoTreatCreate from './OrdersAutoTreatCreate';

const OrdersAutoTreatPage: React.FunctionComponent = () => {
  const { fetchOrdersAutoTreat, detailPage, creatingPage, activeMachine } = React.useContext(OrdersAutoTreatContext);

  const autoTreatOrderPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <OrdersAutoTreatDetail />,
          createPage: <OrdersAutoTreatCreate />,
          listPage: <OrdersAutoTreatList />,
        }[autoTreatOrderPage()]
      }
    </>
  );
};

export default OrdersAutoTreatPage;
