import React from 'react';
import { Button, Grid, GridSize, Typography } from '@mui/material';
import { SelectOption, FilterProps } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { LevelSensorsAutoBulkContext } from '../contexts/LevelSensorsAutoBulkContext';
import FormInput from '../../../modules/shared/form/FormInput';

interface LevelSensorsLiveFilterBarProps {
  filters?: FilterProps[];
  tableFilters?: any;
  setTableFilters?: any;
}

// TODO Live Filters are not working on this page.

const LevelSensorsLiveFilterBar: React.FC<LevelSensorsLiveFilterBarProps> = (props) => {
  const { filters, tableFilters, setTableFilters } = props;

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
            Live Filters
          </Typography>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item md={2} xs={6}>
                <FormInput
                  value={tableFilters?.sensor_name}
                  onChange={(value) => setTableFilters({ ...tableFilters, sensor_name: value })}
                  label="Sensor Name"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <FormInput
                  value={tableFilters?.source_name}
                  onChange={(value) => setTableFilters({ ...tableFilters, source_name: value })}
                  label="Source Name"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <FormInput
                  value={tableFilters?.product_name}
                  onChange={(value) => setTableFilters({ ...tableFilters, product_name: value })}
                  label="Product Name"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <FormInput
                  value={tableFilters?.source_description}
                  onChange={(value) => setTableFilters({ ...tableFilters, source_description: value })}
                  label="Source Desc"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LevelSensorsLiveFilterBar;
