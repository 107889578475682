import * as React from 'react';
import { Grid } from '@mui/material';
import { AutoTreatTransaction } from '../model';
import TransactionDetails from './TransactionDetails';
import SeedDeliveryInformation from './SeedDeliveryInformation';
import RecipeDetails from './RecipeDetails';
import TreatmentProductInformation from './TreatmentProductInformation';

type SideBarContentProps = {
  transaction: AutoTreatTransaction;
};

const SidebarContent: React.FC<SideBarContentProps> = (props) => {
  const { transaction } = props;

  return (
    <>
      <Grid
        justifyContent="space-between"
        container
        spacing={3}
        style={{ marginLeft: '0px', marginTop: '0px', paddingRight: '50px' }}
      >
        <Grid item xs={12}>
          <TransactionDetails transaction={transaction} />
        </Grid>
        <br />
        <Grid item xs={12}>
          <SeedDeliveryInformation transaction={transaction} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <RecipeDetails transaction={transaction} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '0px' }}>
          <TreatmentProductInformation transaction={transaction} />
        </Grid>
      </Grid>
    </>
  );
};

export default SidebarContent;
