/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Grid, Box, Tabs, Tab } from '@mui/material';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import TreatmentProductsAutoTreatDetail from '../components/TreatmentProductsAutoTreatDetail';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import IntegrationsList from '../components/IntegrationsList';
import { TreatmentProductIntegrationsAutoTreatContext } from '../contexts/TreatmentProductIntegrationsAutoTreatContext';
import MachineIntegrationTabs from '../../../modules/shared/tabs/MachineIntegrationTabs';

const TreatmentProductsAutoTreatDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveTreatmentProductAutoTreat, activeTreatmentProductAutoTreat, activeMachine } =
    React.useContext(TreatmentProductsAutoTreatContext);
  const { fetchTreatmentProductIntegrationsAutoTreat } = React.useContext(TreatmentProductIntegrationsAutoTreatContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchAutoTreatMachines, autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);
  const { tabValue, handleTabChange } = MachineIntegrationTabs(
    ['details', 'integrations'],
    `/autotreat/${activeMachine?.value}/treatment_products`,
  );

  React.useEffect(() => {
    const getIntegrations = async () => {
      if (!initialDataLoaded) {
        fetchTreatmentProductIntegrationsAutoTreat(
          activeMachine?.value,
          activeTreatmentProductAutoTreat.liquid_name_id,
        );
      }
    };

    getIntegrations();
  }, [initialDataLoaded, setDataLoaded]);

  const handleBack = () => {
    setDetailPage(false);
    setActiveTreatmentProductAutoTreat(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeTreatmentProductAutoTreat.liquid_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Treatment Product List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
          <Tab style={{ textTransform: 'capitalize' }} label="Integrations" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <TreatmentProductsAutoTreatDetail
              treatmentProduct={activeTreatmentProductAutoTreat}
              machineSerial={activeMachine?.value}
              onSuccess={() => setDetailPage(false)}
              onBack={handleBack}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <IntegrationsList activeMachine={activeMachine} treatmentProduct={activeTreatmentProductAutoTreat} />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default TreatmentProductsAutoTreatDetailPage;
