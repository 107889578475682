import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import CompanyForm from '../components/CompanyForm';

const NewCompanyPage: FunctionComponent = () => {
  const history = useHistory();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/admin/company_management`)}>
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="h1" component="h1">
            Create Company
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography style={{ position: 'relative', top: 10, marginLeft: 10, fontWeight: 700 }}>
            Headquarters
          </Typography>
        </Grid>
      </Grid>
      <br />
      <CompanyForm company={{ status: '1', managed: true }} />
    </>
  );
};

export default NewCompanyPage;
