import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import NotificationFilterBar from '../components/NotificationFilterBar';
import { NotificationContext } from '../contexts/NotificationContext';
import columns from '../constants/columns';
import { UserMachinesContext } from '../../machinesAccountManagement/contexts/UserMachinesContext';

const NotificationListPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchNotifications, notificationsList, notificationsLoading, deleteNotification } =
    React.useContext(NotificationContext);
  const { fetchAllCompanyMachines, accountMachines } = React.useContext(UserMachinesContext);

  const [filterValues, setFilterValues] = useState({
    machineSerialNumber: '',
    notificationName: '',
    product: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initializeData = async () => {
      try {
        await fetchAllCompanyMachines();
        await fetchNotifications();
      } catch (error) {
        setErrorMessage('Failed to fetch data');
      }
    };

    initializeData();
  }, []);

  const applyFilter = useCallback(
    (notifications) => {
      return notifications.filter((notification) => {
        return (
          (filterValues.machineSerialNumber === '' ||
            notification.serial_number.toLowerCase().includes(filterValues.machineSerialNumber.toLowerCase())) &&
          (filterValues.notificationName === '' ||
            notification.notification_name.toLowerCase().includes(filterValues.notificationName.toLowerCase())) &&
          (filterValues.product === '' ||
            notification.product_list.some((product) =>
              product.toLowerCase().includes(filterValues.product.toLowerCase()),
            ))
        );
      });
    },
    [filterValues],
  );

  const handleDeleteNotification = async (notificationId: string) => {
    if (window.confirm('Are you sure you want to delete this notification?')) {
      try {
        await deleteNotification(notificationId);
        await fetchNotifications();
      } catch (error) {
        setErrorMessage('Failed to delete notification');
      }
    }
  };

  return (
    <Box>
      <Typography variant="h1" component="h1" sx={{ mb: 4 }}>
        Notification Management
      </Typography>
      <NotificationFilterBar
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        machineChoices={accountMachines}
      />
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Card sx={{ mt: 2 }} className="notifications-table">
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h2" sx={{ fontSize: '22px' }}>
              Notification List
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/account_management/notifications/new')}
            >
              New Notification
            </Button>
          </Grid>
          {notificationsLoading ? (
            <Typography variant="h2" sx={{ textAlign: 'center', my: 2, fontSize: '20px' }}>
              Loading Notifications...
            </Typography>
          ) : (
            <DataTable
              noHeader
              columns={columns}
              data={applyFilter(notificationsList)}
              onRowClicked={(row) => history.push(`/account_management/notifications/${row.notification_id}`)}
              defaultSortAsc={false}
              striped
              highlightOnHover
              pointerOnHover
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 25, 50]}
              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Delete Notification',
                  onClick: (event, row) => handleDeleteNotification(row.notification_id),
                },
              ]}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotificationListPage;
