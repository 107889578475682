import React from 'react';
import { Grid, Paper, Divider, Skeleton } from '@mui/material';
import MachineConnectionState from '../components/MachineConnectionState';
import MachineDetails from '../components/MachineDetails';
import MachineSubscriptionStatus from '../components/MachineSubscriptionStatus';
import OrdersChart from '../components/OrdersChart';
import UnitsChart from '../components/UnitsChart';
import VarietiesCard from '../components/VarietiesCard';
import CustomersCard from '../components/CustomersCard';
import { MachineDashboardContext } from '../contexts/MachineDashboardContext';

const MachineDashboardPage: React.FC = () => {
  const {
    fetchMachineDashboardData,
    machineDashboardData,
    machineDashboardStats,
    ordersLoading,
    machineSubscriptionLoading,
    connectionStateLoading,
  } = React.useContext(MachineDashboardContext);

  const MachineDetailsMemo = React.memo(MachineDetails);
  const OrdersChartMemo = React.memo(OrdersChart);
  const UnitsChartMemo = React.memo(UnitsChart);
  const VarietiesCardMemo = React.memo(VarietiesCard);
  const CustomersCardMemo = React.memo(CustomersCard);

  React.useEffect(() => {
    fetchMachineDashboardData();
  }, [fetchMachineDashboardData]);

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const options = {
      year: '2-digit' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(dateObj);
  };

  const processSingleMachineData = (machineData) => {
    const ordersData = {
      currentYear: machineData.order_count_current_year,
      lastYear: machineData.order_count_last_year,
    };

    const unitsData = {
      currentYear: machineData.total_amount_current_year,
      lastYear: machineData.total_amount_last_year,
    };

    const machineDetailsData: Record<string, string> = {
      systemType: machineData.system_type ? machineData.system_type : '',
      machineName: machineData.machine_name ? machineData.machine_name : machineData.serial_number,
      serialNumber: machineData.serial_number ? machineData.serial_number : '',
      systemVersion: machineData.system_version ? machineData.system_version : '',
      connectionStatus: machineData.connection_state ? 'Online' : 'Offline',
      lastActive: machineData.last_online_date_time ? formatDate(machineData.last_online_date_time) : '',
      subscriptionStatus: machineData.subscription_active ? 'Active' : '',
      expirationDate: machineData.subscription_expiration_date
        ? formatDate(machineData.subscription_expiration_date)
        : '',
      daysToExpiry: machineData.subscription_expiration_days_remaining
        ? String(Math.round(Number(machineData.subscription_expiration_days_remaining)))
        : '',
      softwareVersion: machineData.software_version ? machineData.software_version : '',
    };

    const customersData = {
      topCustomers: machineData.top_customer_current_year,
      currentYear: machineData.current_year,
    };

    const varietiesData = {
      topVarieties: machineData.top_variety_current_year,
      currentYear: machineData.current_year,
    };

    return {
      ordersData,
      unitsData,
      machineDetailsData,
      customersData,
      varietiesData,
    };
  };

  const processAllMachinesData = (machineDataArray) => {
    return machineDataArray.map((machineData) => processSingleMachineData(machineData));
  };

  const processedMachinesDataArray = React.useMemo(
    () => processAllMachinesData(machineDashboardData),
    [machineDashboardData],
  );

  return (
    <div style={{ padding: '20px', maxHeight: '80vh' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
          <Paper elevation={3} style={{ width: '100%', padding: '10px', height: '100%' }}>
            {machineSubscriptionLoading ? (
              <Skeleton variant="rectangular" height={300} width="100%" />
            ) : (
              machineDashboardStats &&
              machineDashboardStats.online_machines !== undefined && (
                <div style={{ maxWidth: '350px', margin: '0 auto' }}>
                  <MachineConnectionState machineStats={machineDashboardStats} />
                </div>
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
          <Paper elevation={3} style={{ width: '100%', padding: '10px', height: '100%' }}>
            {machineSubscriptionLoading ? (
              <Skeleton variant="rectangular" height={300} width="100%" />
            ) : (
              machineDashboardStats &&
              machineDashboardStats.active_services !== undefined && (
                <div style={{ maxWidth: '350px', margin: '0 auto' }}>
                  <MachineSubscriptionStatus machineStats={machineDashboardStats} />
                </div>
              )
            )}
          </Paper>
        </Grid>
        {processedMachinesDataArray.map((machineData, index) => (
          <Grid key={index} item xs={12} style={{ marginBottom: '10px', border: '0px solid #D1D1D1' }}>
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
              {/* Machine Details */}
              <Grid item xs={12} md={2} style={{ minWidth: '325px' }}>
                <Paper elevation={3} style={{ padding: '10px', height: '100%' }}>
                  {ordersLoading ? (
                    <Skeleton variant="rectangular" height="100%" />
                  ) : (
                    <MachineDetailsMemo
                      machineName={machineData.machineDetailsData.machineName}
                      serialNumber={machineData.machineDetailsData.serialNumber}
                      systemType={machineData.machineDetailsData.systemType}
                      systemVersion={machineData.machineDetailsData.systemVersion}
                      connectionStatus={machineData.machineDetailsData.connectionStatus}
                      lastActive={machineData.machineDetailsData.lastActive}
                      subscriptionStatus={machineData.machineDetailsData.subscriptionStatus}
                      expirationDate={machineData.machineDetailsData.expirationDate}
                      daysToExpiry={machineData.machineDetailsData.daysToExpiry}
                      softwareVersion={machineData.machineDetailsData.softwareVersion}
                    />
                  )}
                </Paper>
              </Grid>

              {/* Orders and Units Chart */}
              <Grid item md={2} style={{ minWidth: '250px' }}>
                <Paper elevation={3} style={{ padding: '10px', height: '100%' }}>
                  {ordersLoading ? (
                    <>
                      <Skeleton variant="rectangular" height="45%" />
                      <Divider style={{ margin: '5% 0' }} />
                      <Skeleton variant="rectangular" height="45%" />
                    </>
                  ) : (
                    <>
                      <OrdersChartMemo
                        currentYear={machineData.ordersData.currentYear}
                        lastYear={machineData.ordersData.lastYear}
                        serialNumber={machineData.machineDetailsData.serialNumber}
                        systemType={machineData.machineDetailsData.systemType}
                        loading={ordersLoading}
                      />
                      <Divider style={{ margin: '5% 0' }} />
                      <UnitsChartMemo
                        currentYear={machineData.unitsData.currentYear}
                        lastYear={machineData.unitsData.lastYear}
                        serialNumber={machineData.machineDetailsData.serialNumber}
                        systemType={machineData.machineDetailsData.systemType}
                        loading={ordersLoading}
                      />
                    </>
                  )}
                </Paper>
              </Grid>

              {/* Varieties Card */}
              <Grid item md={4} style={{ minWidth: '320px' }}>
                <Paper elevation={3} style={{ padding: '10px', height: '100%' }}>
                  {ordersLoading ? (
                    <Skeleton variant="rectangular" height="100%" />
                  ) : (
                    <VarietiesCardMemo
                      topVarieties={machineData.varietiesData.topVarieties}
                      year={machineData.varietiesData.currentYear}
                    />
                  )}
                </Paper>
              </Grid>

              {/* Customers Card */}
              <Grid item md={4} style={{ minWidth: '320px' }}>
                <Paper elevation={3} style={{ padding: '10px', height: '100%' }}>
                  {ordersLoading ? (
                    <Skeleton variant="rectangular" height="100%" />
                  ) : (
                    <CustomersCardMemo
                      topCustomers={machineData.customersData.topCustomers}
                      year={machineData.customersData.currentYear}
                    />
                  )}
                </Paper>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MachineDashboardPage;
