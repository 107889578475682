const seedVarietyFilters = [
  {
    label: 'Seed Variety',
    pluralLabel: 'Seed Varieties',
    selector: 'seed_variety',
    type: 'select',
    optionSelector: 'seed_variety_name_list',
  },
  {
    label: 'Crop',
    pluralLabel: 'Crops',
    selector: 'crops',
    type: 'select',
    optionSelector: 'crop_name_list',
  },
  {
    label: 'Customer',
    pluralLabel: 'Customers',
    selector: 'customers',
    type: 'select',
    optionSelector: 'customer_name_list',
  },
];

export default seedVarietyFilters;
