import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface CustomerIntegrationsAutoTreatContextInterface {
  fetchCustomerIntegrationsAutoTreat?: (
    machineSerial: string,
    customerId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  createCustomerIntegrationAutoTreat?: (
    machineSerial: string,
    customerId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  updateCustomerIntegrationAutoTreat?: (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => Promise<void>;

  deleteCustomerIntegrationAutoTreat?: (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  customerIntegrations?: any[];
  customerIntegrationsLoading?: boolean;

  setCustomerIntegrationsSubmitting?: Dispatch<SetStateAction<boolean>>;
  customerIntegrationsSubmitting?: boolean;
}

const CustomerIntegrationsAutoTreatContext = React.createContext<CustomerIntegrationsAutoTreatContextInterface>({});

const CustomerIntegrationsAutoTreatContextConsumer = CustomerIntegrationsAutoTreatContext.Consumer;
const CustomerIntegrationsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [customerIntegrations, setCustomerIntegrations] = React.useState([]);
  const [customerIntegrationsLoading, setCustomerIntegrationsLoading] = React.useState(true);
  const [customerIntegrationsSubmitting, setCustomerIntegrationsSubmitting] = React.useState(false);

  const fetchCustomerIntegrationsAutoTreat = async (
    machineSerial: string,
    customerId: string,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/customer_integrations/?serial-number=${machineSerial}&customer_id=${customerId}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customer_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              if (retryCount < MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                setCustomerIntegrationsLoading(false);
                setCustomerIntegrations(response.data.results);
                retryCount += 1;
              } else {
                setCustomerIntegrationsLoading(false);
                setCustomerIntegrations([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createCustomerIntegrationAutoTreat = async (
    machineSerial: string,
    customerId: string,
    formValues?: Record<string, string>,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsLoading(true);
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/customer_integrations/?serial-number=${machineSerial}&customer_id=${customerId}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customer_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoTreat(machineSerial, customerId);
              setCustomerIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsLoading(false);
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateCustomerIntegrationAutoTreat = async (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    formValues?: Record<string, string>,
  ) => {
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/customer_integrations/${integrationId}/?serial-number=${machineSerial}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customer_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoTreat(machineSerial, customerId);
              setCustomerIntegrationsSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteCustomerIntegrationAutoTreat = async (
    machineSerial: string,
    customerId: string,
    integrationId: string,
    onSuccess?: () => void,
  ) => {
    setCustomerIntegrationsSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autotreat/onprem/customer_integrations/${integrationId}/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customer_integrations/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              fetchCustomerIntegrationsAutoTreat(machineSerial, customerId);
              showMachineManagementSnackBar(response.data.results[0]);
              setCustomerIntegrationsSubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('No response from machine');
                setCustomerIntegrationsSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <CustomerIntegrationsAutoTreatContext.Provider
      value={{
        fetchCustomerIntegrationsAutoTreat,
        createCustomerIntegrationAutoTreat,
        updateCustomerIntegrationAutoTreat,
        deleteCustomerIntegrationAutoTreat,
        customerIntegrations,
        customerIntegrationsLoading,

        setCustomerIntegrationsSubmitting,
        customerIntegrationsSubmitting,
      }}
    >
      {children}
    </CustomerIntegrationsAutoTreatContext.Provider>
  );
};

export {
  CustomerIntegrationsAutoTreatContextProvider,
  CustomerIntegrationsAutoTreatContextConsumer,
  CustomerIntegrationsAutoTreatContext,
};
