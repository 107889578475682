import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../../modules/utils/axios.utils';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface CustomersAutoBulkImportContextInterface {
  sendCustomersBulkAutoBulk?: (
    machine_serial_number: string,
    csvData: any,
    apiPartner: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  fetchRecordStatus?: (formValues: []) => Promise<void>;

  customerBulkSubmiting?: boolean;
  setCustomerBulkSubmiting?: Dispatch<SetStateAction<boolean>>;

  passedRecords?: any;
  setPassedRecords?: any;

  failedRecords?: any;
  setFailedRecords?: any;
}

const CustomersAutoBulkImportContext = React.createContext<CustomersAutoBulkImportContextInterface>({});

const CustomersAutoBulkImportContextConsumer = CustomersAutoBulkImportContext.Consumer;
const CustomersAutoBulkImportContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { showErrorSnackBar, showSuccessSnackBar } = React.useContext(SnackBarContext);

  const [passedRecords, setPassedRecords] = React.useState([]);
  const [failedRecords, setFailedRecords] = React.useState([]);

  const sendCustomersBulkAutoBulk = async (
    machine_serial_number: string,
    csvData: any,
    apiPartner: string,
    onSuccess?: () => void,
  ) => {
    showSuccessSnackBar('Submitting customers to machine...');

    let apiPartnerParam = '';
    if (apiPartner) {
      apiPartnerParam = `&api_partner=${apiPartner}`;
    }

    axios
      .post<any, any>(
        `api/autobulk/onprem/customers_bulk/?serial-number=${machine_serial_number}${apiPartnerParam}`,
        { ...csvData },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        if (onSuccess) {
          onSuccess();
        } else {
          history.push(`/machine_requests/${res.data.result}/machine_request_records`);
        }
      })
      .catch((error) => {
        showErrorSnackBar('Something went wrong, please check your machine status and try again.');
      });
  };

  return (
    <CustomersAutoBulkImportContext.Provider
      value={{
        sendCustomersBulkAutoBulk,

        passedRecords,
        setPassedRecords,

        failedRecords,
        setFailedRecords,
      }}
    >
      {children}
    </CustomersAutoBulkImportContext.Provider>
  );
};

export {
  CustomersAutoBulkImportContextProvider,
  CustomersAutoBulkImportContextConsumer,
  CustomersAutoBulkImportContext,
};
