/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_ZERO_AUDIENCE } from '../../../modules/utils/env';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import ProductAutoBulkForm from '../components/ProductsAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';

const ProductsAutoBulkCreate: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchProductsAutoBulk, setCreatingPage, activeMachine } = React.useContext(ProductsAutoBulkContext);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchProductsAutoBulk]);

  const links = [
    {
      onClick: () => setCreatingPage(false),
      text: `${activeMachine?.value}`,
    },
    {
      onClick: () => null,
      text: `Create Product`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => setCreatingPage(false)}
          >
            ← Return to Product List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreatingPage(false);
              // setFitlerBarActive(true);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create Product
      </Typography>
      <br />
      <ProductAutoBulkForm product={{}} machineSerial={activeMachine?.value} onSuccess={() => setCreatingPage(false)} />
    </>
  );
};

export default ProductsAutoBulkCreate;
