import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Typography, Box, CircularProgress } from '@mui/material';
import NewUserForm from '../components/UserForm';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { UsersContext } from '../contexts/UsersContext';
import { AUTH_ZERO_AUDIENCE } from '../../utils/env';

const UserNewPage: FunctionComponent = () => {
  const history = useHistory();
  const { companyId } = useParams<Record<string, string>>();
  const { fetchCompanyOptions, companiesLoading } = React.useContext(CompaniesContext);
  const { fetchUserPermissionGroups, fetchAdminPermissionGroups } = React.useContext(UsersContext);

  React.useEffect(() => {
    fetchCompanyOptions();
    fetchUserPermissionGroups();
    fetchAdminPermissionGroups();
  }, []);

  return companiesLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px' }}>Loading form...</h2>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/admin/user_management`)}>
            Back
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h1" component="h1">
        Create User
      </Typography>
      <br />
      <NewUserForm user={{ company: companyId }} creatingUser />
    </>
  );
};

export default UserNewPage;
