import React, { useState, useEffect } from 'react';
import { Button, Grid, FormControlLabel, Checkbox, Typography } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { OrdersAutoTreatFormValues } from '../model';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import OrdersAutoTreatStatusBanner from './OrdersAutoTreatStatusBanner';

interface OrdersAutoTreatFormProps {
  order: any;
  onSuccess?: () => void;
  onBack?: () => void;
}

const OrdersAutoTreatForm: React.FC<OrdersAutoTreatFormProps> = ({ order, onSuccess, onBack }) => {
  const { activeMachine, orderSubmitting, updateOrderAutoTreat, createOrderAutoTreat, ordersOptions } =
    React.useContext(OrdersAutoTreatContext);
  const [formValues, setFormValues] = useState<OrdersAutoTreatFormValues>({});
  const [autoGenerateOrderName, setAutoGenerateOrderName] = useState(!order?.order_id);
  const [isOrderStarted, setIsOrderStarted] = useState(false);
  const [isOrderAPI, setIsOrderAPI] = useState(false);

  const findCustomerDetails = () => {
    const customer = ordersOptions.customer_choices.find((c) => c.value === formValues.customer_id);
    return customer
      ? { customer_first_name: customer.customer_first_name, customer_last_name: customer.customer_last_name }
      : { customer_first_name: '', customer_last_name: '' };
  };

  const findSeedVarietyDetails = () => {
    const variety = ordersOptions.seed_variety_choices.find((v) => v.value === formValues.seed_variety_id);
    return variety
      ? { description: variety.description, crop_name: variety.crop_name }
      : { description: '', crop_name: '' };
  };

  const findLiquidRecipeDetails = () => {
    const recipe = ordersOptions.liquid_recipe_choices.find((r) => r.value === formValues.liquid_recipe_id);
    return recipe
      ? { description: recipe.description, crop_name: recipe.crop_name }
      : { description: '', crop_name: '' };
  };

  const amountOrderedInvalid = () => {
    if (formValues?.seed_amount_ordered !== '' && formValues?.seed_amount_ordered_type === '') {
      return 'Amount Ordered Type is required when Amount Ordered is set.';
    }

    if (formValues?.seed_amount_ordered === '' && formValues?.seed_amount_ordered_type !== '') {
      return 'Amount Ordered is required when Amount Ordered Type is set.';
    }

    return '';
  };

  useEffect(() => {
    setFormValues({
      order_id: order?.order_id,
      order_name: order?.order_name,
      order_desc: order?.order_desc,
      comment: order?.comment,
      seed_amount_ordered: order?.seed_amount_ordered || '',
      seed_amount_ordered_type: order?.seed_amount_ordered_type || '',
      customer_id: order?.customer_id,
      seed_variety_id: order?.seed_variety_id,
      liquid_recipe_id: order?.liquid_recipe_id,
      active: order?.active === 'True' || !order?.active ? '1' : '0',
      date_and_time_last_modified: order?.date_and_time_last_modified,
      seed_variety_id_previous: order?.seed_variety_id_previous,
    });
    setIsOrderStarted(!!order?.pu_date_and_time);

    // Convert the string value of api_linked to a boolean.
    // This is necessary because api_linked is received as a string ("true" or "false"),
    // and needs to be explicitly compared to 'true' to get the correct boolean value.
    const apiLinked = order.api_linked?.toLowerCase() === 'true';
    setIsOrderAPI(apiLinked);
  }, [order]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoGenerateOrderName(event.target.checked);
    if (event.target.checked) {
      setFormValues({ ...formValues, order_name: 'Auto Generate' });
    } else {
      setFormValues({ ...formValues, order_name: '' });
    }
  };

  const handleUpdate = () => {
    updateOrderAutoTreat(activeMachine.value, order.order_id, formValues);
  };

  const handleCreate = () => {
    createOrderAutoTreat(activeMachine.value, formValues, onSuccess);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OrdersAutoTreatStatusBanner isOrderStarted={isOrderStarted} isOrderAPI={isOrderAPI} />
        <form noValidate autoComplete="off">
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={5}>
              {!order.order_id && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoGenerateOrderName}
                      onChange={handleCheckboxChange}
                      name="autoGenerateOrderName"
                    />
                  }
                  label="Auto Generate Order Name"
                />
              )}
              <FormInput
                value={formValues?.order_name || 'Auto Generate'}
                onChange={(value) => setFormValues({ ...formValues, order_name: value })}
                label="Order Name"
                disabled={autoGenerateOrderName || isOrderStarted || isOrderAPI}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.comment}
                onChange={(value) => setFormValues({ ...formValues, comment: value })}
                label="Order Comment"
                disabled={isOrderStarted || isOrderAPI}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.customer_id}
                onChange={(value) => setFormValues({ ...formValues, customer_id: value })}
                label="Customer"
                choices={ordersOptions.customer_choices}
                disabled={isOrderStarted || isOrderAPI}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                First Name: {findCustomerDetails().customer_first_name}
              </Typography>
              <Typography variant="body2">Last Name: {findCustomerDetails().customer_last_name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.liquid_recipe_id}
                onChange={(value) => setFormValues({ ...formValues, liquid_recipe_id: value })}
                label="Recipe"
                choices={ordersOptions.liquid_recipe_choices}
                disabled={isOrderStarted || isOrderAPI}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                Description: {findLiquidRecipeDetails().description}
              </Typography>
              <Typography variant="body2">Crop Name: {findLiquidRecipeDetails().crop_name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.seed_variety_id}
                onChange={(value) => setFormValues({ ...formValues, seed_variety_id: value })}
                label="Variety"
                choices={ordersOptions.seed_variety_choices}
                disabled={isOrderStarted || isOrderAPI}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                Description: {findSeedVarietyDetails().description}
              </Typography>
              <Typography variant="body2">Crop Name: {findSeedVarietyDetails().crop_name}</Typography>
            </Grid>
            {/* here for correct spacing on form */}
            {/* eslint-disable-next-line react/self-closing-comp */}
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_amount_ordered}
                onChange={(value) => setFormValues({ ...formValues, seed_amount_ordered: value })}
                label="Amount Ordered"
                errorMessage={amountOrderedInvalid()}
                disabled={isOrderStarted || isOrderAPI}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.seed_amount_ordered_type}
                onChange={(value) => setFormValues({ ...formValues, seed_amount_ordered_type: value })}
                label="Amount Ordered Type"
                errorMessage={amountOrderedInvalid()}
                choices={[
                  { label: 'Units', value: 'units' },
                  { label: 'Weight', value: 'weight' },
                ]}
                disabled={isOrderStarted || isOrderAPI}
              />
            </Grid>
          </Grid>
          <br />
          {activeMachine && (
            <>
              {order?.order_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={isOrderStarted || isOrderAPI || amountOrderedInvalid()?.length > 0}
                >
                  {orderSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreate}
                  disabled={amountOrderedInvalid()?.length > 0}
                >
                  {orderSubmitting ? 'Creating...' : 'Create'}
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: '13px' }}>
                Cancel
              </Button>
            </>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default OrdersAutoTreatForm;
